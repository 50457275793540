<template>
  <!-- 行程单信息，凸显内控，每次使用都会更新项目余额 -->
  <!-- <van-panel :title="title?'行程信息':null" v-if="travelInfo"> -->
  <div>
    <van-cell v-if="title">
      <template #title>
        <span class="list-title">行程信息</span>
      </template>
    </van-cell>
    <van-cell title="申请单号" v-if="travelInfo.appId" :value="travelInfo.appId" class="auto-cell" />
    <van-cell title="行程限额" class="auto-cell">
      <template #default>
        <span>
          ￥{{travelInfo.appPrice}}
          <span v-if="travelInfo.appSurplus">
            (剩余
            <span
              :style="'color:'+(travelInfo.appSurplus>=totalPrice?'green':'red')"
            >￥{{travelInfo.appSurplus}}</span>)
          </span>
        </span>
      </template>
    </van-cell>
    <van-cell
      title="申请事由"
      v-if="travelInfo.appReason&&title"
      :value="travelInfo.appReason"
      class="auto-cell"
    />
    <van-cell
      title="经办人"
      v-if="travelInfo.loginUser.username&&title"
      :value="travelInfo.loginUser.username"
      class="auto-cell"
    />
    <van-cell
      title="出行性质"
      v-if="travelInfo.travelType"
      :value="travelInfo.travelType"
      class="auto-cell"
    />
    <van-cell title="经费项目" v-if="travelInfo.appProject" class="auto-cell">
      <template #default>{{travelInfo.appProject}}</template>
    </van-cell>
  <!-- </van-panel> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "infoList",
  props: {
    // 总票价
    totalPrice: {
      default: 0,
    },
    // 是否显示标题
    title:{
      default: true
    },
  },
  // 每次用到这个组件都更新一下余额
  async mounted() {
    await this.$store.dispatch("project/updateAppPrice");
    this.$forceUpdate();
  },
  computed: {
    ...mapGetters(["travelInfo"]),
  },
  watch: {
    async travelInfo(val) {
      await this.$store.dispatch("project/updateAppPrice");
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="less" src="@/styles/orderHeadCommon.less" scoped></style>