<template>
  <!-- 列表条目1-结束日期 -->
  <van-step class="node">
    <template #inactive-icon>
      <Icon :classification="val.classification" />
    </template>
    <!-- 左侧时间区域 -->
    <div class="time">
      <div class="MMDD nowrap list-text-big">{{val.departDate | comverTime('MM-DD')}}</div>
    </div>
    <!-- 右侧信息区域 -->
    <van-row class="contain" type="flex" justify="space-between">
      <div style="max-width: calc(100% - 50px)" class="text-sm footer" @click="$emit('golist')">{{val.msg}}</div>
      <van-col class="col">
        <van-icon class="red del-icon" name="delete" @click="del" />
      </van-col>
    </van-row>
  </van-step>
</template>

<script>
import Icon from "./Icon";
export default {
  name: "PolError",
  components: { Icon },
  props: ["val", "peoInfo"],
  methods: {
    // 删除
    del() {
      this.$dialog
        .confirm({
          title: "提示",
          closeOnClickOverlay: true,
          message: "是否要删除该行程？",
        })
        .then(() => {
          this.$emit("del");
        });
    },
  },
};
</script>
<style lang="less" src="@/styles/polymerization.less" scoped></style>
