
const getters = {
  personList: state => state.project.personList, // 出行人列表
  travelInfo: state => state.project.travelInfo, // 行程单信息
  loginParam: state => state.project.loginParam, // 行程单信息
  planRefresh: state => state.project.planRefresh, // 是否刷新订单列表
  config_controlLevel: state => state.project.config_controlLevel, // 管控等级

  // 火车
  tavelList12306: state => state.train.tavelList12306, // 12306常旅客用到的数据
  orderInfo: state => state.train.orderInfo, // 查找火车表单信息（时间，地点）
  ticket: state => state.train.ticket, // 已选车票
  trainchange: state => { // 改签信息
    let data = state.train.trainchange
    state.project.personList.forEach(ele => {
      if (ele.usercode == data.peo.passengerNo) {
        Object.assign(data.peo, ele)
      }
    })
    return data
  },
  originalOrderNo: state => state.train.originalOrderNo, // 原单号（改签用）
  originalprice: state => state.train.originalprice, // 原单价格（改签估价用）

  // 酒店
  hotelOrderInfo: state => state.hotel.hotelOrderInfo, // 查找酒店表单信息（时间，地点，名称）
  hotelticket: state => state.hotel.hotelticket, // 已选酒店
  roomticket: state => state.hotel.roomticket, // 已选房型

  // 飞机
  aircraftOrderInfo: state => state.aircraft.aircraftOrderInfo, // 查找机票表单信息（时间，地点）
  aircraftTicket: state => state.aircraft.aircraftTicket, // 已选机票
  aircraftOrderChange: state => state.aircraft.aircraftOrderChange, // 改签单信息

  // 订单
  orderItem: state => state.history.orderItem, // 订单信息
  nextOrderItem: state => state.history.nextOrderItem, // 下一订单
  orderItemChange: state => state.history.orderItemChange, // 改签单
  
  // 智能规划
  polyList: state => state.polymerization.polyList, // 改签单
}
export default getters