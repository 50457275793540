// 静态机场列表
const CHINA_AIRCRAFT_LIST = {
    'hot': [
        { "id": "12236", "code": "PEK", "name": "北京", "englishiName": "BEIJING", "pinYin": "beijing", "pinYinShort": "bj" },
        { "id": "18482", "code": "SHA", "name": "上海", "englishiName": "SHANGHAI", "pinYin": "shanghai", "pinYinShort": "SH" },
        { "id": "14314", "code": "CAN", "name": "广州", "englishiName": "GUANGZHOU", "pinYin": "guangzhou", "pinYinShort": "GZ" },
        { "id": "18513", "code": "SZX", "name": "深圳", "englishiName": "SHENZHEN", "pinYin": "shenzhen", "pinYinShort": "SZ" },
        { "id": "12941", "code": "CTU", "name": "成都", "englishiName": "CHENGDU", "pinYin": "chengdu", "pinYinShort": "CD" },
        { "id": "19198", "code": "TSN", "name": "天津", "englishiName": "TIANJIN", "pinYin": "tianjin", "pinYinShort": "TJ" },
        { "id": "13020", "code": "CKG", "name": "重庆", "englishiName": "CHONGQING", "pinYin": "chongqing", "pinYinShort": "CQ" },
        { "id": "19941", "code": "WUH", "name": "武汉", "englishiName": "WUHAN", "pinYin": "wuhan", "pinYinShort": "WH" },
        { "id": "12903", "code": "CSX", "name": "长沙", "englishiName": "CHANGSHA", "pinYin": "changsha", "pinYinShort": "CS" },
        { "id": "16782", "code": "NKG", "name": "南京", "englishiName": "NANJING", "pinYin": "nanjing", "pinYinShort": "NJ" },
        { "id": "14410", "code": "HGH", "name": "杭州", "englishiName": "HANGZHOU", "pinYin": "hangzhou", "pinYinShort": "HZ" },
        { "id": "15461", "code": "KMG", "name": "昆明", "englishiName": "KUNMING", "pinYin": "kunming", "pinYinShort": "KM" },
        { "id": "20093", "code": "CGO", "name": "郑州", "englishiName": "ZHENGZHOU", "pinYin": "zhengzhou", "pinYinShort": "ZZ" },
        { "id": "14885", "code": "TNA", "name": "济南", "englishiName": "JINAN", "pinYin": "jinan", "pinYinShort": "JN" },
        { "id": "20673", "code": "FOC", "name": "福州", "englishiName": "FUZHOU", "pinYin": "fuzhou", "pinYinShort": "FZ" },
        { "id": "17841", "code": "TAO", "name": "青岛", "englishiName": "QINGDAO", "pinYin": "qingdao", "pinYinShort": "QD" },
        { "id": "19955", "code": "XMN", "name": "厦门", "englishiName": "XIAMEN", "pinYin": "xiamen", "pinYinShort": "XM" },
        { "id": "19199", "code": "XIY", "name": "西安", "englishiName": "XIAN", "pinYin": "xian", "pinYinShort": "XA" },
        { "id": "13326", "code": "DLC", "name": "大连", "englishiName": "DALIAN", "pinYin": "dalian", "pinYinShort": "DL" },
        { "id": "18328", "code": "SYX", "name": "三亚", "englishiName": "SANYA", "pinYin": "sanya", "pinYinShort": "SY" },
    ],
    A: [
        { "id": "23430", "code": "YIE", "name": "阿尔山", "englishiName": "AERSHAN", "pinYin": "aershan", "pinYinShort": "AES" },
        { "id": "20135", "code": "AKU", "name": "阿克苏", "englishiName": "AKSU", "pinYin": "akesu", "pinYinShort": "AKS" },
        { "id": "23709", "code": "RHT", "name": "阿拉善右旗", "englishiName": "ALXA RIGHT BANNER", "pinYin": "alashanyouqi", "pinYinShort": "ALSYQ" },
        { "id": "23677", "code": "AXF", "name": "阿拉善左旗", "englishiName": "ALXA LEFT BANNER", "pinYin": "alashanzuoqi", "pinYinShort": "ALSZQ" },
        { "id": "20136", "code": "AAT", "name": "阿勒泰", "englishiName": "ALTAY", "pinYin": "aletai", "pinYinShort": "ALT" },
        { "id": "23421", "code": "NGQ", "name": "阿里", "englishiName": "SHIQUANDE", "pinYin": "ali", "pinYinShort": "AL" },
        { "id": "11780", "code": "AKA", "name": "安康", "englishiName": "ANKANG", "pinYin": "ankang", "pinYinShort": "AK" },
        { "id": "11795", "code": "AQG", "name": "安庆", "englishiName": "ANQING", "pinYin": "anqing", "pinYinShort": "AQ" },
        { "id": "11796", "code": "AOG", "name": "鞍山", "englishiName": "ANSHAN", "pinYin": "anshan", "pinYinShort": "AS" },
        { "id": "20140", "code": "AVA", "name": "安顺", "englishiName": "ANSHUN", "pinYin": "anshun", "pinYinShort": "AS" },
        { "id": "11814", "code": "AYN", "name": "安阳", "englishiName": "ANYANG", "pinYin": "anyang", "pinYinShort": "AY" },
    ],
    B: [
        { "id": "12497", "code": "AEB", "name": "百色", "englishiName": "BAISE", "pinYin": "baise", "pinYinShort": "BS" },
        { "id": "20119", "code": "NBS", "name": "白山", "englishiName": "BAISHAN", "pinYin": "baishan", "pinYinShort": "BS" },
        { "id": "20145", "code": "BFU", "name": "蚌埠", "englishiName": "BENGBU", "pinYin": "bangbu", "pinYinShort": "BB" },
        { "id": "12115", "code": "BSD", "name": "保山", "englishiName": "BAOSHAN", "pinYin": "baoshan", "pinYinShort": "BS" },
        { "id": "12116", "code": "BAV", "name": "包头", "englishiName": "BAOTOU", "pinYin": "baotou", "pinYinShort": "BT" },
        { "id": "20818", "code": "RLK", "name": "巴彦淖尔", "englishiName": "BAYANNUR", "pinYin": "bayannaoer", "pinYinShort": "BYNE" },
        { "id": "12234", "code": "BHY", "name": "北海", "englishiName": "BEIHAI", "pinYin": "beihai", "pinYinShort": "BH" },
        { "id": "12236", "code": "PEK", "name": "北京", "englishiName": "BEIJING", "pinYin": "beijing", "pinYinShort": "bj" },
        { "id": "22739", "code": "BFJ", "name": "毕节", "englishiName": "BIJIE", "pinYin": "bijie", "pinYinShort": "BJ" },
        { "id": "20705", "code": "BPL", "name": "博乐", "englishiName": "BOLE", "pinYin": "bole", "pinYinShort": "BL" },
        { "id": "15028", "code": "KJI", "name": "布尔津", "englishiName": "BURQIN", "pinYin": "buerjin", "pinYinShort": "BEJ" },
    ],
    C: [
        { "id": "12900", "code": "CGQ", "name": "长春", "englishiName": "CHANGCHUN", "pinYin": "changchun", "pinYinShort": "CC" },
        { "id": "12901", "code": "CGD", "name": "常德", "englishiName": "CHANGDE", "pinYin": "changde", "pinYinShort": "CD" },
        { "id": "20589", "code": "BPX", "name": "昌都", "englishiName": "QAMDO/CHANGDU", "pinYin": "changdu", "pinYinShort": "CD" },
        { "id": "12902", "code": "CNI", "name": "长海", "englishiName": "CHANGHAI", "pinYin": "changhai", "pinYinShort": "CH" },
        { "id": "12903", "code": "CSX", "name": "长沙", "englishiName": "CHANGSHA", "pinYin": "changsha", "pinYinShort": "CS" },
        { "id": "12905", "code": "CIH", "name": "长治", "englishiName": "CHANGZHI", "pinYin": "changzhi", "pinYinShort": "CZ" },
        { "id": "12906", "code": "CZX", "name": "常州", "englishiName": "CHANGZHOU", "pinYin": "changzhou", "pinYinShort": "CZ" },
        { "id": "20827", "code": "CHG", "name": "朝阳", "englishiName": "CHAOYANG", "pinYin": "chaoyang", "pinYinShort": "CY" },
        { "id": "20168", "code": "CDE", "name": "承德", "englishiName": "CHENGDE", "pinYin": "chengde", "pinYinShort": "CD" },
        { "id": "12941", "code": "CTU", "name": "成都", "englishiName": "CHENGDU", "pinYin": "chengdu", "pinYinShort": "CD" },
        { "id": "12976", "code": "CIF", "name": "赤峰", "englishiName": "CHIFENG", "pinYin": "chifeng", "pinYinShort": "CF" },
        { "id": "23437", "code": "JUH", "name": "池州", "englishiName": "CHIZHOU", "pinYin": "chizhou", "pinYinShort": "CZ" },
        { "id": "13020", "code": "CKG", "name": "重庆", "englishiName": "CHONGQING", "pinYin": "chongqing", "pinYinShort": "CQ" },
    ],
    D: [
        { "id": "20180", "code": "DLU", "name": "大理", "englishiName": "DALI", "pinYin": "dali", "pinYinShort": "DL" },
        { "id": "13326", "code": "DLC", "name": "大连", "englishiName": "DALIAN", "pinYin": "dalian", "pinYinShort": "DL" },
        { "id": "13339", "code": "DDG", "name": "丹东", "englishiName": "DANDONG", "pinYin": "dandong", "pinYinShort": "DD" },
        { "id": "23489", "code": "DCY", "name": "稻城", "englishiName": "DAOCHENG", "pinYin": "daocheng", "pinYinShort": "DC" },
        { "id": "20841", "code": "DQA", "name": "大庆", "englishiName": "DAQING", "pinYin": "daqing", "pinYinShort": "DQ" },
        { "id": "13360", "code": "DAT", "name": "大同", "englishiName": "DATONG", "pinYin": "datong", "pinYinShort": "DT" },
        { "id": "20177", "code": "DAX", "name": "达州", "englishiName": "DAZHOU", "pinYin": "dazhou", "pinYinShort": "DZ" },
        { "id": "13381", "code": "DZU", "name": "大足", "englishiName": "DAZU", "pinYin": "dazu", "pinYinShort": "DZ" },
        { "id": "23547", "code": "HXD", "name": "德令哈", "englishiName": "DELINGHA", "pinYin": "delingha", "pinYinShort": "DLH" },
        { "id": "13478", "code": "DIG", "name": "迪庆", "englishiName": "DEQEN", "pinYin": "diqing", "pinYinShort": "DQ" },
        { "id": "13517", "code": "DOY", "name": "东营", "englishiName": "DONGYING", "pinYin": "dongying", "pinYinShort": "DY" },
        { "id": "13570", "code": "DNH", "name": "敦煌", "englishiName": "DUNHUANG", "pinYin": "dunhuang", "pinYinShort": "DH" },
    ],
    E: [
        { "id": "23497", "code": "DSN", "name": "鄂尔多斯", "englishiName": "ORDOS", "pinYin": "eerduosi", "pinYinShort": "EEDS" },
        { "id": "13719", "code": "ENH", "name": "恩施", "englishiName": "ENSHI", "pinYin": "enshi", "pinYinShort": "ES" },
        { "id": "20205", "code": "ERL", "name": "二连浩特", "englishiName": "ERENHOT", "pinYin": "erlianhaote", "pinYinShort": "ELHT" },
    ],
    F: [
        { "id": "13956", "code": "FUO", "name": "佛山", "englishiName": "FOSHAN", "pinYin": "foshan", "pinYinShort": "FS" },
        { "id": "14013", "code": "FUG", "name": "阜阳", "englishiName": "FUYANG", "pinYin": "fuyang", "pinYinShort": "FY" },
        { "id": "23624", "code": "FYJ", "name": "抚远", "englishiName": "FUYUAN", "pinYin": "fuyuan", "pinYinShort": "FY" },
        { "id": "14014", "code": "FYN", "name": "富蕴", "englishiName": "FUYUN", "pinYin": "fuyun", "pinYinShort": "FY" },
        { "id": "20673", "code": "FOC", "name": "福州", "englishiName": "FUZHOU", "pinYin": "fuzhou", "pinYinShort": "FZ" },
    ],
    G: [
        { "id": "14056", "code": "KOW", "name": "赣州", "englishiName": "GANZHOU", "pinYin": "ganzhou", "pinYinShort": "GZ" },
        { "id": "20220", "code": "GOQ", "name": "格尔木", "englishiName": "GOLMUD", "pinYin": "geermu", "pinYinShort": "GEM" },
        { "id": "14311", "code": "GHN", "name": "广汉", "englishiName": "GUANGHAN", "pinYin": "guanghan", "pinYinShort": "GH" },
        { "id": "14313", "code": "GYS", "name": "广元", "englishiName": "GUANGYUAN", "pinYin": "guangyuan", "pinYinShort": "GY" },
        { "id": "14314", "code": "CAN", "name": "广州", "englishiName": "GUANGZHOU", "pinYin": "guangzhou", "pinYinShort": "GZ" },
        { "id": "14335", "code": "KWL", "name": "桂林", "englishiName": "GUILIN", "pinYin": "guilin", "pinYinShort": "GL" },
        { "id": "14338", "code": "KWE", "name": "贵阳", "englishiName": "GUIYANG", "pinYin": "guiyang", "pinYinShort": "GY" },
        { "id": "23595", "code": "GMQ", "name": "果洛", "englishiName": "GOLOG", "pinYin": "guoluo", "pinYinShort": "GL" },
        { "id": "20668", "code": "GYU", "name": "固原", "englishiName": "GUYUAN", "pinYin": "guyuan", "pinYinShort": "GY" },
    ],
    H: [
        { "id": "20228", "code": "HRB", "name": "哈尔滨", "englishiName": "HARBIN", "pinYin": "haerbin", "pinYinShort": "HEB" },
        { "id": "14377", "code": "HAK", "name": "海口", "englishiName": "HAIKOU", "pinYin": "haikou", "pinYinShort": "HK" },
        { "id": "20249", "code": "HLD", "name": "海拉尔", "englishiName": "HAILAR", "pinYin": "hailaer", "pinYinShort": "HLE" },
        { "id": "14396", "code": "HMI", "name": "哈密", "englishiName": "HAMI", "pinYin": "hami", "pinYinShort": "HM" },
        { "id": "23417", "code": "HDG", "name": "邯郸", "englishiName": "HANDAN", "pinYin": "handan", "pinYinShort": "HD" },
        { "id": "14410", "code": "HGH", "name": "杭州", "englishiName": "HANGZHOU", "pinYin": "hangzhou", "pinYinShort": "HZ" },
        { "id": "14417", "code": "HZG", "name": "汉中", "englishiName": "HANZHONG", "pinYin": "hanzhong", "pinYinShort": "HZ" },
        { "id": "15924", "code": "HFE", "name": "合肥", "englishiName": "HEFEI", "pinYin": "hefei", "pinYinShort": "HF" },
        { "id": "14479", "code": "HEK", "name": "黑河", "englishiName": "HEIHE", "pinYin": "heihe", "pinYinShort": "HH" },
        { "id": "14491", "code": "HNY", "name": "衡阳", "englishiName": "HENGYANG", "pinYin": "hengyang", "pinYinShort": "HY" },
        { "id": "20238", "code": "HTN", "name": "和田", "englishiName": "HOTAN", "pinYin": "hetian", "pinYinShort": "HT" },
        { "id": "20133", "code": "AHJ", "name": "红原", "englishiName": "ABA HONGYUAN", "pinYin": "hongyuan", "pinYinShort": "HY" },
        { "id": "20709", "code": "HIA", "name": "淮安", "englishiName": "HUAI'AN", "pinYin": "huaian", "pinYinShort": "HA" },
        { "id": "20257", "code": "HJJ", "name": "怀化", "englishiName": "HUAIHUA", "pinYin": "huaihua", "pinYinShort": "HH" },
        { "id": "20264", "code": "TXN", "name": "黄山", "englishiName": "HUANGSHAN", "pinYin": "huangshan", "pinYinShort": "HS" },
        { "id": "23620", "code": "HTT", "name": "花土沟", "englishiName": "HUATUGOU", "pinYin": "huatugou", "pinYinShort": "HTG" },
        { "id": "20248", "code": "HET", "name": "呼和浩特", "englishiName": "HOHHOT", "pinYin": "huhehaote", "pinYinShort": "HHHT" },
        { "id": "14616", "code": "HUZ", "name": "惠州", "englishiName": "HUIZHOU", "pinYin": "huizhou", "pinYinShort": "HZ" },
        { "id": "23647", "code": "HUO", "name": "霍林郭勒", "englishiName": "HOULINGUOLE", "pinYin": "huolinguole", "pinYinShort": "HLGL" },
    ],
    I: [],
    J: [
        { "id": "23422", "code": "JGD", "name": "加格达奇", "englishiName": "JIAGEDAQI", "pinYin": "jiagedaqi", "pinYinShort": "JGDQ" },
        { "id": "14877", "code": "JMU", "name": "佳木斯", "englishiName": "JIAMUSI", "pinYin": "jiamusi", "pinYinShort": "JMS" },
        { "id": "14880", "code": "JGN", "name": "嘉峪关", "englishiName": "JIAYUGUAN", "pinYin": "jiayuguan", "pinYinShort": "JYG" },
        { "id": "20286", "code": "SWA", "name": "揭阳", "englishiName": "SHANTOU", "pinYin": "jieyang", "pinYinShort": "JY" },
        { "id": "14883", "code": "JIL", "name": "吉林", "englishiName": "JILIN", "pinYin": "jilin", "pinYinShort": "JL" },
        { "id": "14885", "code": "TNA", "name": "济南", "englishiName": "JINAN", "pinYin": "jinan", "pinYinShort": "JN" },
        { "id": "20291", "code": "JIC", "name": "金昌", "englishiName": "JINCHANG", "pinYin": "jinchang", "pinYinShort": "JC" },
        { "id": "14886", "code": "JDZ", "name": "景德镇", "englishiName": "JINGDEZHEN", "pinYin": "jingdezhen", "pinYinShort": "JDZ" },
        { "id": "20295", "code": "JGS", "name": "井冈山", "englishiName": "JI'AN", "pinYin": "jinggangshan", "pinYinShort": "JGS" },
        { "id": "14889", "code": "JNG", "name": "济宁", "englishiName": "JINING", "pinYin": "jining", "pinYinShort": "JN" },
        { "id": "14892", "code": "HIN", "name": "锦州", "englishiName": "JINZHOU", "pinYin": "jinzhou", "pinYinShort": "JZ" },
        { "id": "14899", "code": "JIU", "name": "九江", "englishiName": "JIUJIANG", "pinYin": "jiujiang", "pinYinShort": "JJ" },
        { "id": "20297", "code": "JZH", "name": "九寨沟", "englishiName": "JIUZHAIGOU", "pinYin": "jiuzhaigou", "pinYinShort": "JZG" },
        { "id": "20268", "code": "JXA", "name": "鸡西", "englishiName": "JIXI", "pinYin": "jixi", "pinYinShort": "JX" },
    ],
    K: [
        { "id": "23590", "code": "KJH", "name": "凯里", "englishiName": "HUANG PING", "pinYin": "kaili", "pinYinShort": "KL" },
        { "id": "20720", "code": "KGT", "name": "康定", "englishiName": "KANGDING", "pinYin": "kangding", "pinYinShort": "KD" },
        { "id": "15096", "code": "KHG", "name": "喀什", "englishiName": "KASHI", "pinYin": "kashi", "pinYinShort": "KS" },
        { "id": "20303", "code": "KRY", "name": "克拉玛依", "englishiName": "KARAMAY", "pinYin": "kelamayi", "pinYinShort": "KLMY" },
        { "id": "15468", "code": "KCA", "name": "库车", "englishiName": "KUQA", "pinYin": "kuche", "pinYinShort": "KC" },
        { "id": "20304", "code": "KRL", "name": "库尔勒", "englishiName": "KORLA", "pinYin": "kuerle", "pinYinShort": "KEL" },
        { "id": "15461", "code": "KMG", "name": "昆明", "englishiName": "KUNMING", "pinYin": "kunming", "pinYinShort": "KM" },
    ],
    L: [
        { "id": "23652", "code": "JMJ", "name": "澜沧", "englishiName": "LANCANG", "pinYin": "lancang", "pinYinShort": "LC" },
        { "id": "15625", "code": "LHW", "name": "兰州", "englishiName": "LANZHOU", "pinYin": "lanzhou", "pinYinShort": "LZ" },
        { "id": "20307", "code": "LXA", "name": "拉萨", "englishiName": "LHASA/LASA", "pinYin": "lasa", "pinYinShort": "LS" },
        { "id": "15737", "code": "LYG", "name": "连云港", "englishiName": "LIANYUNGANG", "pinYin": "lianyungang", "pinYinShort": "LYG" },
        { "id": "23642", "code": "LLB", "name": "荔波", "englishiName": "LIBO", "pinYin": "libo", "pinYinShort": "LB" },
        { "id": "20322", "code": "LJG", "name": "丽江", "englishiName": "LIJIANG", "pinYin": "lijiang", "pinYinShort": "LJ" },
        { "id": "15768", "code": "LNJ", "name": "临沧", "englishiName": "LINCANG", "pinYin": "lincang", "pinYinShort": "LC" },
        { "id": "23457", "code": "LFQ", "name": "临汾", "englishiName": "LINFEN", "pinYin": "linfen", "pinYinShort": "LF" },
        { "id": "15783", "code": "LYI", "name": "临沂", "englishiName": "LINYI", "pinYin": "linyi", "pinYinShort": "LY" },
        { "id": "15778", "code": "LZY", "name": "林芝", "englishiName": "NYINGCHI/LINZHI", "pinYin": "linzhi", "pinYinShort": "LZ" },
        { "id": "23685", "code": "HZH", "name": "黎平", "englishiName": "LIPING", "pinYin": "liping", "pinYinShort": "LP" },
        { "id": "23484", "code": "LPF", "name": "六盘水", "englishiName": "LIUPANSHUI", "pinYin": "liupanshui", "pinYinShort": "LPS" },
        { "id": "15797", "code": "LZH", "name": "柳州", "englishiName": "LIUZHOU", "pinYin": "liuzhou", "pinYinShort": "LZ" },
        { "id": "15853", "code": "LCX", "name": "龙岩", "englishiName": "LONGYAN", "pinYin": "longyan", "pinYinShort": "LY" },
        { "id": "15925", "code": "LYA", "name": "洛阳", "englishiName": "LUOYANG", "pinYin": "luoyang", "pinYinShort": "LY" },
        { "id": "15941", "code": "LZO", "name": "泸州", "englishiName": "LUZHOU", "pinYin": "luzhou", "pinYinShort": "LZ" },
    ],
    M: [
        { "id": "20618", "code": "LUM", "name": "芒市", "englishiName": "MANGSHI", "pinYin": "mangshi", "pinYinShort": "MS" },
        { "id": "16144", "code": "NZH", "name": "满洲里", "englishiName": "MANZHOULI", "pinYin": "manzhouli", "pinYinShort": "MZL" },
        { "id": "16314", "code": "MXZ", "name": "梅州", "englishiName": "MEIXIAN", "pinYin": "meizhou", "pinYinShort": "MZ" },
        { "id": "20359", "code": "MIG", "name": "绵阳", "englishiName": "MIANYANG", "pinYin": "mianyang", "pinYinShort": "MY" },
        { "id": "16490", "code": "OHE", "name": "漠河", "englishiName": "MOHE", "pinYin": "mohe", "pinYinShort": "MH" },
        { "id": "16655", "code": "MDG", "name": "牡丹江", "englishiName": "MUDANJIANG", "pinYin": "mudanjiang", "pinYinShort": "MDJ" },
    ],
    N: [
        { "id": "16772", "code": "KHN", "name": "南昌", "englishiName": "NANCHANG", "pinYin": "nanchang", "pinYinShort": "NC" },
        { "id": "16773", "code": "NAO", "name": "南充", "englishiName": "NANCHONG", "pinYin": "nanchong", "pinYinShort": "NC" },
        { "id": "16782", "code": "NKG", "name": "南京", "englishiName": "NANJING", "pinYin": "nanjing", "pinYinShort": "NJ" },
        { "id": "16783", "code": "NNG", "name": "南宁", "englishiName": "NANNING", "pinYin": "nanning", "pinYinShort": "NN" },
        { "id": "16786", "code": "NTG", "name": "南通", "englishiName": "NANTONG", "pinYin": "nantong", "pinYinShort": "NT" },
        { "id": "16790", "code": "NNY", "name": "南阳", "englishiName": "NANYANG", "pinYin": "nanyang", "pinYinShort": "NY" },
        { "id": "16938", "code": "NGB", "name": "宁波", "englishiName": "NINGBO", "pinYin": "ningbo", "pinYinShort": "NB" },
        { "id": "23589", "code": "NLH", "name": "宁蒗", "englishiName": "NINGLANG", "pinYin": "ninglang", "pinYinShort": "NL" },
    ],
    O: [],
    P: [
        { "id": "20370", "code": "PZI", "name": "攀枝花", "englishiName": "PANZHIHUA", "pinYin": "panzhihua", "pinYinShort": "PZH" },
        { "id": "18583", "code": "SYM", "name": "普洱", "englishiName": "PUER", "pinYin": "puer", "pinYinShort": "PE" },
    ],
    Q: [
        { "id": "20387", "code": "JIQ", "name": "黔江", "englishiName": "QIANJIANG", "pinYin": "qianjiang", "pinYinShort": "QJ" },
        { "id": "17839", "code": "IQM", "name": "且末", "englishiName": "QIEMO", "pinYin": "qiemo", "pinYinShort": "QM" },
        { "id": "17841", "code": "TAO", "name": "青岛", "englishiName": "QINGDAO", "pinYin": "qingdao", "pinYinShort": "QD" },
        { "id": "17842", "code": "IQN", "name": "庆阳", "englishiName": "QINGYANG", "pinYin": "qingyang", "pinYinShort": "QY" },
        { "id": "17843", "code": "SHP", "name": "秦皇岛", "englishiName": "QINHAUNGDAO", "pinYin": "qinhuangdao", "pinYinShort": "QHD" },
        { "id": "23506", "code": "BAR", "name": "琼海", "englishiName": "QIONGHAI", "pinYin": "qionghai", "pinYinShort": "QH" },
        { "id": "20384", "code": "NDG", "name": "齐齐哈尔", "englishiName": "QIQIHAR", "pinYin": "qiqihaer", "pinYinShort": "QQHE" },
        { "id": "14891", "code": "JJN", "name": "泉州", "englishiName": "QUANZHOU", "pinYin": "quanzhou", "pinYinShort": "QZ" },
        { "id": "20397", "code": "JUZ", "name": "衢州", "englishiName": "QUZHOU", "pinYin": "quzhou", "pinYinShort": "QZ" },
    ],
    R: [
        { "id": "20401", "code": "RKZ", "name": "日喀则", "englishiName": "XIGAZE/RIKAZE", "pinYin": "rikaze", "pinYinShort": "RKZ" },
        { "id": "20402", "code": "RIZ", "name": "日照", "englishiName": "RIZHAO", "pinYin": "rizhao", "pinYinShort": "RZ" },
    ],
    S: [
        { "id": "23569", "code": "SQJ", "name": "三明", "englishiName": "SANMING", "pinYin": "sanming", "pinYinShort": "SM" },
        { "id": "18328", "code": "SYX", "name": "三亚", "englishiName": "SANYA", "pinYin": "sanya", "pinYinShort": "SY" },
        { "id": "23676", "code": "QSZ", "name": "莎车", "englishiName": "SHACHE", "pinYin": "shache", "pinYinShort": "sc" },
        { "id": "18482", "code": "SHA", "name": "上海", "englishiName": "SHANGHAI", "pinYin": "shanghai", "pinYinShort": "SH" },
        { "id": "20416", "code": "SQD", "name": "上饶", "englishiName": "SHANGRAO", "pinYin": "shangrao", "pinYinShort": "SR" },
        { "id": "18486", "code": "SXJ", "name": "鄯善", "englishiName": "SHANSHAN", "pinYin": "shanshan", "pinYinShort": "SS" },
        { "id": "23567", "code": "HPG", "name": "神农架", "englishiName": "SHENNONGJIA", "pinYin": "shennongjia", "pinYinShort": "SNJ" },
        { "id": "18512", "code": "SHE", "name": "沈阳", "englishiName": "SHENYANG", "pinYin": "shenyang", "pinYinShort": "SY" },
        { "id": "18513", "code": "SZX", "name": "深圳", "englishiName": "SHENZHEN", "pinYin": "shenzhen", "pinYinShort": "SZ" },
        { "id": "18519", "code": "SJW", "name": "石家庄", "englishiName": "SHIJIAZHUANG", "pinYin": "shijiazhuang", "pinYinShort": "SJZ" },
        { "id": "20425", "code": "WDS", "name": "十堰", "englishiName": "SHIYAN", "pinYin": "shiyan", "pinYinShort": "SY" },
    ],
    T: [
        { "id": "18950", "code": "TCG", "name": "塔城", "englishiName": "TACHENG", "pinYin": "tacheng", "pinYinShort": "TC" },
        { "id": "18975", "code": "TYN", "name": "太原", "englishiName": "TAIYUAN", "pinYin": "taiyuan", "pinYinShort": "TY" },
        { "id": "20695", "code": "HYN", "name": "台州", "englishiName": "TAIZHOU", "pinYin": "taizhou", "pinYinShort": "TZ" },
        { "id": "20708", "code": "TVS", "name": "唐山", "englishiName": "TANGSHAN", "pinYin": "tangshan", "pinYinShort": "TS" },
        { "id": "20130", "code": "TCZ", "name": "腾冲", "englishiName": "TENGCHONG", "pinYin": "tengchong", "pinYinShort": "TC" },
        { "id": "19198", "code": "TSN", "name": "天津", "englishiName": "TIANJIN", "pinYin": "tianjin", "pinYinShort": "TJ" },
        { "id": "20797", "code": "THQ", "name": "天水", "englishiName": "TIANSHUI", "pinYin": "tianshui", "pinYinShort": "TS" },
        { "id": "19285", "code": "TNH", "name": "通化", "englishiName": "TONGHUA", "pinYin": "tonghua", "pinYinShort": "TH" },
        { "id": "19286", "code": "TGO", "name": "通辽", "englishiName": "TONGLIAO", "pinYin": "tongliao", "pinYinShort": "TL" },
        { "id": "20840", "code": "TEN", "name": "铜仁", "englishiName": "TONGREN", "pinYin": "tongren", "pinYinShort": "TR" },
        { "id": "20844", "code": "TLQ", "name": "吐鲁番", "englishiName": "TURPAN", "pinYin": "tulufan", "pinYinShort": "TLF" },
    ],
    U: [],
    V: [],
    W: [
        { "id": "20648", "code": "WXN", "name": "万州", "englishiName": "WANZHOU", "pinYin": "wanzhou", "pinYinShort": "WZ" },
        { "id": "19797", "code": "WEF", "name": "潍坊", "englishiName": "WEIFANG", "pinYin": "weifang", "pinYinShort": "WF" },
        { "id": "19798", "code": "WEH", "name": "威海", "englishiName": "WEIHAI", "pinYin": "weihai", "pinYinShort": "WH" },
        { "id": "19808", "code": "WNH", "name": "文山", "englishiName": "WENSHAN", "pinYin": "wenshan", "pinYinShort": "WS" },
        { "id": "19809", "code": "WNZ", "name": "温州", "englishiName": "WENZHOU", "pinYin": "wenzhou", "pinYinShort": "WZ" },
        { "id": "20792", "code": "WUA", "name": "乌海", "englishiName": "WUHAI", "pinYin": "wuhai", "pinYinShort": "WH" },
        { "id": "19941", "code": "WUH", "name": "武汉", "englishiName": "WUHAN", "pinYin": "wuhan", "pinYinShort": "WH" },
        { "id": "19942", "code": "WHU", "name": "芜湖", "englishiName": "WUHU", "pinYin": "wuhu", "pinYinShort": "WH" },
        { "id": "23608", "code": "UCB", "name": "乌兰察布", "englishiName": "ULANQAB", "pinYin": "wulanchabu", "pinYinShort": "WLCB" },
        { "id": "20468", "code": "HLH", "name": "乌兰浩特", "englishiName": "ULANHOT", "pinYin": "wulanhaote", "pinYinShort": "WLHT" },
        { "id": "20469", "code": "URC", "name": "乌鲁木齐", "englishiName": "URUMQI", "pinYin": "wulumuqi", "pinYinShort": "WLMQ" },
        { "id": "19945", "code": "WUX", "name": "无锡", "englishiName": "WUXI", "pinYin": "wuxi", "pinYinShort": "WX" },
        { "id": "19946", "code": "WUS", "name": "武夷山", "englishiName": "WUYISHAN", "pinYin": "wuyishan", "pinYinShort": "WYS" },
        { "id": "19947", "code": "WUZ", "name": "梧州", "englishiName": "WUZHOU", "pinYin": "wuzhou", "pinYinShort": "WZ" },
    ],
    X: [
        { "id": "19955", "code": "XMN", "name": "厦门", "englishiName": "XIAMEN", "pinYin": "xiamen", "pinYinShort": "XM" },
        { "id": "19956", "code": "XFN", "name": "襄阳", "englishiName": "XIANGYANG", "pinYin": "xiangyang", "pinYinShort": "XY" },
        { "id": "19199", "code": "XIY", "name": "西安", "englishiName": "XIAN", "pinYin": "xian", "pinYinShort": "XA" },
        { "id": "19957", "code": "XIC", "name": "西昌", "englishiName": "XICHANG", "pinYin": "xichang", "pinYinShort": "XC" },
        { "id": "20477", "code": "XIL", "name": "锡林浩特", "englishiName": "XILINHOT", "pinYin": "xilinhaote", "pinYinShort": "XLHT" },
        { "id": "19962", "code": "XEN", "name": "兴城", "englishiName": "XINGCHENG", "pinYin": "xingcheng", "pinYinShort": "XC" },
        { "id": "19964", "code": "XNT", "name": "邢台", "englishiName": "XINGTAI", "pinYin": "xingtai", "pinYinShort": "XT" },
        { "id": "19966", "code": "ACX", "name": "兴义", "englishiName": "XINGYI", "pinYin": "xingyi", "pinYinShort": "XY" },
        { "id": "19967", "code": "XNN", "name": "西宁", "englishiName": "XINING", "pinYin": "xining", "pinYinShort": "XN" },
        { "id": "20658", "code": "NLT", "name": "新源", "englishiName": "XINYUAN", "pinYin": "xinyuan", "pinYinShort": "XY" },
        { "id": "20476", "code": "JHG", "name": "西双版纳", "englishiName": "JINGHONG", "pinYin": "xishuangbanna", "pinYinShort": "XSBN" },
        { "id": "19968", "code": "XUZ", "name": "徐州", "englishiName": "XUZHOU", "pinYin": "xuzhou", "pinYinShort": "XZ" },
    ],
    Y: [
        { "id": "19984", "code": "ENY", "name": "延安", "englishiName": "YAN'AN", "pinYin": "yanan", "pinYinShort": "YA" },
        { "id": "19986", "code": "YNZ", "name": "盐城", "englishiName": "YANCHENG", "pinYin": "yancheng", "pinYinShort": "YC" },
        { "id": "20501", "code": "YTY", "name": "扬州", "englishiName": "YANGZHOU", "pinYin": "yangzhou", "pinYinShort": "YZ" },
        { "id": "19992", "code": "YNJ", "name": "延吉", "englishiName": "YANJI", "pinYin": "yanji", "pinYinShort": "YJ" },
        { "id": "19994", "code": "YNT", "name": "烟台", "englishiName": "YANTAI", "pinYin": "yantai", "pinYinShort": "YT" },
        { "id": "20024", "code": "YBP", "name": "宜宾", "englishiName": "YIBIN", "pinYin": "yibin", "pinYinShort": "YB" },
        { "id": "20025", "code": "YIH", "name": "宜昌", "englishiName": "YICHANG", "pinYin": "yichang", "pinYinShort": "YC" },
        { "id": "20506", "code": "LDS", "name": "宜春", "englishiName": "YICHUN (JIANGXI)", "pinYin": "yichun", "pinYinShort": "YC" },
        { "id": "20026", "code": "YLN", "name": "依兰", "englishiName": "YILAN", "pinYin": "yilan", "pinYinShort": "YL" },
        { "id": "20027", "code": "INC", "name": "银川", "englishiName": "YINCHUAN", "pinYin": "yinchuan", "pinYinShort": "YC" },
        { "id": "20513", "code": "YKH", "name": "营口", "englishiName": "YINGKOU", "pinYin": "yingkou", "pinYinShort": "YK" },
        { "id": "20028", "code": "YIN", "name": "伊宁", "englishiName": "YINING", "pinYin": "yining", "pinYinShort": "YN" },
        { "id": "20029", "code": "YIW", "name": "义乌", "englishiName": "YIWU", "pinYin": "yiwu", "pinYinShort": "YW" },
        { "id": "20516", "code": "LLF", "name": "永州", "englishiName": "YONGZHOU", "pinYin": "yongzhou", "pinYinShort": "YZ" },
        { "id": "20698", "code": "UYN", "name": "榆林", "englishiName": "YULIN", "pinYin": "yulin", "pinYinShort": "YL" },
        { "id": "20524", "code": "YCU", "name": "运城", "englishiName": "YUNCHENG", "pinYin": "yuncheng", "pinYinShort": "YC" },
        { "id": "20520", "code": "YUS", "name": "玉树", "englishiName": "YUSHU", "pinYin": "yushu", "pinYinShort": "YS" },
    ],
    Z: [
        { "id": "20527", "code": "NZL", "name": "扎兰屯", "englishiName": "ZHALANTUN", "pinYin": "zhalantun", "pinYinShort": "ZLT" },
        { "id": "20529", "code": "DYG", "name": "张家界", "englishiName": "ZHANGJIAJIE", "pinYin": "zhangjiajie", "pinYinShort": "ZJJ" },
        { "id": "20530", "code": "ZQZ", "name": "张家口", "englishiName": "ZHANGJIAKOU", "pinYin": "zhangjiakou", "pinYinShort": "ZJK" },
        { "id": "20531", "code": "YZY", "name": "张掖", "englishiName": "ZHANGYE", "pinYin": "zhangye", "pinYinShort": "ZY" },
        { "id": "20091", "code": "ZHA", "name": "湛江", "englishiName": "ZHANJIANG", "pinYin": "zhanjiang", "pinYinShort": "ZJ" },
        { "id": "20092", "code": "ZAT", "name": "昭通", "englishiName": "ZHAOTONG", "pinYin": "zhaotong", "pinYinShort": "ZT" },
        { "id": "20093", "code": "CGO", "name": "郑州", "englishiName": "ZHENGZHOU", "pinYin": "zhengzhou", "pinYinShort": "ZZ" },
        { "id": "20808", "code": "ZHY", "name": "中卫", "englishiName": "ZHONGWEI", "pinYin": "zhongwei", "pinYinShort": "ZW" },
        { "id": "20099", "code": "HSN", "name": "舟山", "englishiName": "ZHOUSHAN", "pinYin": "zhoushan", "pinYinShort": "ZS" },
        { "id": "20100", "code": "ZUH", "name": "珠海", "englishiName": "ZHUHAI", "pinYin": "zhuhai", "pinYinShort": "ZH" },
        { "id": "20112", "code": "ZYI", "name": "遵义", "englishiName": "ZUNYI", "pinYin": "zunyi", "pinYinShort": "ZY" },
    ]
};

export default CHINA_AIRCRAFT_LIST