<template>
  <div>
    <van-icon
      v-if="classification.includes('train')"
      class="trainbg traffic-icon iconfont"
      color="#fff"
      class-prefix="icon"
      name="huochepiaomianxing"
    />
    <van-icon
      v-if="classification.includes('hotel')"
      class="hotelbg traffic-icon iconfont"
      color="#fff"
      class-prefix="icon"
      name="jiudian"
    />
    <van-icon
      v-if="classification.includes('aircraft')"
      class="planebg traffic-icon iconfont"
      color="#fff"
      class-prefix="icon"
      name="tuijianjipiao"
    />
    <van-icon v-if="classification=='error'" class="traffic-icon errorbg" name="warning-o" />
  </div>
</template>

<script>
export default {
  name: "HotelItem",
  props: {
    classification: String,
  },
};
</script>
<style lang="less" scoped>
@import '../../../styles/theme.less';
.traffic-icon {
  border-radius: 50%;
  transform: translateY(2px);
  display: inline-block;
  background-color: #fff;
  padding: 4px;
}
.planebg {
  background-color: @blue;
}
.hotelbg {
  background-color: @orange-dark;
}
.trainbg {
  background-color: @green;
}
.errorbg{
  font-size: 24px;
  padding: 0;
}
</style>
