
import request from '@/services/request'

// 获取车票列表
export function getTrainTicket(data) {
    return request({
        url: 'v1/train/getTrainTicket',
        method: 'post',
        data,
    })
}

// 获取经停站信息
export function getTrainStopStation(data) {
    return request({
        url: 'v1/train/getTrainStopStation',
        method: 'post',
        data
    })
}

// 占座
export function createTrainOrder(data) {
    return request({
        url: 'v1/train/createTrainOrder',
        method: 'post',
        data
    })
}

// 刷新占座状态，订单详情
export function orderStatusReload(data) {
    return request({
        url: 'v1/train/orderStatusReload',
        method: 'post',
        data
    })
}

// 确认出票
export function applyIssueOrder(data) {
    return request({
        url: 'v1/train/applyIssueOrder',
        method: 'post',
        data
    })
}

// 取消出票
export function cancelOrder(data) {
    return request({
        url: 'v1/train/cancelOrder',
        method: 'post',
        data
    })
}

// 获取火车票历史订单
export function getTrainHistory(data) {
    return request({
        url: 'v1/train/getTrainOrders',
        method: 'post',
        data
    })
}

// 改签：下单
export function applyChangeOrder(data) {
    return request({
        url: 'v1/train/applyChangeOrder',
        method: 'post',
        data
    })
}

// 改签：刷新占座状态，订单详情
export function changeOrderStatusReload(data) {
    return request({
        url: 'v1/train/changeOrderStatusReload',
        method: 'post',
        data
    })
}

// 改签：确认出票
export function confirmChangeOrder(data) {
    return request({
        url: 'v1/train/confirmChangeOrder',
        method: 'post',
        data
    })
}

// 改签：取消出票
export function cancelChangeTicket(data) {
    return request({
        url: 'v1/train/cancelChangeTicket',
        method: 'post',
        data
    })
}

// 退票
export function applyRefundOrder(data) {
    return request({
        url: 'v1/train/applyRefundOrder',
        method: 'post',
        data
    })
}

// 登录12306
export function loginRailwayAccount(data) {
    return request({
        url: 'v1/train/loginRailwayAccount',
        method: 'post',
        data
    })
}

// 查询12306常旅客
export function queryTraveler(data) {
    return request({
        url: 'v1/train/queryTraveler',
        method: 'post',
        data
    })
}

// 添加12306常旅客
export function addTraveler(data) {
    return request({
        url: 'v1/train/addTraveler',
        method: 'post',
        data
    })
}
