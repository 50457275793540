const CHINA_HOTEL_CITY_LIST = {
    'hot': [
        {
            "id": "53",
            "isInternational": false,
            "name": "北京",
            "pinyin": "beijing",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "北京"
        },
        {
            "id": "321",
            "isInternational": false,
            "name": "上海",
            "pinyin": "shanghai",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "上海"
        },
        {
            "id": "80",
            "isInternational": false,
            "name": "广州",
            "pinyin": "guangzhou",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "广州"
        },
        {
            "id": "91",
            "isInternational": false,
            "name": "深圳",
            "pinyin": "shenzhen",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "深圳"
        },
        {
            "id": "324",
            "isInternational": false,
            "name": "成都",
            "pinyin": "chengdu",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "成都"
        },
        {
            "id": "343",
            "isInternational": false,
            "name": "天津",
            "pinyin": "tianjin",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "天津"
        },
        {
            "id": "394",
            "isInternational": false,
            "name": "重庆",
            "pinyin": "chongqing",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "重庆"
        },
        {
            "id": "192",
            "isInternational": false,
            "name": "武汉",
            "pinyin": "wuhan",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "武汉"
        },
        {
            "id": "199",
            "isInternational": false,
            "name": "长沙",
            "pinyin": "changsha",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "长沙"
        },
        {
            "id": "224",
            "isInternational": false,
            "name": "南京",
            "pinyin": "nanjing",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "南京"
        },
        {
            "id": "383",
            "isInternational": false,
            "name": "杭州",
            "pinyin": "hangzhou",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "杭州"
        },
        {
            "id": "373",
            "isInternational": false,
            "name": "昆明",
            "pinyin": "kunming",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "昆明"
        },
        {
            "id": "163",
            "isInternational": false,
            "name": "郑州",
            "pinyin": "zhengzhou",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "郑州"
        },
        {
            "id": "287",
            "isInternational": false,
            "name": "济南",
            "pinyin": "jinan",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "济南"
        },
        {
            "id": "54",
            "isInternational": false,
            "name": "福州",
            "pinyin": "fuzhou",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "福州"
        },
        {
            "id": "292",
            "isInternational": false,
            "name": "青岛",
            "pinyin": "qingdao",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "青岛"
        },
        {
            "id": "61",
            "isInternational": false,
            "name": "厦门",
            "pinyin": "xiamen",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "厦门"
        },
        {
            "id": "248",
            "isInternational": false,
            "name": "大连",
            "pinyin": "dalian",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "大连"
        },
        {
            "id": "133",
            "isInternational": false,
            "name": "三亚",
            "pinyin": "sanya",
            "nameSuffix": "",
            "cityType": "1",
            "nameSimple": "三亚"
        },
    ],
    A: [{
        "id": "112",
        "isInternational": false,
        "name": "安顺",
        "pinyin": "anshun",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "安顺"
    }, {
        "id": "36",
        "isInternational": false,
        "name": "安庆",
        "pinyin": "anqing",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "安庆"
    }, {
        "id": "322",
        "isInternational": false,
        "name": "阿坝藏族羌族自治州",
        "pinyin": "abazangzuqiangzuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "阿坝"
    }, {
        "id": "150",
        "isInternational": false,
        "name": "安阳",
        "pinyin": "anyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "安阳"
    }, {
        "id": "352",
        "isInternational": false,
        "name": "阿拉尔",
        "pinyin": "alaer",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "阿拉尔"
    }, {
        "id": "351",
        "isInternational": false,
        "name": "阿克苏地区",
        "pinyin": "akesu",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "阿克苏地区"
    }, {
        "id": "3114",
        "isInternational": false,
        "name": "阿勒泰地区",
        "pinyin": "aletai",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "阿勒泰"
    }, {
        "id": "245",
        "isInternational": false,
        "name": "鞍山",
        "pinyin": "anshan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "鞍山"
    }, {
        "id": "259",
        "isInternational": false,
        "name": "阿拉善盟",
        "pinyin": "alashanmeng",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "阿拉善盟"
    }, {
        "id": "311",
        "isInternational": false,
        "name": "安康",
        "pinyin": "ankang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "安康"
    }, {
        "id": "344",
        "isInternational": false,
        "name": "阿里地区",
        "pinyin": "ali",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "阿里"
    }, {
        "id": "396",
        "isInternational": true,
        "name": "澳门",
        "pinyin": "aomen",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "澳门"
    }],
    B: [{
        "id": "53",
        "isInternational": false,
        "name": "北京",
        "pinyin": "beijing",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "北京"
    }, {
        "id": "323",
        "isInternational": false,
        "name": "巴中",
        "pinyin": "bazhong",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "巴中"
    }, {
        "id": "367",
        "isInternational": false,
        "name": "保山",
        "pinyin": "baoshan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "保山"
    }, {
        "id": "283",
        "isInternational": false,
        "name": "滨州",
        "pinyin": "binzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "滨州"
    }, {
        "id": "121",
        "isInternational": false,
        "name": "白沙黎族自治县",
        "pinyin": "baishalizuzizhixian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "白沙"
    }, {
        "id": "212",
        "isInternational": false,
        "name": "白城",
        "pinyin": "baicheng",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "白城"
    }, {
        "id": "37",
        "isInternational": false,
        "name": "蚌埠",
        "pinyin": "bengbu",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "蚌埠"
    }, {
        "id": "98",
        "isInternational": false,
        "name": "百色",
        "pinyin": "baise",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "百色"
    }, {
        "id": "354",
        "isInternational": false,
        "name": "博尔塔拉蒙古自治州",
        "pinyin": "boertalamengguzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "博尔塔拉"
    }, {
        "id": "353",
        "isInternational": false,
        "name": "巴音郭楞蒙古自治州",
        "pinyin": "bayinguolengmengguzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "巴音郭楞"
    }, {
        "id": "63",
        "isInternational": false,
        "name": "白银",
        "pinyin": "baiyin",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "白银"
    }, {
        "id": "99",
        "isInternational": false,
        "name": "北海",
        "pinyin": "beihai",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "北海"
    }, {
        "id": "113",
        "isInternational": false,
        "name": "毕节",
        "pinyin": "bijie",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "毕节"
    }, {
        "id": "122",
        "isInternational": false,
        "name": "保亭黎族苗族自治县",
        "pinyin": "baotinglizumiaozuzizhixian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "保亭"
    }, {
        "id": "139",
        "isInternational": false,
        "name": "保定",
        "pinyin": "baoding",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "保定"
    }, {
        "id": "213",
        "isInternational": false,
        "name": "白山",
        "pinyin": "baishan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "白山"
    }, {
        "id": "246",
        "isInternational": false,
        "name": "本溪",
        "pinyin": "benxi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "本溪"
    }, {
        "id": "260",
        "isInternational": false,
        "name": "巴彦淖尔市",
        "pinyin": "bayannaoershi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "巴彦淖尔市"
    }, {
        "id": "261",
        "isInternational": false,
        "name": "包头",
        "pinyin": "baotou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "包头"
    }, {
        "id": "312",
        "isInternational": false,
        "name": "宝鸡",
        "pinyin": "baoji",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "宝鸡"
    }],
    C: [{
        "id": "262",
        "isInternational": false,
        "name": "赤峰",
        "pinyin": "chifeng",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "赤峰"
    }, {
        "id": "199",
        "isInternational": false,
        "name": "长沙",
        "pinyin": "changsha",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "长沙"
    }, {
        "id": "247",
        "isInternational": false,
        "name": "朝阳",
        "pinyin": "chaoyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "朝阳"
    }, {
        "id": "355",
        "isInternational": false,
        "name": "昌吉回族自治州",
        "pinyin": "changjihuizuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "昌吉"
    }, {
        "id": "368",
        "isInternational": false,
        "name": "楚雄彝族自治州",
        "pinyin": "chuxiongyizuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "楚雄"
    }, {
        "id": "345",
        "isInternational": false,
        "name": "昌都",
        "pinyin": "changdu",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "昌都"
    }, {
        "id": "394",
        "isInternational": false,
        "name": "重庆",
        "pinyin": "chongqing",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "重庆"
    }, {
        "id": "39",
        "isInternational": false,
        "name": "池州",
        "pinyin": "chizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "池州"
    }, {
        "id": "40",
        "isInternational": false,
        "name": "滁州",
        "pinyin": "chuzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "滁州"
    }, {
        "id": "77",
        "isInternational": false,
        "name": "潮州",
        "pinyin": "chaozhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "潮州"
    }, {
        "id": "100",
        "isInternational": false,
        "name": "崇左",
        "pinyin": "chongzuo",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "崇左"
    }, {
        "id": "123",
        "isInternational": false,
        "name": "昌江黎族自治县",
        "pinyin": "changjianglizuzizhixian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "昌江"
    }, {
        "id": "124",
        "isInternational": false,
        "name": "澄迈县",
        "pinyin": "chengmaixian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "澄迈县"
    }, {
        "id": "140",
        "isInternational": false,
        "name": "沧州",
        "pinyin": "cangzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "沧州"
    }, {
        "id": "141",
        "isInternational": false,
        "name": "承德",
        "pinyin": "chengde",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "承德"
    }, {
        "id": "198",
        "isInternational": false,
        "name": "常德",
        "pinyin": "changde",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "常德"
    }, {
        "id": "200",
        "isInternational": false,
        "name": "郴州",
        "pinyin": "chenzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "郴州"
    }, {
        "id": "214",
        "isInternational": false,
        "name": "长春",
        "pinyin": "changchun",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "长春"
    }, {
        "id": "221",
        "isInternational": false,
        "name": "常州",
        "pinyin": "changzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "常州"
    }, {
        "id": "300",
        "isInternational": false,
        "name": "长治",
        "pinyin": "changzhi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "长治"
    }, {
        "id": "324",
        "isInternational": false,
        "name": "成都",
        "pinyin": "chengdu",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "成都"
    }],
    D: [{
        "id": "369",
        "isInternational": false,
        "name": "大理白族自治州",
        "pinyin": "dali",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "大理"
    }, {
        "id": "125",
        "isInternational": false,
        "name": "定安县",
        "pinyin": "dinganxian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "定安县"
    }, {
        "id": "370",
        "isInternational": false,
        "name": "德宏傣族景颇族自治州",
        "pinyin": "dehongdaizujingpozuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "德宏"
    }, {
        "id": "371",
        "isInternational": false,
        "name": "迪庆藏族自治州",
        "pinyin": "diqingzangzuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "迪庆"
    }, {
        "id": "64",
        "isInternational": false,
        "name": "定西",
        "pinyin": "dingxi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "定西"
    }, {
        "id": "78",
        "isInternational": false,
        "name": "东莞",
        "pinyin": "dongguan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "东莞"
    }, {
        "id": "126",
        "isInternational": false,
        "name": "东方市",
        "pinyin": "dongfang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "东方市"
    }, {
        "id": "138",
        "isInternational": false,
        "name": "儋州",
        "pinyin": "danzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "儋州"
    }, {
        "id": "168",
        "isInternational": false,
        "name": "大庆",
        "pinyin": "daqing",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "大庆"
    }, {
        "id": "169",
        "isInternational": false,
        "name": "大兴安岭地区",
        "pinyin": "daxinganling",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "大兴安岭"
    }, {
        "id": "248",
        "isInternational": false,
        "name": "大连",
        "pinyin": "dalian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "大连"
    }, {
        "id": "249",
        "isInternational": false,
        "name": "丹东",
        "pinyin": "dandong",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "丹东"
    }, {
        "id": "284",
        "isInternational": false,
        "name": "德州",
        "pinyin": "dezhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "德州"
    }, {
        "id": "285",
        "isInternational": false,
        "name": "东营",
        "pinyin": "dongying",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "东营"
    }, {
        "id": "301",
        "isInternational": false,
        "name": "大同",
        "pinyin": "datong",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "大同"
    }, {
        "id": "325",
        "isInternational": false,
        "name": "达州",
        "pinyin": "dazhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "达州"
    }, {
        "id": "326",
        "isInternational": false,
        "name": "德阳",
        "pinyin": "deyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "德阳"
    }],
    E: [{
        "id": "181",
        "isInternational": false,
        "name": "鄂州",
        "pinyin": "ezhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "鄂州"
    }, {
        "id": "182",
        "isInternational": false,
        "name": "恩施土家族苗族自治州",
        "pinyin": "enshitujiazumiaozuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "恩施"
    }, {
        "id": "263",
        "isInternational": false,
        "name": "鄂尔多斯",
        "pinyin": "eerduosi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "鄂尔多斯"
    }],
    F: [{
        "id": "41",
        "isInternational": false,
        "name": "阜阳",
        "pinyin": "fuyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "阜阳"
    }, {
        "id": "54",
        "isInternational": false,
        "name": "福州",
        "pinyin": "fuzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "福州"
    }, {
        "id": "79",
        "isInternational": false,
        "name": "佛山",
        "pinyin": "foshan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "佛山"
    }, {
        "id": "101",
        "isInternational": false,
        "name": "防城港",
        "pinyin": "fangchenggang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "防城港"
    }, {
        "id": "234",
        "isInternational": false,
        "name": "抚州",
        "pinyin": "fuzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "抚州"
    }, {
        "id": "250",
        "isInternational": false,
        "name": "抚顺",
        "pinyin": "fushun",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "抚顺"
    }, {
        "id": "251",
        "isInternational": false,
        "name": "阜新",
        "pinyin": "fuxin",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "阜新"
    }],
    G: [{
        "id": "397",
        "isInternational": true,
        "name": "高雄",
        "pinyin": "gaoxiong",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "高雄"
    }, {
        "id": "103",
        "isInternational": false,
        "name": "贵港",
        "pinyin": "guigang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "贵港"
    }, {
        "id": "65",
        "isInternational": false,
        "name": "甘南藏族自治州",
        "pinyin": "gannanzangzuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "甘南"
    }, {
        "id": "80",
        "isInternational": false,
        "name": "广州",
        "pinyin": "guangzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "广州"
    }, {
        "id": "102",
        "isInternational": false,
        "name": "桂林",
        "pinyin": "guilin",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "桂林"
    }, {
        "id": "114",
        "isInternational": false,
        "name": "贵阳",
        "pinyin": "guiyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "贵阳"
    }, {
        "id": "235",
        "isInternational": false,
        "name": "赣州",
        "pinyin": "ganzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "赣州"
    }, {
        "id": "271",
        "isInternational": false,
        "name": "固原",
        "pinyin": "guyuan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "固原"
    }, {
        "id": "275",
        "isInternational": false,
        "name": "果洛藏族自治州",
        "pinyin": "guoluozangzuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "果洛"
    }, {
        "id": "327",
        "isInternational": false,
        "name": "甘孜藏族自治州",
        "pinyin": "ganzizangzuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "甘孜"
    }, {
        "id": "328",
        "isInternational": false,
        "name": "广安",
        "pinyin": "guangan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "广安"
    }, {
        "id": "329",
        "isInternational": false,
        "name": "广元",
        "pinyin": "guangyuan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "广元"
    }],
    H: [{
        "id": "81",
        "isInternational": false,
        "name": "河源",
        "pinyin": "heyuan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "河源"
    }, {
        "id": "279",
        "isInternational": false,
        "name": "海西蒙古族藏族自治州",
        "pinyin": "haiximengguzuzangzuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "海西"
    }, {
        "id": "276",
        "isInternational": false,
        "name": "海北藏族自治州",
        "pinyin": "haibeizangzuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "海北"
    }, {
        "id": "398",
        "isInternational": true,
        "name": "花莲",
        "pinyin": "hualian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "花莲"
    }, {
        "id": "383",
        "isInternational": false,
        "name": "杭州",
        "pinyin": "hangzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "杭州"
    }, {
        "id": "372",
        "isInternational": false,
        "name": "红河哈尼族彝族自治州",
        "pinyin": "honghehanizuyizuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "红河"
    }, {
        "id": "356",
        "isInternational": false,
        "name": "哈密",
        "pinyin": "hami",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "哈密"
    }, {
        "id": "44",
        "isInternational": false,
        "name": "淮南",
        "pinyin": "huainan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "淮南"
    }, {
        "id": "280",
        "isInternational": false,
        "name": "黄南藏族自治州",
        "pinyin": "huangnanzangzuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "黄南"
    }, {
        "id": "127",
        "isInternational": false,
        "name": "海口",
        "pinyin": "haikou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "海口"
    }, {
        "id": "357",
        "isInternational": false,
        "name": "和田地区",
        "pinyin": "hetian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "和田"
    }, {
        "id": "384",
        "isInternational": false,
        "name": "湖州",
        "pinyin": "huzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "湖州"
    }, {
        "id": "42",
        "isInternational": false,
        "name": "合肥",
        "pinyin": "hefei",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "合肥"
    }, {
        "id": "43",
        "isInternational": false,
        "name": "淮北",
        "pinyin": "huaibei",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "淮北"
    }, {
        "id": "45",
        "isInternational": false,
        "name": "黄山",
        "pinyin": "huangshan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "黄山"
    }, {
        "id": "52",
        "isInternational": false,
        "name": "亳州",
        "pinyin": "haozhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "亳州"
    }, {
        "id": "82",
        "isInternational": false,
        "name": "惠州",
        "pinyin": "huizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "惠州"
    }, {
        "id": "104",
        "isInternational": false,
        "name": "河池",
        "pinyin": "hechi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "河池"
    }, {
        "id": "105",
        "isInternational": false,
        "name": "贺州",
        "pinyin": "hezhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "贺州"
    }, {
        "id": "142",
        "isInternational": false,
        "name": "邯郸",
        "pinyin": "handan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "邯郸"
    }, {
        "id": "143",
        "isInternational": false,
        "name": "衡水",
        "pinyin": "hengshui",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "衡水"
    }, {
        "id": "151",
        "isInternational": false,
        "name": "鹤壁",
        "pinyin": "hebi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "鹤壁"
    }, {
        "id": "170",
        "isInternational": false,
        "name": "哈尔滨",
        "pinyin": "haerbin",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "哈尔滨"
    }, {
        "id": "171",
        "isInternational": false,
        "name": "鹤岗",
        "pinyin": "hegang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "鹤岗"
    }, {
        "id": "172",
        "isInternational": false,
        "name": "黑河",
        "pinyin": "heihe",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "黑河"
    }, {
        "id": "183",
        "isInternational": false,
        "name": "黄冈",
        "pinyin": "huanggang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "黄冈"
    }, {
        "id": "184",
        "isInternational": false,
        "name": "黄石",
        "pinyin": "huangshi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "黄石"
    }, {
        "id": "201",
        "isInternational": false,
        "name": "衡阳",
        "pinyin": "hengyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "衡阳"
    }, {
        "id": "202",
        "isInternational": false,
        "name": "怀化",
        "pinyin": "huaihua",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "怀化"
    }, {
        "id": "222",
        "isInternational": false,
        "name": "淮安",
        "pinyin": "huaian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "淮安"
    }, {
        "id": "252",
        "isInternational": false,
        "name": "葫芦岛",
        "pinyin": "huludao",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "葫芦岛"
    }, {
        "id": "264",
        "isInternational": false,
        "name": "呼和浩特",
        "pinyin": "huhehaote",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "呼和浩特"
    }, {
        "id": "265",
        "isInternational": false,
        "name": "呼伦贝尔",
        "pinyin": "hulunbeier",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "呼伦贝尔"
    }, {
        "id": "277",
        "isInternational": false,
        "name": "海东",
        "pinyin": "haidong",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "海东"
    }, {
        "id": "278",
        "isInternational": false,
        "name": "海南藏族自治州",
        "pinyin": "hainanzangzuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "海南藏族"
    }, {
        "id": "286",
        "isInternational": false,
        "name": "菏泽",
        "pinyin": "heze",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "菏泽"
    }, {
        "id": "313",
        "isInternational": false,
        "name": "汉中",
        "pinyin": "hanzhong",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "汉中"
    }, {
        "id": "13",
        "isInternational": false,
        "name": "湖北",
        "pinyin": "hubei",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "湖北"
    }],
    J: [{
        "id": "400",
        "isInternational": true,
        "name": "嘉义",
        "pinyin": "jiayi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "嘉义"
    }, {
        "id": "237",
        "isInternational": false,
        "name": "景德镇",
        "pinyin": "jingdezhen",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "景德镇"
    }, {
        "id": "68",
        "isInternational": false,
        "name": "酒泉",
        "pinyin": "jiuquan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "酒泉"
    }, {
        "id": "386",
        "isInternational": false,
        "name": "金华",
        "pinyin": "jinhua",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "金华"
    }, {
        "id": "303",
        "isInternational": false,
        "name": "晋中",
        "pinyin": "jinzhong",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "晋中"
    }, {
        "id": "385",
        "isInternational": false,
        "name": "嘉兴",
        "pinyin": "jiaxing",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "嘉兴"
    }, {
        "id": "66",
        "isInternational": false,
        "name": "嘉峪关",
        "pinyin": "jiayuguan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "嘉峪关"
    }, {
        "id": "67",
        "isInternational": false,
        "name": "金昌",
        "pinyin": "jinchang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "金昌"
    }, {
        "id": "83",
        "isInternational": false,
        "name": "江门",
        "pinyin": "jiangmen",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "江门"
    }, {
        "id": "84",
        "isInternational": false,
        "name": "揭阳",
        "pinyin": "jieyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "揭阳"
    }, {
        "id": "152",
        "isInternational": false,
        "name": "济源市",
        "pinyin": "jiyuan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "济源市"
    }, {
        "id": "153",
        "isInternational": false,
        "name": "焦作",
        "pinyin": "jiaozuo",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "焦作"
    }, {
        "id": "173",
        "isInternational": false,
        "name": "鸡西",
        "pinyin": "jixi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "鸡西"
    }, {
        "id": "174",
        "isInternational": false,
        "name": "佳木斯",
        "pinyin": "jiamusi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "佳木斯"
    }, {
        "id": "185",
        "isInternational": false,
        "name": "荆门",
        "pinyin": "jingmen",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "荆门"
    }, {
        "id": "186",
        "isInternational": false,
        "name": "荆州",
        "pinyin": "jingzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "荆州"
    }, {
        "id": "215",
        "isInternational": false,
        "name": "吉林",
        "pinyin": "jilin",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "吉林"
    }, {
        "id": "236",
        "isInternational": false,
        "name": "吉安",
        "pinyin": "jian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "吉安"
    }, {
        "id": "238",
        "isInternational": false,
        "name": "九江",
        "pinyin": "jiujiang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "九江"
    }, {
        "id": "253",
        "isInternational": false,
        "name": "锦州",
        "pinyin": "jinzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "锦州"
    }, {
        "id": "287",
        "isInternational": false,
        "name": "济南",
        "pinyin": "jinan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "济南"
    }, {
        "id": "288",
        "isInternational": false,
        "name": "济宁",
        "pinyin": "jining",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "济宁"
    }, {
        "id": "302",
        "isInternational": false,
        "name": "晋城",
        "pinyin": "jincheng",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "晋城"
    }, {
        "id": "5127",
        "isInternational": true,
        "name": "金门",
        "pinyin": "jinmen",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "金门"
    }, {
        "id": "399",
        "isInternational": true,
        "name": "基隆",
        "pinyin": "jilong",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "基隆"
    }],
    K: [{
        "id": "373",
        "isInternational": false,
        "name": "昆明",
        "pinyin": "kunming",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "昆明"
    }, {
        "id": "154",
        "isInternational": false,
        "name": "开封",
        "pinyin": "kaifeng",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "开封"
    }, {
        "id": "358",
        "isInternational": false,
        "name": "喀什地区",
        "pinyin": "kashi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "喀什"
    }, {
        "id": "359",
        "isInternational": false,
        "name": "克拉玛依",
        "pinyin": "kelamayi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "克拉玛依"
    }, {
        "id": "360",
        "isInternational": false,
        "name": "克孜勒苏柯尔克孜自治州",
        "pinyin": "kezilesukeerkezizizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "克孜勒苏柯尔克孜"
    }],
    L: [{
        "id": "69",
        "isInternational": false,
        "name": "兰州",
        "pinyin": "lanzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "兰州"
    }, {
        "id": "291",
        "isInternational": false,
        "name": "临沂",
        "pinyin": "linyi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "临沂"
    }, {
        "id": "203",
        "isInternational": false,
        "name": "娄底",
        "pinyin": "loudi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "娄底"
    }, {
        "id": "387",
        "isInternational": false,
        "name": "丽水",
        "pinyin": "lishui",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "丽水"
    }, {
        "id": "130",
        "isInternational": false,
        "name": "陵水黎族自治县",
        "pinyin": "lingshuilizuzizhixian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "陵水"
    }, {
        "id": "374",
        "isInternational": false,
        "name": "丽江",
        "pinyin": "lijiang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "丽江"
    }, {
        "id": "342",
        "isInternational": false,
        "name": "泸州",
        "pinyin": "luzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "泸州"
    }, {
        "id": "346",
        "isInternational": false,
        "name": "拉萨",
        "pinyin": "lasa",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "拉萨"
    }, {
        "id": "347",
        "isInternational": false,
        "name": "林芝",
        "pinyin": "linzhi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "林芝"
    }, {
        "id": "46",
        "isInternational": false,
        "name": "六安",
        "pinyin": "liuan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "六安"
    }, {
        "id": "55",
        "isInternational": false,
        "name": "龙岩",
        "pinyin": "longyan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "龙岩"
    }, {
        "id": "70",
        "isInternational": false,
        "name": "临夏回族自治州",
        "pinyin": "linxiahuizuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "临夏"
    }, {
        "id": "71",
        "isInternational": false,
        "name": "陇南",
        "pinyin": "longnan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "陇南"
    }, {
        "id": "106",
        "isInternational": false,
        "name": "来宾",
        "pinyin": "laibin",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "来宾"
    }, {
        "id": "107",
        "isInternational": false,
        "name": "柳州",
        "pinyin": "liuzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "柳州"
    }, {
        "id": "115",
        "isInternational": false,
        "name": "六盘水",
        "pinyin": "liupanshui",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "六盘水"
    }, {
        "id": "128",
        "isInternational": false,
        "name": "乐东黎族自治县",
        "pinyin": "ledonglizuzizhixian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "乐东"
    }, {
        "id": "129",
        "isInternational": false,
        "name": "临高县",
        "pinyin": "lingaoxian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "临高县"
    }, {
        "id": "144",
        "isInternational": false,
        "name": "廊坊",
        "pinyin": "langfang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "廊坊"
    }, {
        "id": "155",
        "isInternational": false,
        "name": "洛阳",
        "pinyin": "luoyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "洛阳"
    }, {
        "id": "166",
        "isInternational": false,
        "name": "漯河",
        "pinyin": "luohe",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "漯河"
    }, {
        "id": "216",
        "isInternational": false,
        "name": "辽源",
        "pinyin": "liaoyuan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "辽源"
    }, {
        "id": "223",
        "isInternational": false,
        "name": "连云港",
        "pinyin": "lianyungang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "连云港"
    }, {
        "id": "254",
        "isInternational": false,
        "name": "辽阳",
        "pinyin": "liaoyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "辽阳"
    }, {
        "id": "290",
        "isInternational": false,
        "name": "聊城",
        "pinyin": "liaocheng",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "聊城"
    }, {
        "id": "304",
        "isInternational": false,
        "name": "临汾",
        "pinyin": "linfen",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "临汾"
    }, {
        "id": "305",
        "isInternational": false,
        "name": "吕梁",
        "pinyin": "lvliang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "吕梁"
    }, {
        "id": "330",
        "isInternational": false,
        "name": "乐山",
        "pinyin": "leshan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "乐山"
    }, {
        "id": "331",
        "isInternational": false,
        "name": "凉山彝族自治州",
        "pinyin": "liangshanyizuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "凉山"
    }, {
        "id": "375",
        "isInternational": false,
        "name": "临沧",
        "pinyin": "lincang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "临沧"
    }],
    M: [{
        "id": "47",
        "isInternational": false,
        "name": "马鞍山",
        "pinyin": "maanshan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "马鞍山"
    }, {
        "id": "85",
        "isInternational": false,
        "name": "茂名",
        "pinyin": "maoming",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "茂名"
    }, {
        "id": "86",
        "isInternational": false,
        "name": "梅州",
        "pinyin": "meizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "梅州"
    }, {
        "id": "175",
        "isInternational": false,
        "name": "牡丹江",
        "pinyin": "mudanjiang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "牡丹江"
    }, {
        "id": "332",
        "isInternational": false,
        "name": "眉山",
        "pinyin": "meishan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "眉山"
    }, {
        "id": "333",
        "isInternational": false,
        "name": "绵阳",
        "pinyin": "mianyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "绵阳"
    }, {
        "id": "5117",
        "isInternational": true,
        "name": "苗栗",
        "pinyin": "miaoli",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "苗栗"
    }],
    N: [{
        "id": "239",
        "isInternational": false,
        "name": "南昌",
        "pinyin": "nanchang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "南昌"
    }, {
        "id": "108",
        "isInternational": false,
        "name": "南宁",
        "pinyin": "nanning",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "南宁"
    }, {
        "id": "388",
        "isInternational": false,
        "name": "宁波",
        "pinyin": "ningbo",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "宁波"
    }, {
        "id": "376",
        "isInternational": false,
        "name": "怒江傈僳族自治州",
        "pinyin": "nujianglisuzuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "怒江"
    }, {
        "id": "5119",
        "isInternational": true,
        "name": "南投",
        "pinyin": "nantou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "南投"
    }, {
        "id": "348",
        "isInternational": false,
        "name": "那曲",
        "pinyin": "naqu",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "那曲"
    }, {
        "id": "56",
        "isInternational": false,
        "name": "南平",
        "pinyin": "nanping",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "南平"
    }, {
        "id": "57",
        "isInternational": false,
        "name": "宁德",
        "pinyin": "ningde",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "宁德"
    }, {
        "id": "156",
        "isInternational": false,
        "name": "南阳",
        "pinyin": "nanyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "南阳"
    }, {
        "id": "224",
        "isInternational": false,
        "name": "南京",
        "pinyin": "nanjing",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "南京"
    }, {
        "id": "225",
        "isInternational": false,
        "name": "南通",
        "pinyin": "nantong",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "南通"
    }, {
        "id": "334",
        "isInternational": false,
        "name": "南充",
        "pinyin": "nanchong",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "南充"
    }, {
        "id": "335",
        "isInternational": false,
        "name": "内江",
        "pinyin": "neijiang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "内江"
    }],
    P: [{
        "id": "58",
        "isInternational": false,
        "name": "莆田",
        "pinyin": "putian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "莆田"
    }, {
        "id": "255",
        "isInternational": false,
        "name": "盘锦",
        "pinyin": "panjin",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "盘锦"
    }, {
        "id": "5121",
        "isInternational": true,
        "name": "屏东",
        "pinyin": "pingdong",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "屏东"
    }, {
        "id": "378",
        "isInternational": false,
        "name": "普洱",
        "pinyin": "puer",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "普洱"
    }, {
        "id": "72",
        "isInternational": false,
        "name": "平凉",
        "pinyin": "pingliang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "平凉"
    }, {
        "id": "157",
        "isInternational": false,
        "name": "平顶山",
        "pinyin": "pingdingshan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "平顶山"
    }, {
        "id": "167",
        "isInternational": false,
        "name": "濮阳",
        "pinyin": "puyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "濮阳"
    }, {
        "id": "240",
        "isInternational": false,
        "name": "萍乡",
        "pinyin": "pingxiang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "萍乡"
    }, {
        "id": "336",
        "isInternational": false,
        "name": "攀枝花",
        "pinyin": "panzhihua",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "攀枝花"
    }, {
        "id": "5130",
        "isInternational": true,
        "name": "澎湖",
        "pinyin": "penghu",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "澎湖"
    }],
    Q: [{
        "id": "292",
        "isInternational": false,
        "name": "青岛",
        "pinyin": "qingdao",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "青岛"
    }, {
        "id": "393",
        "isInternational": false,
        "name": "衢州",
        "pinyin": "quzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "衢州"
    }, {
        "id": "377",
        "isInternational": false,
        "name": "曲靖",
        "pinyin": "qujing",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "曲靖"
    }, {
        "id": "177",
        "isInternational": false,
        "name": "齐齐哈尔",
        "pinyin": "qiqihaer",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "齐齐哈尔"
    }, {
        "id": "59",
        "isInternational": false,
        "name": "泉州",
        "pinyin": "quanzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "泉州"
    }, {
        "id": "73",
        "isInternational": false,
        "name": "庆阳",
        "pinyin": "qingyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "庆阳"
    }, {
        "id": "87",
        "isInternational": false,
        "name": "清远",
        "pinyin": "qingyuan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "清远"
    }, {
        "id": "109",
        "isInternational": false,
        "name": "钦州",
        "pinyin": "qinzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "钦州"
    }, {
        "id": "116",
        "isInternational": false,
        "name": "黔东南苗族侗族自治州",
        "pinyin": "qiandongnanmiaozudongzuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "黔东南"
    }, {
        "id": "117",
        "isInternational": false,
        "name": "黔南布依族苗族自治州",
        "pinyin": "qiannanbuyizumiaozuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "黔南"
    }, {
        "id": "118",
        "isInternational": false,
        "name": "黔西南布依族苗族自治州",
        "pinyin": "qianxinanbuyizumiaozuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "黔西南"
    }, {
        "id": "131",
        "isInternational": false,
        "name": "琼海",
        "pinyin": "qionghai",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "琼海"
    }, {
        "id": "132",
        "isInternational": false,
        "name": "琼中黎族苗族自治县",
        "pinyin": "qiongzhonglizumiaozuzizhixian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "琼中"
    }, {
        "id": "145",
        "isInternational": false,
        "name": "秦皇岛",
        "pinyin": "qinhuangdao",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "秦皇岛"
    }, {
        "id": "176",
        "isInternational": false,
        "name": "七台河",
        "pinyin": "qitaihe",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "七台河"
    }, {
        "id": "187",
        "isInternational": false,
        "name": "潜江",
        "pinyin": "qianjiang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "潜江"
    }],
    R: [{
        "id": "349",
        "isInternational": false,
        "name": "日喀则",
        "pinyin": "rikaze",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "日喀则"
    }, {
        "id": "293",
        "isInternational": false,
        "name": "日照",
        "pinyin": "rizhao",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "日照"
    }],
    S: [{
        "id": "189",
        "isInternational": false,
        "name": "十堰",
        "pinyin": "shiyan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "十堰"
    }, {
        "id": "321",
        "isInternational": false,
        "name": "上海",
        "pinyin": "shanghai",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "上海"
    }, {
        "id": "88",
        "isInternational": false,
        "name": "汕头",
        "pinyin": "shantou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "汕头"
    }, {
        "id": "159",
        "isInternational": false,
        "name": "商丘",
        "pinyin": "shangqiu",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "商丘"
    }, {
        "id": "178",
        "isInternational": false,
        "name": "双鸭山",
        "pinyin": "shuangyashan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "双鸭山"
    }, {
        "id": "389",
        "isInternational": false,
        "name": "绍兴",
        "pinyin": "shaoxing",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "绍兴"
    }, {
        "id": "90",
        "isInternational": false,
        "name": "韶关",
        "pinyin": "shaoguan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "韶关"
    }, {
        "id": "179",
        "isInternational": false,
        "name": "绥化",
        "pinyin": "suihua",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "绥化"
    }, {
        "id": "361",
        "isInternational": false,
        "name": "石河子",
        "pinyin": "shihezi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "石河子"
    }, {
        "id": "314",
        "isInternational": false,
        "name": "商洛",
        "pinyin": "shangluo",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "商洛"
    }, {
        "id": "350",
        "isInternational": false,
        "name": "山南",
        "pinyin": "shannan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "山南"
    }, {
        "id": "48",
        "isInternational": false,
        "name": "宿州",
        "pinyin": "suzhoushi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "宿州"
    }, {
        "id": "60",
        "isInternational": false,
        "name": "三明",
        "pinyin": "sanming",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "三明"
    }, {
        "id": "89",
        "isInternational": false,
        "name": "汕尾",
        "pinyin": "shanwei",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "汕尾"
    }, {
        "id": "91",
        "isInternational": false,
        "name": "深圳",
        "pinyin": "shenzhen",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "深圳"
    }, {
        "id": "133",
        "isInternational": false,
        "name": "三亚",
        "pinyin": "sanya",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "三亚"
    }, {
        "id": "146",
        "isInternational": false,
        "name": "石家庄",
        "pinyin": "shijiazhuang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "石家庄"
    }, {
        "id": "158",
        "isInternational": false,
        "name": "三门峡",
        "pinyin": "sanmenxia",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "三门峡"
    }, {
        "id": "188",
        "isInternational": false,
        "name": "神农架林区",
        "pinyin": "shennongjialinqu",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "神农架林区"
    }, {
        "id": "190",
        "isInternational": false,
        "name": "随州",
        "pinyin": "suizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "随州"
    }, {
        "id": "204",
        "isInternational": false,
        "name": "邵阳",
        "pinyin": "shaoyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "邵阳"
    }, {
        "id": "217",
        "isInternational": false,
        "name": "四平",
        "pinyin": "siping",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "四平"
    }, {
        "id": "218",
        "isInternational": false,
        "name": "松原",
        "pinyin": "songyuan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "松原"
    }, {
        "id": "226",
        "isInternational": false,
        "name": "苏州",
        "pinyin": "suzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "苏州"
    }, {
        "id": "227",
        "isInternational": false,
        "name": "宿迁",
        "pinyin": "suqian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "宿迁"
    }, {
        "id": "241",
        "isInternational": false,
        "name": "上饶",
        "pinyin": "shangrao",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "上饶"
    }, {
        "id": "256",
        "isInternational": false,
        "name": "沈阳",
        "pinyin": "shenyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "沈阳"
    }, {
        "id": "272",
        "isInternational": false,
        "name": "石嘴山",
        "pinyin": "shizuishan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "石嘴山"
    }, {
        "id": "306",
        "isInternational": false,
        "name": "朔州",
        "pinyin": "shuozhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "朔州"
    }, {
        "id": "337",
        "isInternational": false,
        "name": "遂宁",
        "pinyin": "suining",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "遂宁"
    }, {
        "id": "101222",
        "isInternational": false,
        "name": "双河",
        "pinyin": "shuanghe",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "双河"
    }],
    T: [{
        "id": "343",
        "isInternational": false,
        "name": "天津",
        "pinyin": "tianjin",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "天津"
    }, {
        "id": "403",
        "isInternational": true,
        "name": "台南",
        "pinyin": "tainan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "台南"
    }, {
        "id": "401",
        "isInternational": true,
        "name": "台北",
        "pinyin": "taibei",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "台北"
    }, {
        "id": "49",
        "isInternational": false,
        "name": "铜陵",
        "pinyin": "tongling",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "铜陵"
    }, {
        "id": "147",
        "isInternational": false,
        "name": "唐山",
        "pinyin": "tangshan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "唐山"
    }, {
        "id": "191",
        "isInternational": false,
        "name": "天门",
        "pinyin": "tianmen",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "天门"
    }, {
        "id": "404",
        "isInternational": true,
        "name": "台中",
        "pinyin": "taizhong",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "台中"
    }, {
        "id": "390",
        "isInternational": false,
        "name": "台州",
        "pinyin": "taizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "台州"
    }, {
        "id": "5116",
        "isInternational": true,
        "name": "桃园",
        "pinyin": "taoyuan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "桃园"
    }, {
        "id": "362",
        "isInternational": false,
        "name": "图木舒克",
        "pinyin": "tumushuke",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "图木舒克"
    }, {
        "id": "3113",
        "isInternational": false,
        "name": "塔城地区",
        "pinyin": "tacheng",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "塔城"
    }, {
        "id": "363",
        "isInternational": false,
        "name": "吐鲁番",
        "pinyin": "tulufan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "吐鲁番"
    }, {
        "id": "402",
        "isInternational": true,
        "name": "台东",
        "pinyin": "taidong",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "台东"
    }, {
        "id": "74",
        "isInternational": false,
        "name": "天水",
        "pinyin": "tianshui",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "天水"
    }, {
        "id": "119",
        "isInternational": false,
        "name": "铜仁",
        "pinyin": "tongren",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "铜仁"
    }, {
        "id": "134",
        "isInternational": false,
        "name": "屯昌县",
        "pinyin": "tunchangxian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "屯昌县"
    }, {
        "id": "219",
        "isInternational": false,
        "name": "通化",
        "pinyin": "tonghua",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "通化"
    }, {
        "id": "228",
        "isInternational": false,
        "name": "泰州",
        "pinyin": "taizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "泰州"
    }, {
        "id": "257",
        "isInternational": false,
        "name": "铁岭",
        "pinyin": "tieling",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "铁岭"
    }, {
        "id": "266",
        "isInternational": false,
        "name": "通辽",
        "pinyin": "tongliao",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "通辽"
    }, {
        "id": "294",
        "isInternational": false,
        "name": "泰安",
        "pinyin": "taian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "泰安"
    }, {
        "id": "307",
        "isInternational": false,
        "name": "太原",
        "pinyin": "taiyuan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "太原"
    }, {
        "id": "315",
        "isInternational": false,
        "name": "铜川",
        "pinyin": "tongchuan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "铜川"
    }],
    W: [{
        "id": "391",
        "isInternational": false,
        "name": "温州",
        "pinyin": "wenzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "温州"
    }, {
        "id": "365",
        "isInternational": false,
        "name": "五家渠",
        "pinyin": "wujiaqu",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "五家渠"
    }, {
        "id": "268",
        "isInternational": false,
        "name": "乌兰察布市",
        "pinyin": "wulanchabushi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "乌兰察布市"
    }, {
        "id": "295",
        "isInternational": false,
        "name": "威海",
        "pinyin": "weihai",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "威海"
    }, {
        "id": "379",
        "isInternational": false,
        "name": "文山壮族苗族自治州",
        "pinyin": "wenshanzhuangzumiaozuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "文山"
    }, {
        "id": "364",
        "isInternational": false,
        "name": "乌鲁木齐",
        "pinyin": "wulumuqi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "乌鲁木齐"
    }, {
        "id": "50",
        "isInternational": false,
        "name": "芜湖",
        "pinyin": "wuhu",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "芜湖"
    }, {
        "id": "75",
        "isInternational": false,
        "name": "武威",
        "pinyin": "wuwei",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "武威"
    }, {
        "id": "110",
        "isInternational": false,
        "name": "梧州",
        "pinyin": "wuzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "梧州"
    }, {
        "id": "135",
        "isInternational": false,
        "name": "万宁市",
        "pinyin": "wanning",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "万宁市"
    }, {
        "id": "136",
        "isInternational": false,
        "name": "文昌市",
        "pinyin": "wenchang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "文昌市"
    }, {
        "id": "137",
        "isInternational": false,
        "name": "五指山市",
        "pinyin": "wuzhishan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "五指山市"
    }, {
        "id": "192",
        "isInternational": false,
        "name": "武汉",
        "pinyin": "wuhan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "武汉"
    }, {
        "id": "229",
        "isInternational": false,
        "name": "无锡",
        "pinyin": "wuxi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "无锡"
    }, {
        "id": "267",
        "isInternational": false,
        "name": "乌海",
        "pinyin": "wuhai",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "乌海"
    }, {
        "id": "273",
        "isInternational": false,
        "name": "吴忠",
        "pinyin": "wuzhong",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "吴忠"
    }, {
        "id": "296",
        "isInternational": false,
        "name": "潍坊",
        "pinyin": "weifang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "潍坊"
    }, {
        "id": "316",
        "isInternational": false,
        "name": "渭南",
        "pinyin": "weinan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "渭南"
    }],
    X: [{
        "id": "195",
        "isInternational": false,
        "name": "襄阳",
        "pinyin": "xiangyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "襄阳"
    }, {
        "id": "5114",
        "isInternational": true,
        "name": "新竹",
        "pinyin": "xinzhu",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "新竹"
    }, {
        "id": "148",
        "isInternational": false,
        "name": "邢台",
        "pinyin": "xingtai",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "邢台"
    }, {
        "id": "380",
        "isInternational": false,
        "name": "西双版纳傣族自治州",
        "pinyin": "xishuangbannadaizuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "西双版纳"
    }, {
        "id": "317",
        "isInternational": false,
        "name": "西安",
        "pinyin": "xian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "西安"
    }, {
        "id": "193",
        "isInternational": false,
        "name": "仙桃",
        "pinyin": "xiantao",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "仙桃"
    }, {
        "id": "51",
        "isInternational": false,
        "name": "宣城",
        "pinyin": "xuancheng",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "宣城"
    }, {
        "id": "61",
        "isInternational": false,
        "name": "厦门",
        "pinyin": "xiamen",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "厦门"
    }, {
        "id": "160",
        "isInternational": false,
        "name": "新乡",
        "pinyin": "xinxiang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "新乡"
    }, {
        "id": "161",
        "isInternational": false,
        "name": "信阳",
        "pinyin": "xinyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "信阳"
    }, {
        "id": "162",
        "isInternational": false,
        "name": "许昌",
        "pinyin": "xuchang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "许昌"
    }, {
        "id": "194",
        "isInternational": false,
        "name": "咸宁",
        "pinyin": "xianning",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "咸宁"
    }, {
        "id": "196",
        "isInternational": false,
        "name": "孝感",
        "pinyin": "xiaogan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "孝感"
    }, {
        "id": "205",
        "isInternational": false,
        "name": "湘潭",
        "pinyin": "xiangtan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "湘潭"
    }, {
        "id": "206",
        "isInternational": false,
        "name": "湘西土家族苗族自治州",
        "pinyin": "xiangxitujiazumiaozuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "湘西"
    }, {
        "id": "230",
        "isInternational": false,
        "name": "徐州",
        "pinyin": "xuzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "徐州"
    }, {
        "id": "242",
        "isInternational": false,
        "name": "新余",
        "pinyin": "xinyu",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "新余"
    }, {
        "id": "269",
        "isInternational": false,
        "name": "锡林郭勒盟",
        "pinyin": "xilinguolemeng",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "锡林郭勒盟"
    }, {
        "id": "270",
        "isInternational": false,
        "name": "兴安盟",
        "pinyin": "xinganmeng",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "兴安盟"
    }, {
        "id": "281",
        "isInternational": false,
        "name": "西宁",
        "pinyin": "xining",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "西宁"
    }, {
        "id": "308",
        "isInternational": false,
        "name": "忻州",
        "pinyin": "xinzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "忻州"
    }, {
        "id": "318",
        "isInternational": false,
        "name": "咸阳",
        "pinyin": "xianyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "咸阳"
    }, {
        "id": "6571",
        "isInternational": true,
        "name": "新北",
        "pinyin": "xinbei",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "新北"
    }, {
        "id": "395",
        "isInternational": true,
        "name": "香港",
        "pinyin": "xianggang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "香港"
    }],
    Y: [{
        "id": "381",
        "isInternational": false,
        "name": "玉溪",
        "pinyin": "yuxi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "玉溪"
    }, {
        "id": "339",
        "isInternational": false,
        "name": "宜宾",
        "pinyin": "yibin",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "宜宾"
    }, {
        "id": "92",
        "isInternational": false,
        "name": "阳江",
        "pinyin": "yangjiang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "阳江"
    }, {
        "id": "231",
        "isInternational": false,
        "name": "盐城",
        "pinyin": "yancheng",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "盐城"
    }, {
        "id": "209",
        "isInternational": false,
        "name": "岳阳",
        "pinyin": "yueyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "岳阳"
    }, {
        "id": "5120",
        "isInternational": true,
        "name": "云林",
        "pinyin": "yunlin",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "云林"
    }, {
        "id": "338",
        "isInternational": false,
        "name": "雅安",
        "pinyin": "yaan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "雅安"
    }, {
        "id": "93",
        "isInternational": false,
        "name": "云浮",
        "pinyin": "yunfu",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "云浮"
    }, {
        "id": "111",
        "isInternational": false,
        "name": "玉林",
        "pinyin": "yulin",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "玉林"
    }, {
        "id": "180",
        "isInternational": false,
        "name": "伊春",
        "pinyin": "yichun",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "伊春"
    }, {
        "id": "197",
        "isInternational": false,
        "name": "宜昌",
        "pinyin": "yichang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "宜昌"
    }, {
        "id": "207",
        "isInternational": false,
        "name": "益阳",
        "pinyin": "yiyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "益阳"
    }, {
        "id": "208",
        "isInternational": false,
        "name": "永州",
        "pinyin": "yongzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "永州"
    }, {
        "id": "220",
        "isInternational": false,
        "name": "延边朝鲜族自治州",
        "pinyin": "yanbianchaoxianzuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "延边"
    }, {
        "id": "232",
        "isInternational": false,
        "name": "扬州",
        "pinyin": "yangzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "扬州"
    }, {
        "id": "243",
        "isInternational": false,
        "name": "宜春",
        "pinyin": "yichun",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "宜春"
    }, {
        "id": "244",
        "isInternational": false,
        "name": "鹰潭",
        "pinyin": "yingtan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "鹰潭"
    }, {
        "id": "258",
        "isInternational": false,
        "name": "营口",
        "pinyin": "yingkou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "营口"
    }, {
        "id": "274",
        "isInternational": false,
        "name": "银川",
        "pinyin": "yinchuan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "银川"
    }, {
        "id": "282",
        "isInternational": false,
        "name": "玉树藏族自治州",
        "pinyin": "yushuzangzuzizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "玉树"
    }, {
        "id": "297",
        "isInternational": false,
        "name": "烟台",
        "pinyin": "yantai",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "烟台"
    }, {
        "id": "309",
        "isInternational": false,
        "name": "阳泉",
        "pinyin": "yangquan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "阳泉"
    }, {
        "id": "310",
        "isInternational": false,
        "name": "运城",
        "pinyin": "yuncheng",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "运城"
    }, {
        "id": "319",
        "isInternational": false,
        "name": "延安",
        "pinyin": "yanan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "延安"
    }, {
        "id": "320",
        "isInternational": false,
        "name": "榆林",
        "pinyin": "yulin",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "榆林"
    }, {
        "id": "366",
        "isInternational": false,
        "name": "伊犁哈萨克自治州",
        "pinyin": "yilihasakezizhizhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "伊犁"
    }, {
        "id": "5115",
        "isInternational": true,
        "name": "宜兰",
        "pinyin": "yilan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "宜兰"
    }],
    Z: [{
        "id": "62",
        "isInternational": false,
        "name": "漳州",
        "pinyin": "zhangzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "漳州"
    }, {
        "id": "382",
        "isInternational": false,
        "name": "昭通",
        "pinyin": "zhaotong",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "昭通"
    }, {
        "id": "3105",
        "isInternational": false,
        "name": "中卫",
        "pinyin": "zhongwei",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "中卫"
    }, {
        "id": "120",
        "isInternational": false,
        "name": "遵义",
        "pinyin": "zunyi",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "遵义"
    }, {
        "id": "392",
        "isInternational": false,
        "name": "舟山",
        "pinyin": "zhoushan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "舟山"
    }, {
        "id": "341",
        "isInternational": false,
        "name": "自贡",
        "pinyin": "zigong",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "自贡"
    }, {
        "id": "76",
        "isInternational": false,
        "name": "张掖",
        "pinyin": "zhangye",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "张掖"
    }, {
        "id": "94",
        "isInternational": false,
        "name": "湛江",
        "pinyin": "zhanjiang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "湛江"
    }, {
        "id": "95",
        "isInternational": false,
        "name": "肇庆",
        "pinyin": "zhaoqing",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "肇庆"
    }, {
        "id": "96",
        "isInternational": false,
        "name": "中山",
        "pinyin": "zhongshan",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "中山"
    }, {
        "id": "97",
        "isInternational": false,
        "name": "珠海",
        "pinyin": "zhuhai",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "珠海"
    }, {
        "id": "149",
        "isInternational": false,
        "name": "张家口",
        "pinyin": "zhangjiakou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "张家口"
    }, {
        "id": "163",
        "isInternational": false,
        "name": "郑州",
        "pinyin": "zhengzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "郑州"
    }, {
        "id": "164",
        "isInternational": false,
        "name": "周口",
        "pinyin": "zhoukou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "周口"
    }, {
        "id": "165",
        "isInternational": false,
        "name": "驻马店",
        "pinyin": "zhumadian",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "驻马店"
    }, {
        "id": "210",
        "isInternational": false,
        "name": "张家界",
        "pinyin": "zhangjiajie",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "张家界"
    }, {
        "id": "211",
        "isInternational": false,
        "name": "株洲",
        "pinyin": "zhuzhou",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "株洲"
    }, {
        "id": "233",
        "isInternational": false,
        "name": "镇江",
        "pinyin": "zhenjiang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "镇江"
    }, {
        "id": "298",
        "isInternational": false,
        "name": "枣庄",
        "pinyin": "zaozhuang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "枣庄"
    }, {
        "id": "299",
        "isInternational": false,
        "name": "淄博",
        "pinyin": "zibo",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "淄博"
    }, {
        "id": "340",
        "isInternational": false,
        "name": "资阳",
        "pinyin": "ziyang",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "资阳"
    }, {
        "id": "5118",
        "isInternational": true,
        "name": "彰化",
        "pinyin": "zhanghua",
        "nameSuffix": "",
        "cityType": "1",
        "nameSimple": "彰化"
    }]
};
export default CHINA_HOTEL_CITY_LIST
