import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css'
import 'vant/lib/index.less'
import './styles/style.less'
import './styles/iconfont/iconfont.css'
import './styles/font_21_1_8/iconfont.css'
import Vant from 'vant';
import { Toast } from 'vant';
import Moment from 'moment'
import 'moment/locale/zh-cn'
import { Tooltip, Select, Option } from "element-ui";
import '@/permission'
import '@/common/vueSentry'

Vue.filter('comverTime', function (data, format) {
  return Moment(data).format(format);
});
Toast.setDefaultOptions({ duration: 5000, closeOnClick: true });

Vue.use(Vant);
Vue.use(Tooltip)
Vue.use(Select)
Vue.use(Option)
Vue.use(Toast);

//暂时用在选择城市后，返回前一个页面，带回城市信息用
Vue.prototype.$bus = new Vue();

// 设置window的title，有默认值。使用方式：window.setTitle(name)
Window.prototype.setTitle = function (title = '神州浩天商旅平台') { window.document.title = title }

// 提取字符串中的数字
String.prototype.fliterNumber = function () {
  return this.replace(/[^0-9]/ig, "");
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
