import request from '@/services/request'

// 获取酒店列表
export function getHotelList(data) {
    return request({
        url: 'v1/hotel/getHotelList',
        method: 'post',
        data
    })
}

// 获取酒店详情
export function getHotelDetail(data) {
    return request({
        url: 'v1/hotel/getHotelDetail',
        method: 'post',
        data
    })
}

// 获取房型查询
export function getHotelRoomDetail(data) {
    return request({
        url: 'v1/hotel/getHotelRoomDetail',
        method: 'post',
        data
    })
}

// 酒店差标
export function getHotelStandard(data) {
    return request({
        url: 'v1/hotel/getHotelStandard',
        method: 'post',
        data
    })
}

// 下单
export function createHotelOrder(data) {
    return request({
        url: 'v1/hotel/createHotelOrder',
        method: 'post',
        data
    })
}

// 酒店--根据城市获取行政中心
export function getHotelDistrict(data) {
    return request({
        url: 'v1/hotel/getHotelDistrict',
        method: 'post',
        data
    })
}

// 获取订单列表
export function getMyHotelOrders(data) {
    return request({
        url: 'v1/hotel/getMyHotelOrders',
        method: 'post',
        data
    })
}

// 订单详情
export function hotelOrderReload(data) {
    return request({
        url: 'v1/hotel/hotelOrderReload',
        method: 'post',
        data
    })
}

// 取消订单
export function cancelHotelOrder(data) {
    return request({
        url: 'v1/hotel/cancelHotelOrder',
        method: 'post',
        data
    })
}

// 提交订单
export function commitHotelOrder(data) {
    return request({
        url: 'v1/hotel/commitHotelOrder',
        method: 'post',
        data
    })
}

// 提交订单
export function getHotelFilterInfo(data) {
    return request({
        url: 'v1/hotel/getHotelFilterInfo',
        method: 'post',
        data
    })
}