// 酒店相关功能
import {
    HotelRed,
    HotelOrange,
    HotelBlue,
    HotelStandType
} from "@/common/config";
import { getHotelList } from "@/api/hotel";
import { HotelServiceProvider } from "@/common/config"
import CHINA_HOTEL_LIST from "../common/国内酒店城市list";

/**
 * 返回酒店选房需要用的数组，[[房间一],[房间二],[]]  3个人最多3间房
 * @param {number} len 人员数量 
 */
export function initRoomArray(len){
    len++
    let result = []
    for (let i = 0; i < len; i++) {
        result.push([])
    }
    return result
}

/**
 * 判断酒店是否超标
 * 针对第二个和第三个参数做如下解释
 * 8个人住容量2的房间，三种情况：必然超标、 必然合规、主观能动（舍弃）
 * 2个人住容量2的房间，两种情况：超标、合规
 * @param {Array} hotelPrice 酒店价格列表，多天
 * @param {Array} peoList 人员列表
 * @param {number} capacity 容量
 * 
 */
export function standardJudge(hotelPrice = [{ price: 0 }], peoList = [{ hotelMaxPrice: 0 }], capacity=1) {
    let maxHotPrice = 0, minHotPrice = hotelPrice[0].price;      // 酒店单日最高价格、最低价格
    let maxPeoPrice = 0,minPeoPrice = peoList[0].hotelMaxPrice;    // 人员最高标准、最低标准
    let standardArr = []                // 人员差标数组：低→高。 （长度>=容量，不足处为0）
    let maxPeoCapPrice = 0              // 房间住满后,所有人员差标之和  （最高）
    hotelPrice.forEach(element => {
        maxHotPrice = Math.max(maxHotPrice,parseInt(element.price))
        minHotPrice = Math.min(minHotPrice,parseInt(element.price))
    });
    peoList.forEach(element => {
        maxPeoPrice = Math.max(maxPeoPrice,parseInt(element.hotelMaxPrice))
        minPeoPrice = Math.min(minPeoPrice,parseInt(element.hotelMaxPrice))
        standardArr.push(parseInt(element.hotelMaxPrice))
    });
    standardArr.sort().reverse()
    // 补齐容量差方便下方计算
    for (let index = capacity - peoList.length; index >= 0; index--) {
        standardArr.push(0)
    }
    for (let i = 0; i < capacity; i++) {
        const element = standardArr[i];
        maxPeoCapPrice += element
    }

    // 判断开始了
    switch (HotelStandType) {
        // 浩天政策
        case "HT":
            // 单人240 多人300
            if (minHotPrice <= minPeoPrice) return {status: '全员合规'}
            else if (capacity <= 1 && minHotPrice > maxPeoPrice) return {status: '全员超标', max: maxPeoPrice}
            else if (capacity > 1 && minHotPrice > 300) return {status: '全员超标', max: 300}
            else return '主观能动'
        // 大唐政策
        case "DT":
        default:
            // 单人淡旺季价格，多人淡旺季价格之和
            if (minHotPrice <= minPeoPrice) return {status: '全员合规'}
            else if (capacity <= 1 && minHotPrice > maxPeoPrice) return {status: '全员超标', max: maxPeoPrice}
            else if (capacity > 1 && minHotPrice > maxPeoCapPrice) return {status: '全员超标', max: maxPeoCapPrice}
            else return '主观能动'
    }
}

/**
 * 获取入住日期最高单价 - 参数如下
 * [ {
 *   "breakfastCount": 0,
 *   "date": "2021-02-07",
 *   "price": 152,
 *   "canBook": false
 * }]
 */
function getMaxPrice(ticketInfo){
    let max = 0
    ticketInfo.forEach(element => {
        max = Math.max(max,parseInt(element.price))
    });
    return max
}

/**
 * 返回指定城市的id
 * @param {String} city 城市全称
 * @returns {String} cityCode 城市code
 */
export function FetchCityId(city) {
    let code = ""
    for (let key in CHINA_HOTEL_LIST) {
        CHINA_HOTEL_LIST[key].forEach(element => {
            if (element.name == city) {
                code = element.id
            }
        });
        if (code != "") break;
    }
    return code
}

// 下方根据经纬度计算距离（地球是个球）
const EARTH_RADIUS = 6378137.0;    //单位M
const PI = Math.PI;

function getRad(d) {
    return d * PI / 180.0;
}

// 获得两地经纬度距离
export function getGreatCircleDistance(lat1, lng1, lat2, lng2) {
    let radLat1 = getRad(lat1);
    let radLat2 = getRad(lat2);

    let a = radLat1 - radLat2;
    let b = getRad(lng1) - getRad(lng2);

    let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
    s = s * EARTH_RADIUS;
    s = Math.round(s * 10000) / 10000.0;

    return s;
}


/**
 * 生成一个选房配置，不同性别分居
 * @param {number} capacity 房间容量
 * @param {Array} personList 人员信息
 * @returns {Object}
 *      personList: 人员列表，新增了roomNo属性
 *      total： 房间数
 *      roomPeo: select中人员标签所使用的数据 
 */
export function Recommend(capacity = 1, personList = []) {
    let maxLen = personList.length
    capacity = capacity > 0 ? capacity : 1 // 房间至少可住1人
    // 男人数量   女人数量  性别不详数量  男人房号     女人房号        性别不详房号   
    let man = 0, woman = 0, other = 0, manRoom = 0, womanRoom = 0, otherRoom = 0;
    let roomPeo = {} // { 1: [], 2: [], 3: [], 4: [], 5: [] }
    for (let i = 1; i <= maxLen; i++) {
        roomPeo[i]=[]
    }
    personList.forEach(element => {
        if (element.gender == "男") {
            let tempRoom = Math.ceil(++man / capacity)
            if(manRoom<tempRoom) manRoom = Math.max(manRoom,womanRoom,otherRoom) + 1 // 如果当前房号满了 计算下一房号
            element.roomNo = manRoom // 指派房号
            roomPeo[manRoom].push(element.idCard) // 房间追加人
        }
        else if (element.gender == "女") {
            let tempRoom = Math.ceil(++woman / capacity)
            if(womanRoom<tempRoom) womanRoom = Math.max(manRoom,womanRoom,otherRoom) + 1 // 如果当前房号满了 计算下一房号
            element.roomNo = womanRoom
            roomPeo[womanRoom].push(element.idCard)
        }
        else {
            let tempRoom = Math.ceil(++other / capacity)
            if(otherRoom<tempRoom) otherRoom = Math.max(manRoom,womanRoom,otherRoom) + 1 // 如果当前房号满了 计算下一房号
            element.roomNo = otherRoom
            roomPeo[otherRoom].push(element.idCard)
        }
    });
    let totalRoom = Math.max(manRoom, womanRoom, otherRoom)
    return {
        personList,
        total: totalRoom > 5 ? 5 : totalRoom,
        roomPeo: roomPeo
    }
}

/**
 * 获取经纬度  注意！受限于百度api形式，该函数异步。后续操作使用回调函数完成。少用
 * @returns {
 *      longitude: float 经度,
        latitude: float 维度
 * }
 */
export function getPosition(cb = () => { }) {
    let geoLocation = new BMap.Geolocation();
    return geoLocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
            cb({
                longitude: r.point.lng,
                latitude: r.point.lat
            })
        }
        else {
            return {
                longitude: -1,
                latitude: -1
            }
        }
    });
}

/**
 * 搜索地址  注意！受限于百度api形式，该函数异步。后续操作使用回调函数完成。少用
 * @param {String} key // 关键字
 * @param {function} city // 城市
 * @param {function} cb() // 回调，参数为 经纬度
 */
export function searchPosition(key = "", city = "北京", cb = () => { }) {
    key = key || city //没有关键字就搜城市
    city = city || key
    var local = new BMap.LocalSearch(city, {
        onSearchComplete: function (results) {
            if (local.getStatus() == BMAP_STATUS_SUCCESS) {
                let point = results.Hr[0].point  // {lat:number,lng:number}
                cb(point, results)
            }
        }
    });
    local.search(key);
}

/**
 * 获取状态颜色。（对应到button的type字段，例如红色返回danger）
 * @param {Sting} text 
 */
export function getStatusColor(text = '占座中') {
    if (HotelRed.includes(text.trim())) return "danger";
    if (HotelOrange.includes(text.trim())) return "warning";
    if (HotelBlue.includes(text.trim())) return "info";
    return "default";
}

/**
 * 列表排序  PA价格升序 PD价格降序 DA距离升序
 * @param {Array} list ajax获得的数据 
 * @param {Array} type 排序方式 
 */
export function sortList(list, type, ids = HotelServiceProvider) {
    let corps = ids.filter(e => HotelServiceProvider.includes(e))
    let arr = list;
    if (0 in corps)
        arr = arr.filter(e => {
            return ids.includes(e.corp)
        });
    switch (type.sort) {
        case 'PD':
            arr.sort((a, b) => {
                return b.price - a.price
            })
            break;
        case 'PA':
            arr.sort((a, b) => {
                return a.price - b.price
            })
            break;
        case 'DA':
            arr.sort((a, b) => {
                return a.distance - b.distance
            })
            break;
    }
    return arr;
}


/**
 * ----------- 下方酒店列表的实现类 ----------- 
 */
class htoelList {
    _list = []
    _refresh = 0 // 刷新量
    _supplier = [] // 已获取到的供应商
    _total = 0  // 已有供应商的总条目
    check = 0
    get list() { return this._list }    // 提供给外部的列表
    get total() { return this._total }  // 提供给外部总条数
    get supplier() { return this._supplier }  // 提供给外部当前供应商数量

    // 往列表中追加数据，不同刷新量不会追加
    setList(newData, refresh, jsonString) {
        if (this._refresh == refresh) {
            let req = JSON.parse(jsonString)
            this._list.push(...newData);
            this.check++;
            this._total += (0 in newData) ? newData[0].total : 0
            this._supplier.push(req.corp)
        }
    }

    // 刷新列表。清空且刷新量++
    resetList() {
        this._total = 0
        this._refresh++
        this._supplier = []
        this._list = []
    }

    // 更新数据，分发ajax
    update(query, ids) {
        let city = '' // 市区，依据“：”做为判断
        ids.forEach(a => {
            if (a.includes(":")) city = a.split(':')[0]
        })
        searchPosition(query.key, query.city + " " + city, position => { // 获取新的经纬度
            this._supplier = []
            ids = ids.filter(e => {
                return HotelServiceProvider.includes(e)
            })
            HotelServiceProvider.forEach(async (corp) => {
                const refreshf = this._refresh // 记录一下key
                query.latitude = position.lat
                query.longitude = position.lng
                query.corp = corp
                let req = JSON.stringify(query)
                if (0 in ids && !ids.includes(corp)) return this.setList([], refreshf, req)
                let result = await fetchHotelList(req, position)
                this.setList(result, refreshf, req)
            })
        })
    }

    // 列表排序，ajax完成后手动调用下
    sort(type, ids) {
        this._list = sortList(this._list, type, ids)
    }
}

// 获得实例
export function init() { return new htoelList }

// 获取酒店列表ajax接口
export function fetchHotelList(query) {
    return new Promise(function (resolve, reject) {
        let req = JSON.parse(query)
        getHotelList(req).then(response => {
            let list = OrganizeHotelList(response)
            resolve(list)
        }).catch(error => {
            resolve([])
        })
    })
}

// 整理酒店列表
function OrganizeHotelList(list = []) {
    let arr = []
    list.forEach(first => {
        arr.push(...first.data)
    });
    return arr
}