<template>
  <div class="plan" id="plan">
    <van-sticky>
      <van-dropdown-menu class="head-background">
        <van-dropdown-item v-model="value2" :options="option2" @change="listfilter" />
        <van-dropdown-item v-model="value3" :options="option3" @change="listfilter" />
        <van-dropdown-item title="查找" ref="item">
          <van-search v-model="search" show-action placeholder="请输入人名或目的地" @search="listfilter">
            <template #action>
              <div @click="listfilter">搜索</div>
            </template>
          </van-search>
        </van-dropdown-item>
      </van-dropdown-menu>
    </van-sticky>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-steps direction="vertical" :active="-1" style="min-height:calc(100vh - 100px)">
        <van-list 
          v-model="loading"
          :finished="finished"
          :finished-text="trainList.length==0?'':'没有更多了'"
          :loading-text="trainList.length==0?' ':'加载中...'"
          @load="onLoad">
          <van-step v-for="(item,index) in trainList" :key="index">
            <van-tag
              round
              plain
              color="#4a4a4a"
              class="time"
            >下单日期：{{item[0].createTime | comverTime('YYYY-MM-DD')}}</van-tag>
            <div v-for="(detail,i) in item" :key="i">
              <div class="gap"></div>
              <component :data="detail" v-bind:is="detail.type" @click.native="goDetail(detail)"></component>
            </div>
          </van-step>
        </van-list>
        <van-empty v-if="trainList.length==0" :description="loading?'加载中...':'暂无订单'" style="transform: translateX(-16px);"/>
      </van-steps>
    </van-pull-refresh>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import aircraft from "./component/PlaneItem";
import hotel from "./component/HotelItem";
import train from "./component/TrainItem";
import car from "./component/CarItem";
import { FetchHistoryList, FilterList, HistoryReset } from "@/services/plan";

export default {
  name: "Plan",
  mounted() {
    this.onRefresh()
  },
  // activated() {
  //   // 清空并重新拉取数据
  //   if (this.planRefresh || !(0 in this.trainList)) {
  //     this.$store.dispatch("project/setPlanRefresh", false); // 修改vuex中的刷新变量
  //     HistoryReset();
  //     this.search = "";
  //     this.value2 = "";
  //     this.value1 = "";
  //   }
  // },
  computed: {
    ...mapGetters(["personList", "travelInfo", "planRefresh"]),
  },
  components: { aircraft, hotel, train, car },
  data() {
    return {
      search: "", //查找的关键字
      value2: "",
      value3: "",
      option2: [
        { text: "全部类型", value: "" },
        { text: "火车", value: "train" },
        { text: "飞机", value: "aircraft" },
        { text: "酒店", value: "hotel" },
      ],
      option3: [
        { text: "全部状态", value: "" },
        { text: "已出票", value: "出票" },
        { text: "待处理", value: "待" },
        { text: "退票", value: "退票" },
        { text: "取消", value: "取消" },
        { text: "过期", value: "过期" },
      ],
      trainList: [],
      loading: false,
      finished: false,
      refreshing: false,
      page: 1,
      pagesize: 10,
    };
  },
  methods: {
    // 筛选列表
    listfilter() {
      this.trainList = FilterList({
        type: this.value2,
        status: this.value3,
        names: this.search,
      });
    },
    // 跳转详情
    goDetail(detail) {
      this.$store.dispatch("history/setOrderItem", detail);
      switch (detail.type) {
        case "train":
          return this.$router.push("/payment");
        case "aircraft":
          return this.$router.push("/paymentAircraft");
        case "hotel":
          return this.$router.push("/paymentHotel");
      }
    },
    // 上拉加载
    onLoad(f=true) {
      // 这个if语句功能，参见bug#001
      if(f&&this.trainList.length==0) {
        this.finished = false;
        this.loading = false;
        return
      }
      // 加载请求
      this.loading = true;
      setTimeout(() => {
        if (this.refreshing) {
          this.trainList = [];
          this.refreshing = false;
        }

        if (!this.finished)
          FetchHistoryList(
            this.travelInfo.loginUser.usercode,
            this.page++,
            this.pagesize,
            {
              type: this.value2,
              status: this.value3,
            }
          )
            .then((data) => {
              this.trainList = data.list.map((e) => {
                return e.sort((a, b) => {
                  return (
                    b.createTime.fliterNumber() - a.createTime.fliterNumber()
                  );
                });
              });
              this.listfilter();
              this.finished = data.finish;
              this.loading = false;
            })
            .catch((err) => {
              this.finished = true;
              this.loading = false;
            });

      }, 400);
    },
    // 下拉重置
    onRefresh() {
      HistoryReset();
      this.finished = false;
      this.page = 1;
      this.loading = true;
      this.onLoad(false);
    },
  },
};
</script>

<style lang="less">
@import '../../styles/theme.less';
#plan {
  margin-bottom: 50px;
  .van-step,
  .van-step__circle {
    color: @gray-8;
  }
  .van-step__line {
    background-color: @gray-3;
  }
  .van-steps {
    background-color: @gray-1;
  }
  .van-calendar__header-title {
    display: none; // 隐藏下拉日历的标题
  }
  .van-loading {
    padding-right: 30px;
  }
}
</style>
<style lang="less" scoped>
.time {
  padding: 2px 8px;
}
</style>