/**
 * 聚合订相关
 */
import { DiffDate } from "@/services/date.js";

const state = {
    // 行程列表
    polyList: []
}

const mutations = {
    // 依照时间插入到数组
    Insert_PolyList: (state, info) => {
        state.polyList.push(info)
        let len = state.polyList.length
        let time = info.trainBeginDate||info.departDate||info.checkInDate
        for (let i = len-2; i >= 0; i--) {
            let element = state.polyList[i];
            let elementTime = element.trainBeginDate||element.departDate||element.checkInDate
            if(DiffDate(time,elementTime)>0){
                [state.polyList[i+1],state.polyList[i]]=[state.polyList[i],state.polyList[i+1]];
            }
            else break
        }
    },
    // 清空
    Empty_PlanRefresh: (state) => {
        state.polyList = []
    },
    // 删除一个
    Del_PlanRefresh: (state, i) => {
        state.polyList.splice(i,1)
    },

}

const actions = {
    insertPlanRefresh({ commit }, f) {
        commit('Insert_PolyList', f)
    },
    emptyPlanRefresh({ commit }) {
        commit('Empty_PlanRefresh')
    },
    delOnePlanRefresh({ commit },i) {
        commit('Del_PlanRefresh',i)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}