class CancelAxios {
    _list = [] // CancelToken，在ajax拦截器中查看

    get list() {
        return this._list;
    }

    constructor() {
        //首次使用构造器实例
        if (!CancelAxios.instance) {
            //将this挂载到SingletonApple这个类的instance属性上
            CancelAxios.instance = this;
        }
        return CancelAxios.instance;
    }

    push(arr = []) {
        this._list.push(arr);
    }
}

// 获得实例
export function init() { return new CancelAxios }