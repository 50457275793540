import { render, staticRenderFns } from "./App.vue?vue&type=template&id=770d1add&scoped=true&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&id=770d1add&lang=less&scoped=true&"
import style1 from "./App.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "770d1add",
  null
  
)

export default component.exports