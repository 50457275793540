<template>
    <van-step class="node">
        <template #inactive-icon>
            <Icon :classification="val.classType" />
        </template>
        <div class="time">
            <div
            class="MMDD nowrap list-text-big"
            >{{(val.planEndDate||val.arriveTime||val.checkOut)| comverTime('MM-DD')}}</div>
            <div class="hhmm" v-if="val.classType!='hotel'">{{(val.planEndDate||val.arriveTime)| comverTime('HH:mm')}}</div>
        </div>
        <div style="min-height:36px">
            <van-row type="flex" justify="space-between">
            <div>
                <van-row type="flex" justify="space-between">
                <div
                    class="list-text-big"
                    v-if="val.toStation||val.arriveAirPort"
                >{{val.trainNo||val.flightNo}}到达{{val.toStation||val.arriveAirPort}}</div>
                <div class="list-text-big" v-if="val.hotelName">离开 <span v-if="val.city">【{{val.city}}】</span>{{val.hotelName}}</div>
                </van-row>
            </div>
            </van-row>
        </div>
    </van-step>
</template>

<script>
import Icon from "./Icon";
export default {
  name: "PolEnd",
  components: { Icon },
  props: ["val"],
};
</script>

<style lang="less" src="@/styles/polymerization.less" scoped></style>
