<template>
  <!-- 列表 -->
  <van-steps direction="vertical" :active="-1" class="memorabilia-cont">
    <!-- 列表条目 -->
    <template v-for="(val,i) in timeList">
      <!-- 列表条目1-开始-动态组件 -->
      <component
        :key="i"
        v-bind:is="val.classification"
        :val="val"
        @refresh="$emit('refresh')"
        @goTC="goTC"
      ></component>
    </template>
  </van-steps>
</template>

<script>
import Icon from "./components/Icon";
import error from "./components/Error";
import train from "./components/Train";
import hotel from "./components/Hotel";
import end from "./components/End";
import aircraft from "./components/Aircraft";
import { mapGetters } from "vuex";
export default {
  components: { Icon, train, hotel, aircraft, error, end },
  props:['timeList'],
  computed: {
    ...mapGetters(["travelInfo"]),
  },
  data() {
    return {
      // timeList:[], // 列表
    };
  },
  methods: {
    goTC(applyNo) {
      this.$emit('goTC',applyNo)
    },
  },
};
</script>

<style lang="less" src="@/styles/polymerization.less" scoped></style>
<style lang="less" scoped>
@import '../../styles/theme.less';
.del-icon {
  font-size: 1.3rem;
  color: @red;
  padding: 0.2rem;
}
.contain {
  min-height: 2rem;
}
.memorabilia-cont {
  padding-left: 5.3rem !important;
}
.small{
  font-size: 12px;
}
</style>

<style lang="less">
.memorabilia-cont {
  .van-step__icon--active {
    font-size: 1.3rem;
    background-color: #fff;
  }
  .van-icon-diamond-o {
    padding-right: 0.6rem;
  }
}
</style>

