<template>
  <!-- 列表条目1-结束日期 -->
  <van-step class="node">
    <template #inactive-icon>
      <Icon :classification="val.classification" />
    </template>
    <!-- 左侧时间区域 -->
    <div class="time">
      <div class="MMDD nowrap list-text-big">{{val.planBeginDate | comverTime('MM-DD')}}</div>
      <div class="hhmm">{{val.planBeginDate | comverTime('HH:mm')}}</div>
    </div>
    <!-- 右侧信息区域 -->
    <van-row class="contain" type="flex" justify="space-between">
      <!-- 左半边文本信息 -->
      <div class="left-cont">
        <div class="list-text-big">{{val.fromStation }}->{{val.toStation}}</div>
        <div class="text-sm footer" v-if="val.seatNo">席位：{{val.seatClass}},{{val.seatNo}}</div>
        <div class="text-sm footer" v-if="val.runTimeSpan">历时：{{val.runTimeSpan}}</div>
        <div class="text-sm footer" v-if="val.trainNo">车次：{{val.trainNo}}</div>
        <div class="text-sm footer" v-if="val.corp=='同程自付'">付款方式：自付</div>
      </div>
      <!-- 右半边-价格-删除 -->
      <van-col class="col" @click="goDetail(val)">
        <span class="list-text-big red">￥{{val.ticketPrice}}</span>
        <span class="list-text-big warning" style="display:flex" v-if="orderStatus=='占座中'">占座中&nbsp;<van-loading color="#ff976a" size="12px"/></span>
        <span :class="'list-text-big '+statusColor(orderStatus)" v-else>{{orderStatus}}</span>
        <div style="text-align:right">
          <span class="blue">详情 >></span> 
        </div>
      </van-col>
    </van-row>
  </van-step>
</template>

<script>
import Icon from "./Icon";
import { getStatusColor, getTicketStatus } from "@/services/train.js";
import { fetchOrderStatus } from "@/services/utils.js";
import { applyIssueOrder } from "@/api/train";
import { TrainOrderConfirmBtn } from "@/common/config.js";
const intermediate= ['占座中','待提交'] // 中间状态 - 除此以外其他状态不显示。展示票状态
export default {
  name: "PolTrain",
  components: { Icon },
  props: ["val"],
  computed: {
    orderStatus: function () {
    let text = fetchOrderStatus(this.val.corp,'train',this.val.orderStatus)
      if(intermediate.includes(text)) return text
      if(!this.val.bz2) return getTicketStatus(this.val.ticketStatus)
      return this.val.bz2
    }
  },
  data() {
    return {
      config_trainOrderConfirmBtn:TrainOrderConfirmBtn, // 配置 - 可否出票
      confirmLoading:false, // 加载动画
    };
  },
  methods: {
    // 删除
    del() {
      this.$dialog
        .confirm({
          title: "提示",
          closeOnClickOverlay: true,
          message: "是否要删除该行程？",
        })
        .then(() => {
          this.$emit("del");
        });
    },
    // 前往详情
    goDetail(detail=this.val){
      if(detail.corp=="同程自付"){
        this.$emit('goTC',detail.applyNo)
      }
      else{
        this.$store.dispatch("history/setOrderItem", detail);
        this.$router.push("/payment");
      }
    },
    // 状态颜色
    statusColor(data) {
      return getStatusColor(data);
    },
    // 出票
    submit(){
      this.confirmLoading = true;
      applyIssueOrder({
        orderNo: this.val.orderNo,
        outOrderNo: this.val.outOrderNo,
        corp: this.val.corp,
        originalOrderNo: this.val.bz3||this.originalOrderNo,
        itemId: ""
      })
        .then(response => {
          // 如果经费不足提示。
          if (!response.success) {
            this.confirmLoading = false;
            return this.$toast.fail(response.msg);
          }
          this.$toast.success("申请出票成功，请稍后。");
          this.confirmLoading = false;
          this.$emit("refresh");
        })
        .catch(() => {
          this.confirmLoading = false;
        })
    }
  }
};
</script>
<style lang="less" src="@/styles/polymerization.less" scoped></style>
