<template>
  <div id="app">
    <transition v-if="$route.meta.trans_index>0" name="slide" appear
      :enter-active-class="'animate__animated animate__faster animate__'+transitionName.in"
      :leave-active-class="'animate__animated animate__faster animate__'+transitionName.out">
      <!-- 有动画-保活 -->
      <keep-alive v-if="$route.meta.keepAlive">
        <router-view></router-view>
      </keep-alive>
      <!-- 有动画-不保活 -->
      <router-view v-else style="min-height:100vh" />
    </transition>
    <!-- 无动画-保活 -->
    <keep-alive v-else-if="$route.meta.keepAlive">
      <router-view></router-view>
    </keep-alive>
    <!-- 无动画-不保活 -->
    <router-view v-else style="min-height:100vh" />
    <!-- 导航组件 -->
    <vuenime :value="count" :duration="1000" v-slot="theCount">
      <nav id="nav" :class="count > 100?'':'transparent '+textClass" :style="`height:${theCount}px;`"
        v-show="isView||$route.name!='travel'">
        <div class="item" @click="flod">
          <van-icon class="iconfont nav-icon" class-prefix="icon" name="toutiao" />
          <span class="nav-text">导航</span>
        </div>
        <router-link :to="isView?'/view':'/'" class="item" v-show="count>100">
          <van-icon class="iconfont nav-icon" class-prefix="icon" name="fangzi" />
          <span class="nav-text">首页</span>
        </router-link>
        <div class="item" v-show="count>100" @click="$router.back(-1)">
          <van-icon class="iconfont nav-icon" class-prefix="icon" name="fanhui" />
          <span class="nav-text">返回</span>
        </div>
        <a href="http://trip.szhtkj.com.cn:8000/" class="item" v-show="count>100">
          <van-icon class="iconfont nav-icon" class-prefix="icon" name="tuichu" />
          <span class="nav-text">退出</span>
        </a>
      </nav>
    </vuenime>
  </div>
</template>

<script>
import { Vuenime } from "vuenime";
import { record } from "@/common/vueSentry";
export default {
  components: { Vuenime },
  computed: {
    // 是否为预览页面
    isView: function() {
      return this.$route.path.toLocaleLowerCase().includes("view");
    }
  },
  data() {
    return {
      transitionName: {
        // 页面过渡动画
        in: "slideInRight",
        out: "slideOutLeft"
      },
      count: 41, // 左下角导航-缩放高度
      textClass: "", // 左下角导航-滚动时字体透明样式
      timer: "" // 左下角导航-节流控制器
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle); // 绑定页面的滚动事件
  },
  beforeDestory() {
    record();
  },
  methods: {
    flod() {
      this.count = this.count > 100 ? 41 : this.count * 4 + 20;
    },
    scrollHandle() {
      this.textClass = "text-transparent";
      window.clearTimeout(this.timer);
      this.timer = window.setTimeout(() => {
        this.textClass = "";
      }, 1000);
    }
  },
  watch: {
    // 路由动画
    $route(to, from) {
      // 判断是返回切换动画...这个组件不会销毁..所以一个缓存数组即可
      if (from.meta.trans_index < 0) {
        // 从没动话那也过来的比较特殊
        this.transitionName = {
          in: "slideInRight",
          out: "slideOutRight"
        };
      } else if (to.meta.trans_index < from.meta.trans_index) {
        this.transitionName = {
          in: "slideInLeft",
          out: "slideOutRight"
        };
      } else {
        this.transitionName = {
          in: "slideInRight",
          out: "slideOutLeft"
        };
      }
    }
  }
};
</script>

<style lang="less" scoped>
#app {
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.animate__animated {
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
// 导航组件
.transparent {
  background-color: rgba(255, 255, 255, 0.2) !important;
  .nav-icon,
  .nav-text {
    background-color: rgba(250, 250, 250, 0.6);
    opacity: 0.8;
  }
}
.text-transparent {
  .nav-icon,
  .nav-text {
    background-color: rgba(250, 250, 250, 0.2);
    opacity: 0.4;
  }
}
#nav {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  flex-direction: column-reverse;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0.5rem;
  position: fixed;
  padding: 0 8px;
  display: flex;
  left: 0.5rem;
  bottom: 8rem;
  overflow: hidden;
  min-height: 44px;
  .item {
    margin-top: 6px;
    height: 42px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #323233;
    .nav-icon {
      font-size: 1.4rem;
      line-height: 1.2rem;
      border-radius: 50%;
      transition: opacity 0.4s;
    }
    .nav-text {
      margin: 3px 0;
      font-size: 12px;
      line-height: 12px;
      transition: opacity 0.4s;
      border-radius: 50%;
      display: inline-block;
      padding: 1px;
    }
  }
}
</style>

<style>
/*** 兼容问题 ***/
@supports (bottom: constant(safe-area-inset-bottom)) or
  (bottom: env(safe-area-inset-bottom)) {
  .am-tab-bar-box,
  .am-tab-bar-box .am-tab-bar-bar {
    height: constant(60px + env(safe-area-inset-bottom));
    height: calc(60px + env(safe-area-inset-bottom));
  }

  .am-tab-bar-box .am-tab-bar-bar {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
}
</style>