/** 
 * 仅针对vue提供的demo。
 * 
 * 当前已实现功能：
 * 1、sentry监听错误
 * 2、自定义用户轨迹，信息级别为info，同时清洗info级别的url参数。 下单或满5条轨迹主动记录
 * 
 * 逻辑简述：利用router钩子组装轨迹在退出页面前（onbeforeunload）发送轨迹信息
 * 如需改造，参考文档：https://docs.sentry.io/
 */


/**********************    需要用到的包    ***********************/

import * as Sentry from '@sentry/browser'; // 监控 - 必选
import moment from "moment"; // 日期格式化插件
import router from '@/router';  // 路由，借用路由钩子记录信息
import store from '@/store'; // vuex 用户信息在这里


// 如果是本地就别一直往sentry里塞报告了，注销的话别忘记最下面的大括号
const banList = ['localhost', '127.0.0.1', '192.168.']
// const banList = []



/**********************    下方配置项    ***********************/

// 例：需要将sentry生成的dsn中 xxx@192.168.120.140:9000/2 替换为 xxx@221.238.155.34:12000/sentry/2  注意最后那个2不要换
const dsn = 'http://fc28a97cdc3d435fb6fcbf051bbec72d@221.238.155.34:12000/sentry_api/1'

// true时开启轨迹追踪，false不记录用户轨迹。（无需记录轨迹可不开）
const trail = true


// 获取用户信息的方法，  Q：为啥是函数而不是变量？ A：因为在main引入时不一定登录了，但是这段代码已经执行了。方法可以随用随取
// @return Object < username: String, mail: String > 当前所用到的用户信息。默认为空
function getUserInfo() {
    return store.getters.travelInfo.loginUser || {}
}



/***************     下方逻辑。    ***************/

let SentryMsg = [] // 用于记录的变量


if (banList.filter(e => window.location.hostname.includes(e)).length <= 0) {

    // 错误监控初始化 - 必须 - 初始化后错误就会自动发送到sentry了
    Sentry.init({
        dsn: dsn,  // dsn在上面配置

        // 记录以前的钩子 - 此例为聚合系统中删除info级别的url登录信息
        beforeSend(event) {
            if (event.level == 'info') {
                if (event.request) event.request.url = 'http://' + window.location.host + window.location.pathname
                if (event.breadcrumbs)
                    event.breadcrumbs.forEach(bread => {
                        if (Object.prototype.hasOwnProperty.call(bread, 'data')) {
                            if (Object.prototype.hasOwnProperty.call(bread.data, 'from')) bread.data.from = '/#/' + bread.data.from.split('#/')[1]
                            if (Object.prototype.hasOwnProperty.call(bread.data, 'to')) bread.data.to = '/#/' + bread.data.to.split('#/')[1]
                        }
                    })
            }
            return event;
        }
    });

    // 离开页面时钩子 - 记录轨迹
    window.onbeforeunload = function () { if (trail) record() }

    // 进入路由后的钩子：用来拼装轨迹 - 根据自身情况设计
    router.afterEach((to, from, next) => {
        if (trail) SentryMsg.push(`${to.path}【${moment().format('HH:mm:ss')}】`)// 组装轨迹
        if (SentryMsg.length > 5 || (from.path.includes('order') && (to.path.includes('payment') || to.path.includes('plan')))) record()
    })

}


export function record() {
    // 记录用户数据 - 可选
    Sentry.setUser({
        id: getUserInfo().username || '',
        username: getUserInfo().username | '',
        email: getUserInfo().mail || ''
    });

    // 记录轨迹上下文 - 可选（message过长会有省略号 上下文不会有省略号）
    Sentry.setContext("character", {
        name: (getUserInfo().username || '') + '轨迹',
        trail: SentryMsg.join('→'),
    });

    // 记录数据 - 【必须】
    Sentry.captureMessage((getUserInfo().username || '') + "轨迹");

    SentryMsg = []
}

