import { TravelFrom } from "@/common/config.js"
import { GetApplyTravelInfo } from "@/api/user";
import { ControlLevel } from "@/common/config.js";
/**
 * 项目相关信息，项目中的人员，项目类型 经费 起始站等。
 */

const state = {
    // 人员信息
    personList: [],
    // 差旅信息
    travelInfo: {},
    // 登录参数
    loginParam: '',
    // 是否刷新订单列表
    planRefresh: true,
    // 管控等级--以后可能从后台获取
    config_controlLevel: ControlLevel
}

const mutations = {
    SET_PlanRefresh: (state, info) => {
        state.planRefresh = info
    },
    SET_PERSONLIST: (state, info) => {
        info.forEach(element => {
            element.isTravel = element.isTravel == 'true' ? true : false
            let reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
            if (Object.prototype.hasOwnProperty.call(element, 'idCard') && reg.test(element.idCard)) {
                element.birthday = element.idCard.substring(6, 10) + "-" + element.idCard.substring(10, 12) + "-" + element.idCard.substring(12, 14);
                element.gender = element.idCard.substr(16, 1) % 2 == 1 ? '男' : '女'
            }
        });
        state.personList = info
    },
    SET_TRAVELINFO: (state, info) => {
        state.travelInfo = info
    },
    SET_APPPRICE: (state, price) => { // 设置余额
        state.travelInfo.appSurplus = price
    },
    SET_LOGINPARAM: (state, param) => { // 登录参数
        state.loginParam = param
    },
    Reset_ControlLevel: (state, param) => { // 重置管控等级
        state.config_controlLevel = ControlLevel
    },

}

const actions = {
    setPlanRefresh({ commit }, f) {
        commit('SET_PlanRefresh', f)
    },
    setLoginParam({ commit }, param) {
        commit('SET_LOGINPARAM', param)
    },
    setPersonList({ commit }, info) {
        commit('SET_PERSONLIST', info)
    },
    // 整理行程单信息，出发城市通过config配置
    setTravelInfo({ commit }, info) {
        if (Object.prototype.hasOwnProperty.call(info, 'travelUsers'))
            delete info["travelUsers"]
        info.travelType = info.travelType == "self" ? "因私" : "因公"
        if(!info.trainPayTypeFlag)info.trainPayTypeFlag="false" // 如果没有跳转信息，默认不跳
        info.travelFrom = info.routeCityEnd[0]
        if(info.routeCityEnd.length==1) info.routeCityEnd.push(info.routeCityEnd[0])
        info.appPrice = parseFloat(info.routePreMoney) // 设置总额
        commit('SET_TRAVELINFO', info)
    },
    cleanTravelInfo({ commit },) {
        commit('SET_TRAVELINFO', null)
    },
    // 更新余额，顺带比较返回：真假
    // 页面用到的字段：appPrice 总额 appSurplus 余额
    updateAppPrice({ commit, state }, price = 0) {
        return new Promise(function (resolve, reject) {
            if (state.travelInfo)
                GetApplyTravelInfo({ applyNo: state.travelInfo.appId }).then(response => {
                    let surPrice = parseFloat(response.currentEstimate || state.travelInfo.appPrice)
                    commit('SET_APPPRICE', surPrice)
                    resolve(price <= surPrice);
                })
                    .catch(err => {
                        resolve(false); // 报错控死 
                    });
            else resolve(false);
        })
    },
    mergeTicket({ commit }, info) {
        let list = state.personList
        info.forEach(ticket => {
            list.forEach(person => {
                if (person.tel == ticket.tel) {
                    person.ticket = ticket
                    person.ticket.type = 2
                }
            });
        });
        commit('SET_PERSONLIST', list)
    },
    resetControlLevel({ commit }) {
        commit('Reset_ControlLevel')
    },
    // 合并酒店差标
    mergeHotelStandard({ commit, state }, info){
        let peoList = state.personList
        peoList.forEach(peo => {
            info.forEach(e => {
                if(peo.usercode==e.userId) peo.stand.hotelMaxPrice = parseInt(e.hotelMaxPrice)
            });    
        });
        commit('SET_PERSONLIST', peoList)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}