<template>
  <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :disabled="refreshable">
    <van-image class="pic_margin" width="100vw" :src="img_travel" />
      <van-row class="pic-next-cont">
        <!--购票功能区域 start-->
        <van-row class="itembk">
          <van-row style="padding: 10px">
            <van-notice-bar left-icon="volume-o" class="blue" background="#ffffff">神州浩天商旅平台提供高校差旅报销一体化解决方案</van-notice-bar>
            <div style="height: 1px;width: 90vw;background-color: #f1f1f1;margin-bottom:5px"></div>
            <div class="box">
              <div class="hezi">
                <!-- 机票 -->
                <div class="shang" @click="$router.push('/buyAircraft')">
                  <p>
                    <button class="button" style="background-image: linear-gradient(to right, white ,white,#cff5fe);">飞机票</button>
                    <van-icon class="iconfont" color="#319afb" class-prefix="icon" name="jichangxiao" />
                  </p>
                  <h6>特价机票 随心出行</h6>
                </div>
                <!-- 酒店 -->
                <div class="shang" @click="$router.push('/buyHotel')">
                  <p>
                    <button class="button" style="background-image: linear-gradient(to right, white ,white,#fed7da);">享酒店</button>
                    <van-icon class="iconfont" color="#f55c5f" class-prefix="icon" name="jiudian" />
                  </p>
                  <h6>商旅尊享 雅致安心</h6>
                </div>
              </div>
              <div class="hezi">
                <!-- 火车 -->
                <div class="shang" @click="goTrain(travelInfo.appId,false)">
                  <p>
                    <button class="button" style="background-image: linear-gradient(to right, white ,white,#bfffec);">火车票</button>
                    <van-icon class="iconfont" color="#2acac8" class-prefix="icon" name="huochepiaomianxing" />
                  </p>
                  <h6>闪电出票 畅快预订</h6>
                </div>
                <!-- 用车 -->
                <div class="shang" @click="goTrain(travelInfo.appId,true,'4')" v-if="carList.includes(travelInfo.loginUser.username)">
                  <p>
                    <button class="button" style="background-image: linear-gradient(to right, white ,white,#ffe8b1);">享用车</button>
                    <van-icon class="iconfont" color="#ffb400" class-prefix="icon" name="zuche" style="justify-content: flex-end;" />
                  </p>
                  <h6>尊享用车 便捷出行</h6>
                </div>
              </div>
            </div>
          </van-row>
        </van-row>

        <div class="gap"></div>

        <div class="itembk">
          <van-cell title="出行人员" is-link value="详情" :label="personList.map((obj)=>obj.username).join('、')" to="peoInfo"/>
          <!--行程单展示区域 start-->
          <van-collapse v-model="travelInfoShow">
            <van-collapse-item title="行程信息" name="1" value="展开">
              <template #value>
                <div class="free-item top-item">展开</div>
                <div class="van-cell__value free-item">{{travelInfo.loginUser.username}}</div>
                <div class="van-cell__value free-item">{{travelInfo.appReason}}</div>
              </template>
              <template #title>
                <div class="free-item top-item">行程信息</div>
                <div class="van-cell__title free-title free-item">经办人</div>
                <div class="van-cell__title free-title free-item">申请事由</div>
              </template>
              <BodyList :title="false"/>
            </van-collapse-item>
          </van-collapse>
          <div class="gp"></div>
        </div>

        <div class="gap"></div>

        <div class="itembk" v-if="config_showTimeLine">
          <van-panel>
            <template #header>
              <div class="panel-head">
                <span class="van-cell__title">我的行程</span>
                <van-switch v-model="checked" size="16" />
              </div>
              <small class="small-tip">过滤已失效的票信息</small>
            </template>
            <TimeLine :timeList="showTimeList" @refresh="onRefresh()" @goTC="goTrain(applyno,true)"/>
            <van-divider v-if="timeList<=0">暂无行程</van-divider>
          </van-panel>
        </div>

        <div class="gap"></div>

        <!--底部垫底用，对付Tabbar-->
        <van-row type="flex" style="height: 5rem;font-size: 12px;color: #bfbfbf;" justify="center">
          <van-col>2021 © 天津神州浩天科技有限公司. All Rights Reserved.</van-col>
        </van-row>
      </van-row>

      <LoadingCircle :show="wrapper1" />

  </van-pull-refresh>
</template>

<script>
import { mapGetters } from "vuex";
import BodyList from "@/components/InfoList";
import LoadingCircle from "@/components/LoadingCircle";
import TimeLine from "@/views/Polymerization/TimeLine";
import { DiffDate } from "@/services/date.js";
import { getTicketByApplyNoAndUserId } from "@/api/order";
import { FetchUrl } from "@/api/user";
import { ShowTimeLine, HotelOrange, HotelBlue, TrainOrderOrange, TrainOrderBlue, AircraftBlue, AircraftOrange } from "@/common/config";
export default {
  name: "Travel",
  components: { BodyList, TimeLine, LoadingCircle },
  computed: {
    ...mapGetters(["personList", "travelInfo"]),
    // 用来展示的timeline列表，可以过滤无效票信息
    showTimeList(){
      if(!this.checked) return this.timeList
      return this.timeList.filter(e=>{
        let status = e.bz2||e.bz1||e.orderStatusText
        return [...HotelOrange, ...HotelBlue, ...TrainOrderOrange, ...TrainOrderBlue, ...AircraftBlue, ...AircraftOrange].includes(status)
      })
    }
  },
  data() {
    return {
      checked: true, // 行程过滤开关
      refreshable: false, // true可以下拉刷新
      carList:['段宏勇','任浩','展保华','刘士燕','张文杰','刘鹏飞','张向友'], // 这些人可见用车
      config_showTimeLine:ShowTimeLine, // 配置 - 是否展示timeline
      img_travel: require("../assets/travel.jpg"),
      isLoading: false, // 下拉刷新
      travelInfoShow: [], // 折叠 - 行程单信息
      timeList:[], // timeline用的数组
      wrapper1: false, // 圈圈遮罩层
    };
  },
  mounted() {
    this.onRefresh()
  },
  methods: {
    // 点击火车跳转
    goTrain(applyno = this.travelInfo.appId, f = false, type = '2'){
      if(this.travelInfo.trainPayTypeFlag=="true"||f){
        this.wrapper1 = true
        FetchUrl({
          applyno:applyno,
          isMobile:true,
          userId:this.travelInfo.loginUser.usercode,
          productType: type
        }).then(res=>{
          this.wrapper1 = false
          window.location.href=res.loginUrl
        }).catch(err=>{
          this.wrapper1 = false
          this.$toast.fail("获取连接异常");
        })
      }
      else this.$router.push('/buyTrain')
    },
    // 获取timeLine列表
    onRefresh(){
      this.refreshable = true
      setTimeout(() => {
        this.refreshable = false
      }, 10000);
      getTicketByApplyNoAndUserId({
        applyNo:this.travelInfo.appId,
        userId:this.travelInfo.loginUser.usercode
      })
        .then(response => {
          // 组合 - 出发
          let array = [...response.train.map(e=>{
              e.classification='train';
              e.classType='train';
              return e
            }),...response.hotel.map(e=>{
              e.classification='hotel';
              e.classType='hotel';
              return e
            }),...response.flight.map(e=>{
              e.classification='aircraft';
              e.classType='aircraft';
              return e
            })]
            
          response = JSON.parse(JSON.stringify(response))
          // 组合 - 到达
          array.push(
            ...response.train.map(e=>{
              e.classification='end';
              e.classType='train';
              return e
            }),
            ...response.hotel.map(e=>{
              e.classification='end';
              e.classType='hotel';
              return e
            }),
            ...response.flight.map(e=>{
              e.classification='end';
              e.classType='aircraft';
              return e
          }))
           // 依照时间排序
          array.sort((a,b)=>{
            let left,right;
            if(!a.classType!='end') left = a.planBeginDate||a.departTime||a.checkIn
            else left = a.planEndDate||a.arriveTime||a.checkOut
            if(!b.classType!='end') right = b.planBeginDate||b.departTime||b.checkIn
            else right = b.planEndDate||b.arriveTime||b.checkOut
            return DiffDate(right,left)
          })
          this.timeList = array
          this.isLoading=false
        }).catch(err=>this.isLoading=false)
    },
    // 折叠
    allCollapse() {
      if (this.collapseItems.length === 0) {
        let list = [];
        this.personList.forEach((val, ind) => {
          list.push(val.tel);
        });
        this.collapseItems = list;
      } else {
        this.collapseItems = [];
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import '../styles/theme.less';
.collapse_value span {
  font-size: @font-size-xs;
}
.service {
  color: @blue;
}
.box {
  width: 100%;
  height: 100%;
}
.hezi {
  width: 100%;
  height: 80px;
  gap: 0.6rem;
  display: grid;
  grid-auto-flow: column dense;
}
p {
  position: relative;
  width: 90%;
  line-height: 8px;
  margin: 11px auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.shang {
  position: relative;
  width: 100%;
  height: 90%;
  background-color: white;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
h6 {
  margin: 6px auto;
  position: relative;
  right: -7px;
  color: @gray-6;
}
.button {
  position: relative;
  top: -2px;
  right: 10px;
  border: none;
  border-radius: 20px;
  padding: 3px 15px;
  margin: 3px 10px;
}
.iconfont {
  font-size: 24px;
}
.free-item{
  padding: 8px 0;
  border-bottom: 1px solid @gray-2;
}
.free-title{
  padding-left: 16px;
}
.top-item{
  padding-top: 0;
}
.small-tip{
  padding: 0 1rem;
  text-align: right;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  color: @gray-6;
  transform: translateY(-5px);
}
</style>
<style lang="less">
.pic-next-cont{
  .van-collapse-item__title{
    padding-bottom: 0;
  }
  .van-collapse-item__title--expanded::after{
    display: none;
  }
  .van-collapse-item__content{
    padding-top: 0;
  }
}
</style>