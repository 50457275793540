// 路由守卫，仅针对流程上出现的跳转做优化控制。
import router from './router'
import store from './store'
import { LoginFun } from "@/services/user.js";
import { init } from '@/services/cancelAxios.js' // 存放CancelToken得单例
import { Notification } from 'element-ui';
import { Dialog, Toast } from 'vant';
import { ToTop, fetchRoutes } from "@/services/utils";


let cancelList = init() // 获得实例

const whiteList = ['/login'] // no redirect whitelist

const NoRedirectList = ['/', '/plan'] // 登录后不重定向到首页

// 此列表下的路由不滚回顶部
const NoScrollList = ['/plan','/searchTrain','/searchTrainChange','/searchAircraftTo','/searchAircraftBack','/searchAircraftSingle','/searchAircraftChange'] 

let f = 1 // 动态路由标记，大于1不加了



// 进入路由后的钩子：用来拼装轨迹 - 根据自身情况设计
router.afterEach(async (to, from, next) => {
    if(to.meta.title) window.setTitle(to.meta.title);
    if(!NoScrollList.includes(to.path))
        setTimeout(() => {
            ToTop()
        }, 500);
 })



/**   拦截器   **/
router.beforeEach(async (to, from, next) => {
    // 取消之前页面全部ajax，关闭所有弹窗
    cancelList.list.forEach(element => {
        element.cancel()
    });
    Dialog.close()
    Notification.closeAll()
    Toast.clear()


    // 登录参数
    const curparam = document.location.search.split('?param=')[1]
    // 是否登录？有用户信息，切登录参数和
    const isLogin = store.getters.travelInfo
    // 是否切换用户
    const changeLogin = curparam ? curparam != store.getters.loginParam : false

    let userStand = true // 用户差标合规否，false合规、无需登录。true不合规、需要登陆
    try {
        if(store.getters.personList[0].stand.trainSeatInfo_seatInfo_text) userStand = false
    } catch (error) {
        userStand = true        
    }

    // 如果需要登录(没登录必须登录，如果登录了就看是否切换用户，切了需要登陆，没切不管)
    if (!isLogin || changeLogin || userStand) {
        // 如果在白名单 放行
        if (whiteList.includes(to.path)) {
            return next()
        }
        
        // 如果有登录参数，清空vuex，登录，并根据参数判断去订单或者首页
        if (curparam) {
            // 清空一些数据
            store.dispatch("project/cleanTravelInfo");
            store.dispatch("aircraft/cleanAircraftOrderInfo");
            store.dispatch("train/cleanOrderInfo");
            await LoginFun(curparam) // 调用登录方法，该方法会检验是否为新的申请单
            store.dispatch("project/setLoginParam", curparam); // 记录参数
            if (f < 2) {
                f++;
                fetchRoutes().forEach(element => {
                    router.addRoute(element)
                });
            }
            // 转到订单页
            let travelInfo = store.getters.travelInfo
            if (travelInfo.corp && travelInfo.orderNo) {
                store.dispatch("history/setOrderItem", {
                    orderNo: travelInfo.orderNo,
                    outOrderNo: "",
                    corp: travelInfo.corp
                });
                switch (travelInfo.orderType) {
                    case 'hotel':
                        return next({ path: '/paymentHotel', replace: true })
                    case 'flight':
                        return next({ path: '/paymentAircraft', replace: true })
                    default:
                        return next({ path: '/payment', replace: true })
                }
            }
            // 如果不需要重定向继续，否则回主页
            if(NoRedirectList.includes(to.path)) return next()
            else return next({ path: '/', replace: true })
        }
        // 你要没参数就别进来了，这边登录不好使
        else {
            return next({ path: '/login' });
        }
    }
    else {
        // 如果已经登陆禁止访问白名单
        if (whiteList.includes(to.path)) {
            return next({ path: '/' })
        }

        // 这边跳过来必须刷新
        if (to.path == "/plan" && from.path.includes("order") && !to.path.params) store.dispatch("project/setPlanRefresh", true);

        // 订单详情页不允许跳转到下单界面
        if (to.path.includes("order") && from.path.includes("plan")) return next({ path: '/' })

        // 如果添加过一次就不加了哈
        if (f < 2) {
            f++
            fetchRoutes().forEach(element => {
                router.addRoute(element)
            });
            return next({ ...to, replace: true })
        }
        else{
            next()
        }
    }
})




