<template>
  <div class="contain">
    <div class="part">
      <p class="top">
        <van-tag plain color="#ff9a6e" type="warning" class="icon-gap">
          <van-icon class="iconfont text-sm" class-prefix="icon" name="zuche" />用车
        </van-tag>
        <span>山东航空&nbsp;|&nbsp;机型738</span>
      </p>
      <van-row type="flex" justify="space-between" class="mid">
        <van-col span="12">
          <h3>06:55</h3>
        </van-col>
        <van-col>
          <h3 span="8">
            <span class="icon iconfont text-sm icon-icon_jipiaodancheng"></span>
          </h3>
        </van-col>
        <van-col span="12" style="text-align:right">
          <h3>08:15</h3>
        </van-col>
      </van-row>
      <van-row type="flex" justify="space-between" class="mid">
        <van-col span="12" class="van-ellipsis">天津机场</van-col>
        <van-col style="word-break: keep-all;">SC4717</van-col>
        <van-col span="12" class="van-ellipsis" style="text-align:right">青岛机场</van-col>
      </van-row>
    </div>
    <van-divider class="divider" />
    <van-row gutter="5" class="mid text-sm">
      <van-col>人员0</van-col>
      <van-col>|</van-col>
      <van-col>人员1</van-col>
      <van-col>|</van-col>
      <van-col>人员2</van-col>
      <van-col>|</van-col>
      <van-col>人员4</van-col>
      <van-col>|</van-col>
      <van-col>人员5</van-col>
    </van-row>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
export default {
  name: "CarItem",
  components: { Logo },
  props: {
    data: {
      default: 1
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
.iconfont {
  font-weight: 500;
  padding-right: 2px;
}
.top {
  margin: 0;
  display: flex;
  align-items: center;
}
.divider {
  margin: 0.2rem 0;
}
.mid {
  padding: 0 0.5rem;
}
.contain {
  background-image: linear-gradient(30deg, #fff, #fff, #fff, #fdceb9, #ff9a6e);
  border-radius: 0.5rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 0.4rem 0.7rem;
  h3 {
    margin: 0.5rem 0 0.3rem;
  }
  h4 {
    margin: 0;
  }
}
</style>