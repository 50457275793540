
import request from '@/services/request'

// 获取机票列表
export function getFlightTicket(data) {
    return request({
        url: 'v1/flight/getFlightTicket',
        method: 'post',
        data
    })
}

// 查是否变价票价
export function journeyPriceCheck(data) {
    return request({
        url: 'v1/flight/journeyPriceCheck',
        method: 'post',
        data
    })
}

// 机票创建订单
export function createFlightOrder(data) {
    return request({
        url: 'v1/flight/createFlightOrder',
        method: 'post',
        data
    })
}

// 获取我的订单列表
export function getFlightOrders(data) {
    return request({
        url: 'v1/flight/getFlightOrders',
        method: 'post',
        data
    })
}

// 机票申请出票
export function applyIssueFlightOrder(data) {
    return request({
        url: 'v1/flight/applyIssueFlightOrder',
        method: 'post',
        data
    })
}

// 国内机票取消订单
export function cancelFlightOrder(data) {
    return request({
        url: 'v1/flight/cancelFlightOrder',
        method: 'post',
        data
    })
}

// 国内机票申请退票
export function applyRefundFlightOrder(data) {
    return request({
        url: 'v1/flight/applyRefundFlightOrder',
        method: 'post',
        data
    })
}

// 机票订单reload
export function flightOrderReload(data) {
    return request({
        url: 'v1/flight/flightOrderReload',
        method: 'post',
        data
    })
}

// 机票订单 + 订单项reload
export function flightOrderItemReload(data) {
    return request({
        url: 'v1/flight/flightOrderItemReload',
        method: 'post',
        data
    })
}

// 机票退票订单详情
export function getFlightRefundOrderDetail(data) {
    return request({
        url: 'v1/flight/getFlightRefundOrderDetail',
        method: 'post',
        data
    })
}

// 改签航班列表查询
export function getChangeFlightTicket(data) {
    return request({
        url: 'v1/flight/getChangeFlightTicket',
        method: 'post',
        data
    })
}

// 机票订单申请改签
export function applyFlightOrderChange(data) {
    return request({
        url: 'v1/flight/applyFlightOrderChange',
        method: 'post',
        data
    })
}

// 机票订单改签确认
export function confirmFlightOrderChange(data) {
    return request({
        url: 'v1/flight/confirmFlightOrderChange',
        method: 'post',
        data
    })
}

// 机票订单改签取消
export function cancelFlightOrderChange(data) {
    return request({
        url: 'v1/flight/cancelFlightOrderChange',
        method: 'post',
        data
    })
}

// 获取机票退票费信息
export function getFlightOrderRefundInfo(data) {
    return request({
        url: 'v1/flight/getFlightOrderRefundInfo',
        method: 'post',
        data
    })
}

// 获取机票退改签规则信息
export function getFlightModifyAndRefundRules(data) {
    return request({
        url: 'v1/flight/getFlightModifyAndRefundRules',
        method: 'post',
        data
    })
}

// 获取机票退保险
export function getFlightInsurance(data) {
    return request({
        url: 'v1/flight/getFlightInsurance',
        method: 'post',
        data
    })
}
