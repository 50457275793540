import Vue from 'vue'
import VueRouter from 'vue-router'
import Travel from '../views/Travel.vue'
import Plan from '../views/Plan/Plan.vue'
import Setting from '../views/Setting/Setting.vue'

// 防止重定向报错
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//    return originalPush.call(this, location).catch(err => err)
// }
// // 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
return originalReplace.call(this, location).catch(err => err)
}

/**
 * 看一下 ！！看一下！！
 * 1、path遵守规则：  
 *      创建订单页面的路由：order....   订单详情页面的路由：payment....
 *      原因：在路由守卫中依据上方两个关键字做跳转控制，详情看permission.js。
 * 2、meta: { trans_index: 10 }
 *      trans_index为过渡动画的权重参数（左滑右滑）
 * 3、DynamicRoutes下面是动态路由，在/services/utils.js中过滤
 */

Vue.use(VueRouter)

// 这边是动态路由，依据登录参数选用其中之一。具体判断在/services/utils.js中
export const DynamicRoutes = []

export const StaticRoutes = [
    {
        path: '/',
        component: () => import('@/views/Home.vue'),
        meta: { keepAlive: true },
        children: [
            {
                path: '/',
                name: 'travel',
                component: Travel,
                meta: { trans_index: 1, title: '神州浩天商旅平台' }  // trans_index: 控制跳转效果，比大小，小的返回大的前进
            },
            {
                path: '/plan',
                name: 'plan',
                component: Plan,
                meta: { trans_index: 1,keepAlive: true, title: '历史订单' }
            },
            {
                path: '/setting',
                name: 'setting',
                component: Setting,
                meta: { trans_index: 1, title: '设置' }
            },
        ]
    },
    {
        path: '/view',
        name: 'view',
        component: () => import('../views/View.vue'),
        meta: { trans_index: 1, keepAlive: true, title: '神州浩天商旅平台' }  // trans_index: 控制跳转效果，比大小，小的返回大的前进
    },
    {
        path: '/policy',
        name: 'policy',
        component: () => import('../views/Setting/Policy.vue'),
        meta: { trans_index: 13, title: '差旅政策' }
    },
    {
        path: '/memorabilia',
        name: 'memorabilia',
        component: () => import('../views/Setting/Memorabilia.vue'),
        meta: { trans_index: 13, title: '大事记' }
    },
    {
        path: '/Login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: { trans_index: 0, title: '登录' }
    },
    {
        path: '/selectCity', // 选择城市
        name: 'selectCity',
        component: () => import('@/views/SelectCity/SelectCity.vue'),
        meta: { trans_index: 200, title: '选择城市' }
    },
    {
        path: '/peoInfo',
        name: 'peoInfo',
        component: () => import('@/views/PeoInfo.vue'),
        meta: { trans_index: 301, title: '出行人信息' }
    },

    // 火车
    {
        path: '/buyTrain',
        name: 'buyTrain',
        component: () => import('@/views/Train/BuyTrain.vue'),
        meta: { trans_index: 20, title: '选择城市' }
    },
    {
        path: '/buyTrainChange',
        name: 'buyTrainChange',
        component: () => import('@/views/Train/BuyTrainChange.vue'),
        meta: { trans_index: 20, title: '选择城市' }
    },
    {
        path: '/buyTrainView',
        name: 'buyTrainView',
        component: () => import('@/views/Train/BuyTrainView.vue'),
        meta: { trans_index: 20, title: '选择城市' }
    },
    {
        path: '/searchTrain',
        name: 'searchTrain',
        component: () => import('@/views/Train/SearchTrain.vue'),
        meta: { trans_index: 21, keepAlive: true, title: null }
    },
    {
        path: '/searchTrainChange',
        name: 'searchTrainChange',
        component: () => import('@/views/Train/SearchTrainChange.vue'),
        meta: { trans_index: 21, title: null }
    },
    {
        path: '/searchTrainView',
        name: 'searchTrainView',
        component: () => import('@/views/Train/SearchTrainView.vue'),
        meta: { trans_index: 21, title: null }
    },
    {
        path: '/orderTrain',
        name: 'orderTrain',
        component: () => import('@/views/Train/OrderTrain.vue'),
        meta: { trans_index: 22, keepAlive: true, title: null }
    },
    {
        path: '/payment',
        name: 'payment',
        component: () => import('@/views/Train/Payment.vue'),
        meta: { trans_index: 101, keepAlive: true, title: '订单详情' }
    },
    {
        path: '/orderTrainChange',
        name: 'orderTrainChange',
        component: () => import('@/views/Train/OrderChangeTrain.vue'),
        meta: { trans_index: 23, keepAlive: true, title: null }
    },
    {
        path: '/passengerList',
        name: 'passengerList',
        component: () => import('@/views/Train/PassengerList.vue'),
        meta: { trans_index: 24, keepAlive: true, title: '常旅客' }
    },
    {
        path: '/paymentChange',
        name: 'paymentChange',
        component: () => import('@/views/Train/PaymentChange.vue'),
        meta: { trans_index: 102, keepAlive: true, title: '改签详情' }
    },

    // 酒店
    {
        path: '/buyHotel',
        name: 'buyHotel',
        component: () => import('@/views/Hotel/BuyHotel.vue'),
        meta: { trans_index: 31, title: '选择入住城市' }
    },
    {
        path: '/buyHotelView',
        name: 'buyHotelView',
        component: () => import('@/views/Hotel/BuyHotelView.vue'),
        meta: { trans_index: 31, title: '选择入住城市' }
    },
    {
        path: '/searchHotel',
        name: 'searchHotel',
        component: () => import('@/views/Hotel/SearchHotel.vue'),
        meta: { trans_index: 32, keepAlive: true, title: null }
    },
    {
        path: '/searchHotelView',
        name: 'searchHotelView',
        component: () => import('@/views/Hotel/SearchHotelView.vue'),
        meta: { trans_index: 32, keepAlive: true, title: null }
    },
    {
        path: '/detailHotel',
        name: 'detailHotel',
        component: () => import('@/views/Hotel/DetailHotel.vue'),
        meta: { trans_index: 33, keepAlive: true, title: null }
    },
    {
        path: '/orderHotel',
        name: 'orderHotel',
        component: () => import('@/views/Hotel/OrderHotel.vue'),
        meta: { trans_index: 34, keepAlive: true, title: '入住信息' }
    },
    {
        path: '/paymentHotel',
        name: 'paymentHotel',
        component: () => import('@/views/Hotel/PaymentHotel.vue'),
        meta: { trans_index: 35, keepAlive: true, title: '订单详情' }
    },
    {
        path: '/paymentHotelRefund',
        name: 'paymentHotelRefund',
        component: () => import('@/views/Hotel/PaymentRefundHotel.vue'),
        meta: { trans_index: 36, keepAlive: true, title: '退订详情' }
    },

    // 飞机
    {
        path: '/buyAircraft',
        name: 'buyAircraft',
        component: () => import('@/views/Aircraft/BuyAircraft.vue'),
        meta: { trans_index: 41, title: '选择城市' }
    },
    {
        path: '/buyAircraftView',
        name: 'buyAircraftView',
        component: () => import('@/views/Aircraft/BuyAircraftView.vue'),
        meta: { trans_index: 41, title: '选择城市' }
    },
    {
        path: '/searchAircraftTo',
        name: 'searchAircraftTo',
        component: () => import('@/views/Aircraft/SearchAircraftTo.vue'),
        meta: { trans_index: 42, keepAlive: true, title: '选去程' }
    },
    {
        path: '/searchAircraftBack',
        name: 'searchAircraftBack',
        component: () => import('@/views/Aircraft/SearchAircraftBack.vue'),
        meta: { trans_index: 43, keepAlive: true, title: '选返程' }
    },
    {
        path: '/searchAircraftSingle',
        name: 'searchAircraftSingle',
        component: () => import('@/views/Aircraft/SearchAircraftSingle.vue'),
        meta: { trans_index: 42, keepAlive: true, title: '选择机票' }
    },
    {
        path: '/searchAircraftView',
        name: 'searchAircraftView',
        component: () => import('@/views/Aircraft/SearchAircraftView.vue'),
        meta: { trans_index: 42, keepAlive: true, title: '查看机票' }
    },
    {
        path: '/searchAircraftChange',
        name: 'searchAircraftChange',
        component: () => import('@/views/Aircraft/SearchAircraftChange.vue'),
        meta: { trans_index: 42, keepAlive: true, title: '选择机票' }
    },
    {
        path: '/orderAircraft',
        name: 'orderAircraft',
        component: () => import('@/views/Aircraft/OrderAircraft.vue'),
        meta: { trans_index: 43, keepAlive: true, title: null }
    },
    {
        path: '/orderAircraftChange',
        name: 'orderAircraftChange',
        component: () => import('@/views/Aircraft/OrderAircraftChange.vue'),
        meta: { trans_index: 43, keepAlive: true, title: null }
    },
    {
        path: '/orderReturnAircraft',
        name: 'orderReturnAircraft',
        component: () => import('@/views/Aircraft/OrderReturnAircraft.vue'),
        meta: { trans_index: 44, keepAlive: true, title: null }
    },
    {
        path: '/paymentAircraft',
        name: 'paymentAircraft',
        component: () => import('@/views/Aircraft/Payment.vue'),
        meta: { trans_index: 101, keepAlive: true, title: '订单详情' }
    },
    {
        path: '/paymentAircraftRefund',
        name: 'paymentAircraftRefund',
        component: () => import('@/views/Aircraft/PaymentRefund.vue'),
        meta: { trans_index: 103, keepAlive: true, title: '退票详情' }
    },
    {
        path: '/paymentAircraftChange',
        name: 'paymentAircraftChange',
        component: () => import('@/views/Aircraft/PaymentChange.vue'),
        meta: { trans_index: 102, keepAlive: true, title: '改签详情' }
    },

    // // 租车
    // {
    //     path: '/BuyCar',
    //     name: 'car',
    //     component: () => import('@/views/Car/BuyCar.vue'),
    //     meta: { trans_index: 51 }
    // },

    // // 聚合订票
    // {
    //     path: '/Polymerization',
    //     name: 'polymerization',
    //     component: () => import('@/views/Polymerization/Polymerization.vue'),
    //     meta: { trans_index: 61 }
    // },
];


const router = new VueRouter({
    routes: StaticRoutes
});


export default router
