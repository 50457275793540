/**
 * 火车相关信息
 */
import { DiffDate, AddDate } from "@/services/date"
import { FetchCityCode } from "@/services/train";
import store from '@/store'
import { CityRE } from "@/common/config.js";

const state = {
  ticket: {}, // 选择的列车信息
  orderInfo: { // 订票信息
    goBackDate: [], // 往返日期 - 缓存
    trainFromStation: "", // 出发站
    trainToStation: "", // 终点站
    trainType: '不限车型' //车次类型
  },
  trainchange: {}, // 改签车票
  originalOrderNo: '', // 改签时的原单
  originalprice: '', //原单价格
  tavelList12306:{}, //12306的常旅客等信息 
}

const mutations = {
  SET_TRAVELLIST: (state, info) => {
    state.tavelList12306 = info
  },
  SET_TICKET: (state, info) => {
    state.ticket = info
  },
  SET_ORDER: (state, info) => {
    info.goBackDate = CheckTime(info.goBackDate)
    state.orderInfo = info
    state.orderInfo.trainFromStationCode = FetchCityCode(info.trainFromStation)
    state.orderInfo.trainToStationCode = FetchCityCode(info.trainToStation)
  },
  SET_FROM: (state, info) => {
    state.orderInfo.trainFromStation = info
    state.orderInfo.trainFromStationCode = FetchCityCode(info)
  },
  SET_TO: (state, info) => {
    state.orderInfo.trainToStation = info
    state.orderInfo.trainToStationCode = FetchCityCode(info)
  },
  SET_TCHANGE: (state, info) => {
    state.trainchange = info
  },
  SET_originalOrderNo: (state, info) => {
    state.originalOrderNo = info
  },
  SET_originalprice: (state, info) => {
    state.originalprice = parseFloat(info)
  },
}

const actions = {
  // 设置常旅客信息
  setTravelList({ commit }, info) {
    commit('SET_TRAVELLIST', info)
  },
  // 设置火车票
  setTicket({ commit }, info) {
    commit('SET_TICKET', info)
  },
  
  // 订单基本信息相关
  setOrderInfo({ commit }, info) {
    commit('SET_ORDER', info)
  },
  cleanOrderInfo({ commit }) {
    commit('SET_ORDER', {
      date: '', // 出发日期
      trainFromStation: "", // 出发站
      trainToStation: "", // 终点站
      trainType: '不限车型' //车次类型
    })
  },
  setOrderFrom({ commit, state }, info) {
    if (Object.prototype.hasOwnProperty.call(info, 'name')) {
      info = info.name // 出发
      switch (store.getters.config_controlLevel) {
        case 20: //如果严控，一并该目的地
          let arr = store.getters.travelInfo.routeCityEnd
          let i = arr.indexOf(info.replace(CityRE, ''))
          let newTo = arr[(i + 1) % arr.length]
          if (state.orderInfo.trainToStation.replace(CityRE, '') != newTo.replace(CityRE, ''))
            commit('SET_TO', arr[(i + 1) % arr.length])
          break;
      }
      commit('SET_FROM', info)
    }
  },
  setOrderTo({ commit, state }, info) {
    if (Object.prototype.hasOwnProperty.call(info, 'name')) {
      info = info.name
      switch (store.getters.config_controlLevel) {
        case 20:
          let arr = store.getters.travelInfo.routeCityEnd
          let i = arr.indexOf(info.replace(CityRE, '')) - 1
          let newFrom = arr[i > 0 ? i : arr.length - 1]
          if (state.orderInfo.trainFromStation.replace(CityRE, '') != newFrom.replace(CityRE, ''))
            commit('SET_FROM', newFrom)
          break;
      }
      commit('SET_TO', info)
    }
  },
  setTrainChange({ commit }, info) {
    let order = {
      date: info.order.planBeginDate,
      trainFromStation: info.order.fromStation,
      trainToStation: info.order.toStation,
      trainType: '不限车型'
    }
    commit('SET_ORDER', order)
    commit('SET_TCHANGE', info)
  },
  // 设置改签原单
  setOriginalOrderNo({ commit }, info) {
    commit('SET_originalOrderNo', info)
  },
  setOriginPrice({ commit }, info) {
    commit('SET_originalprice', info)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}


/**
 * 检查日期是否合规，只要大于今天即可
 * @param {Array<Date, Date>} arr 
 */
 function CheckTime(arr = []) {
  let dif = DiffDate(new Date(), arr[0]);
  if (dif < 0) arr[0] = AddDate(new Date(), 1);
  if (dif > 29) arr[0] = AddDate(new Date(), 29)
  dif = DiffDate(new Date(), arr[1]);
  if (dif < 0) arr[1] = AddDate(arr[0], 1);
  if (dif > 29) arr[1] = AddDate(new Date(), 29)
  return arr;
}