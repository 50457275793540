<template>
  <!-- 列表条目1-结束日期 -->
  <van-step class="node">
    <template #inactive-icon>
      <Icon :classification="val.classification" />
    </template>
    <!-- 左侧时间区域 -->
    <div class="time">
      <div class="MMDD nowrap list-text-big">
        {{ val.checkIn | comverTime('MM-DD') }}
      </div>
      <!-- <div class="hhmm">{{val.checkInTime||''}}</div> -->
    </div>
    <!-- 右侧信息区域 -->
    <van-row class="contain" type="flex" justify="space-between">
      <!-- 左半边文本信息 -->
      <div class="left-cont">
        <div class="list-text-big van-multi-ellipsis--l2">
          <span v-if="val.city">【{{ val.city }}】</span>{{ val.hotelName }}
        </div>
        <a
          v-if="val.hotelAddress"
          class="footer foota van-ellipsis--l2"
          :href="
            'https://uri.amap.com/marker?position=' +
            val.longitude +
            ',' +
            val.latitude +
            '&name=' +
            val.hotelName +
            '&callnative=1'
          "
        >
          酒店地址：{{ val.hotelAddress }}
          <van-icon name="location" />
        </a>
        <div v-if="val.hotelTel">
          <a class="text-sm footer" :href="'tel:' + val.hotelTel"
            >酒店电话：{{ val.hotelTel }}</a
          ><van-icon name="phone" />
        </div>
        <div v-if="val.payment" class="text-sm footer">
          付款方式：{{ val.payment }}
        </div>
      </div>
      <!-- 右半边-价格-删除 -->
      <van-col class="col" @click="goDetail(val)">
        <span class="list-text-big red"
          >￥{{
            parseFloat(val.totalPrice) / parseFloat(val.numberOfRooms)
          }}</span
        >
        <span :class="'list-text-big ' + statusColor(val.bz1)" v-if="val.bz1">{{
          val.bz1
        }}</span>
        <div style="text-align: right">
          <span class="blue">详情 >></span>
        </div>
      </van-col>
    </van-row>
  </van-step>
</template>

<script>
import Icon from "./Icon";
import { HotelConfirmBtn } from "@/common/config";
import { getStatusColor } from "@/services/hotel.js";
import { commitHotelOrder } from "@/api/hotel";
export default {
  name: "PolHotel",
  components: { Icon },
  props: ["val", "peoInfo"],
  computed: {
    sealList: function () {
      let result = [];
      this.val.roomDetail.forEach((element, i) => {
        result.push({ text: element.seatType, value: i });
      });
      return result;
    },
  },
  data () {
    return {
      config_hotelConfirmBtn: HotelConfirmBtn, // 配置 - 可否出票
      confirmLoading: false, // 加载动画
    };
  },
  methods: {
    // 状态颜色
    statusColor (data) {
      return getStatusColor(data);
    },
    // 前往详情
    goDetail (detail) {
      this.$store.dispatch("history/setOrderItem", detail);
      this.$router.push("/paymentHotel");
    },
    // 出票
    submit () {
      this.confirmLoading = true;
      commitHotelOrder({
        orderNo: this.val.orderNo,
        outOrderNo: "",
        originalOrderNo: this.val.bz3,
        itemId: "",
        totalPrice: 0,
        corp: this.val.corp,
      })
        .then(response => {
          // 如果经费不足提示。
          if (!response.success) {
            this.confirmLoading = false;
            return this.$toast.fail(response.msg);
          }
          this.$toast.success("申请出票成功，请稍后。");
          this.confirmLoading = false;
          this.$emit("refresh");
        })
        .catch(() => {
          this.confirmLoading = false;
        })
    }
  },
};
</script>
<style lang="less" src="@/styles/polymerization.less" scoped></style>
<style lang="less" scoped>
.foota {
  color: rgb(146, 146, 146);
  display: inline-block;
  max-width: 100%;
}
a {
  color: rgb(146, 146, 146);
}
</style>
