<template>
  <!-- 列表条目1-结束日期 -->
  <van-step class="node">
    <template #inactive-icon>
      <Icon :classification="val.classification" />
    </template>
    <!-- 左侧时间区域 -->
    <div class="time">
      <div class="MMDD nowrap list-text-big">{{val.departTime | comverTime('MM-DD')}}</div>
      <div class="hhmm">{{val.departTime | comverTime('HH:mm')}}</div>
    </div>
    <!-- 右侧信息区域 -->
    <van-row class="contain" type="flex" justify="space-between">
      <!-- 左半边文本信息 -->
      <div class="left-cont">
        <div class="list-text-big">{{val.departAirPort }}->{{val.arriveAirPort}}</div>
        <div class="text-sm footer">席位：{{val.seatDiscount}}{{val.seatClass}}</div>
        <div class="text-sm footer" v-if="val.runTimeSpan">历时：{{val.runTimeSpan}}</div>
        <div class="text-sm footer" v-if="val.flightNo">航班：{{val.flightNo}}</div>
      </div>
      <!-- 右半边-价格-删除 -->
      <van-col class="col" @click="goDetail(val)">
        <span class="list-text-big red">￥{{val.parPrice}}</span>
        <span class="list-text-big warning" style="display:flex" v-if="orderStatus=='占座中'">占座中&nbsp;<van-loading color="#ff976a" size="12px"/></span>
        <span :class="'list-text-big '+statusColor(orderStatus)" v-else>{{orderStatus}}</span>
        <div style="text-align:right">
          <span class="blue">详情 >></span>
        </div>
      </van-col>
    </van-row>
  </van-step>
</template>

<script>
import Icon from "./Icon";
import { getStatusColor, getPassengerStatus } from "@/services/aircraft";
import { AircraftConfirmBtn } from "@/common/config.js";
import {applyIssueFlightOrder } from "@/api/aircraft";
const intermediate= ['占座中','待提交','待支付','等待出票'] // 中间状态 - 除此以外其他状态不显示。展示票状态
export default {
  name: "PolAircraft",
  components: { Icon },
  props: ["val"],
  computed: {
    orderStatus: function () {
      let text = this.val.orderStatusText
      if(intermediate.includes(text)) return text
      return getPassengerStatus(this.val.passengerStatus)
    }
  },
  data() {
    return {
      config_aircraftConfirmBtn:AircraftConfirmBtn, // 配置 - 显示出票按钮
      confirmLoading:false, // 加载动画
    };
  },
  methods: {
    // 前往详情
    goDetail(detail){
      this.$store.dispatch("history/setOrderItem", detail);
      this.$router.push("/paymentAircraft");
    },
    // 状态中文
    getStatusName(code){
      return getPassengerStatus(code)
    },
    // 状态颜色
    statusColor(data) {
      return getStatusColor(data);
    },
    // 出票
    submit(){
      this.confirmLoading = true;
      applyIssueFlightOrder({
        orderNo: this.val.orderSerialNo,
        outOrderNo: this.val.outOrderNo,
        originalOrderNo: this.val.bz3 || this.val.orderSerialNo,
        corp: this.val.corp,
      })
        .then(response => {
          // 如果经费不足提示。
          if (!response.success) {
            this.confirmLoading = false;
            return this.$toast.fail(response.msg);
          }
          this.$toast.success("申请出票成功，请稍后。");
          this.confirmLoading = false;
          this.$emit("refresh");
        })
        .catch(() => {
          this.confirmLoading = false;
        })

    }
  },
};
</script>
<style lang="less" src="@/styles/polymerization.less" scoped></style>
