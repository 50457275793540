<template>
  <!-- 遮罩层 -->
  <van-overlay :show="show" z-index="2000">
    <div class="wrapper">
      <van-loading type="spinner" />
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: "LoadingCircle",
  props: {
    show: {
      default: () => false
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>