/**
 * 酒店相关信息
 */
import { DiffDate, AddDate } from "@/services/date.js"
import { FetchCityCode } from "@/services/aircraft";
import moment from "moment";
import store from '@/store'
import { CityRE } from "@/common/config.js";


const state = {
    aircraftOrderInfo: { // 订票信息
        date: [], // 出发日期
        from: '', // 出发地
        fromCode: '',
        to: '', // 目的地
        toCode: ''
    },
    aircraftTicket: [{}, {}], // 机票信息[去程， 返程]
    aircraftOrderChange: {}, // 改签单
}

const mutations = {
    // 修改订单信息
    SET_AIRCRAFTORDERINFO: (state, info) => {
        info.date = info.date.map(item => moment(item).format("YYYY-MM-DD"))
        info.fromCode = FetchCityCode(info.from)
        info.toCode = FetchCityCode(info.to)
        if (!info.toCode) info.to = "请选择"
        if (!info.fromCode) info.from = "请选择"
        state.aircraftOrderInfo = info
    },
    SET_FROM: (state, info) => {
        if (Object.prototype.hasOwnProperty.call(info, 'name')) info = info.name
        state.aircraftOrderInfo.fromCode = FetchCityCode(info)
        state.aircraftOrderInfo.from = state.aircraftOrderInfo.fromCode ? info : '请选择'
    },
    SET_TO: (state, info) => {
        if (Object.prototype.hasOwnProperty.call(info, 'name')) info = info.name
        state.aircraftOrderInfo.toCode = FetchCityCode(info)
        state.aircraftOrderInfo.to = state.aircraftOrderInfo.toCode ? info : '请选择'
    },


    // 已选票
    SET_AIRCRAFTTICKET: (state, info) => {
        state.aircraftTicket[info.index] = info.data
    },

    // 改签单
    SET_AIRCRAFTORDERCHANGE: (state, info) => {
        state.aircraftOrderChange = info
    },
}

const actions = {
    // 修改订单信息
    setAircraftOrderInfo({ commit }, info) {
        info.date = CheckTime(info.date)
        commit('SET_AIRCRAFTORDERINFO', info)
    },
    // 清空aircraftOrder信息
    cleanAircraftOrderInfo({ commit }) {
        commit('SET_AIRCRAFTORDERINFO', {
            date: [], // 出发日期
            from: '', // 出发地
            fromCode: '',
            to: '', // 目的地
            toCode: ''
        })
    },
    setAircraftOrderFrom({ commit }, info) {
        switch (store.getters.config_controlLevel) {
            case 20: //如果严控，一并该目的地
                let arr = store.getters.travelInfo.routeCityEnd
                let i = arr.indexOf(info.name.replace(CityRE, ''))
                let newTo = arr[(i + 1) % arr.length]
                if (state.aircraftOrderInfo.to.replace(CityRE, '') != newTo.replace(CityRE, ''))
                    commit('SET_TO', arr[(i + 1) % arr.length])
                break;
        }
        commit('SET_FROM', info)
    },
    setAircraftOrderTo({ commit }, info) {
        switch (store.getters.config_controlLevel) {
            case 20:
                let arr = store.getters.travelInfo.routeCityEnd
                let i = arr.indexOf(info.name.replace(CityRE, '')) - 1
                let newFrom = arr[i > 0 ? i : arr.length - 1]
                if (state.aircraftOrderInfo.from.replace(CityRE, '') != newFrom.replace(CityRE, ''))
                    commit('SET_FROM', newFrom)
                break;
        }
        commit('SET_TO', info)
    },

    // 已选票
    setAircraftTicket({ commit }, info) {
        commit('SET_AIRCRAFTTICKET', info)
    },

    // 改签单
    setAircraftOrderChange({ commit, state }, info) {
        info.peoList.forEach((e) => {
            store.getters.personList.forEach(element => {
                if (element.usercode == e.passengerId)
                    e.stand = element.stand
            });
        })
        commit('SET_AIRCRAFTORDERCHANGE', info)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}


/**
 * 检查aircraftOrderInfo.date日期是否合规，只要大于今天即可
 * @param {Array<Date, Date>} arr 
 */
function CheckTime(arr = []) {
    let dif = DiffDate(new Date(), arr[0]);
    if (dif < 0) arr[0] = AddDate(new Date(), 1);
    dif = DiffDate(new Date(), arr[1]);
    if (dif < 0) arr[1] = AddDate(arr[0], 1);
    return arr;
}