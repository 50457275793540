// 行程单从这个城市出发
export const TravelFrom = "天津"


// 第三方供应商配置：["大唐", "差旅壹号", "行旅", "差旅管家"]
export const TrainServiceProvider = ["大唐"]                    // 火车包含的供应商
// export const TrainServiceProvider = ["大唐", "差旅管家"]                    // 火车包含的供应商
export const AircraftServiceProvider = ["大唐", "差旅管家", "差旅壹号"]      // 飞机包含的供应商
export const HotelServiceProvider = ["大唐", "行旅", "差旅管家", "差旅壹号"] // 酒店包含的供应商

/* 行程管控 - 可选城市 - 预留数字有新需求再加吧 */
// 0：不控，想去哪儿就去哪儿
// 10：仅控目的地，不按照规划出行也可以。 
// 20：严格控制，必须按照规划出行。 
export const ControlLevel = 10

/* 改签管控配置 */
// Array[0]：all表示全部订单、team表示当前订单
// Array[0]: all所有人的票、team当前订单随行人员的票、self仅自己的票
export const ChangeControl = ['all', 'team'] // ['all','all'] 无法管控

export const CityRE = new RegExp(/(市|自治州|地区|东|南|西|北)$/) // 过滤城市的正则


/* 首页 */
export const ShowTimeLine = true // 是否开启“我的行程”


/* 火车 */
export const Show12306 = true       // 《12306》开关
export const MaxSelectDate = 30     // 购票可选日期范围，默认是30内
export const TrainDefaultSort = "early";    // 默认排序方式: 提早'early'、 省钱'money'、  省时'time'、 空''
export const TrainAsyncList = "async";      // 列表渲染方式：同步渲染'sync'、 异步渲染'async'


/* 机票 */
export const AircraftDefaultSort = "";    // 默认排序方式: 提早'early'、 省钱'money'、  省时'time'、 空''
export const AircraftAsyncList = "async";   // 列表渲染方式：同步渲染'sync'、 异步渲染'async'
export const ServicePrice = 0               // 服务费，0元不会显示在页面
export const BusinessTicketConfig = false;  // 《公务票》 开关
export const NeedCheckPrice = ["差旅管家"];  // 下单前需要验价的供应商
export const TicketSecondConfirm = false;   // 选票二次确认,false 点击就可选票，true 选好票之后还要点确认

/* 保险相关 */
export const NeedAircraftInsurance = true // 如果买机票时想买保险设置true
export const DefaultSelect = ["差旅壹号"]   // 默认勾选的供应商
export const PreventModify = ["差旅壹号"]   // 禁止勾选的供应商


/* 酒店 */
export const HotelDefaultSort = "DA";   // 默认排序方式: 价格升序PA、 价格降序PD、  距离升序DA
export const HotelAsyncList = "async";  // 列表渲染方式：同步渲染'sync'、 异步渲染'async'
export const HotelCorpPageSize = 60;    // 每个供应商每次拉取多少条数据
export const HotelStandType = "HT";     // 酒店超标计算方式，具体参考services/hotel下的standardJudge方法
export const BanCorp = ["行旅"];        // 这些服务商超标不可预定
export const BlackList = ['差旅管家'];  // 这些服务商的剩余房间数量数量不可靠，不显示在页面上
export const TimePickList = ['12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '23:59', '次日1点', '次日2点', '次日3点', '次日4点', '次日5点', '次日6点'];  // 入住日期可选时间


/* 通用 */
export const GoWhere = "order"; // 下单成功后转到订单列表'order' 转到详情'detail'
export const REASONS = ["陪同出行", "商务洽谈", "其他情况"] // 超标缘由、默认第一个


/* Logo组件配置 */
export const RotateLogo = false;    // 点击logo翻转出供应商中文名
export const LogoImg = [
    { name: '大唐', img: '/logo/dt.png' },
    { name: '同程', img: '/logo/tc.png' },
    { name: '途牛', img: '/logo/tn.png' },
    { name: '行旅', img: '/logo/auvgo.png' },
    { name: '差旅壹号', img: '/logo/yihao.png' },
    { name: '差旅管家', img: '/logo/guanjia.png' },
    { name: '公务票', img: '/logo/GP.png' },
]


/* 酒店订单状态-----（不同供应商状态名不同） */

// 订单状态跟下方数组某项匹配时 状态文字变“红”
export const HotelRed = ['已退房', '退房中', '未入住', '无效']

// 订单状态跟下方数组某项匹配时 状态文字变”黄“
export const HotelOrange = ['待确认', '待提交', '待支付', '确认中', '等待确认', '等待支付']

// 订单状态跟下方数组某项匹配时 状态文字变“蓝”
export const HotelBlue = ['已确认', '已入住', '待入住']

// 订单状态跟下方数组某项匹配时 会展示“退房”按钮
export const HotelRefundBtn = ['待提交', '待支付', '已确认', '待确认', '待入住', '等待确认', '等待支付']

// 订单状态跟下方数组某项匹配时 会展示“确认”按钮
export const HotelConfirmBtn = ['待提交', '待确认', '待支付']


/* 火车 */

/* 火车订单状态 */

// 订单状态跟下方数组某项匹配时 状态文字变”红“
export const TrainOrderRed = ['审批不通过', '购票失败', '出票失败', '抢票失败', '退票失败', '已售罄', '信息修改失败']

// 订单状态跟下方数组某项匹配时 状态文字变“黄”
export const TrainOrderOrange = ['抢票中', '等待支付', '待支付', '待付款', '审批中', '待提交', '待审批', '改签待付款', '占座中', '未出票', '改签中', '出票中', '退票中', '信息修改中']

// 订单状态跟下方数组某项匹配时 状态文字变”蓝“
export const TrainOrderBlue = ['已出票', '已改签', '出票成功', '部分改签', '全员改签', '出票成功', '已申请出票', '已付款', '部分退票', '退票成功', '信息修改成功']

// 订单状态跟下方数组某项匹配时 会展示“提交，取消”按钮
export const TrainOrderConfirmBtn = ['未出票', '待提交', '待付款', '改签待付款']


/* 火车票状态 */

// 订单状态跟下方数组某项匹配时 会展示“退票，改签”按钮
export const TrainTicketRefundBtn = ['已出票', '出票成功', 'F', '改签成功']

// 订单状态跟下方数组某项匹配时 会展示“查看改签”按钮
export const TrainTicketChangeBtn = ['改签中', '已改签', '部分改签', '全员改签', '改签成功', '改签待付款']


/* 飞机订单状态 */

// 订单状态跟下方数组某项匹配时 状态文字变”红“
export const AircraftRed = ['退票失败', '出票失败', '退票审批未通过', '改签失败', '改签审批未通过']

// 订单状态跟下方数组某项匹配时 状态文字变“黄”
export const AircraftOrange = ['待审批', '出票中', '待付款', '待提交', '占座中', '退票待审批', '退票待确认', '退票中', '改签待审批', '等待服务商确认', '改签中', '未出票']

// 订单状态跟下方数组某项匹配时 状态文字变”蓝“
export const AircraftBlue = ['已出票', '已改签', '部分改签', '部分退票', '退票完成']

// 订单状态跟下方数组某项匹配时 会展示“出票，取消”按钮
export const AircraftConfirmBtn = ['待提交', '待付款']

// 订单状态跟下方数组某项匹配时 会展示“退票，改签”按钮
export const AircraftRefundBtn = ['已出票', '部分改签', '部分退票', '改签成功']


/* 我的-提交反馈 & 反馈历史 */
export const NeedFeedback = true // 开启相关功能 
export const FeedbackUrl = "https://tech.szhtkj.com.cn/feedback/static/index.html/#/" // 反馈系统地址
export const FeedbackSchool = "school=jhxt" // url参数中的学校标识符


// 各个服务商电话
export function fetchPhone(corp = "大唐") {
    switch (corp) {
        case '大唐':
            return '4008225060'
        case '差旅一号':
        case '差旅壹号':
            return '4000517092'
        case '差旅管家':
            return '4009005788'
        case '行旅':
            return '4006060011'
        default:
            return null
    }
}
