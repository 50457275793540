/**
 * 项目相关信息，项目中的人员，项目类型 经费 起始站等。
 */

const state = {
    orderItem:{},// 订单
    nextOrderItem:{}, // 机票往返订单的返程单
    orderItemChange:{},
}

const mutations = {
    SET_ORDERITEM: (state, info) => {
        state.orderItem = info
    },
    SET_NEXTORDERITEM: (state, info) => {
        state.nextOrderItem = info
    },
    SET_ORDERITEMCHANGE: (state, info) => {
        state.orderItemChange = info
    },
}

const actions = {
    setOrderItem({ commit }, info) {
        commit('SET_ORDERITEM', info)
    },
    setNextOrderItem({ commit }, info) {
        commit('SET_NEXTORDERITEM', info)
    },
    setOrderItemChange({ commit }, info) {
        commit('SET_ORDERITEMCHANGE', info)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}