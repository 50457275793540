/**
 * 时间处理方法
 */
import moment from "moment";

/**
 * 计算两个日期间隔少 天|月|年  ---  例：23：00->00：30（间隔1.5小时）算一天
 * @param {Date} start 开始时间 
 * @param {Date} end 结束时间，默认今日
 * @param {String} type 计算类型，days|month|year，默认days
 * @returns {Number} 相差天数
 */
export function DiffDate(start, end = new Date(), type = "days", format = "YYYY-MM-DD") {
    return moment(moment(end).format(format)).diff(
        moment(moment(start).format(format)),
        type
    )
}

/**
 * 日期加N 天|月|年
 * @param {Date} start 开始日期
 * @param {Number} range 加多久
 * @param {String} type 类型，hour|days|month|year，默认days
 * @returns {Date} 日期
 */
export function AddDate(start = moment().format('YYYY-MM-DD'), range = 1, type = "days") {
    return moment(start).add(range, type).format('YYYY-MM-DD')
}
// 默认返回+1天的13:00 -- YYYY-MM-DD HH:mm:ss
export function AddDateFull(start = moment().format('YYYY-MM-DD'), days = 1, hours = 13, format = 'YYYY-MM-DD HH:mm') {
    let time = moment(start).format('YYYY-MM-DD')
    return moment(time).add(days, 'days').add(hours, 'hours').format(format)
}

/**
 * 判断日期是周几，三天之内日期用今天，明天等表示
 * @param {Date} date 日期 
 * @returns {String} 周几|今天|明天
 */
export function WhichDay(date) {
    let diff = DiffDate(moment(), date);
    switch (diff) {
        case 0:
            return '今天'
        case 1:
            return '明天'
        case 2:
            return '后天'
        case -1:
            return '昨天'
        case -2:
            return '前天'
        default:
            return moment(date).format('ddd')
    }
}

/**
 * 整理时间格式，如果参数小于当前日期则返回当前日期
 * @param {Date} benchmark 基准时间
 */
export function DepartureDate(benchmark,format="YYYY-MM-DD") {
    benchmark = moment(benchmark).format(format)
    let cur = moment().format(format)
    let diff = DiffDate(cur, benchmark,'days',format);
    return diff > 0 ? benchmark : cur
}