<template>
  <!-- 页面任意位置的品牌logo请使用该组件，改颜色调样式方便 -->
  <!-- logo.png比例均为110*30 -->
  <div class="logo-cont" @click="click($event)">
    <div v-if="corp&&corp != '其他'" :class="'animate-cont '+(animate?'logo-animate':'')">
      <van-image class="logo-img transition" :src="showCorp.img" />
      <van-tag class="logo-text transition" plain type="primary">{{showCorp.name}}</van-tag>
    </div>
    <van-tag :plain="plain" v-else>其他</van-tag>
  </div>
</template>

<script>
import { RotateLogo, LogoImg } from "@/common/config";
export default {
  name: "Logo",
  props: {
    corp: {
      // 匹配名称，如需按照其他匹配自个儿加下html
      default: () => "其他",
    },
    plain: {
      // 线性风格（弃用）
      default: () => false,
    },
  },
  computed: {
    // 当前页展示的logo数据
    showCorp() {
      let arr = LogoImg.filter((e) => {
        return e.name == this.corp;
      });
      if (!(0 in arr)) return { name: "其他" };
      return arr[0];
    },
  },
  data() {
    return {
      config_rotateLogo: RotateLogo, // 是否旋转
      animate: false, // 翻转控制
    };
  },
  methods:{
    // 点击翻转，通过config.js配置是否可用
    click(e){
      if(this.config_rotateLogo){
        this.animate=!this.animate
        e.stopPropagation();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.logo-cont {
  display: inline-block;
  min-width: 32px;
  .logo-img {
    width: 44px;
    height: 12px;
    backface-visibility: hidden;
  }
  .animate-cont {
    position: relative;
  }
  .logo-text {
    position: absolute;
    display: block;
    left: 0;
    text-align: center;
    top: 50%;
    width: 40px;
    max-width: 100%;
    box-sizing: border-box;
    transform: rotateY(180deg);
    backface-visibility: hidden;
  }
}
.logo-animate {
  .logo-img {
    transform: rotateY(180deg);
  }
  .logo-text {
    transform: rotateY(0deg) translateY(-40%);
  }
}
</style>