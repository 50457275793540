import { orderStatusReload, changeOrderStatusReload } from "@/api/train";
import CHINA_TRAIN_LIST from "../common/火车站站点list";
import {
    TrainOrderRed,
    TrainOrderOrange,
    TrainOrderBlue
} from "@/common/config";

/* 火车票相关方法 */
/* 普通购票，改签购票控制 */
let isNormal = true

/**
 * 正常购票返回true
 * 改签购票返回false
 */
export function IsNormal() {
    return isNormal
}

/**
 * 返回指定城市的code
 * @param {String} city 城市全称
 * @returns {String} cityCode 城市code
 */
 export function FetchCityCode(city) {
    let code = ""
    for (let key in CHINA_TRAIN_LIST) {
        CHINA_TRAIN_LIST[key].forEach(element => {
            if (element.name == city) {
                code = element.code
            }
        });
        if (code != "") break;
    }
    return code
}

/**
 * 查询占座是否成功
 * @param {Object} req
 * @returns {Object} 票据信息
 */
export async function refreshTicket(req = {}) {
    return new Promise(function (resolve, reject) {
        if (req == {}) resolve("参数异常")
        setTimeout(() => {
            orderStatusReload(req).then(response => {
                if (response.order.bz1 != '占座中') {
                    resolve(response)
                }
                else resolve(false)
            });
        }, 10 * 1000);
    })
}

/**
 * 查询改签占座是否成功
 * @param {Object} req
 * @returns {Object} 票据信息
 */
export async function refreshChangeTicket(req = {}) {
    return new Promise(function (resolve, reject) {
        setTimeout(() => {
            changeOrderStatusReload(req).then(response => {
                if (response.orderStatus == 'N') {
                    resolve(false)
                }
                else resolve(response)
            });
        }, 10 * 1000);
    })
}

/**
 * 获取状态颜色。（对应到button的type字段，例如红色返回danger）
 * @param {Sting} text 
 */
export function getStatusColor(text = '占座中') {
    if (TrainOrderRed.includes(text.trim())) return "danger";
    if (TrainOrderBlue.includes(text.trim())) return "info";
    if (TrainOrderOrange.includes(text.trim())) return "warning";
    return "default";
}

/**
 * 检查是否有指定城市(include匹配)
 * @param {String} city 城市名 
 * @returns {Object} 返回匹配的对象，没有匹配到返回{}
 */
export function checkCity(city) {
    let result = []
    for (let key in CHINA_TRAIN_LIST) {
        result = result.concat(CHINA_TRAIN_LIST[key])
    }
    result = result.filter(element => {
        return element.name.includes(city)
    })
    result.push({})
    return result[0]
}

export function getTicketStatus(code){
    switch (code) {
        case 'N':
            return '未出票'
        case 'F':
            return '已出票'
        case 'C':
            return '退票中'
        case 'B':
            return '申请退票失败'
        case 'T':
            return '已退票'
        case 'O':
            return '退票失败'
        case 'Y':
            return '改签中'
        case 'G':
            return '已改签'
        default:
            return '其他'
    }
}
