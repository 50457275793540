/**
 * 酒店的vuex
 */
import moment from "moment";
import { AddDate, DiffDate, DepartureDate } from "@/services/date"
import store from '@/store'
import {TravelFrom} from '@/common/config'

const state = {
  hotelOrderInfo: null, // 酒店订单，参数参考initHotelOrder
  hotelticket: {}, // 选中的酒店
  roomticket:{}, // 选中房型
}

const mutations = {
  SET_HOTELORDER: (state, info) => {
    let { city = state.hotelOrderInfo.city, checkInDate = state.hotelOrderInfo.checkInDate, departDate = state.hotelOrderInfo.departDate, keyword =  state.hotelOrderInfo.keyword } = info
    let f = DiffDate(checkInDate, departDate)
    checkInDate = moment(checkInDate).format("YYYY-MM-DD")
    departDate = f >= 1 ? moment(departDate).format("YYYY-MM-DD") : AddDate(checkInDate)
    state.hotelOrderInfo = { city: city, checkInDate: checkInDate, departDate: departDate, keyword: keyword }
  },
  SET_HOTELTICKET:(state,info)=>{
    state.hotelticket = info
  },
  SET_ROOMTICKET:(state,info)=>{
    state.roomticket = info
  },
}

const actions = {
  // 修改hotelOrder
  setHotelOrder({ commit }, info) {
    commit('SET_HOTELORDER', info)
  },
  // 重置hotelOrder
  initHotelOrder({ commit }) {
    let order = store.getters.travelInfo
    commit('SET_HOTELORDER', {
      city: (order.routeCityEnd && order.routeCityEnd.constructor == Array) ? order.routeCityEnd[1] : TravelFrom,    // 目的城市
      checkInDate: DepartureDate(order.startDate), // 入住日期
      departDate: DepartureDate(order.startDate), // 离店日期
      keyword: '' // 搜索词
    })
  },
  // 选择城市
  setHotelOrderFrom({ commit }, city) {
    commit('SET_HOTELORDER', {
      city: city.name
    })
  },
  // 选酒店
  setHotelTicket({ commit }, info) {
    commit('SET_HOTELTICKET', info)
  },
  // 选房型
  setRoomTicket({ commit }, info) {
    commit('SET_ROOMTICKET', info)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}