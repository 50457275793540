import CHINA_STATION_LIST from "../common/火车站站点list";
import CHINA_AIRCRAFT_LIST from "../common/机场list";
import CHINA_HOTEL_LIST from "../common/国内酒店城市list";
import { CityRE } from "@/common/config.js";
import { DynamicRoutes } from "@/router/index.js";
import store from '@/store'



/* 滚回顶部 */
export function ToTop() {
    document.documentElement.scrollTop = 0 // 兼容pc
    document.body.scrollTop = 0 // 兼容微信游览器
}

/**
 * 获取选择城市列表
 * @param {String} which 类型 机火酒
 * @param {Boolean} all 是否强制获取完整列表
 */
export function fetchCityList(which,all) {
    let list = {}, count = 0
    switch (which) {
        case "aircraft":
            list = CHINA_AIRCRAFT_LIST;
            break;
        case "train":
            list = CHINA_STATION_LIST;
            break;
        case "hotel":
            list = CHINA_HOTEL_LIST;
            break;
    }

    if(all) return list

    // 如果不是自由行
    if (store.getters.config_controlLevel != 0) {
        // 过滤行程以外的城市
        for (let key in list) {
            let result = list[key].filter(e => {
                return store.getters.travelInfo.routeCityEnd.includes(e.name.replace(CityRE, ''))
            })
            list[key] = result
            count += result.length // 统计合规城市数量
        }
        // 删除热门城市
        delete list.hot
    }

    return list
}

// 加载动态路由
export function fetchRoutes() {
    return DynamicRoutes
}

/**
 * 
 * @param {String} corp 品牌 - 大唐、差旅壹号、差旅管家、行旅
 * @param {String} traffic 交通方式 - 机票、火车、酒店
 * @param {String} status 状态码
 */
export function fetchOrderStatus(corp, traffic, status, type) {
    switch (corp) {
        case '大唐':
            switch (traffic) {
                case 'aircraft':
                    // 大唐 - 机票
                    switch (status) {
                        case "0":
                            return "待审批"
                        case "3":
                            return "待提交"
                        case "4":
                            return "占座中"
                        case "22":
                            return "出票失败"
                        case "40":
                            return "退票待审批"
                        case "41":
                            return "退票待确认"
                        case "42":
                            return "退票中"
                        case "47":
                            return "退票失败"
                        case "48":
                            return "退票审批不通过"
                        case "49":
                            return "取消退票"
                        case "60":
                            return "改签待审批"
                        case "63":
                            return "改签成功"
                        case "64":
                            return "部分改签"
                        case "66":
                            return "取消改签"
                        case "67":
                            return "改签失败"
                        case "68":
                            return "改签审批不通过"
                        case "92":
                            return "待确认"
                        case "93":
                            return "改签中"
                        case "94":
                            return "部分退票"
                        case "95":
                            return "退票成功"
                        case "96":
                            return "已取消"
                        case "97":
                            return "已出票"
                        case "98":
                            return "出票中"
                        case "99":
                            return "待付款"
                        default:
                            return '其他';
                    }
                case 'train':
                    // 大唐 - 火车
                    switch (status) {
                        case "N":
                            return "占座中"
                        case "D":
                            return "待提交"
                        case "S":
                            return "待审批"
                        case "G":
                            return "审批中"
                        case "H":
                            return "审批不通过"
                        case "B":
                            return "购票失败"
                        case "A":
                            return "待支付"
                        case "O":
                            return "订单已过期"
                        case "K":
                            return "抢票中"
                        case "M":
                            return "已付款"
                        case "U":
                            return "申请出票失败"
                        case "J":
                            return "申请抢票失败"
                        case "C":
                            return "订单已取消"
                        case "F":
                            return "出票成功"
                        case "P":
                            return "出票失败"
                        case "T":
                            return "已全部退票"
                        case "Q":
                            return "已全部改签"
                        case "X":
                            return "部分改签"
                        case "Y":
                            return "部分退票"
                    }
                    break;
                case 'hotel':
                default:
                    return '其他';
            }
            break;
        case '差旅壹号':
            switch (traffic) {
                case 'aircraft':
                    switch (type) {
                        case 'original':
                            // 差旅一号 - 机票 - 原单
                            switch (parseInt(status)) {
                                case 0:
                                    return '待支付'
                                case 1:
                                    return '待出票'
                                case 2:
                                    return '已出票'
                                case 3:
                                    return '订单取消'
                                case 4:
                                    return '订单取消，待退款'
                                case 5:
                                    return '订单取消，已退款'
                                case 6:
                                    return '补录已出票'
                                case 7:
                                    return '违规待审核'
                                case 8:
                                    return '违规审核通过'
                                case 9:
                                    return '违规审核未通过'
                                case 10:
                                    return '全部改签'
                                case 11:
                                    return '部分改签'
                                case 12:
                                    return '全部退票'
                                case 13:
                                    return '部分退票'
                                case 14:
                                    return '部分改签，部分退票'
                                default:
                                    return '其他'
                            }
                        case 'change':
                            // 差旅一号 - 机票 - 改签
                            switch (parseInt(status)) {
                                case 1:
                                    return '改签待审核'
                                case 2:
                                    return '改签待支付'
                                case 3:
                                    return '改签中'
                                case 4:
                                    return '改签成功'
                                case 5:
                                    return '改签失败退款中'
                                case 6:
                                    return '改签失败已退款'
                                case 7:
                                    return '订单已取消'
                                case 8:
                                    return '改签失败'
                                case 9:
                                    return '改签提交成功'
                                default:
                                    return '其他'
                            }
                        case 'refund':
                            // 差旅一号 - 机票 - 退票
                            switch (parseInt(status)) {
                                case 1:
                                    return '退票待审核'
                                case 2:
                                    return '退票审核中'
                                case 3:
                                    return '退票待退款'
                                case 4:
                                    return '退票退款中'
                                case 5:
                                    return '退票完成'
                                case 6:
                                    return '退票失败'
                                case 7:
                                    return '退票提交成功'
                                default:
                                    return '其他'
                            }
                        default:
                            return '其他'
                    }
                case 'train':
                case 'hotel':
                default:
                    return '其他'
            }
        case '差旅管家':
            switch (traffic) {
                case 'aircraft':
                    // 管家 - 机票
                    switch (parseInt(status)) {
                        case 1:
                            return '新订单'
                        case 2:
                            return '等待出票'
                        case 3:
                            return '出票处理中'
                        case 4:
                            return '已出票'
                        case 5:
                            return '退票处理中'
                        case 6:
                            return '退票操作完成待航司审核'
                        case 7:
                            return '航司审核完成待财务与客户退款'
                        case 8:
                            return '退票处理完成'
                        case 13:
                            return '改签审核中'
                        case 14:
                            return '改签审核完成待支付'
                        case 18:
                            return '支付完成改签处理中'
                        case 15:
                            return '改签完成'
                        case 0:
                            return '已取消订单'
                        default:
                            return '其他'
                    }
                case 'hotel':
                    // 管家 - 酒店
                    switch (parseInt(status)) {
                        case 10:
                            return "超时未支付"
                        case 11:
                            return "申请取消"
                        case 12:
                            return "确认无房"
                        case 15:
                            return "客户主动取消"
                        case 22:
                            return "确认中"
                        case 23:
                            return "待入住"
                        case 32:
                            return "待提交"
                        case 40:
                            return "已入住"
                        case 41:
                            return "已离店"
                        case 50:
                            return "申请退款"
                        case 51:
                            return "已退款"
                        default:
                            return '其他'
                    }
                // 管家 - 火车 - ！！没有
                case 'train':
                default:
                    return '其他'
            }
        case '行旅':
            switch (traffic) {
                case 'hotel':
                    // 行旅 - 酒店
                    switch (status) {
                        case "WAIT_CONFIRM":
                            return "待确认"
                        case "WAIT_PAY":
                            return "待支付"
                        case "CONFIRM":
                            return "已确认"
                        case "NO_SHOW":
                            return "未入住"
                        case "CHECK_OUT":
                            return "已离店"
                        case "CHECK_IN":
                            return "已入住"
                        case "INVALID":
                            return "无效"
                        case "CANCELED":
                            return "已取消"
                        default:
                            return '其他';
                    }
                default:
                    return '其他';
            }
        case '同程自付':
            switch (traffic) {
                case 'train':
                    // 自付 - 火车
                    switch (status) {
                        case "N":
                            return "占座中"
                        case "D":
                            return "待提交"
                        case "S":
                            return "待审批"
                        case "G":
                            return "审批中"
                        case "H":
                            return "审批不通过"
                        case "B":
                            return "购票失败"
                        case "A":
                            return "待支付"
                        case "O":
                            return "订单过期"
                        case "E":
                            return "已申请出票"
                        case "K":
                            return "抢票中"
                        case "R":
                            return "已预约"
                        case "U":
                            return "出票失败"
                        case "C":
                            return "订单已取消"
                        case "F":
                            return "出票成功"
                        case "P":
                            return "出票失败"
                        case "T":
                            return "全部退票"
                        case "Q":
                            return "全部改签"
                        case "X":
                            return "部分改签"
                        case "Y":
                            return "部分退票"
                        default:
                            return '其他';
                    }
                default:
                    return '其他';
            }
        default:
            return '其他';
    }

}