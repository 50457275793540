// 静态的车站列表
const CHINA_STATION_LIST = {
    'hot': [
        { "id": "00092", "name": "北京", "pinyin": "beijing", "kaitouzimu": "bj", "code": "BJP" },
        { "id": "02063", "name": "天津", "pinyin": "tianjin", "kaitouzimu": "tj", "code": "TJP" },
        { "id": "01859", "name": "石家庄", "pinyin": "shijiazhuang", "kaitouzimu": "sjz", "code": "SJP" },
        { "id": "00788", "name": "呼和浩特", "pinyin": "huhehaote", "kaitouzimu": "hhht", "code": "HHC" },
        { "id": "01965", "name": "沈阳", "pinyin": "shenyang", "kaitouzimu": "sy", "code": "SYT" },
        { "id": "00388", "name": "大连", "pinyin": "dalian", "kaitouzimu": "dl", "code": "DLT" },
        { "id": "00181", "name": "长春", "pinyin": "changchun", "kaitouzimu": "cc", "code": "CCT" },
        { "id": "00759", "name": "哈尔滨", "pinyin": "haerbin", "kaitouzimu": "heb", "code": "HBB" },
        { "id": "01804", "name": "上海", "pinyin": "shanghai", "kaitouzimu": "sh", "code": "SHH" },
        { "id": "01496", "name": "南京", "pinyin": "nanjing", "kaitouzimu": "nj", "code": "NJH" },
        { "id": "02255", "name": "无锡", "pinyin": "wuxi", "kaitouzimu": "wx", "code": "WXH" },
        { "id": "00297", "name": "常州", "pinyin": "changzhou", "kaitouzimu": "cz", "code": "CZH" },
        { "id": "02008", "name": "苏州", "pinyin": "suzhou", "kaitouzimu": "sz", "code": "SZH" },
        { "id": "00929", "name": "杭州", "pinyin": "hangzhou", "kaitouzimu": "hz", "code": "HZH" },
        { "id": "01459", "name": "宁波", "pinyin": "ningbo", "kaitouzimu": "nb", "code": "NGH" },
        { "id": "02276", "name": "温州", "pinyin": "wenzhou", "kaitouzimu": "wz", "code": "RZH" },
        { "id": "00766", "name": "合肥", "pinyin": "hefei", "kaitouzimu": "hf", "code": "HFH" },
        { "id": "00590", "name": "福州", "pinyin": "fuzhou", "kaitouzimu": "fz", "code": "FZS" },
        { "id": "02387", "name": "厦门", "pinyin": "xiamen", "kaitouzimu": "xm", "code": "XMS" },
        { "id": "01466", "name": "南昌", "pinyin": "nanchang", "kaitouzimu": "nc", "code": "NCG" },
        { "id": "01004", "name": "济南", "pinyin": "jinan", "kaitouzimu": "jn", "code": "JNK" },
        { "id": "01641", "name": "青岛", "pinyin": "qingdao", "kaitouzimu": "qd", "code": "QDK" },
        { "id": "02851", "name": "郑州", "pinyin": "zhengzhou", "kaitouzimu": "zz", "code": "ZZF" },
        { "id": "02186", "name": "武汉", "pinyin": "wuhan", "kaitouzimu": "wh", "code": "WHN" },
        { "id": "00257", "name": "长沙", "pinyin": "changsha", "kaitouzimu": "cs", "code": "CSQ" },
        { "id": "00709", "name": "广州", "pinyin": "guangzhou", "kaitouzimu": "gz", "code": "GZQ" },
        { "id": "02003", "name": "深圳", "pinyin": "shenzhen", "kaitouzimu": "sz", "code": "SZQ" },
        { "id": "00555", "name": "佛山", "pinyin": "foshan", "kaitouzimu": "fs", "code": "FSQ" },
        { "id": "00354", "name": "东莞", "pinyin": "dongguan", "kaitouzimu": "dg", "code": "RTQ" },
        { "id": "01519", "name": "南宁", "pinyin": "nanning", "kaitouzimu": "nn", "code": "NNZ" },
        { "id": "00245", "name": "重庆", "pinyin": "chongqing", "kaitouzimu": "cq", "code": "CQW" },
        { "id": "00192", "name": "成都", "pinyin": "chengdu", "kaitouzimu": "cd", "code": "CDW" },
        { "id": "00687", "name": "贵阳", "pinyin": "guiyang", "kaitouzimu": "gy", "code": "GIW" },
        { "id": "01115", "name": "昆明", "pinyin": "kunming", "kaitouzimu": "km", "code": "KMM" },
        { "id": "02287", "name": "西安", "pinyin": "xian", "kaitouzimu": "xa", "code": "XAY" },
    ],
    A: [
        { "id": "00001", "name": "昂昂溪", "pinyin": "angangxi", "kaitouzimu": "aax", "code": "AAX" },
        { "id": "00002", "name": "阿巴嘎旗", "pinyin": "abagaqi", "kaitouzimu": "abgq", "code": "AQC" },
        { "id": "00003", "name": "阿城北", "pinyin": "achengbei", "kaitouzimu": "acb", "code": "ABB" },
        { "id": "00004", "name": "阿城", "pinyin": "acheng", "kaitouzimu": "ac", "code": "ACB" },
        { "id": "00005", "name": "安达", "pinyin": "anda", "kaitouzimu": "ad", "code": "ADX" },
        { "id": "00006", "name": "安德", "pinyin": "ande", "kaitouzimu": "ad", "code": "ARW" },
        { "id": "00007", "name": "安定", "pinyin": "anding", "kaitouzimu": "ad", "code": "ADP" },
        { "id": "00008", "name": "阿尔山北", "pinyin": "aershanbei", "kaitouzimu": "aesb", "code": "ARX" },
        { "id": "00009", "name": "阿尔山", "pinyin": "aershan", "kaitouzimu": "aes", "code": "ART" },
        { "id": "00010", "name": "安广", "pinyin": "anguang", "kaitouzimu": "ag", "code": "AGT" },
        { "id": "00011", "name": "艾河", "pinyin": "aihe", "kaitouzimu": "ah", "code": "AHP" },
        { "id": "00012", "name": "安化", "pinyin": "anhua", "kaitouzimu": "ah", "code": "PKQ" },
        { "id": "00013", "name": "艾家村", "pinyin": "aijiacun", "kaitouzimu": "ajc", "code": "AJJ" },
        { "id": "00014", "name": "安江东", "pinyin": "anjiangdong", "kaitouzimu": "ajd", "code": "ADA" },
        { "id": "00015", "name": "阿金", "pinyin": "ajin", "kaitouzimu": "aj", "code": "AJD" },
        { "id": "00016", "name": "安家", "pinyin": "anjia", "kaitouzimu": "aj", "code": "AJB" },
        { "id": "00017", "name": "安靖", "pinyin": "anjing", "kaitouzimu": "aj", "code": "PYW" },
        { "id": "00018", "name": "鳌江", "pinyin": "aojiang", "kaitouzimu": "aj", "code": "ARH" },
        { "id": "00019", "name": "安康", "pinyin": "ankang", "kaitouzimu": "ak", "code": "AKY" },
        { "id": "00020", "name": "阿克苏", "pinyin": "akesu", "kaitouzimu": "aks", "code": "ASR" },
        { "id": "00021", "name": "阿克陶", "pinyin": "aketao", "kaitouzimu": "akt", "code": "AER" },
        { "id": "00022", "name": "安口窑", "pinyin": "ankouyao", "kaitouzimu": "aky", "code": "AYY" },
        { "id": "00023", "name": "阿勒泰", "pinyin": "aletai", "kaitouzimu": "alt", "code": "AUR" },
        { "id": "00024", "name": "敖力布告", "pinyin": "aolibugao", "kaitouzimu": "albg", "code": "ALD" },
        { "id": "00025", "name": "阿里河", "pinyin": "alihe", "kaitouzimu": "alh", "code": "AHX" },
        { "id": "00026", "name": "阿拉山口", "pinyin": "alashankou", "kaitouzimu": "alsk", "code": "AKR" },
        { "id": "00027", "name": "安龙", "pinyin": "anlong", "kaitouzimu": "al", "code": "AUZ" },
        { "id": "00028", "name": "阿龙山", "pinyin": "alongshan", "kaitouzimu": "als", "code": "ASX" },
        { "id": "00029", "name": "安陆", "pinyin": "anlu", "kaitouzimu": "al", "code": "ALN" },
        { "id": "00030", "name": "阿木尔", "pinyin": "amuer", "kaitouzimu": "ame", "code": "JTX" },
        { "id": "00031", "name": "安多", "pinyin": "anduo", "kaitouzimu": "ad", "code": "ADO" },
        { "id": "00032", "name": "安顺西", "pinyin": "anshunxi", "kaitouzimu": "asx", "code": "ASE" },
        { "id": "00033", "name": "阿南庄", "pinyin": "ananzhuang", "kaitouzimu": "anz", "code": "AZM" },
        { "id": "00034", "name": "敖汉", "pinyin": "aohan", "kaitouzimu": "ah", "code": "YED" },
        { "id": "00035", "name": "安平", "pinyin": "anping", "kaitouzimu": "ap", "code": "APT" },
        { "id": "00036", "name": "安庆", "pinyin": "anqing", "kaitouzimu": "aq", "code": "AQH" },
        { "id": "00037", "name": "安庆西", "pinyin": "anqingxi", "kaitouzimu": "aqx", "code": "APH" },
        { "id": "00038", "name": "安仁", "pinyin": "anren", "kaitouzimu": "ar", "code": "ARG" },
        { "id": "00039", "name": "安顺", "pinyin": "anshun", "kaitouzimu": "as", "code": "ASW" },
        { "id": "00040", "name": "鞍山", "pinyin": "anshan", "kaitouzimu": "as", "code": "AST" },
        { "id": "00041", "name": "鞍山西", "pinyin": "anshanxi", "kaitouzimu": "asx", "code": "AXT" },
        { "id": "00042", "name": "安塘", "pinyin": "antang", "kaitouzimu": "at", "code": "ATV" },
        { "id": "00043", "name": "安亭北", "pinyin": "antingbei", "kaitouzimu": "atb", "code": "ASH" },
        { "id": "00044", "name": "阿图什", "pinyin": "atushi", "kaitouzimu": "ats", "code": "ATR" },
        { "id": "00045", "name": "安图", "pinyin": "antu", "kaitouzimu": "at", "code": "ATL" },
        { "id": "00046", "name": "安图西", "pinyin": "antuxi", "kaitouzimu": "atx", "code": "AXL" },
        { "id": "00047", "name": "安溪", "pinyin": "anxi", "kaitouzimu": "ax", "code": "AXS" },
        { "id": "00048", "name": "安阳", "pinyin": "anyang", "kaitouzimu": "ay", "code": "AYF" },
        { "id": "00049", "name": "安阳东", "pinyin": "anyangdong", "kaitouzimu": "ayd", "code": "ADF" },],
    B: [
        { "id": "00050", "name": "白沙铺", "pinyin": "baishapu", "kaitouzimu": "bsp", "code": "BSN" },
        { "id": "00051", "name": "北安", "pinyin": "beian", "kaitouzimu": "ba", "code": "BAB" },
        { "id": "00052", "name": "板塘", "pinyin": "bantang", "kaitouzimu": "bt", "code": "NGQ" },
        { "id": "00053", "name": "博鳌", "pinyin": "boao", "kaitouzimu": "ba", "code": "BWQ" },
        { "id": "00054", "name": "包头 东", "pinyin": "baotoudong", "kaitouzimu": "btd", "code": "FDC" },
        { "id": "00055", "name": "博白", "pinyin": "bobai", "kaitouzimu": "bb", "code": "BBZ" },
        { "id": "00056", "name": "北碚", "pinyin": "beibei", "kaitouzimu": "bb", "code": "BPW" },
        { "id": "00057", "name": "白壁关", "pinyin": "baibiguan", "kaitouzimu": "bbg", "code": "BGV" },
        { "id": "00058", "name": "蚌埠南", "pinyin": "bengbunan", "kaitouzimu": "bbn", "code": "BMH" },
        { "id": "00059", "name": "八步", "pinyin": "babu", "kaitouzimu": "bb", "code": "BBE" },
        { "id": "00060", "name": "蚌埠", "pinyin": "bengbu", "kaitouzimu": "bb", "code": "BBH" },
        { "id": "00061", "name": "巴楚", "pinyin": "bachu", "kaitouzimu": "bc", "code": "BCR" },
        { "id": "00062", "name": "白城", "pinyin": "baicheng", "kaitouzimu": "bc", "code": "BCT" },
        { "id": "00063", "name": "板城", "pinyin": "bancheng", "kaitouzimu": "bc", "code": "BUP" },
        { "id": "00064", "name": "栟茶", "pinyin": "bencha", "kaitouzimu": "bc", "code": "FWH" },
        { "id": "00065", "name": "保定东", "pinyin": "baodingdong", "kaitouzimu": "bdd", "code": "BMP" },
        { "id": "00066", "name": "北戴河", "pinyin": "beidaihe", "kaitouzimu": "bdh", "code": "BEP" },
        { "id": "00067", "name": "宝坻", "pinyin": "baodi", "kaitouzimu": "bd", "code": "BPP" },
        { "id": "00068", "name": "保定", "pinyin": "baoding", "kaitouzimu": "bd", "code": "BDP" },
        { "id": "00069", "name": "八达岭", "pinyin": "badaling", "kaitouzimu": "bdl", "code": "ILP" },
        { "id": "00070", "name": "巴东", "pinyin": "badong", "kaitouzimu": "bd", "code": "BNN" },
        { "id": "00071", "name": "八方山", "pinyin": "bafangshan", "kaitouzimu": "bfs", "code": "FGQ" },
        { "id": "00072", "name": "白沟", "pinyin": "baigou", "kaitouzimu": "bg", "code": "FEP" },
        { "id": "00073", "name": "柏果", "pinyin": "baiguo", "kaitouzimu": "bg", "code": "BGM" },
        { "id": "00074", "name": "北海", "pinyin": "beihai", "kaitouzimu": "bh", "code": "BHZ" },
        { "id": "00075", "name": "滨海", "pinyin": "binhai", "kaitouzimu": "bh", "code": "FHP" },
        { "id": "00076", "name": "布海", "pinyin": "buhai", "kaitouzimu": "bh", "code": "BUT" },
        { "id": "00077", "name": "滨海北", "pinyin": "binhaibei", "kaitouzimu": "bhb", "code": "FCP" },
        { "id": "00078", "name": "白河东", "pinyin": "baihedong", "kaitouzimu": "bhd", "code": "BIY" },
        { "id": "00079", "name": "白河", "pinyin": "baihe", "kaitouzimu": "bh", "code": "BEL" },
        { "id": "00080", "name": "滨海港", "pinyin": "binhaigang", "kaitouzimu": "bhg", "code": "BGU" },
        { "id": "00081", "name": "贲红", "pinyin": "benhong", "kaitouzimu": "bh", "code": "BVC" },
        { "id": "00082", "name": "宝华山", "pinyin": "baohuashan", "kaitouzimu": "bhs", "code": "BWH" },
        { "id": "00083", "name": "白河县", "pinyin": "baihexian", "kaitouzimu": "bhx", "code": "BEY" },
        { "id": "00084", "name": "滨海西", "pinyin": "binhaixi", "kaitouzimu": "bhx", "code": "FHP" },
        { "id": "00085", "name": "北京北", "pinyin": "beijingbei", "kaitouzimu": "bjb", "code": "VAP" },
        { "id": "00086", "name": "北京东", "pinyin": "beijingdong", "kaitouzimu": "bjd", "code": "BOP" },
        { "id": "00087", "name": "白芨沟", "pinyin": "baijigou", "kaitouzimu": "bjg", "code": "BJJ" },
        { "id": "00088", "name": "碧鸡关", "pinyin": "bijiguan", "kaitouzimu": "bjg", "code": "BJM" },
        { "id": "00089", "name": "白涧", "pinyin": "baijian", "kaitouzimu": "bj", "code": "BAP" },
        { "id": "00090", "name": "宝鸡", "pinyin": "baoji", "kaitouzimu": "bj", "code": "BJY" },
        { "id": "00091", "name": "北滘", "pinyin": "beijiao", "kaitouzimu": "b", "code": "IBQ" },
        { "id": "00092", "name": "北京", "pinyin": "beijing", "kaitouzimu": "bj", "code": "BJP" },
        { "id": "00093", "name": "碧江", "pinyin": "bijiang", "kaitouzimu": "bj", "code": "BLQ" },
        { "id": "00094", "name": "滨江", "pinyin": "binjiang", "kaitouzimu": "bj", "code": "BJB" },
        { "id": "00095", "name": "宝鸡南", "pinyin": "baojinan", "kaitouzimu": "bjn", "code": "BBY" },
        { "id": "00096", "name": "北京南", "pinyin": "beijingnan", "kaitouzimu": "bjn", "code": "VNP" },
        { "id": "00097", "name": "白鸡坡", "pinyin": "baijipo", "kaitouzimu": "bjp", "code": "BBM" },
        { "id": "00098", "name": "笔架山", "pinyin": "bijiashan", "kaitouzimu": "bjs", "code": "BSB" },
        { "id": "00099", "name": "八角台", "pinyin": "bajiaotai", "kaitouzimu": "bjt", "code": "BTD" },
        { "id": "00100", "name": "北京西", "pinyin": "beijingxi", "kaitouzimu": "bjx", "code": "BXP" },
        { "id": "00101", "name": "北井子", "pinyin": "beijingzi", "kaitouzimu": "bjz", "code": "BRT" },
        { "id": "00102", "name": "保康", "pinyin": "baokang", "kaitouzimu": "bk", "code": "BKD" },
        { "id": "00103", "name": "白奎堡", "pinyin": "baikuipu", "kaitouzimu": "bkb", "code": "BKB" },
        { "id": "00104", "name": "博克图", "pinyin": "boketu", "kaitouzimu": "bkt", "code": "BKX" },
        { "id": "00105", "name": "白狼", "pinyin": "bailang", "kaitouzimu": "bl", "code": "BAT" },
        { "id": "00106", "name": "百浪", "pinyin": "bailang", "kaitouzimu": "bl", "code": "BRZ" },
        { "id": "00107", "name": "博乐", "pinyin": "bole", "kaitouzimu": "bl", "code": "BOR" },
        { "id": "00108", "name": "宝拉格", "pinyin": "baolage", "kaitouzimu": "blg", "code": "BQC" },
        { "id": "00109", "name": "巴林", "pinyin": "balin", "kaitouzimu": "bl", "code": "BLX" },
        { "id": "00110", "name": "宝林", "pinyin": "baolin", "kaitouzimu": "bl", "code": "BNB" },
        { "id": "00111", "name": "北流", "pinyin": "beiliu", "kaitouzimu": "bl", "code": "BOZ" },
        { "id": "00112", "name": "勃利", "pinyin": "boli", "kaitouzimu": "bl", "code": "BLB" },
        { "id": "00113", "name": "布列开", "pinyin": "buliekai", "kaitouzimu": "blk", "code": "BLR" },
        { "id": "00114", "name": "宝龙山", "pinyin": "baolongshan", "kaitouzimu": "bls", "code": "BND" },
        { "id": "00115", "name": "百里峡", "pinyin": "bailixia", "kaitouzimu": "blx", "code": "AAP" },
        { "id": "00116", "name": "八面城", "pinyin": "bamiancheng", "kaitouzimu": "bmc", "code": "BMD" },
        { "id": "00117", "name": "白马井", "pinyin": "baimajing", "kaitouzimu": "bmj", "code": "BFQ" },
        { "id": "00118", "name": "班猫箐", "pinyin": "banmaoqing", "kaitouzimu": "bmj", "code": "BNM" },
        { "id": "00119", "name": "八面通", "pinyin": "bamiantong", "kaitouzimu": "bmt", "code": "BMB" },
        { "id": "00120", "name": "北马圈子", "pinyin": "beimaquanzi", "kaitouzimu": "bmqz", "code": "BRP" },
        { "id": "00121", "name": "北票", "pinyin": "beipiao", "kaitouzimu": "bp", "code": "BPT" },
        { "id": "00122", "name": "北票南", "pinyin": "beipiaonan", "kaitouzimu": "bpn", "code": "RPD" },
        { "id": "00123", "name": "白旗", "pinyin": "baiqi", "kaitouzimu": "bq", "code": "BQP" },
        { "id": "00124", "name": "宝清", "pinyin": "baoqing", "kaitouzimu": "bq", "code": "BUB" },
        { "id": "00125", "name": "宝泉岭", "pinyin": "baoquanling", "kaitouzimu": "bql", "code": "BQB" },
        { "id": "00126", "name": "白泉", "pinyin": "baiquan", "kaitouzimu": "bq", "code": "BQL" },
        { "id": "00127", "name": "百色", "pinyin": "baise", "kaitouzimu": "bs", "code": "BIZ" },
        { "id": "00128", "name": "巴山", "pinyin": "bashan", "kaitouzimu": "bs", "code": "BAY" },
        { "id": "00129", "name": "白沙", "pinyin": "baisha", "kaitouzimu": "bs", "code": "BSW" },
        { "id": "00130", "name": "璧山", "pinyin": "bishan", "kaitouzimu": "bs", "code": "FZW" },
        { "id": "00131", "name": "白水江", "pinyin": "baishuijiang", "kaitouzimu": "bsj", "code": "BSY" },
        { "id": "00132", "name": "白沙坡", "pinyin": "baishapo", "kaitouzimu": "bsp", "code": "BPM" },
        { "id": "00133", "name": "白山市", "pinyin": "baishanshi", "kaitouzimu": "bss", "code": "HJL" },
        { "id": "00134", "name": "白石山", "pinyin": "baishishan", "kaitouzimu": "bss", "code": "BAL" },
        { "id": "00135", "name": "白水县", "pinyin": "baishuixian", "kaitouzimu": "bsx", "code": "BGY" },
        { "id": "00136", "name": "白水镇", "pinyin": "baishuizhen", "kaitouzimu": "bsz", "code": "BUM" },
        { "id": "00137", "name": "北台", "pinyin": "beitai", "kaitouzimu": "bt", "code": "BTT" },
        { "id": "00138", "name": "包头东", "pinyin": "baotoudong", "kaitouzimu": "btd", "code": "BDC" },
        { "id": "00139", "name": "坂田", "pinyin": "bantian", "kaitouzimu": "bt", "code": "BTQ" },
        { "id": "00140", "name": "包头", "pinyin": "baotou", "kaitouzimu": "bt", "code": "BTC" },
        { "id": "00141", "name": "泊头", "pinyin": "botou", "kaitouzimu": "bt", "code": "BZP" },
        { "id": "00142", "name": "北屯市", "pinyin": "beitunshi", "kaitouzimu": "bts", "code": "BXR" },
        { "id": "00143", "name": "北屯", "pinyin": "beitun", "kaitouzimu": "bt", "code": "BYP" },
        { "id": "00144", "name": "白文东", "pinyin": "baiwendong", "kaitouzimu": "bwd", "code": "BCV" },
        { "id": "00145", "name": "宾西北", "pinyin": "binxibei", "kaitouzimu": "bxb", "code": "BBB" },
        { "id": "00146", "name": "本溪新城", "pinyin": "benxixincheng", "kaitouzimu": "bxxc", "code": "BVT" },
        { "id": "00147", "name": "本溪湖", "pinyin": "benxihu", "kaitouzimu": "bxh", "code": "BHT" },
        { "id": "00148", "name": "本溪", "pinyin": "benxi", "kaitouzimu": "bx", "code": "BXT" },
        { "id": "00149", "name": "彬县", "pinyin": "binxian", "kaitouzimu": "bx", "code": "BXY" },
        { "id": "00150", "name": "博兴", "pinyin": "boxing", "kaitouzimu": "bx", "code": "BXK" },
        { "id": "00151", "name": "八仙筒", "pinyin": "baxiantong", "kaitouzimu": "bxt", "code": "VXD" },
        { "id": "00152", "name": "宾阳", "pinyin": "binyang", "kaitouzimu": "by", "code": "UKZ" },
        { "id": "00153", "name": "白云鄂博", "pinyin": "baiyunebo", "kaitouzimu": "byeb", "code": "BEC" },
        { "id": "00154", "name": "白洋淀", "pinyin": "baiyangdian", "kaitouzimu": "byd", "code": "FWP" },
        { "id": "00155", "name": "白音察干", "pinyin": "baiyinchagan", "kaitouzimu": "bycg", "code": "BYC" },
        { "id": "00156", "name": "背荫河", "pinyin": "beiyinhe", "kaitouzimu": "byh", "code": "BYB" },
        { "id": "00157", "name": "百宜", "pinyin": "baiyi", "kaitouzimu": "by", "code": "FHW" },
        { "id": "00158", "name": "北营", "pinyin": "beiying", "kaitouzimu": "by", "code": "BIV" },
        { "id": "00159", "name": "巴彦高勒", "pinyin": "bayangaole", "kaitouzimu": "bygl", "code": "BAC" },
        { "id": "00160", "name": "白音他拉", "pinyin": "baiyintala", "kaitouzimu": "bytl", "code": "BID" },
        { "id": "00161", "name": "白音华南", "pinyin": "baiyinhuanan", "kaitouzimu": "byhn", "code": "FNC" },
        { "id": "00162", "name": "鲅鱼圈", "pinyin": "bayuquan", "kaitouzimu": "byq", "code": "BYT" },
        { "id": "00163", "name": "白音胡硕", "pinyin": "baiyinhushuo", "kaitouzimu": "byhs", "code": "BCD" },
        { "id": "00164", "name": "白银市", "pinyin": "baiyinshi", "kaitouzimu": "bys", "code": "BNJ" },
        { "id": "00165", "name": "白银西", "pinyin": "baiyinxi", "kaitouzimu": "byx", "code": "BXJ" },
        { "id": "00166", "name": "巴中东", "pinyin": "bazhongdong", "kaitouzimu": "bzd", "code": "BDE" },
        { "id": "00167", "name": "巴中", "pinyin": "bazhong", "kaitouzimu": "bz", "code": "IEW" },
        { "id": "00168", "name": "霸州", "pinyin": "bazhou", "kaitouzimu": "bz", "code": "RMP" },
        { "id": "00169", "name": "北宅", "pinyin": "beizhai", "kaitouzimu": "bz", "code": "BVP" },
        { "id": "00170", "name": "宾州", "pinyin": "binzhou", "kaitouzimu": "bz", "code": "BZB" },
        { "id": "00171", "name": "彬州", "pinyin": "binzhou", "kaitouzimu": "bz", "code": "BXY" },
        { "id": "00172", "name": "滨州", "pinyin": "binzhou", "kaitouzimu": "bz", "code": "BIK" },
        { "id": "00173", "name": "亳州", "pinyin": "bozhou", "kaitouzimu": "bz", "code": "BZH" },
        { "id": "00174", "name": "霸州西", "pinyin": "bazhouxi", "kaitouzimu": "bzx", "code": "FOP" },],
    C: [
        { "id": "00175", "name": "赤壁北", "pinyin": "chibibei", "kaitouzimu": "cbb", "code": "CIN" },
        { "id": "00176", "name": "查布嘎", "pinyin": "chabuga", "kaitouzimu": "cbg", "code": "CBC" },
        { "id": "00177", "name": "赤壁", "pinyin": "chibi", "kaitouzimu": "cb", "code": "CBN" },
        { "id": "00178", "name": "澄城", "pinyin": "chengcheng", "kaitouzimu": "cc", "code": "CUY" },
        { "id": "00179", "name": "长城", "pinyin": "changcheng", "kaitouzimu": "cc", "code": "CEJ" },
        { "id": "00180", "name": "长冲", "pinyin": "changchong", "kaitouzimu": "cc", "code": "CCM" },
        { "id": "00181", "name": "长春", "pinyin": "changchun", "kaitouzimu": "cc", "code": "CCT" },
        { "id": "00182", "name": "长春南", "pinyin": "changchunnan", "kaitouzimu": "ccn", "code": "CET" },
        { "id": "00183", "name": "长春西", "pinyin": "changchunxi", "kaitouzimu": "ccx", "code": "CRT" },
        { "id": "00184", "name": "承德县北", "pinyin": "chengdexianbei", "kaitouzimu": "cdxb", "code": "IYP" },
        { "id": "00185", "name": "成都东", "pinyin": "chengdudong", "kaitouzimu": "cdd", "code": "ICW" },
        { "id": "00186", "name": "承德东", "pinyin": "chengdedong", "kaitouzimu": "cdd", "code": "CCP" },
        { "id": "00187", "name": "常德", "pinyin": "changde", "kaitouzimu": "cd", "code": "VGQ" },
        { "id": "00188", "name": "承德", "pinyin": "chengde", "kaitouzimu": "cd", "code": "CDP" },
        { "id": "00189", "name": "长甸", "pinyin": "changdian", "kaitouzimu": "cd", "code": "CDT" },
        { "id": "00190", "name": "成都南", "pinyin": "chengdunan", "kaitouzimu": "cdn", "code": "CNW" },
        { "id": "00191", "name": "承德南", "pinyin": "chengdenan", "kaitouzimu": "cdn", "code": "IVP" },
        { "id": "00192", "name": "成都", "pinyin": "chengdu", "kaitouzimu": "cd", "code": "CDW" },
        { "id": "00193", "name": "成都西", "pinyin": "chengduxi", "kaitouzimu": "cdx", "code": "CMW" },
        { "id": "00194", "name": "曹妃甸东", "pinyin": "caofeidiandong", "kaitouzimu": "cfdd", "code": "POP" },
        { "id": "00195", "name": "赤峰", "pinyin": "chifeng", "kaitouzimu": "cf", "code": "CFD" },
        { "id": "00196", "name": "曹妃甸港", "pinyin": "caofeidiangang", "kaitouzimu": "cfdg", "code": "PGP" },
        { "id": "00197", "name": "赤峰西", "pinyin": "chifengxi", "kaitouzimu": "cfx", "code": "CID" },
        { "id": "00198", "name": "柴岗", "pinyin": "chaigang", "kaitouzimu": "cg", "code": "CGT" },
        { "id": "00199", "name": "嵯岗", "pinyin": "cuogang", "kaitouzimu": "cg", "code": "CAX" },
        { "id": "00200", "name": "长葛", "pinyin": "changge", "kaitouzimu": "cg", "code": "CEF" },
        { "id": "00201", "name": "柴沟堡", "pinyin": "chaigoupu", "kaitouzimu": "cgb", "code": "CGV" },
        { "id": "00202", "name": "城固", "pinyin": "chenggu", "kaitouzimu": "cg", "code": "CGY" },
        { "id": "00203", "name": "陈官营", "pinyin": "chenguanying", "kaitouzimu": "cgy", "code": "CAJ" },
        { "id": "00204", "name": "成高子", "pinyin": "chenggaozi", "kaitouzimu": "cgz", "code": "CZB" },
        { "id": "00205", "name": "草海", "pinyin": "caohai", "kaitouzimu": "ch", "code": "WBW" },
        { "id": "00206", "name": "查干湖", "pinyin": "chaganhu", "kaitouzimu": "cgh", "code": "VAT" },
        { "id": "00207", "name": "常山", "pinyin": "changshan", "kaitouzimu": "cs", "code": "CSU" },
        { "id": "00208", "name": "长寿湖", "pinyin": "changshouhu", "kaitouzimu": "csh", "code": "CSE" },
        { "id": "00209", "name": "朝天", "pinyin": "chaotian", "kaitouzimu": "ct", "code": "CTE" },
        { "id": "00210", "name": "朝天南", "pinyin": "chaotiannan", "kaitouzimu": "ctn", "code": "CTY" },
        { "id": "00211", "name": "朝阳南", "pinyin": "chaoyangnan", "kaitouzimu": "cyn", "code": "CYD" },
        { "id": "00212", "name": "巢湖东", "pinyin": "chaohudong", "kaitouzimu": "chd", "code": "GUH" },
        { "id": "00213", "name": "册亨", "pinyin": "ceheng", "kaitouzimu": "ch", "code": "CHZ" },
        { "id": "00214", "name": "柴河", "pinyin": "chaihe", "kaitouzimu": "ch", "code": "CHB" },
        { "id": "00215", "name": "城固北", "pinyin": "chenggubei", "kaitouzimu": "cgb", "code": "CBY" },
        { "id": "00216", "name": "草河口", "pinyin": "caohekou", "kaitouzimu": "chk", "code": "CKT" },
        { "id": "00217", "name": "崔黄口", "pinyin": "cuihuangkou", "kaitouzimu": "chk", "code": "CHP" },
        { "id": "00218", "name": "巢湖", "pinyin": "chaohu", "kaitouzimu": "ch", "code": "CIH" },
        { "id": "00219", "name": "蔡家沟", "pinyin": "caijiagou", "kaitouzimu": "cjg", "code": "CJT" },
        { "id": "00220", "name": "成吉思汗", "pinyin": "chengjisihan", "kaitouzimu": "cjsh", "code": "CJX" },
        { "id": "00221", "name": "岔江", "pinyin": "chajiang", "kaitouzimu": "cj", "code": "CAM" },
        { "id": "00222", "name": "从江", "pinyin": "congjiang", "kaitouzimu": "cj", "code": "KNW" },
        { "id": "00223", "name": "蔡家坡", "pinyin": "caijiapo", "kaitouzimu": "cjp", "code": "CJY" },
        { "id": "00224", "name": "蔡家崖", "pinyin": "caijiaya", "kaitouzimu": "cjy", "code": "EBV" },
        { "id": "00225", "name": "茶卡", "pinyin": "chaka", "kaitouzimu": "ck", "code": "CVO" },
        { "id": "00226", "name": "昌乐", "pinyin": "changle", "kaitouzimu": "cl", "code": "CLK" },
        { "id": "00227", "name": "超梁沟", "pinyin": "chaolianggou", "kaitouzimu": "clg", "code": "CYP" },
        { "id": "00228", "name": "长临河", "pinyin": "changlinhe", "kaitouzimu": "clh", "code": "FVH" },
        { "id": "00229", "name": "茶陵", "pinyin": "chaling", "kaitouzimu": "cl", "code": "CDG" },
        { "id": "00230", "name": "昌黎", "pinyin": "changli", "kaitouzimu": "cl", "code": "CLP" },
        { "id": "00231", "name": "慈利", "pinyin": "cili", "kaitouzimu": "cl", "code": "CUQ" },
        { "id": "00232", "name": "茶陵南", "pinyin": "chalingnan", "kaitouzimu": "cln", "code": "CNG" },
        { "id": "00233", "name": "长岭子", "pinyin": "changlingzi", "kaitouzimu": "clz", "code": "CLT" },
        { "id": "00234", "name": "晨明", "pinyin": "chenming", "kaitouzimu": "cm", "code": "CMB" },
        { "id": "00235", "name": "苍南", "pinyin": "cangnan", "kaitouzimu": "cn", "code": "CEH" },
        { "id": "00236", "name": "长农", "pinyin": "changnong", "kaitouzimu": "cn", "code": "CNJ" },
        { "id": "00237", "name": "昌平北", "pinyin": "changpingbei", "kaitouzimu": "cpb", "code": "VBP" },
        { "id": "00238", "name": "常平东", "pinyin": "changpingdong", "kaitouzimu": "cpd", "code": "FQQ" },
        { "id": "00239", "name": "昌平", "pinyin": "changping", "kaitouzimu": "cp", "code": "CPP" },
        { "id": "00240", "name": "常平", "pinyin": "changping", "kaitouzimu": "cp", "code": "DAQ" },
        { "id": "00241", "name": "长坡岭", "pinyin": "changpoling", "kaitouzimu": "cpl", "code": "CPM" },
        { "id": "00242", "name": "常平南", "pinyin": "changpingnan", "kaitouzimu": "cpn", "code": "FPQ" },
        { "id": "00243", "name": "重庆北", "pinyin": "chongqingbei", "kaitouzimu": "cqb", "code": "CUW" },
        { "id": "00244", "name": "辰清", "pinyin": "chenqing", "kaitouzimu": "cq", "code": "CQB" },
        { "id": "00245", "name": "重庆", "pinyin": "chongqing", "kaitouzimu": "cq", "code": "CQW" },
        { "id": "00246", "name": "重庆南", "pinyin": "chongqingnan", "kaitouzimu": "cqn", "code": "CRW" },
        { "id": "00247", "name": "长庆桥", "pinyin": "changqingqiao", "kaitouzimu": "cqq", "code": "CQJ" },
        { "id": "00248", "name": "重庆西", "pinyin": "chongqingxi", "kaitouzimu": "cqx", "code": "CXW" },
        { "id": "00249", "name": "崇仁", "pinyin": "chongren", "kaitouzimu": "cr", "code": "CRG" },
        { "id": "00250", "name": "长寿北", "pinyin": "changshoubei", "kaitouzimu": "csb", "code": "COW" },
        { "id": "00251", "name": "蔡山", "pinyin": "caishan", "kaitouzimu": "cs", "code": "CON" },
        { "id": "00252", "name": "苍石", "pinyin": "cangshi", "kaitouzimu": "cs", "code": "CST" },
        { "id": "00253", "name": "草市", "pinyin": "caoshi", "kaitouzimu": "cs", "code": "CSL" },
        { "id": "00254", "name": "潮汕", "pinyin": "chaoshan", "kaitouzimu": "cs", "code": "CBQ" },
        { "id": "00255", "name": "楚山", "pinyin": "chushan", "kaitouzimu": "cs", "code": "CSB" },
        { "id": "00256", "name": "磁山", "pinyin": "cishan", "kaitouzimu": "cs", "code": "CSP" },
        { "id": "00257", "name": "长沙", "pinyin": "changsha", "kaitouzimu": "cs", "code": "CSQ" },
        { "id": "00258", "name": "长寿", "pinyin": "changshou", "kaitouzimu": "cs", "code": "EFW" },
        { "id": "00259", "name": "长沙南", "pinyin": "changshanan", "kaitouzimu": "csn", "code": "CWQ" },
        { "id": "00260", "name": "察素齐", "pinyin": "chasuqi", "kaitouzimu": "csq", "code": "CSC" },
        { "id": "00261", "name": "长山屯", "pinyin": "changshantun", "kaitouzimu": "cst", "code": "CVT" },
        { "id": "00262", "name": "长沙西", "pinyin": "changshaxi", "kaitouzimu": "csx", "code": "RXQ" },
        { "id": "00263", "name": "长汀", "pinyin": "changting", "kaitouzimu": "ct", "code": "CES" },
        { "id": "00264", "name": "长汀南", "pinyin": "changtingnan", "kaitouzimu": "ctn", "code": "CNS" },
        { "id": "00265", "name": "昌图", "pinyin": "changtu", "kaitouzimu": "ct", "code": "CTT" },
        { "id": "00266", "name": "昌图西", "pinyin": "changtuxi", "kaitouzimu": "ctx", "code": "CPT" },
        { "id": "00267", "name": "长汀镇", "pinyin": "changtingzhen", "kaitouzimu": "ctz", "code": "CDB" },
        { "id": "00268", "name": "春湾", "pinyin": "chunwan", "kaitouzimu": "cw", "code": "CQQ" },
        { "id": "00269", "name": "长武", "pinyin": "changwu", "kaitouzimu": "cw", "code": "CWY" },
        { "id": "00270", "name": "苍溪", "pinyin": "cangxi", "kaitouzimu": "cx", "code": "CXE" },
        { "id": "00271", "name": "曹县", "pinyin": "caoxian", "kaitouzimu": "cx", "code": "CXK" },
        { "id": "00272", "name": "岑溪", "pinyin": "cenxi", "kaitouzimu": "cx", "code": "CNZ" },
        { "id": "00273", "name": "辰溪", "pinyin": "chenxi", "kaitouzimu": "cx", "code": "CXQ" },
        { "id": "00274", "name": "楚雄", "pinyin": "chuxiong", "kaitouzimu": "cx", "code": "COM" },
        { "id": "00275", "name": "磁西", "pinyin": "cixi", "kaitouzimu": "cx", "code": "CRP" },
        { "id": "00276", "name": "磁县", "pinyin": "cixian", "kaitouzimu": "cx", "code": "CIP" },
        { "id": "00277", "name": "长兴", "pinyin": "changxing", "kaitouzimu": "cx", "code": "CBH" },
        { "id": "00278", "name": "楚雄南", "pinyin": "chuxiongnan", "kaitouzimu": "cxn", "code": "COM" },
        { "id": "00279", "name": "长兴南", "pinyin": "changxingnan", "kaitouzimu": "cxn", "code": "CFH" },
        { "id": "00280", "name": "陈相屯", "pinyin": "chenxiangtun", "kaitouzimu": "cxt", "code": "CXT" },
        { "id": "00281", "name": "朝阳", "pinyin": "chaoyang", "kaitouzimu": "cy", "code": "CYD" },
        { "id": "00282", "name": "潮阳", "pinyin": "chaoyang", "kaitouzimu": "cy", "code": "CNQ" },
        { "id": "00283", "name": "城阳", "pinyin": "chengyang", "kaitouzimu": "cy", "code": "CEK" },
        { "id": "00284", "name": "春阳", "pinyin": "chunyang", "kaitouzimu": "cy", "code": "CAL" },
        { "id": "00285", "name": "磁窑", "pinyin": "ciyao", "kaitouzimu": "cy", "code": "CYK" },
        { "id": "00286", "name": "长阳", "pinyin": "changyang", "kaitouzimu": "cy", "code": "CYN" },
        { "id": "00287", "name": "朝阳川", "pinyin": "chaoyangchuan", "kaitouzimu": "cyc", "code": "CYL" },
        { "id": "00288", "name": "创业村", "pinyin": "chuangyecun", "kaitouzimu": "cyc", "code": "CEX" },
        { "id": "00289", "name": "朝阳地", "pinyin": "chaoyangdi", "kaitouzimu": "cyd", "code": "CDD" },
        { "id": "00290", "name": "朝阳湖", "pinyin": "chaoyanghu", "kaitouzimu": "cyh", "code": "CYE" },
        { "id": "00291", "name": "长垣", "pinyin": "changyuan", "kaitouzimu": "cy", "code": "CYF" },
        { "id": "00292", "name": "朝阳镇", "pinyin": "chaoyangzhen", "kaitouzimu": "cyz", "code": "CZL" },
        { "id": "00293", "name": "常州北", "pinyin": "changzhoubei", "kaitouzimu": "czb", "code": "ESH" },
        { "id": "00294", "name": "滁州北", "pinyin": "chuzhoubei", "kaitouzimu": "czb", "code": "CUH" },
        { "id": "00295", "name": "长治北", "pinyin": "changzhibei", "kaitouzimu": "czb", "code": "CBF" },
        { "id": "00296", "name": "沧州", "pinyin": "cangzhou", "kaitouzimu": "cz", "code": "COP" },
        { "id": "00297", "name": "常州", "pinyin": "changzhou", "kaitouzimu": "cz", "code": "CZH" },
        { "id": "00298", "name": "常庄", "pinyin": "changzhuang", "kaitouzimu": "cz", "code": "CVK" },
        { "id": "00299", "name": "潮州", "pinyin": "chaozhou", "kaitouzimu": "cz", "code": "CKQ" },
        { "id": "00300", "name": "郴州", "pinyin": "chenzhou", "kaitouzimu": "cz", "code": "CZQ" },
        { "id": "00301", "name": "池州", "pinyin": "chizhou", "kaitouzimu": "cz", "code": "IYH" },
        { "id": "00302", "name": "崇州", "pinyin": "chongzhou", "kaitouzimu": "cz", "code": "CZE" },
        { "id": "00303", "name": "滁州", "pinyin": "chuzhou", "kaitouzimu": "cz", "code": "CXH" },
        { "id": "00304", "name": "长征", "pinyin": "changzheng", "kaitouzimu": "cz", "code": "CZJ" },
        { "id": "00305", "name": "长治", "pinyin": "changzhi", "kaitouzimu": "cz", "code": "CZF" },
        { "id": "00306", "name": "曹子里", "pinyin": "caozili", "kaitouzimu": "czl", "code": "CFP" },
        { "id": "00307", "name": "城子坦", "pinyin": "chengzitan", "kaitouzimu": "czt", "code": "CWT" },
        { "id": "00308", "name": "崇左", "pinyin": "chongzuo", "kaitouzimu": "cz", "code": "CZZ" },
        { "id": "00309", "name": "车转湾", "pinyin": "chezhuanwan", "kaitouzimu": "czw", "code": "CWM" },
        { "id": "00310", "name": "沧州西", "pinyin": "cangzhouxi", "kaitouzimu": "czx", "code": "CBP" },
        { "id": "00311", "name": "郴州西", "pinyin": "chenzhouxi", "kaitouzimu": "czx", "code": "ICQ" },],
    D: [
        { "id": "00312", "name": "大安北", "pinyin": "daanbei", "kaitouzimu": "dab", "code": "RNT" },
        { "id": "00313", "name": "东安东", "pinyin": "dongandong", "kaitouzimu": "dad", "code": "DCZ" },
        { "id": "00314", "name": "大方南", "pinyin": "dafangnan", "kaitouzimu": "dfn", "code": "DNE" },
        { "id": "00315", "name": "大安", "pinyin": "daan", "kaitouzimu": "da", "code": "RAT" },
        { "id": "00316", "name": "德安", "pinyin": "dean", "kaitouzimu": "da", "code": "DAG" },
        { "id": "00317", "name": "砀山南", "pinyin": "dangshannan", "kaitouzimu": "dsn", "code": "PRH" },
        { "id": "00318", "name": "大巴", "pinyin": "daba", "kaitouzimu": "db", "code": "DBD" },
        { "id": "00319", "name": "大坝", "pinyin": "daba", "kaitouzimu": "db", "code": "DBJ" },
        { "id": "00320", "name": "大板", "pinyin": "daban", "kaitouzimu": "db", "code": "DBC" },
        { "id": "00321", "name": "到保", "pinyin": "daobao", "kaitouzimu": "db", "code": "RBT" },
        { "id": "00322", "name": "德保", "pinyin": "debao", "kaitouzimu": "db", "code": "RBZ" },
        { "id": "00323", "name": "电白", "pinyin": "dianbai", "kaitouzimu": "db", "code": "NWQ" },
        { "id": "00324", "name": "达坂城", "pinyin": "dabancheng", "kaitouzimu": "dbc", "code": "DCR" },
        { "id": "00325", "name": "定边", "pinyin": "dingbian", "kaitouzimu": "db", "code": "DYJ" },
        { "id": "00326", "name": "东边井", "pinyin": "dongbianjing", "kaitouzimu": "dbj", "code": "DBB" },
        { "id": "00327", "name": "德伯斯", "pinyin": "debosi", "kaitouzimu": "dbs", "code": "RDT" },
        { "id": "00328", "name": "打柴沟", "pinyin": "dachaigou", "kaitouzimu": "dcg", "code": "DGJ" },
        { "id": "00329", "name": "大成", "pinyin": "dacheng", "kaitouzimu": "dc", "code": "DCT" },
        { "id": "00330", "name": "德昌", "pinyin": "dechang", "kaitouzimu": "dc", "code": "DVW" },
        { "id": "00331", "name": "东岔", "pinyin": "dongcha", "kaitouzimu": "dc", "code": "DCJ" },
        { "id": "00332", "name": "东城南", "pinyin": "dongchengnan", "kaitouzimu": "dcn", "code": "IYQ" },
        { "id": "00333", "name": "滴道", "pinyin": "didao", "kaitouzimu": "dd", "code": "DDB" },
        { "id": "00334", "name": "大磴沟", "pinyin": "dadenggou", "kaitouzimu": "ddg", "code": "DKJ" },
        { "id": "00335", "name": "东戴河", "pinyin": "dongdaihe", "kaitouzimu": "ddh", "code": "RDD" },
        { "id": "00336", "name": "丹东", "pinyin": "dandong", "kaitouzimu": "dd", "code": "DUT" },
        { "id": "00337", "name": "丹东西", "pinyin": "dandongxi", "kaitouzimu": "ddx", "code": "RWT" },
        { "id": "00338", "name": "杜尔伯特", "pinyin": "duerbote", "kaitouzimu": "drbt", "code": "TKX" },
        { "id": "00339", "name": "刀尔登", "pinyin": "daoerdeng", "kaitouzimu": "ded", "code": "DRD" },
        { "id": "00340", "name": "得耳布尔", "pinyin": "deerbuer", "kaitouzimu": "debe", "code": "DRX" },
        { "id": "00341", "name": "东二道河", "pinyin": "dongerdaohe", "kaitouzimu": "dedh", "code": "DRB" },
        { "id": "00342", "name": "德兴东", "pinyin": "dexingdong", "kaitouzimu": "dxd", "code": "DDG" },
        { "id": "00343", "name": "东方", "pinyin": "dongfang", "kaitouzimu": "df", "code": "UFQ" },
        { "id": "00344", "name": "大丰", "pinyin": "dafeng", "kaitouzimu": "df", "code": "KRQ" },
        { "id": "00345", "name": "丹凤", "pinyin": "danfeng", "kaitouzimu": "df", "code": "DGY" },
        { "id": "00346", "name": "东丰", "pinyin": "dongfeng", "kaitouzimu": "df", "code": "DIL" },
        { "id": "00347", "name": "东方红", "pinyin": "dongfanghong", "kaitouzimu": "dfh", "code": "DFB" },
        { "id": "00348", "name": "东港北", "pinyin": "donggangbei", "kaitouzimu": "dgb", "code": "RGT" },
        { "id": "00349", "name": "东莞东", "pinyin": "dongguandong", "kaitouzimu": "dgd", "code": "DMQ" },
        { "id": "00350", "name": "都格", "pinyin": "duge", "kaitouzimu": "dg", "code": "DMM" },
        { "id": "00351", "name": "大孤山", "pinyin": "dagushan", "kaitouzimu": "dgs", "code": "RMT" },
        { "id": "00352", "name": "大官屯", "pinyin": "daguantun", "kaitouzimu": "dgt", "code": "DTT" },
        { "id": "00353", "name": "大关", "pinyin": "daguan", "kaitouzimu": "dg", "code": "RGW" },
        { "id": "00354", "name": "东莞", "pinyin": "dongguan", "kaitouzimu": "dg", "code": "RTQ" },
        { "id": "00355", "name": "东光", "pinyin": "dongguang", "kaitouzimu": "dg", "code": "DGP" },
        { "id": "00356", "name": "东海", "pinyin": "donghai", "kaitouzimu": "dh", "code": "DHB" },
        { "id": "00357", "name": "大灰厂", "pinyin": "dahuichang", "kaitouzimu": "dhc", "code": "DHP" },
        { "id": "00358", "name": "鼎湖东", "pinyin": "dinghudong", "kaitouzimu": "dhd", "code": "UWQ" },
        { "id": "00359", "name": "大红旗", "pinyin": "dahongqi", "kaitouzimu": "dhq", "code": "DQD" },
        { "id": "00360", "name": "大虎山", "pinyin": "dahushan", "kaitouzimu": "dhs", "code": "DHD" },
        { "id": "00361", "name": "鼎湖山", "pinyin": "dinghushan", "kaitouzimu": "dhs", "code": "NVQ" },
        { "id": "00362", "name": "大禾塘", "pinyin": "shaodong", "kaitouzimu": "sd", "code": "SOQ" },
        { "id": "00363", "name": "德惠", "pinyin": "dehui", "kaitouzimu": "dh", "code": "DHT" },
        { "id": "00364", "name": "敦化", "pinyin": "dunhua", "kaitouzimu": "dh", "code": "DHL" },
        { "id": "00365", "name": "敦煌", "pinyin": "dunhuang", "kaitouzimu": "dh", "code": "DHJ" },
        { "id": "00366", "name": "德惠西", "pinyin": "dehuixi", "kaitouzimu": "dhx", "code": "DXT" },
        { "id": "00367", "name": "东海县", "pinyin": "donghaixian", "kaitouzimu": "dhx", "code": "DQH" },
        { "id": "00368", "name": "垫江", "pinyin": "dianjiang", "kaitouzimu": "dj", "code": "DJE" },
        { "id": "00369", "name": "定西北", "pinyin": "dingxibei", "kaitouzimu": "dxb", "code": "DNJ" },
        { "id": "00370", "name": "东京城", "pinyin": "dongjingcheng", "kaitouzimu": "djc", "code": "DJB" },
        { "id": "00371", "name": "达家沟", "pinyin": "dajiagou", "kaitouzimu": "djg", "code": "DJT" },
        { "id": "00372", "name": "大涧", "pinyin": "dajian", "kaitouzimu": "dj", "code": "DFP" },
        { "id": "00373", "name": "道滘", "pinyin": "daojiao", "kaitouzimu": "dj", "code": "RRQ" },
        { "id": "00374", "name": "东津", "pinyin": "dongjin", "kaitouzimu": "dj", "code": "DKB" },
        { "id": "00375", "name": "杜家", "pinyin": "dujia", "kaitouzimu": "dj", "code": "DJL" },
        { "id": "00376", "name": "董家口", "pinyin": "dongjiakou", "kaitouzimu": "djk", "code": "DTK" },
        { "id": "00377", "name": "大苴", "pinyin": "daju", "kaitouzimu": "dj", "code": "DIM" },
        { "id": "00378", "name": "都江堰", "pinyin": "dujiangyan", "kaitouzimu": "djy", "code": "DDW" },
        { "id": "00379", "name": "洞口", "pinyin": "dongkou", "kaitouzimu": "dk", "code": "DKA" },
        { "id": "00380", "name": "大口屯", "pinyin": "dakoutun", "kaitouzimu": "dkt", "code": "DKP" },
        { "id": "00381", "name": "东来", "pinyin": "donglai", "kaitouzimu": "dl", "code": "RVD" },
        { "id": "00382", "name": "大连北", "pinyin": "dalianbei", "kaitouzimu": "dlb", "code": "DFT" },
        { "id": "00383", "name": "达连河", "pinyin": "dalianhe", "kaitouzimu": "dlh", "code": "DCB" },
        { "id": "00384", "name": "大陆号", "pinyin": "daluhao", "kaitouzimu": "dlh", "code": "DLC" },
        { "id": "00385", "name": "德令哈", "pinyin": "delingha", "kaitouzimu": "dlh", "code": "DHO" },
        { "id": "00386", "name": "大理", "pinyin": "dali", "kaitouzimu": "dl", "code": "DKM" },
        { "id": "00387", "name": "大荔", "pinyin": "dali", "kaitouzimu": "dl", "code": "DNY" },
        { "id": "00388", "name": "大连", "pinyin": "dalian", "kaitouzimu": "dl", "code": "DLT" },
        { "id": "00389", "name": "大林", "pinyin": "dalin", "kaitouzimu": "dl", "code": "DLD" },
        { "id": "00390", "name": "带岭", "pinyin": "dailing", "kaitouzimu": "dl", "code": "DLB" },
        { "id": "00391", "name": "达拉特旗", "pinyin": "dalateqi", "kaitouzimu": "dltq", "code": "DIC" },
        { "id": "00392", "name": "独立屯", "pinyin": "dulitun", "kaitouzimu": "dlt", "code": "DTX" },
        { "id": "00393", "name": "豆罗", "pinyin": "douluo", "kaitouzimu": "dl", "code": "DLV" },
        { "id": "00394", "name": "达拉特西", "pinyin": "dalatexi", "kaitouzimu": "dltx", "code": "DNC" },
        { "id": "00395", "name": "大连西", "pinyin": "dalianxi", "kaitouzimu": "dlx", "code": "GZT" },
        { "id": "00396", "name": "大朗镇", "pinyin": "dalangzhen", "kaitouzimu": "dlz", "code": "KOQ" },
        { "id": "00397", "name": "东明村", "pinyin": "dongmingcun", "kaitouzimu": "dmc", "code": "DMD" },
        { "id": "00398", "name": "大明湖", "pinyin": "daminghu", "kaitouzimu": "dmh", "code": "JAK" },
        { "id": "00399", "name": "洞庙河", "pinyin": "dongmiaohe", "kaitouzimu": "dmh", "code": "DEP" },
        { "id": "00400", "name": "得莫利", "pinyin": "demoli", "kaitouzimu": "dml", "code": "DTB" },
        { "id": "00401", "name": "东明县", "pinyin": "dongmingxian", "kaitouzimu": "dmx", "code": "DNF" },
        { "id": "00402", "name": "定南", "pinyin": "dingnan", "kaitouzimu": "dn", "code": "DNG" },
        { "id": "00403", "name": "大拟", "pinyin": "dani", "kaitouzimu": "dn", "code": "DNZ" },
        { "id": "00404", "name": "洞井", "pinyin": "dongjing", "kaitouzimu": "dj", "code": "FWQ" },
        { "id": "00405", "name": "大平房", "pinyin": "dapingfang", "kaitouzimu": "dpf", "code": "DPD" },
        { "id": "00406", "name": "大盘石", "pinyin": "dapanshi", "kaitouzimu": "dps", "code": "RPP" },
        { "id": "00407", "name": "大堡", "pinyin": "dapu", "kaitouzimu": "db", "code": "DVT" },
        { "id": "00408", "name": "大埔", "pinyin": "dapu", "kaitouzimu": "dp", "code": "DPI" },
        { "id": "00409", "name": "大庆东", "pinyin": "daqingdong", "kaitouzimu": "dqd", "code": "LFX" },
        { "id": "00410", "name": "大青沟", "pinyin": "daqinggou", "kaitouzimu": "dqg", "code": "DSD" },
        { "id": "00411", "name": "大其拉哈", "pinyin": "daqilaha", "kaitouzimu": "dqlh", "code": "DQX" },
        { "id": "00412", "name": "大庆", "pinyin": "daqing", "kaitouzimu": "dq", "code": "DZX" },
        { "id": "00413", "name": "道清", "pinyin": "daoqing", "kaitouzimu": "dq", "code": "DML" },
        { "id": "00414", "name": "德清", "pinyin": "deqing", "kaitouzimu": "dq", "code": "DRH" },
        { "id": "00415", "name": "对青山", "pinyin": "duiqingshan", "kaitouzimu": "dqs", "code": "DQB" },
        { "id": "00416", "name": "大庆西", "pinyin": "daqingxi", "kaitouzimu": "dqx", "code": "RHX" },
        { "id": "00417", "name": "德清西", "pinyin": "deqingxi", "kaitouzimu": "dqx", "code": "MOH" },
        { "id": "00418", "name": "东胜东", "pinyin": "dongshengdong", "kaitouzimu": "dsd", "code": "RSC" },
        { "id": "00419", "name": "砀山", "pinyin": "dangshan", "kaitouzimu": "ds", "code": "DKH" },
        { "id": "00420", "name": "登沙河", "pinyin": "dengshahe", "kaitouzimu": "dsh", "code": "DWT" },
        { "id": "00421", "name": "东升", "pinyin": "dongsheng", "kaitouzimu": "ds", "code": "DRQ" },
        { "id": "00422", "name": "东胜", "pinyin": "dongsheng", "kaitouzimu": "ds", "code": "DOC" },
        { "id": "00423", "name": "独山", "pinyin": "dushan", "kaitouzimu": "ds", "code": "RWW" },
        { "id": "00424", "name": "大石头南", "pinyin": "dashitounan", "kaitouzimu": "dstn", "code": "DAL" },
        { "id": "00425", "name": "读书铺", "pinyin": "dushupu", "kaitouzimu": "dsp", "code": "DPM" },
        { "id": "00426", "name": "大石桥", "pinyin": "dashiqiao", "kaitouzimu": "dsq", "code": "DQT" },
        { "id": "00427", "name": "大石头", "pinyin": "dashitou", "kaitouzimu": "dst", "code": "DSL" },
        { "id": "00428", "name": "东胜西", "pinyin": "dongshengxi", "kaitouzimu": "dsx", "code": "DYC" },
        { "id": "00429", "name": "大石寨", "pinyin": "dashizhai", "kaitouzimu": "dsz", "code": "RZT" },
        { "id": "00430", "name": "灯塔", "pinyin": "dengta", "kaitouzimu": "dt", "code": "DGT" },
        { "id": "00431", "name": "定陶", "pinyin": "dingtao", "kaitouzimu": "dt", "code": "DQK" },
        { "id": "00432", "name": "东台", "pinyin": "dongtai", "kaitouzimu": "dt", "code": "DBH" },
        { "id": "00433", "name": "大田边", "pinyin": "datianbian", "kaitouzimu": "dtb", "code": "DBM" },
        { "id": "00434", "name": "当涂东", "pinyin": "dangtudong", "kaitouzimu": "dtd", "code": "OWH" },
        { "id": "00435", "name": "东通化", "pinyin": "dongtonghua", "kaitouzimu": "dth", "code": "DTL" },
        { "id": "00436", "name": "大同", "pinyin": "datong", "kaitouzimu": "dt", "code": "DTV" },
        { "id": "00437", "name": "大屯", "pinyin": "datun", "kaitouzimu": "dt", "code": "DNT" },
        { "id": "00438", "name": "丹徒", "pinyin": "dantu", "kaitouzimu": "dt", "code": "RUH" },
        { "id": "00439", "name": "大通西", "pinyin": "datongxi", "kaitouzimu": "dtx", "code": "DTO" },
        { "id": "00440", "name": "都昌", "pinyin": "duchang", "kaitouzimu": "dc", "code": "DCG" },
        { "id": "00441", "name": "大旺", "pinyin": "dawang", "kaitouzimu": "dw", "code": "WWQ" },
        { "id": "00442", "name": "东湾", "pinyin": "dongwan", "kaitouzimu": "dw", "code": "DRJ" },
        { "id": "00443", "name": "大武口", "pinyin": "dawukou", "kaitouzimu": "dwk", "code": "DFJ" },
        { "id": "00444", "name": "低窝铺", "pinyin": "diwopu", "kaitouzimu": "dwp", "code": "DWJ" },
        { "id": "00445", "name": "大王滩", "pinyin": "dawangtan", "kaitouzimu": "dwt", "code": "DZZ" },
        { "id": "00446", "name": "大湾子", "pinyin": "dawanzi", "kaitouzimu": "dwz", "code": "DFM" },
        { "id": "00447", "name": "大兴沟", "pinyin": "daxinggou", "kaitouzimu": "dxg", "code": "DXL" },
        { "id": "00448", "name": "大兴", "pinyin": "daxing", "kaitouzimu": "dx", "code": "DXX" },
        { "id": "00449", "name": "代县", "pinyin": "daixian", "kaitouzimu": "dx", "code": "DKV" },
        { "id": "00450", "name": "德兴", "pinyin": "dexing", "kaitouzimu": "dx", "code": "DWG" },
        { "id": "00451", "name": "甸心", "pinyin": "dianxin", "kaitouzimu": "dx", "code": "DXM" },
        { "id": "00452", "name": "定西", "pinyin": "dingxi", "kaitouzimu": "dx", "code": "DSJ" },
        { "id": "00453", "name": "定襄", "pinyin": "dingxiang", "kaitouzimu": "dx", "code": "DXV" },
        { "id": "00454", "name": "东乡", "pinyin": "dongxiang", "kaitouzimu": "dx", "code": "DXG" },
        { "id": "00455", "name": "丹霞山", "pinyin": "danxiashan", "kaitouzimu": "dxs", "code": "IRQ" },
        { "id": "00456", "name": "东戌", "pinyin": "dongxu", "kaitouzimu": "dx", "code": "RXP" },
        { "id": "00457", "name": "东辛庄", "pinyin": "dongxinzhuang", "kaitouzimu": "dxz", "code": "DXD" },
        { "id": "00458", "name": "大雁", "pinyin": "dayan", "kaitouzimu": "dy", "code": "DYX" },
        { "id": "00459", "name": "丹阳", "pinyin": "danyang", "kaitouzimu": "dy", "code": "DYH" },
        { "id": "00460", "name": "当阳", "pinyin": "dangyang", "kaitouzimu": "dy", "code": "DYN" },
        { "id": "00461", "name": "德阳", "pinyin": "deyang", "kaitouzimu": "dy", "code": "DYW" },
        { "id": "00462", "name": "大冶北", "pinyin": "dayebei", "kaitouzimu": "dyb", "code": "DBN" },
        { "id": "00463", "name": "丹阳北", "pinyin": "danyangbei", "kaitouzimu": "dyb", "code": "EXH" },
        { "id": "00464", "name": "大英东", "pinyin": "dayingdong", "kaitouzimu": "dyd", "code": "IAW" },
        { "id": "00465", "name": "东淤地", "pinyin": "dongyudi", "kaitouzimu": "dyd", "code": "DBV" },
        { "id": "00466", "name": "都匀东", "pinyin": "duyundong", "kaitouzimu": "dyd", "code": "KJW" },
        { "id": "00467", "name": "大邑", "pinyin": "dayi", "kaitouzimu": "dy", "code": "DEE" },
        { "id": "00468", "name": "大营", "pinyin": "daying", "kaitouzimu": "dy", "code": "DYV" },
        { "id": "00469", "name": "东营", "pinyin": "dongying", "kaitouzimu": "dy", "code": "DPK" },
        { "id": "00470", "name": "东营南", "pinyin": "dongyingnan", "kaitouzimu": "dyn", "code": "DOK" },
        { "id": "00471", "name": "大杨树", "pinyin": "dayangshu", "kaitouzimu": "dys", "code": "DUX" },
        { "id": "00472", "name": "大余", "pinyin": "dayu", "kaitouzimu": "dy", "code": "DYG" },
        { "id": "00473", "name": "大元", "pinyin": "dayuan", "kaitouzimu": "dy", "code": "DYZ" },
        { "id": "00474", "name": "岱岳", "pinyin": "daiyue", "kaitouzimu": "dy", "code": "RYV" },
        { "id": "00475", "name": "定远", "pinyin": "dingyuan", "kaitouzimu": "dy", "code": "EWH" },
        { "id": "00476", "name": "都匀", "pinyin": "duyun", "kaitouzimu": "dy", "code": "RYW" },
        { "id": "00477", "name": "大营镇", "pinyin": "dayingzhen", "kaitouzimu": "dyz", "code": "DJP" },
        { "id": "00478", "name": "大营子", "pinyin": "dayingzi", "kaitouzimu": "dyz", "code": "DZD" },
        { "id": "00479", "name": "大战场", "pinyin": "dazhanchang", "kaitouzimu": "dzc", "code": "DTJ" },
        { "id": "00480", "name": "德州东", "pinyin": "dezhoudong", "kaitouzimu": "dzd", "code": "DIP" },
        { "id": "00481", "name": "定州东", "pinyin": "dingzhoudong", "kaitouzimu": "dzd", "code": "DOP" },
        { "id": "00482", "name": "达州", "pinyin": "dazhou", "kaitouzimu": "dz", "code": "RXW" },
        { "id": "00483", "name": "道州", "pinyin": "daozhou", "kaitouzimu": "dz", "code": "DFZ" },
        { "id": "00484", "name": "德州", "pinyin": "dezhou", "kaitouzimu": "dz", "code": "DZP" },
        { "id": "00485", "name": "邓州", "pinyin": "dengzhou", "kaitouzimu": "dz", "code": "DOF" },
        { "id": "00486", "name": "低庄", "pinyin": "dizhuang", "kaitouzimu": "dz", "code": "DVQ" },
        { "id": "00487", "name": "定州", "pinyin": "dingzhou", "kaitouzimu": "dz", "code": "DXP" },
        { "id": "00488", "name": "东镇", "pinyin": "dongzhen", "kaitouzimu": "dz", "code": "DNV" },
        { "id": "00489", "name": "东至", "pinyin": "dongzhi", "kaitouzimu": "dz", "code": "DCH" },
        { "id": "00490", "name": "东庄", "pinyin": "dongzhuang", "kaitouzimu": "dz", "code": "DZV" },
        { "id": "00491", "name": "豆庄", "pinyin": "douzhuang", "kaitouzimu": "dz", "code": "ROP" },
        { "id": "00492", "name": "端州", "pinyin": "duanzhou", "kaitouzimu": "dz", "code": "WZQ" },
        { "id": "00493", "name": "兑镇", "pinyin": "duizhen", "kaitouzimu": "dz", "code": "DWV" },
        { "id": "00494", "name": "大足南", "pinyin": "dazunan", "kaitouzimu": "dzn", "code": "FQW" },
        { "id": "00495", "name": "大竹园", "pinyin": "dazhuyuan", "kaitouzimu": "dzy", "code": "DZY" },
        { "id": "00496", "name": "大杖子", "pinyin": "dazhangzi", "kaitouzimu": "dzz", "code": "DAP" },
        { "id": "00497", "name": "豆张庄", "pinyin": "douzhangzhuang", "kaitouzimu": "dzz", "code": "RZP" },],
    E: [
        { "id": "00498", "name": "峨边", "pinyin": "ebian", "kaitouzimu": "eb", "code": "EBW" },
        { "id": "00499", "name": "二道沟门", "pinyin": "erdaogoumen", "kaitouzimu": "edgm", "code": "RDP" },
        { "id": "00500", "name": "二道湾", "pinyin": "erdaowan", "kaitouzimu": "edw", "code": "RDX" },
        { "id": "00501", "name": "鄂尔多斯", "pinyin": "eerduosi", "kaitouzimu": "eeds", "code": "EEC" },
        { "id": "00502", "name": "额济纳", "pinyin": "ejina", "kaitouzimu": "ejn", "code": "EJC" },
        { "id": "00503", "name": "二连", "pinyin": "erlian", "kaitouzimu": "el", "code": "RLC" },
        { "id": "00504", "name": "二龙", "pinyin": "erlong", "kaitouzimu": "el", "code": "RLD" },
        { "id": "00505", "name": "二龙山屯", "pinyin": "erlongshantun", "kaitouzimu": "elst", "code": "ELA" },
        { "id": "00506", "name": "峨眉", "pinyin": "emei", "kaitouzimu": "em", "code": "EMW" },
        { "id": "00507", "name": "二密河", "pinyin": "ermihe", "kaitouzimu": "emh", "code": "RML" },
        { "id": "00508", "name": "峨眉山", "pinyin": "emeishan", "kaitouzimu": "ems", "code": "IXW" },
        { "id": "00509", "name": "阿房宫", "pinyin": "epanggong", "kaitouzimu": "epg", "code": "EGY" },
        { "id": "00510", "name": "恩平", "pinyin": "enping", "kaitouzimu": "ep", "code": "PXQ" },
        { "id": "00511", "name": "恩施", "pinyin": "enshi", "kaitouzimu": "es", "code": "ESN" },
        { "id": "00512", "name": "二营", "pinyin": "erying", "kaitouzimu": "ey", "code": "RYJ" },
        { "id": "00513", "name": "鄂州东", "pinyin": "ezhoudong", "kaitouzimu": "ezd", "code": "EFN" },
        { "id": "00514", "name": "鄂州", "pinyin": "ezhou", "kaitouzimu": "ez", "code": "ECN" },],
    F: [
        { "id": "00515", "name": "福安", "pinyin": "fuan", "kaitouzimu": "fa", "code": "FAS" },
        { "id": "00516", "name": "防城港北", "pinyin": "fangchenggangbei", "kaitouzimu": "fcgb", "code": "FBZ" },
        { "id": "00517", "name": "凤城东", "pinyin": "fengchengdong", "kaitouzimu": "fcd", "code": "FDT" },
        { "id": "00518", "name": "丰城", "pinyin": "fengcheng", "kaitouzimu": "fc", "code": "FCG" },
        { "id": "00519", "name": "富川", "pinyin": "fuchuan", "kaitouzimu": "fc", "code": "FDZ" },
        { "id": "00520", "name": "丰城南", "pinyin": "fengchengnan", "kaitouzimu": "fcn", "code": "FNG" },
        { "id": "00521", "name": "繁昌西", "pinyin": "fanchangxi", "kaitouzimu": "fcx", "code": "PUH" },
        { "id": "00522", "name": "福鼎", "pinyin": "fuding", "kaitouzimu": "fd", "code": "FES" },
        { "id": "00523", "name": "肥东", "pinyin": "feidong", "kaitouzimu": "fd", "code": "FIH" },
        { "id": "00524", "name": "丰都", "pinyin": "fengdu", "kaitouzimu": "fd", "code": "FUW" },
        { "id": "00525", "name": "枫林", "pinyin": "fenglin", "kaitouzimu": "fl", "code": "FLN" },
        { "id": "00526", "name": "发耳", "pinyin": "faer", "kaitouzimu": "fe", "code": "FEM" },
        { "id": "00527", "name": "福海", "pinyin": "fuhai", "kaitouzimu": "fh", "code": "FHR" },
        { "id": "00528", "name": "富海", "pinyin": "fuhai", "kaitouzimu": "fh", "code": "FHX" },
        { "id": "00529", "name": "凤凰城", "pinyin": "fenghuangcheng", "kaitouzimu": "fhc", "code": "FHT" },
        { "id": "00530", "name": "凤凰机场", "pinyin": "fenghuangjichang", "kaitouzimu": "fhjc", "code": "FJQ" },
        { "id": "00531", "name": "汾河", "pinyin": "fenhe", "kaitouzimu": "fh", "code": "FEV" },
        { "id": "00532", "name": "奉化", "pinyin": "fenghua", "kaitouzimu": "fh", "code": "FHH" },
        { "id": "00533", "name": "富锦", "pinyin": "fujin", "kaitouzimu": "fj", "code": "FIB" },
        { "id": "00534", "name": "范家屯", "pinyin": "fanjiatun", "kaitouzimu": "fjt", "code": "FTT" },
        { "id": "00535", "name": "涪陵北", "pinyin": "fulingbei", "kaitouzimu": "flb", "code": "FEW" },
        { "id": "00536", "name": "风陵渡", "pinyin": "fenglingdu", "kaitouzimu": "fld", "code": "FLV" },
        { "id": "00537", "name": "涪陵", "pinyin": "fuling", "kaitouzimu": "fl", "code": "FLW" },
        { "id": "00538", "name": "富拉尔基", "pinyin": "fulaerji", "kaitouzimu": "flej", "code": "FRX" },
        { "id": "00539", "name": "福利区", "pinyin": "fuliqu", "kaitouzimu": "flq", "code": "FLJ" },
        { "id": "00540", "name": "福利屯", "pinyin": "fulitun", "kaitouzimu": "flt", "code": "FTB" },
        { "id": "00541", "name": "丰乐镇", "pinyin": "fenglezhen", "kaitouzimu": "flz", "code": "FZB" },
        { "id": "00542", "name": "阜南", "pinyin": "funan", "kaitouzimu": "fn", "code": "FNH" },
        { "id": "00543", "name": "阜宁东", "pinyin": "funingdong", "kaitouzimu": "fnd", "code": "FDU" },
        { "id": "00544", "name": "抚宁", "pinyin": "funing", "kaitouzimu": "fn", "code": "FNP" },
        { "id": "00545", "name": "阜宁", "pinyin": "funing", "kaitouzimu": "fn", "code": "AKH" },
        { "id": "00546", "name": "佛坪", "pinyin": "foping", "kaitouzimu": "fp", "code": "FUY" },
        { "id": "00547", "name": "佛山西", "pinyin": "foshanxi", "kaitouzimu": "fsx", "code": "FOQ" },
        { "id": "00548", "name": "法启", "pinyin": "faqi", "kaitouzimu": "fq", "code": "FQE" },
        { "id": "00549", "name": "福清", "pinyin": "fuqing", "kaitouzimu": "fq", "code": "FQS" },
        { "id": "00550", "name": "福泉", "pinyin": "fuquan", "kaitouzimu": "fq", "code": "VMW" },
        { "id": "00551", "name": "抚顺北", "pinyin": "fushunbei", "kaitouzimu": "fsb", "code": "FET" },
        { "id": "00552", "name": "丰水村", "pinyin": "fengshuicun", "kaitouzimu": "fsc", "code": "FSJ" },
        { "id": "00553", "name": "繁峙", "pinyin": "fanshi", "kaitouzimu": "fs", "code": "FSV" },
        { "id": "00554", "name": "丰顺", "pinyin": "fengshun", "kaitouzimu": "fs", "code": "FUQ" },
        { "id": "00555", "name": "佛山", "pinyin": "foshan", "kaitouzimu": "fs", "code": "FSQ" },
        { "id": "00556", "name": "抚顺", "pinyin": "fushun", "kaitouzimu": "fs", "code": "FST" },
        { "id": "00557", "name": "福山口", "pinyin": "fushankou", "kaitouzimu": "fsk", "code": "FKP" },
        { "id": "00558", "name": "抚松", "pinyin": "fusong", "kaitouzimu": "fs", "code": "FSL" },
        { "id": "00559", "name": "扶绥", "pinyin": "fusui", "kaitouzimu": "fs", "code": "FSZ" },
        { "id": "00560", "name": "福山镇", "pinyin": "fushanzhen", "kaitouzimu": "fsz", "code": "FZQ" },
        { "id": "00561", "name": "福田", "pinyin": "futian", "kaitouzimu": "ft", "code": "NZQ" },
        { "id": "00562", "name": "冯屯", "pinyin": "fengtun", "kaitouzimu": "ft", "code": "FTX" },
        { "id": "00563", "name": "浮图峪", "pinyin": "futuyu", "kaitouzimu": "fty", "code": "FYP" },
        { "id": "00564", "name": "富宁", "pinyin": "funing", "kaitouzimu": "fn", "code": "FNM" },
        { "id": "00565", "name": "芙蓉南", "pinyin": "furongnan", "kaitouzimu": "frn", "code": "KCQ" },
        { "id": "00566", "name": "复盛", "pinyin": "fusheng", "kaitouzimu": "fs", "code": "FAW" },
        { "id": "00567", "name": "阜新南", "pinyin": "fuxinnan", "kaitouzimu": "fxn", "code": "FXD" },
        { "id": "00568", "name": "富源北", "pinyin": "fuyuanbei", "kaitouzimu": "fyb", "code": "FBM" },
        { "id": "00569", "name": "富县东", "pinyin": "fuxiandong", "kaitouzimu": "fxd", "code": "FDY" },
        { "id": "00570", "name": "费县", "pinyin": "feixian", "kaitouzimu": "fx", "code": "FXK" },
        { "id": "00571", "name": "凤县", "pinyin": "fengxian", "kaitouzimu": "fx", "code": "FXY" },
        { "id": "00572", "name": "阜新", "pinyin": "fuxin", "kaitouzimu": "fx", "code": "FXD" },
        { "id": "00573", "name": "富县", "pinyin": "fuxian", "kaitouzimu": "fx", "code": "FEY" },
        { "id": "00574", "name": "汾阳", "pinyin": "fenyang", "kaitouzimu": "fy", "code": "FAV" },
        { "id": "00575", "name": "凤阳", "pinyin": "fengyang", "kaitouzimu": "fy", "code": "FUH" },
        { "id": "00576", "name": "阜阳", "pinyin": "fuyang", "kaitouzimu": "fy", "code": "FYH" },
        { "id": "00577", "name": "富阳", "pinyin": "fuyang", "kaitouzimu": "fy", "code": "FYU" },
        { "id": "00578", "name": "扶余北", "pinyin": "fuyubei", "kaitouzimu": "fyb", "code": "FBT" },
        { "id": "00579", "name": "分宜", "pinyin": "fenyi", "kaitouzimu": "fy", "code": "FYG" },
        { "id": "00580", "name": "扶余", "pinyin": "fuyu", "kaitouzimu": "fy", "code": "FYT" },
        { "id": "00581", "name": "抚远", "pinyin": "fuyuan", "kaitouzimu": "fy", "code": "FYB" },
        { "id": "00582", "name": "富裕", "pinyin": "fuyu", "kaitouzimu": "fy", "code": "FYX" },
        { "id": "00583", "name": "富源", "pinyin": "fuyuan", "kaitouzimu": "fy", "code": "FYM" },
        { "id": "00584", "name": "抚州北", "pinyin": "fuzhoubei", "kaitouzimu": "fzb", "code": "FBG" },
        { "id": "00585", "name": "抚州东", "pinyin": "fuzhoudong", "kaitouzimu": "fzd", "code": "FDG" },
        { "id": "00586", "name": "范镇", "pinyin": "fanzhen", "kaitouzimu": "fz", "code": "VZK" },
        { "id": "00587", "name": "方正", "pinyin": "fangzheng", "kaitouzimu": "fz", "code": "FNB" },
        { "id": "00588", "name": "丰镇", "pinyin": "fengzhen", "kaitouzimu": "fz", "code": "FZC" },
        { "id": "00589", "name": "凤州", "pinyin": "fengzhou", "kaitouzimu": "fz", "code": "FZY" },
        { "id": "00590", "name": "福州", "pinyin": "fuzhou", "kaitouzimu": "fz", "code": "FZS" },
        { "id": "00591", "name": "抚州", "pinyin": "fuzhou", "kaitouzimu": "fz", "code": "FZG" },
        { "id": "00592", "name": "福州 南", "pinyin": "fuzhounan", "kaitouzimu": "fzn", "code": "FXS" },
        { "id": "00593", "name": "福州南", "pinyin": "fuzhounan", "kaitouzimu": "fzn", "code": "FYS" },],
    G: [
        { "id": "00594", "name": "高安", "pinyin": "gaoan", "kaitouzimu": "ga", "code": "GCG" },
        { "id": "00595", "name": "固安", "pinyin": "guan", "kaitouzimu": "ga", "code": "GFP" },
        { "id": "00596", "name": "广安", "pinyin": "guangan", "kaitouzimu": "ga", "code": "VJW" },
        { "id": "00597", "name": "广安南", "pinyin": "guangannan", "kaitouzimu": "gan", "code": "VUW" },
        { "id": "00598", "name": "贵安", "pinyin": "guian", "kaitouzimu": "ga", "code": "GAE" },
        { "id": "00599", "name": "赶水东", "pinyin": "ganshuidong", "kaitouzimu": "gsd", "code": "GDE" },
        { "id": "00600", "name": "高碑店", "pinyin": "gaobeidian", "kaitouzimu": "gbd", "code": "GBP" },
        { "id": "00601", "name": "高碑店东", "pinyin": "gaobeidiandong", "kaitouzimu": "gbdd", "code": "GMP" },
        { "id": "00602", "name": "沟帮子", "pinyin": "goubangzi", "kaitouzimu": "gbz", "code": "GBD" },
        { "id": "00603", "name": "甘草店", "pinyin": "gancaodian", "kaitouzimu": "gcd", "code": "GDJ" },
        { "id": "00604", "name": "藁城", "pinyin": "gaocheng", "kaitouzimu": "gc", "code": "GEP" },
        { "id": "00605", "name": "恭城", "pinyin": "gongcheng", "kaitouzimu": "gc", "code": "GCZ" },
        { "id": "00606", "name": "谷城", "pinyin": "gucheng", "kaitouzimu": "gc", "code": "GCN" },
        { "id": "00607", "name": "藁城南", "pinyin": "gaochengnan", "kaitouzimu": "gcn", "code": "GUP" },
        { "id": "00608", "name": "高村", "pinyin": "gaocun", "kaitouzimu": "gc", "code": "GCV" },
        { "id": "00609", "name": "古城镇", "pinyin": "guchengzhen", "kaitouzimu": "gcz", "code": "GZB" },
        { "id": "00610", "name": "贵定北", "pinyin": "guidingbei", "kaitouzimu": "gdb", "code": "FMW" },
        { "id": "00611", "name": "广德", "pinyin": "guangde", "kaitouzimu": "gd", "code": "GRH" },
        { "id": "00612", "name": "贵定", "pinyin": "guiding", "kaitouzimu": "gd", "code": "GTW" },
        { "id": "00613", "name": "葛店南", "pinyin": "gediannan", "kaitouzimu": "gdn", "code": "GNN" },
        { "id": "00614", "name": "贵定南", "pinyin": "guidingnan", "kaitouzimu": "gdn", "code": "IDW" },
        { "id": "00615", "name": "古东", "pinyin": "gudong", "kaitouzimu": "gd", "code": "GDV" },
        { "id": "00616", "name": "贵定县", "pinyin": "guidingxian", "kaitouzimu": "gdx", "code": "KIW" },
        { "id": "00617", "name": "格尔木", "pinyin": "geermu", "kaitouzimu": "gem", "code": "GRO" },
        { "id": "00618", "name": "官高", "pinyin": "guangao", "kaitouzimu": "gg", "code": "GVP" },
        { "id": "00619", "name": "贵港", "pinyin": "guigang", "kaitouzimu": "gg", "code": "GGZ" },
        { "id": "00620", "name": "葛根庙", "pinyin": "gegenmiao", "kaitouzimu": "ggm", "code": "GGT" },
        { "id": "00621", "name": "干沟", "pinyin": "gangou", "kaitouzimu": "gg", "code": "GGL" },
        { "id": "00622", "name": "甘谷", "pinyin": "gangu", "kaitouzimu": "gg", "code": "GGJ" },
        { "id": "00623", "name": "高各庄", "pinyin": "gaogezhuang", "kaitouzimu": "ggz", "code": "GGP" },
        { "id": "00624", "name": "广汉", "pinyin": "guanghan", "kaitouzimu": "gh", "code": "GHW" },
        { "id": "00625", "name": "广汉北", "pinyin": "guanghanbei", "kaitouzimu": "ghb", "code": "GVW" },
        { "id": "00626", "name": "甘河", "pinyin": "ganhe", "kaitouzimu": "gh", "code": "GAX" },
        { "id": "00627", "name": "根河", "pinyin": "genhe", "kaitouzimu": "gh", "code": "GEX" },
        { "id": "00628", "name": "高花", "pinyin": "gaohua", "kaitouzimu": "gh", "code": "HGD" },
        { "id": "00629", "name": "郭家店", "pinyin": "guojiadian", "kaitouzimu": "gjd", "code": "GDT" },
        { "id": "00630", "name": "古交", "pinyin": "gujiao", "kaitouzimu": "gj", "code": "GJV" },
        { "id": "00631", "name": "革居", "pinyin": "geju", "kaitouzimu": "gj", "code": "GEM" },
        { "id": "00632", "name": "孤家子", "pinyin": "gujiazi", "kaitouzimu": "gjz", "code": "GKT" },
        { "id": "00633", "name": "皋兰", "pinyin": "gaolan", "kaitouzimu": "gl", "code": "GEJ" },
        { "id": "00634", "name": "古浪", "pinyin": "gulang", "kaitouzimu": "gl", "code": "GLJ" },
        { "id": "00635", "name": "桂林北", "pinyin": "guilinbei", "kaitouzimu": "glb", "code": "GBZ" },
        { "id": "00636", "name": "高楞", "pinyin": "gaoleng", "kaitouzimu": "gl", "code": "GLB" },
        { "id": "00637", "name": "高楼房", "pinyin": "gaoloufang", "kaitouzimu": "glf", "code": "GFM" },
        { "id": "00638", "name": "归流河", "pinyin": "guiliuhe", "kaitouzimu": "glh", "code": "GHT" },
        { "id": "00639", "name": "古莲", "pinyin": "gulian", "kaitouzimu": "gl", "code": "GRX" },
        { "id": "00640", "name": "关林", "pinyin": "guanlin", "kaitouzimu": "gl", "code": "GLF" },
        { "id": "00641", "name": "桂林", "pinyin": "guilin", "kaitouzimu": "gl", "code": "GLZ" },
        { "id": "00642", "name": "甘洛", "pinyin": "ganluo", "kaitouzimu": "gl", "code": "VOW" },
        { "id": "00643", "name": "桂林西", "pinyin": "guilinxi", "kaitouzimu": "glx", "code": "GEZ" },
        { "id": "00644", "name": "郭磊庄", "pinyin": "guoleizhuang", "kaitouzimu": "glz", "code": "GLP" },
        { "id": "00645", "name": "高密北", "pinyin": "gaomibei", "kaitouzimu": "gmb", "code": "GVK" },
        { "id": "00646", "name": "光明城", "pinyin": "guangmingcheng", "kaitouzimu": "gmc", "code": "IMQ" },
        { "id": "00647", "name": "高密", "pinyin": "gaomi", "kaitouzimu": "gm", "code": "GMK" },
        { "id": "00648", "name": "公庙子", "pinyin": "gongmiaozi", "kaitouzimu": "gmz", "code": "GMC" },
        { "id": "00649", "name": "工农湖", "pinyin": "gongnonghu", "kaitouzimu": "gnh", "code": "GRT" },
        { "id": "00650", "name": "广宁", "pinyin": "guangning", "kaitouzimu": "gn", "code": "FBQ" },
        { "id": "00651", "name": "广宁寺南", "pinyin": "guangningsinan", "kaitouzimu": "gns", "code": "GNT" },
        { "id": "00652", "name": "广宁寺", "pinyin": "guangningsi", "kaitouzimu": "gns", "code": "GQT" },
        { "id": "00653", "name": "广南卫", "pinyin": "guangnanwei", "kaitouzimu": "gnw", "code": "GNM" },
        { "id": "00654", "name": "高平", "pinyin": "gaoping", "kaitouzimu": "gp", "code": "GPF" },
        { "id": "00655", "name": "桂平", "pinyin": "guiping", "kaitouzimu": "gp", "code": "GAZ" },
        { "id": "00656", "name": "弓棚子", "pinyin": "gongpengzi", "kaitouzimu": "gpz", "code": "GPT" },
        { "id": "00657", "name": "甘泉北", "pinyin": "ganquanbei", "kaitouzimu": "gqb", "code": "GEY" },
        { "id": "00658", "name": "共青城", "pinyin": "gongqingcheng", "kaitouzimu": "gqc", "code": "GAG" },
        { "id": "00659", "name": "甘旗卡", "pinyin": "ganqika", "kaitouzimu": "gqk", "code": "GQD" },
        { "id": "00660", "name": "甘泉", "pinyin": "ganquan", "kaitouzimu": "gq", "code": "GQY" },
        { "id": "00661", "name": "高桥镇", "pinyin": "gaoqiaozhen", "kaitouzimu": "gqz", "code": "GZD" },
        { "id": "00662", "name": "赶水", "pinyin": "ganshui", "kaitouzimu": "gs", "code": "GSW" },
        { "id": "00663", "name": "谷山", "pinyin": "gushan", "kaitouzimu": "gs", "code": "FFQ" },
        { "id": "00664", "name": "固始", "pinyin": "gushi", "kaitouzimu": "gs", "code": "GXN" },
        { "id": "00665", "name": "灌水", "pinyin": "guanshui", "kaitouzimu": "gs", "code": "GST" },
        { "id": "00666", "name": "光山", "pinyin": "guangshan", "kaitouzimu": "gs", "code": "GUN" },
        { "id": "00667", "name": "广水", "pinyin": "guangshui", "kaitouzimu": "gs", "code": "GSN" },
        { "id": "00668", "name": "孤山口", "pinyin": "gushankou", "kaitouzimu": "gsk", "code": "GSP" },
        { "id": "00669", "name": "观沙岭", "pinyin": "guanshaling", "kaitouzimu": "gsl", "code": "FKQ" },
        { "id": "00670", "name": "果松", "pinyin": "guosong", "kaitouzimu": "gs", "code": "GSL" },
        { "id": "00671", "name": "嘎什甸子", "pinyin": "gashidianzi", "kaitouzimu": "gsdz", "code": "GXD" },
        { "id": "00672", "name": "高山子", "pinyin": "gaoshanzi", "kaitouzimu": "gsz", "code": "GSD" },
        { "id": "00673", "name": "干塘", "pinyin": "gantang", "kaitouzimu": "gt", "code": "GNJ" },
        { "id": "00674", "name": "高台", "pinyin": "gaotai", "kaitouzimu": "gt", "code": "GTJ" },
        { "id": "00675", "name": "高滩", "pinyin": "gaotan", "kaitouzimu": "gt", "code": "GAY" },
        { "id": "00676", "name": "古田北", "pinyin": "gutianbei", "kaitouzimu": "gtb", "code": "GBS" },
        { "id": "00677", "name": "广通北", "pinyin": "guangtongbei", "kaitouzimu": "gtb", "code": "GPM" },
        { "id": "00678", "name": "古田", "pinyin": "gutian", "kaitouzimu": "gt", "code": "GTS" },
        { "id": "00679", "name": "官厅", "pinyin": "guanting", "kaitouzimu": "gt", "code": "GTP" },
        { "id": "00680", "name": "高台南", "pinyin": "gaotainan", "kaitouzimu": "gtn", "code": "GAJ" },
        { "id": "00681", "name": "官厅西", "pinyin": "guantingxi", "kaitouzimu": "gtx", "code": "KEP" },
        { "id": "00682", "name": "古田会址", "pinyin": "gutianhuizhi", "kaitouzimu": "gthz", "code": "STS" },
        { "id": "00683", "name": "广南县", "pinyin": "guangnanxian", "kaitouzimu": "gnx", "code": "GXM" },
        { "id": "00684", "name": "关岭", "pinyin": "guanling", "kaitouzimu": "gl", "code": "GLE" },
        { "id": "00685", "name": "贵阳东", "pinyin": "guiyangdong", "kaitouzimu": "gyd", "code": "KEW" },
        { "id": "00686", "name": "贵溪", "pinyin": "guixi", "kaitouzimu": "gx", "code": "GXG" },
        { "id": "00687", "name": "贵阳", "pinyin": "guiyang", "kaitouzimu": "gy", "code": "GIW" },
        { "id": "00688", "name": "涡阳", "pinyin": "guoyang", "kaitouzimu": "gy", "code": "GYH" },
        { "id": "00689", "name": "贵阳北", "pinyin": "guiyangbei", "kaitouzimu": "gyb", "code": "KQW" },
        { "id": "00690", "name": "高邑", "pinyin": "gaoyi", "kaitouzimu": "gy", "code": "GIP" },
        { "id": "00691", "name": "巩义", "pinyin": "gongyi", "kaitouzimu": "gy", "code": "GXF" },
        { "id": "00692", "name": "巩义南", "pinyin": "gongyinan", "kaitouzimu": "gyn", "code": "GYF" },
        { "id": "00693", "name": "广元南", "pinyin": "guangyuannan", "kaitouzimu": "gyn", "code": "GAW" },
        { "id": "00694", "name": "赣榆", "pinyin": "ganyu", "kaitouzimu": "gy", "code": "GYU" },
        { "id": "00695", "name": "菇园", "pinyin": "guyuan", "kaitouzimu": "gy", "code": "GYL" },
        { "id": "00696", "name": "固原", "pinyin": "guyuan", "kaitouzimu": "gy", "code": "GUJ" },
        { "id": "00697", "name": "广元", "pinyin": "guangyuan", "kaitouzimu": "gy", "code": "GYW" },
        { "id": "00698", "name": "高邑西", "pinyin": "gaoyixi", "kaitouzimu": "gyx", "code": "GNP" },
        { "id": "00699", "name": "公营子", "pinyin": "gongyingzi", "kaitouzimu": "gyz", "code": "GYD" },
        { "id": "00700", "name": "广州北", "pinyin": "guangzhoubei", "kaitouzimu": "gzb", "code": "GBQ" },
        { "id": "00701", "name": "广州东", "pinyin": "guangzhoudong", "kaitouzimu": "gzd", "code": "GGQ" },
        { "id": "00702", "name": "光泽", "pinyin": "guangze", "kaitouzimu": "gz", "code": "GZS" },
        { "id": "00703", "name": "盖州", "pinyin": "gaizhou", "kaitouzimu": "gz", "code": "GXT" },
        { "id": "00704", "name": "赣州", "pinyin": "ganzhou", "kaitouzimu": "gz", "code": "GZG" },
        { "id": "00705", "name": "高州", "pinyin": "gaozhou", "kaitouzimu": "gz", "code": "GSQ" },
        { "id": "00706", "name": "古镇", "pinyin": "guzhen", "kaitouzimu": "gz", "code": "GNQ" },
        { "id": "00707", "name": "固镇", "pinyin": "guzhen", "kaitouzimu": "gz", "code": "GEH" },
        { "id": "00708", "name": "瓜州", "pinyin": "guazhou", "kaitouzimu": "gz", "code": "GZJ" },
        { "id": "00709", "name": "广州", "pinyin": "guangzhou", "kaitouzimu": "gz", "code": "GZQ" },
        { "id": "00710", "name": "虢镇", "pinyin": "guozhen", "kaitouzimu": "gz", "code": "GZY" },
        { "id": "00711", "name": "官字井", "pinyin": "guanzijing", "kaitouzimu": "gzj", "code": "GOT" },
        { "id": "00712", "name": "公主岭", "pinyin": "gongzhuling", "kaitouzimu": "gzl", "code": "GLT" },
        { "id": "00713", "name": "公主岭南", "pinyin": "gongzhulingnan", "kaitouzimu": "gzln", "code": "GBT" },
        { "id": "00714", "name": "广州南", "pinyin": "guangzhounan", "kaitouzimu": "gzn", "code": "IZQ" },
        { "id": "00715", "name": "革镇堡", "pinyin": "gezhenpu", "kaitouzimu": "gzb", "code": "GZT" },
        { "id": "00716", "name": "冠豸山", "pinyin": "guanzhaishan", "kaitouzimu": "gzs", "code": "GSS" },
        { "id": "00717", "name": "盖州西", "pinyin": "gaizhouxi", "kaitouzimu": "gzx", "code": "GAT" },
        { "id": "00718", "name": "广州西", "pinyin": "guangzhouxi", "kaitouzimu": "gzx", "code": "GXQ" },],
    H: [{ "id": "00719", "name": "哈达铺", "pinyin": "hadapu", "kaitouzimu": "hdp", "code": "HDJ" },
    { "id": "00720", "name": "海  口东", "pinyin": "haikoudong", "kaitouzimu": "hkd", "code": "KEQ" },
    { "id": "00721", "name": "海晏", "pinyin": "haiyan", "kaitouzimu": "hy", "code": "HFO" },
    { "id": "00722", "name": "海安", "pinyin": "haian", "kaitouzimu": "ha", "code": "HIH" },
    { "id": "00723", "name": "红安", "pinyin": "hongan", "kaitouzimu": "ha", "code": "HWN" },
    { "id": "00724", "name": "淮安", "pinyin": "huaian", "kaitouzimu": "ha", "code": "AUH" },
    { "id": "00725", "name": "淮安南", "pinyin": "huaiannan", "kaitouzimu": "han", "code": "AMH" },
    { "id": "00726", "name": "惠安", "pinyin": "huian", "kaitouzimu": "ha", "code": "HNS" },
    { "id": "00727", "name": "海安县", "pinyin": "haianxian", "kaitouzimu": "hax", "code": "HIH" },
    { "id": "00728", "name": "红安西", "pinyin": "honganxi", "kaitouzimu": "hax", "code": "VXN" },
    { "id": "00729", "name": "黄柏", "pinyin": "huangbai", "kaitouzimu": "hb", "code": "HBL" },
    { "id": "00730", "name": "鹤壁东", "pinyin": "hebidong", "kaitouzimu": "hbd", "code": "HFF" },
    { "id": "00731", "name": "海北", "pinyin": "haibei", "kaitouzimu": "hb", "code": "HEB" },
    { "id": "00732", "name": "鹤北", "pinyin": "hebei", "kaitouzimu": "hb", "code": "HMB" },
    { "id": "00733", "name": "淮北", "pinyin": "huaibei", "kaitouzimu": "hb", "code": "HRH" },
    { "id": "00734", "name": "河边", "pinyin": "hebian", "kaitouzimu": "hb", "code": "HBV" },
    { "id": "00735", "name": "鹤壁", "pinyin": "hebi", "kaitouzimu": "hb", "code": "HAF" },
    { "id": "00736", "name": "淮滨", "pinyin": "huaibin", "kaitouzimu": "hb", "code": "HVN" },
    { "id": "00737", "name": "会昌北", "pinyin": "huichangbei", "kaitouzimu": "hcb", "code": "XEG" },
    { "id": "00738", "name": "寒葱沟", "pinyin": "hanconggou", "kaitouzimu": "hcg", "code": "HKB" },
    { "id": "00739", "name": "海城", "pinyin": "haicheng", "kaitouzimu": "hc", "code": "HCT" },
    { "id": "00740", "name": "韩城", "pinyin": "hancheng", "kaitouzimu": "hc", "code": "HCY" },
    { "id": "00741", "name": "汉川", "pinyin": "hanchuan", "kaitouzimu": "hc", "code": "HCN" },
    { "id": "00742", "name": "合川", "pinyin": "hechuan", "kaitouzimu": "hc", "code": "WKW" },
    { "id": "00743", "name": "河唇", "pinyin": "hechun", "kaitouzimu": "hc", "code": "HCZ" },
    { "id": "00744", "name": "华城", "pinyin": "huacheng", "kaitouzimu": "hc", "code": "VCQ" },
    { "id": "00745", "name": "潢川", "pinyin": "huangchuan", "kaitouzimu": "hc", "code": "KCN" },
    { "id": "00746", "name": "珲春", "pinyin": "hunchun", "kaitouzimu": "hch", "code": "HUL" },
    { "id": "00747", "name": "黑冲滩", "pinyin": "heichongtan", "kaitouzimu": "hct", "code": "HCJ" },
    { "id": "00748", "name": "黄村", "pinyin": "huangcun", "kaitouzimu": "hc", "code": "HCP" },
    { "id": "00749", "name": "海城西", "pinyin": "haichengxi", "kaitouzimu": "hcx", "code": "HXT" },
    { "id": "00750", "name": "邯郸", "pinyin": "handan", "kaitouzimu": "hd", "code": "HDP" },
    { "id": "00751", "name": "邯郸东", "pinyin": "handandong", "kaitouzimu": "hdd", "code": "HPP" },
    { "id": "00752", "name": "横道河子东", "pinyin": "hengdaohezidong", "kaitouzimu": "hdhzd", "code": "KUX" },
    { "id": "00753", "name": "化德", "pinyin": "huade", "kaitouzimu": "hd", "code": "HGC" },
    { "id": "00754", "name": "洪洞", "pinyin": "hongtong", "kaitouzimu": "hd", "code": "HDV" },
    { "id": "00755", "name": "惠东", "pinyin": "huidong", "kaitouzimu": "hd", "code": "KDQ" },
    { "id": "00756", "name": "海东西", "pinyin": "haidongxi", "kaitouzimu": "hdx", "code": "HDO" },
    { "id": "00757", "name": "洪洞西", "pinyin": "hongtongxi", "kaitouzimu": "hdx", "code": "HTV" },
    { "id": "00758", "name": "横道河子", "pinyin": "hengdaohezi", "kaitouzimu": "hdhz", "code": "HDB" },
    { "id": "00759", "name": "哈尔滨", "pinyin": "haerbin", "kaitouzimu": "heb", "code": "HBB" },
    { "id": "00760", "name": "哈尔滨北", "pinyin": "haerbinbei", "kaitouzimu": "hebb", "code": "HTB" },
    { "id": "00761", "name": "哈尔滨东", "pinyin": "haerbindong", "kaitouzimu": "hebd", "code": "VBB" },
    { "id": "00762", "name": "霍尔果斯", "pinyin": "huoerguosi", "kaitouzimu": "hegs", "code": "HFR" },
    { "id": "00763", "name": "荷塘", "pinyin": "hetang", "kaitouzimu": "ht", "code": "KXQ" },
    { "id": "00764", "name": "哈尔滨西", "pinyin": "haerbinxi", "kaitouzimu": "hebx", "code": "VAB" },
    { "id": "00765", "name": "合肥北城", "pinyin": "hefeibeicheng", "kaitouzimu": "hfbc", "code": "COH" },
    { "id": "00766", "name": "合肥", "pinyin": "hefei", "kaitouzimu": "hf", "code": "HFH" },
    { "id": "00767", "name": "横峰", "pinyin": "hengfeng", "kaitouzimu": "hf", "code": "HFG" },
    { "id": "00768", "name": "合肥南", "pinyin": "hefeinan", "kaitouzimu": "hfn", "code": "ENH" },
    { "id": "00769", "name": "韩府湾", "pinyin": "hanfuwan", "kaitouzimu": "hfw", "code": "HXJ" },
    { "id": "00770", "name": "合肥西", "pinyin": "hefeixi", "kaitouzimu": "hfx", "code": "HTH" },
    { "id": "00771", "name": "鹤岗", "pinyin": "hegang", "kaitouzimu": "hg", "code": "HGB" },
    { "id": "00772", "name": "黄冈", "pinyin": "huanggang", "kaitouzimu": "hg", "code": "KGN" },
    { "id": "00773", "name": "横沟桥东", "pinyin": "henggouqiaodong", "kaitouzimu": "hgqd", "code": "HNN" },
    { "id": "00774", "name": "黄冈东", "pinyin": "huanggangdong", "kaitouzimu": "hgd", "code": "KAN" },
    { "id": "00775", "name": "皇姑屯", "pinyin": "huanggutun", "kaitouzimu": "hgt", "code": "HTT" },
    { "id": "00776", "name": "汉沽", "pinyin": "hangu", "kaitouzimu": "hg", "code": "HGP" },
    { "id": "00777", "name": "红果", "pinyin": "hongguo", "kaitouzimu": "hg", "code": "HEM" },
    { "id": "00778", "name": "黄冈西", "pinyin": "huanggangxi", "kaitouzimu": "hgx", "code": "KXN" },
    { "id": "00779", "name": "黄瓜园", "pinyin": "huangguayuan", "kaitouzimu": "hgy", "code": "HYM" },
    { "id": "00780", "name": "红光镇", "pinyin": "hongguangzhen", "kaitouzimu": "hgz", "code": "IGW" },
    { "id": "00781", "name": "呼和浩特东", "pinyin": "huhehaotedong", "kaitouzimu": "hhhtd", "code": "NDC" },
    { "id": "00782", "name": "黑河", "pinyin": "heihe", "kaitouzimu": "hh", "code": "HJB" },
    { "id": "00783", "name": "洪河", "pinyin": "honghe", "kaitouzimu": "hh", "code": "HPB" },
    { "id": "00784", "name": "浑河", "pinyin": "hunhe", "kaitouzimu": "hh", "code": "HHT" },
    { "id": "00785", "name": "红花沟", "pinyin": "honghuagou", "kaitouzimu": "hhg", "code": "VHD" },
    { "id": "00786", "name": "怀化南", "pinyin": "huaihuanan", "kaitouzimu": "hhn", "code": "KAQ" },
    { "id": "00787", "name": "黄河景区", "pinyin": "huanghejingqu", "kaitouzimu": "hhjq", "code": "HCF" },
    { "id": "00788", "name": "呼和浩特", "pinyin": "huhehaote", "kaitouzimu": "hhht", "code": "HHC" },
    { "id": "00789", "name": "黄花筒", "pinyin": "huanghuatong", "kaitouzimu": "hht", "code": "HUD" },
    { "id": "00790", "name": "后湖", "pinyin": "houhu", "kaitouzimu": "hh", "code": "IHN" },
    { "id": "00791", "name": "花湖", "pinyin": "huahu", "kaitouzimu": "hh", "code": "KHN" },
    { "id": "00792", "name": "怀化", "pinyin": "huaihua", "kaitouzimu": "hh", "code": "HHQ" },
    { "id": "00793", "name": "惠环", "pinyin": "huihuan", "kaitouzimu": "hh", "code": "KHQ" },
    { "id": "00794", "name": "贺家店", "pinyin": "hejiadian", "kaitouzimu": "hjd", "code": "HJJ" },
    { "id": "00795", "name": "涵江", "pinyin": "hanjiang", "kaitouzimu": "hj", "code": "HJS" },
    { "id": "00796", "name": "和静", "pinyin": "hejing", "kaitouzimu": "hj", "code": "HJR" },
    { "id": "00797", "name": "河津", "pinyin": "hejin", "kaitouzimu": "hj", "code": "HJV" },
    { "id": "00798", "name": "黑井", "pinyin": "heijing", "kaitouzimu": "hj", "code": "HIM" },
    { "id": "00799", "name": "红江", "pinyin": "hongjiang", "kaitouzimu": "hj", "code": "HFM" },
    { "id": "00800", "name": "华家", "pinyin": "huajia", "kaitouzimu": "hj", "code": "HJT" },
    { "id": "00801", "name": "怀集", "pinyin": "huaiji", "kaitouzimu": "hj", "code": "FAQ" },
    { "id": "00802", "name": "获嘉", "pinyin": "huojia", "kaitouzimu": "hj", "code": "HJF" },
    { "id": "00803", "name": "杭锦后旗", "pinyin": "hangjinhouqi", "kaitouzimu": "hjhq", "code": "HDC" },
    { "id": "00804", "name": "河间西", "pinyin": "hejianxi", "kaitouzimu": "hjx", "code": "HXP" },
    { "id": "00805", "name": "花家庄", "pinyin": "huajiazhuang", "kaitouzimu": "hjz", "code": "HJM" },
    { "id": "00806", "name": "河口北", "pinyin": "hekoubei", "kaitouzimu": "hkb", "code": "HBM" },
    { "id": "00807", "name": "海口东", "pinyin": "haikoudong", "kaitouzimu": "hkd", "code": "HMQ" },
    { "id": "00808", "name": "宏克力", "pinyin": "hongkeli", "kaitouzimu": "hkl", "code": "OKB" },
    { "id": "00809", "name": "河口南", "pinyin": "hekounan", "kaitouzimu": "hkn", "code": "HKJ" },
    { "id": "00810", "name": "海口", "pinyin": "haikou", "kaitouzimu": "hk", "code": "VUQ" },
    { "id": "00811", "name": "汉口", "pinyin": "hankou", "kaitouzimu": "hk", "code": "HKN" },
    { "id": "00812", "name": "湖口", "pinyin": "hukou", "kaitouzimu": "hk", "code": "HKG" },
    { "id": "00813", "name": "黄口", "pinyin": "huangkou", "kaitouzimu": "hk", "code": "KOH" },
    { "id": "00814", "name": "呼兰", "pinyin": "hulan", "kaitouzimu": "hl", "code": "HUB" },
    { "id": "00815", "name": "海林北", "pinyin": "hailinbei", "kaitouzimu": "hlb", "code": "KBX" },
    { "id": "00816", "name": "葫芦岛北", "pinyin": "huludaobei", "kaitouzimu": "hldb", "code": "HPD" },
    { "id": "00817", "name": "葫芦岛", "pinyin": "huludao", "kaitouzimu": "hld", "code": "HLD" },
    { "id": "00818", "name": "海拉尔", "pinyin": "hailaer", "kaitouzimu": "hle", "code": "HRX" },
    { "id": "00819", "name": "哈拉海", "pinyin": "halahai", "kaitouzimu": "hlh", "code": "HIT" },
    { "id": "00820", "name": "浩良河", "pinyin": "haolianghe", "kaitouzimu": "hlh", "code": "HHB" },
    { "id": "00821", "name": "海林", "pinyin": "hailin", "kaitouzimu": "hl", "code": "HRB" },
    { "id": "00822", "name": "寒岭", "pinyin": "hanling", "kaitouzimu": "hl", "code": "HAT" },
    { "id": "00823", "name": "鹤立", "pinyin": "heli", "kaitouzimu": "hl", "code": "HOB" },
    { "id": "00824", "name": "虎林", "pinyin": "hulin", "kaitouzimu": "hl", "code": "VLB" },
    { "id": "00825", "name": "桦林", "pinyin": "hualin", "kaitouzimu": "hl", "code": "HIB" },
    { "id": "00826", "name": "黄陵", "pinyin": "huangling", "kaitouzimu": "hl", "code": "ULY" },
    { "id": "00827", "name": "黄流", "pinyin": "huangliu", "kaitouzimu": "hl", "code": "KLQ" },
    { "id": "00828", "name": "霍林郭勒", "pinyin": "huolinguole", "kaitouzimu": "hlgl", "code": "HWD" },
    { "id": "00829", "name": "黄陵南", "pinyin": "huanglingnan", "kaitouzimu": "hln", "code": "VLY" },
    { "id": "00830", "name": "海龙", "pinyin": "hailong", "kaitouzimu": "hl", "code": "HIL" },
    { "id": "00831", "name": "和龙", "pinyin": "helong", "kaitouzimu": "hl", "code": "HLL" },
    { "id": "00832", "name": "哈拉苏", "pinyin": "halasu", "kaitouzimu": "hls", "code": "HAX" },
    { "id": "00833", "name": "呼鲁斯太", "pinyin": "hulusitai", "kaitouzimu": "hlst", "code": "VTJ" },
    { "id": "00834", "name": "海伦", "pinyin": "hailun", "kaitouzimu": "hl", "code": "HLB" },
    { "id": "00835", "name": "火连寨", "pinyin": "huolianzhai", "kaitouzimu": "hlz", "code": "HLT" },
    { "id": "00836", "name": "侯马", "pinyin": "houma", "kaitouzimu": "hm", "code": "HMV" },
    { "id": "00837", "name": "海门", "pinyin": "haimen", "kaitouzimu": "hm", "code": "HMU" },
    { "id": "00838", "name": "鲘门", "pinyin": "houmen", "kaitouzimu": "hm", "code": "KMQ" },
    { "id": "00839", "name": "虎门", "pinyin": "humen", "kaitouzimu": "hm", "code": "IUQ" },
    { "id": "00840", "name": "黄梅", "pinyin": "huangmei", "kaitouzimu": "hm", "code": "VEH" },
    { "id": "00841", "name": "哈密", "pinyin": "hami", "kaitouzimu": "hm", "code": "HMR" },
    { "id": "00842", "name": "侯马西", "pinyin": "houmaxi", "kaitouzimu": "hmx", "code": "HPV" },
    { "id": "00843", "name": "韩麻营", "pinyin": "hanmaying", "kaitouzimu": "hmy", "code": "HYP" },
    { "id": "00844", "name": "衡南", "pinyin": "hengnan", "kaitouzimu": "hn", "code": "HNG" },
    { "id": "00845", "name": "桦南", "pinyin": "huanan", "kaitouzimu": "hn", "code": "HNB" },
    { "id": "00846", "name": "淮南", "pinyin": "huainan", "kaitouzimu": "hn", "code": "HAH" },
    { "id": "00847", "name": "淮南东", "pinyin": "huainandong", "kaitouzimu": "hnd", "code": "HOH" },
    { "id": "00848", "name": "黄泥河", "pinyin": "huangnihe", "kaitouzimu": "hnh", "code": "HHL" },
    { "id": "00849", "name": "海宁", "pinyin": "haining", "kaitouzimu": "hn", "code": "HNH" },
    { "id": "00850", "name": "惠农", "pinyin": "huinong", "kaitouzimu": "hn", "code": "HMJ" },
    { "id": "00851", "name": "海宁西", "pinyin": "hainingxi", "kaitouzimu": "hnx", "code": "EUH" },
    { "id": "00852", "name": "和平", "pinyin": "heping", "kaitouzimu": "hp", "code": "VAQ" },
    { "id": "00853", "name": "合浦", "pinyin": "hepu", "kaitouzimu": "hp", "code": "HVZ" },
    { "id": "00854", "name": "花棚子", "pinyin": "huapengzi", "kaitouzimu": "hpz", "code": "HZM" },
    { "id": "00855", "name": "鹤庆", "pinyin": "heqing", "kaitouzimu": "hq", "code": "HQM" },
    { "id": "00856", "name": "宏庆", "pinyin": "hongqing", "kaitouzimu": "hq", "code": "HEY" },
    { "id": "00857", "name": "花桥", "pinyin": "huaqiao", "kaitouzimu": "hq", "code": "VQH" },
    { "id": "00858", "name": "霍邱", "pinyin": "huoqiu", "kaitouzimu": "hq", "code": "FBH" },
    { "id": "00859", "name": "怀柔北", "pinyin": "huairoubei", "kaitouzimu": "hrb", "code": "HBP" },
    { "id": "00860", "name": "华容东", "pinyin": "huarongdong", "kaitouzimu": "hrd", "code": "HPN" },
    { "id": "00861", "name": "怀仁东", "pinyin": "huairendong", "kaitouzimu": "hrd", "code": "HFV" },
    { "id": "00862", "name": "怀仁", "pinyin": "huairen", "kaitouzimu": "hr", "code": "HRV" },
    { "id": "00863", "name": "华容南", "pinyin": "huarongnan", "kaitouzimu": "hrn", "code": "KRN" },
    { "id": "00864", "name": "华容", "pinyin": "huarong", "kaitouzimu": "hr", "code": "HRN" },
    { "id": "00865", "name": "怀柔", "pinyin": "huairou", "kaitouzimu": "hr", "code": "HRP" },
    { "id": "00866", "name": "黑山北", "pinyin": "heishanbei", "kaitouzimu": "hsb", "code": "HQT" },
    { "id": "00867", "name": "衡水北", "pinyin": "hengshuibei", "kaitouzimu": "hsb", "code": "IHP" },
    { "id": "00868", "name": "华山北", "pinyin": "huashanbei", "kaitouzimu": "hsb", "code": "HDY" },
    { "id": "00869", "name": "黄山北", "pinyin": "huangshanbei", "kaitouzimu": "hsb", "code": "NYH" },
    { "id": "00870", "name": "黄石北", "pinyin": "huangshibei", "kaitouzimu": "hsb", "code": "KSN" },
    { "id": "00871", "name": "贺胜桥东", "pinyin": "heshengqiaodong", "kaitouzimu": "hsqd", "code": "HLN" },
    { "id": "00872", "name": "黄石东", "pinyin": "huangshidong", "kaitouzimu": "hsd", "code": "OSN" },
    { "id": "00873", "name": "黄松甸", "pinyin": "huangsongdian", "kaitouzimu": "hsd", "code": "HDL" },
    { "id": "00874", "name": "和什托洛盖", "pinyin": "heshituoluogai", "kaitouzimu": "hstlg", "code": "VSR" },
    { "id": "00875", "name": "汉寿", "pinyin": "hanshou", "kaitouzimu": "hs", "code": "VSQ" },
    { "id": "00876", "name": "和硕", "pinyin": "heshuo", "kaitouzimu": "hs", "code": "VUR" },
    { "id": "00877", "name": "黑水", "pinyin": "heishui", "kaitouzimu": "hs", "code": "HOT" },
    { "id": "00878", "name": "衡山", "pinyin": "hengshan", "kaitouzimu": "hs", "code": "HSQ" },
    { "id": "00879", "name": "衡水", "pinyin": "hengshui", "kaitouzimu": "hs", "code": "HSP" },
    { "id": "00880", "name": "红山", "pinyin": "hongshan", "kaitouzimu": "hs", "code": "VSB" },
    { "id": "00881", "name": "虎什哈", "pinyin": "hushiha", "kaitouzimu": "hsh", "code": "HHP" },
    { "id": "00882", "name": "华山", "pinyin": "huashan", "kaitouzimu": "hs", "code": "HSY" },
    { "id": "00883", "name": "黄山", "pinyin": "huangshan", "kaitouzimu": "hs", "code": "HKH" },
    { "id": "00884", "name": "黄石", "pinyin": "huangshi", "kaitouzimu": "hs", "code": "HSN" },
    { "id": "00885", "name": "惠山", "pinyin": "huishan", "kaitouzimu": "hs", "code": "VCH" },
    { "id": "00886", "name": "花山南", "pinyin": "huashannan", "kaitouzimu": "hsn", "code": "KNN" },
    { "id": "00887", "name": "红寺堡", "pinyin": "hongsipu", "kaitouzimu": "hsb", "code": "HSJ" },
    { "id": "00888", "name": "虎石台", "pinyin": "hushitai", "kaitouzimu": "hst", "code": "HUT" },
    { "id": "00889", "name": "海石湾", "pinyin": "haishiwan", "kaitouzimu": "hsw", "code": "HSO" },
    { "id": "00890", "name": "衡山西", "pinyin": "hengshanxi", "kaitouzimu": "hsx", "code": "HEQ" },
    { "id": "00891", "name": "红砂岘", "pinyin": "hongshaxian", "kaitouzimu": "hsj", "code": "VSJ" },
    { "id": "00892", "name": "黑台", "pinyin": "heitai", "kaitouzimu": "ht", "code": "HQB" },
    { "id": "00893", "name": "桓台", "pinyin": "huantai", "kaitouzimu": "ht", "code": "VTK" },
    { "id": "00894", "name": "黄土店", "pinyin": "huangtudian", "kaitouzimu": "htd", "code": "HKP" },
    { "id": "00895", "name": "和田", "pinyin": "hetian", "kaitouzimu": "ht", "code": "VTR" },
    { "id": "00896", "name": "会同", "pinyin": "huitong", "kaitouzimu": "ht", "code": "VTQ" },
    { "id": "00897", "name": "海坨子", "pinyin": "haituozi", "kaitouzimu": "htz", "code": "HZT" },
    { "id": "00898", "name": "淮北北", "pinyin": "huaibeibei", "kaitouzimu": "hbb", "code": "PLH" },
    { "id": "00899", "name": "霍城", "pinyin": "huocheng", "kaitouzimu": "hc", "code": "SER" },
    { "id": "00900", "name": "鄠邑", "pinyin": "huyi", "kaitouzimu": "hyi", "code": "KXY" },
    { "id": "00901", "name": "海湾", "pinyin": "haiwan", "kaitouzimu": "hw", "code": "RWH" },
    { "id": "00902", "name": "黑旺", "pinyin": "heiwang", "kaitouzimu": "hw", "code": "HWK" },
    { "id": "00903", "name": "红星", "pinyin": "hongxing", "kaitouzimu": "hx", "code": "VXB" },
    { "id": "00904", "name": "徽县", "pinyin": "huixian", "kaitouzimu": "hx", "code": "HYY" },
    { "id": "00905", "name": "红兴隆", "pinyin": "hongxinglong", "kaitouzimu": "hxl", "code": "VHB" },
    { "id": "00906", "name": "红岘台", "pinyin": "hongxiantai", "kaitouzimu": "hxt", "code": "HTJ" },
    { "id": "00907", "name": "换新天", "pinyin": "huanxintian", "kaitouzimu": "hxt", "code": "VTB" },
    { "id": "00908", "name": "海阳", "pinyin": "haiyang", "kaitouzimu": "hy", "code": "HYK" },
    { "id": "00909", "name": "合阳", "pinyin": "heyang", "kaitouzimu": "hy", "code": "HAY" },
    { "id": "00910", "name": "衡阳", "pinyin": "hengyang", "kaitouzimu": "hy", "code": "HYQ" },
    { "id": "00911", "name": "红彦", "pinyin": "hongyan", "kaitouzimu": "hy", "code": "VIX" },
    { "id": "00912", "name": "海阳北", "pinyin": "haiyangbei", "kaitouzimu": "hyb", "code": "HEK" },
    { "id": "00913", "name": "合阳北", "pinyin": "heyangbei", "kaitouzimu": "hyb", "code": "HTY" },
    { "id": "00914", "name": "衡阳东", "pinyin": "hengyangdong", "kaitouzimu": "hyd", "code": "HVQ" },
    { "id": "00915", "name": "汉阴", "pinyin": "hanyin", "kaitouzimu": "hy", "code": "HQY" },
    { "id": "00916", "name": "华蓥", "pinyin": "huaying", "kaitouzimu": "hy", "code": "HUW" },
    { "id": "00917", "name": "槐荫", "pinyin": "huaiyin", "kaitouzimu": "hy", "code": "IYN" },
    { "id": "00918", "name": "花园口", "pinyin": "huayuankou", "kaitouzimu": "hyk", "code": "HYT" },
    { "id": "00919", "name": "黄羊滩", "pinyin": "huangyangtan", "kaitouzimu": "hyt", "code": "HGJ" },
    { "id": "00920", "name": "汉源", "pinyin": "hanyuan", "kaitouzimu": "hy", "code": "WHW" },
    { "id": "00921", "name": "河源", "pinyin": "heyuan", "kaitouzimu": "hy", "code": "VIQ" },
    { "id": "00922", "name": "花园", "pinyin": "huayuan", "kaitouzimu": "hy", "code": "HUN" },
    { "id": "00923", "name": "湟源", "pinyin": "huangyuan", "kaitouzimu": "hy", "code": "HNO" },
    { "id": "00924", "name": "黄羊镇", "pinyin": "huangyangzhen", "kaitouzimu": "hyz", "code": "HYJ" },
    { "id": "00925", "name": "杭州东", "pinyin": "hangzhoudong", "kaitouzimu": "hzd", "code": "HGH" },
    { "id": "00926", "name": "霍州东", "pinyin": "huozhoudong", "kaitouzimu": "hzd", "code": "HWV" },
    { "id": "00927", "name": "菏泽", "pinyin": "heze", "kaitouzimu": "hz", "code": "HIK" },
    { "id": "00928", "name": "汉中", "pinyin": "hanzhong", "kaitouzimu": "hz", "code": "HOY" },
    { "id": "00929", "name": "杭州", "pinyin": "hangzhou", "kaitouzimu": "hz", "code": "HZH" },
    { "id": "00930", "name": "贺州", "pinyin": "hezhou", "kaitouzimu": "hz", "code": "HXZ" },
    { "id": "00931", "name": "湖州", "pinyin": "huzhou", "kaitouzimu": "hz", "code": "VZH" },
    { "id": "00932", "name": "化州", "pinyin": "huazhou", "kaitouzimu": "hz", "code": "HZZ" },
    { "id": "00933", "name": "黄州", "pinyin": "huangzhou", "kaitouzimu": "hz", "code": "VON" },
    { "id": "00934", "name": "惠州", "pinyin": "huizhou", "kaitouzimu": "hz", "code": "HCQ" },
    { "id": "00935", "name": "霍州", "pinyin": "huozhou", "kaitouzimu": "hz", "code": "HZV" },
    { "id": "00936", "name": "杭州南", "pinyin": "hangzhounan", "kaitouzimu": "hzn", "code": "XHH" },
    { "id": "00937", "name": "惠州南", "pinyin": "huizhounan", "kaitouzimu": "hzn", "code": "KNQ" },
    { "id": "00938", "name": "惠州西", "pinyin": "huizhouxi", "kaitouzimu": "hzx", "code": "VXQ" },],
    I: [],
    J: [
        { "id": "00939", "name": "吉安", "pinyin": "jian", "kaitouzimu": "ja", "code": "VAG" },
        { "id": "00940", "name": "集安", "pinyin": "jian", "kaitouzimu": "ja", "code": "JAL" },
        { "id": "00941", "name": "建安", "pinyin": "jianan", "kaitouzimu": "ja", "code": "JUL" },
        { "id": "00942", "name": "巨宝", "pinyin": "jubao", "kaitouzimu": "jb", "code": "JRT" },
        { "id": "00943", "name": "江边村", "pinyin": "jiangbiancun", "kaitouzimu": "jbc", "code": "JBG" },
        { "id": "00944", "name": "靖边", "pinyin": "jingbian", "kaitouzimu": "jb", "code": "JIY" },
        { "id": "00945", "name": "金宝屯", "pinyin": "jinbaotun", "kaitouzimu": "jbt", "code": "JBD" },
        { "id": "00946", "name": "晋城北", "pinyin": "jinchengbei", "kaitouzimu": "jcb", "code": "JEF" },
        { "id": "00947", "name": "建昌", "pinyin": "jianchang", "kaitouzimu": "jc", "code": "JFD" },
        { "id": "00948", "name": "交城", "pinyin": "jiaocheng", "kaitouzimu": "jc", "code": "JNV" },
        { "id": "00949", "name": "金昌", "pinyin": "jinchang", "kaitouzimu": "jc", "code": "JCJ" },
        { "id": "00950", "name": "晋城", "pinyin": "jincheng", "kaitouzimu": "jc", "code": "JCF" },
        { "id": "00951", "name": "泾川", "pinyin": "jingchuan", "kaitouzimu": "jc", "code": "JAJ" },
        { "id": "00952", "name": "鄄城", "pinyin": "juancheng", "kaitouzimu": "jc", "code": "JCK" },
        { "id": "00953", "name": "金城江", "pinyin": "jinchengjiang", "kaitouzimu": "jcj", "code": "JJZ" },
        { "id": "00954", "name": "建德", "pinyin": "jiande", "kaitouzimu": "jd", "code": "JDU" },
        { "id": "00955", "name": "旌德", "pinyin": "jingde", "kaitouzimu": "jd", "code": "NSH" },
        { "id": "00956", "name": "峻德", "pinyin": "junde", "kaitouzimu": "jd", "code": "JDB" },
        { "id": "00957", "name": "井店", "pinyin": "jingdian", "kaitouzimu": "jd", "code": "JFP" },
        { "id": "00958", "name": "鸡东", "pinyin": "jidong", "kaitouzimu": "jd", "code": "JOB" },
        { "id": "00959", "name": "江都", "pinyin": "jiangdu", "kaitouzimu": "jd", "code": "UDH" },
        { "id": "00960", "name": "景德镇", "pinyin": "jingdezhen", "kaitouzimu": "jdz", "code": "JCG" },
        { "id": "00961", "name": "嘉峰", "pinyin": "jiafeng", "kaitouzimu": "jf", "code": "JFF" },
        { "id": "00962", "name": "尖峰", "pinyin": "jianfeng", "kaitouzimu": "jf", "code": "PFQ" },
        { "id": "00963", "name": "加格达奇", "pinyin": "jiagedaqi", "kaitouzimu": "jgdq", "code": "JGX" },
        { "id": "00964", "name": "鸡冠山", "pinyin": "jiguanshan", "kaitouzimu": "jgs", "code": "JST" },
        { "id": "00965", "name": "井冈山", "pinyin": "jinggangshan", "kaitouzimu": "jgs", "code": "JGG" },
        { "id": "00966", "name": "金沟屯", "pinyin": "jingoutun", "kaitouzimu": "jgt", "code": "VGP" },
        { "id": "00967", "name": "近海", "pinyin": "jinhai", "kaitouzimu": "jh", "code": "JHD" },
        { "id": "00968", "name": "静海", "pinyin": "jinghai", "kaitouzimu": "jh", "code": "JHP" },
        { "id": "00969", "name": "蛟河", "pinyin": "jiaohe", "kaitouzimu": "jh", "code": "JHL" },
        { "id": "00970", "name": "金河", "pinyin": "jinhe", "kaitouzimu": "jh", "code": "JHX" },
        { "id": "00971", "name": "锦河", "pinyin": "jinhe", "kaitouzimu": "jh", "code": "JHB" },
        { "id": "00972", "name": "精河", "pinyin": "jinghe", "kaitouzimu": "jh", "code": "JHR" },
        { "id": "00973", "name": "金华南", "pinyin": "jinhuanan", "kaitouzimu": "jhn", "code": "RNH" },
        { "id": "00974", "name": "精河南", "pinyin": "jinghenan", "kaitouzimu": "jhn", "code": "JIR" },
        { "id": "00975", "name": "建湖", "pinyin": "jianhu", "kaitouzimu": "jh", "code": "AJH" },
        { "id": "00976", "name": "江华", "pinyin": "jianghua", "kaitouzimu": "jh", "code": "JHZ" },
        { "id": "00977", "name": "金华", "pinyin": "jinhua", "kaitouzimu": "jh", "code": "JBH" },
        { "id": "00978", "name": "蛟河西", "pinyin": "jiaohexi", "kaitouzimu": "jhx", "code": "JOL" },
        { "id": "00979", "name": "江油北", "pinyin": "jiangyoubei", "kaitouzimu": "jyb", "code": "JBE" },
        { "id": "00980", "name": "剑门关", "pinyin": "jianmenguan", "kaitouzimu": "jmg", "code": "JME" },
        { "id": "00981", "name": "景德镇北", "pinyin": "jingdezhenbei", "kaitouzimu": "jdzb", "code": "JDG" },
        { "id": "00982", "name": "金月湾", "pinyin": "jinyuewan", "kaitouzimu": "jyw", "code": "PYQ" },
        { "id": "00983", "name": "纪家沟", "pinyin": "jijiagou", "kaitouzimu": "jjg", "code": "VJD" },
        { "id": "00984", "name": "江津", "pinyin": "jiangjin", "kaitouzimu": "jj", "code": "JJW" },
        { "id": "00985", "name": "姜家", "pinyin": "jiangjia", "kaitouzimu": "jj", "code": "JJB" },
        { "id": "00986", "name": "锦界", "pinyin": "jinjie", "kaitouzimu": "jj", "code": "JEY" },
        { "id": "00987", "name": "晋江", "pinyin": "jinjiang", "kaitouzimu": "jj", "code": "JJS" },
        { "id": "00988", "name": "九江", "pinyin": "jiujiang", "kaitouzimu": "jj", "code": "JJG" },
        { "id": "00989", "name": "金坑", "pinyin": "jinkeng", "kaitouzimu": "jk", "code": "JKT" },
        { "id": "00990", "name": "军粮城北", "pinyin": "junliangchengbei", "kaitouzimu": "jlcb", "code": "JMP" },
        { "id": "00991", "name": "将乐", "pinyin": "jiangle", "kaitouzimu": "jl", "code": "JLS" },
        { "id": "00992", "name": "贾鲁河", "pinyin": "jialuhe", "kaitouzimu": "jlh", "code": "JLF" },
        { "id": "00993", "name": "芨岭", "pinyin": "jiling", "kaitouzimu": "jl", "code": "JLJ" },
        { "id": "00994", "name": "吉林", "pinyin": "jilin", "kaitouzimu": "jl", "code": "JLL" },
        { "id": "00995", "name": "九郎山", "pinyin": "jiulangshan", "kaitouzimu": "jls", "code": "KJQ" },
        { "id": "00996", "name": "即墨北", "pinyin": "jimobei", "kaitouzimu": "jmb", "code": "JVK" },
        { "id": "00997", "name": "金马村", "pinyin": "jinmacun", "kaitouzimu": "jmc", "code": "JMM" },
        { "id": "00998", "name": "江门东", "pinyin": "jiangmendong", "kaitouzimu": "jmd", "code": "JWQ" },
        { "id": "00999", "name": "江门", "pinyin": "jiangmen", "kaitouzimu": "jm", "code": "JWQ" },
        { "id": "01000", "name": "角美", "pinyin": "jiaomei", "kaitouzimu": "jm", "code": "JES" },
        { "id": "01001", "name": "荆门", "pinyin": "jingmen", "kaitouzimu": "jm", "code": "JMN" },
        { "id": "01002", "name": "佳木斯", "pinyin": "jiamusi", "kaitouzimu": "jms", "code": "JMB" },
        { "id": "01003", "name": "佳木斯西", "pinyin": "jiamusixi", "kaitouzimu": "jmsx", "code": "JUB" },
        { "id": "01004", "name": "济南", "pinyin": "jinan", "kaitouzimu": "jn", "code": "JNK" },
        { "id": "01005", "name": "井南", "pinyin": "jingnan", "kaitouzimu": "jn", "code": "JNP" },
        { "id": "01006", "name": "莒南", "pinyin": "junan", "kaitouzimu": "jn", "code": "JOK" },
        { "id": "01007", "name": "建宁县北", "pinyin": "jianningxianbei", "kaitouzimu": "jnxb", "code": "JCS" },
        { "id": "01008", "name": "济南东", "pinyin": "jinandong", "kaitouzimu": "jnd", "code": "MDK" },
        { "id": "01009", "name": "济宁", "pinyin": "jining", "kaitouzimu": "jn", "code": "JIK" },
        { "id": "01010", "name": "江宁", "pinyin": "jiangning", "kaitouzimu": "jn", "code": "JJH" },
        { "id": "01011", "name": "集宁南", "pinyin": "jiningnan", "kaitouzimu": "jnn", "code": "JAC" },
        { "id": "01012", "name": "济南西", "pinyin": "jinanxi", "kaitouzimu": "jnx", "code": "JGK" },
        { "id": "01013", "name": "江宁西", "pinyin": "jiangningxi", "kaitouzimu": "jnx", "code": "OKH" },
        { "id": "01014", "name": "建瓯", "pinyin": "jianou", "kaitouzimu": "jo", "code": "JVS" },
        { "id": "01015", "name": "建瓯西", "pinyin": "jianouxi", "kaitouzimu": "jox", "code": "JUS" },
        { "id": "01016", "name": "经棚", "pinyin": "jingpeng", "kaitouzimu": "jp", "code": "JPC" },
        { "id": "01017", "name": "江桥", "pinyin": "jiangqiao", "kaitouzimu": "jq", "code": "JQX" },
        { "id": "01018", "name": "酒泉南", "pinyin": "jiuquannan", "kaitouzimu": "jqn", "code": "JNJ" },
        { "id": "01019", "name": "酒泉", "pinyin": "jiuquan", "kaitouzimu": "jq", "code": "JQJ" },
        { "id": "01020", "name": "句容西", "pinyin": "jurongxi", "kaitouzimu": "jrx", "code": "JWH" },
        { "id": "01021", "name": "九三", "pinyin": "jiusan", "kaitouzimu": "js", "code": "SSX" },
        { "id": "01022", "name": "金山北", "pinyin": "jinshanbei", "kaitouzimu": "jsb", "code": "EGH" },
        { "id": "01023", "name": "吉首", "pinyin": "jishou", "kaitouzimu": "js", "code": "JIQ" },
        { "id": "01024", "name": "吉舒", "pinyin": "jishu", "kaitouzimu": "js", "code": "JSL" },
        { "id": "01025", "name": "稷山", "pinyin": "jishan", "kaitouzimu": "js", "code": "JVV" },
        { "id": "01026", "name": "嘉善", "pinyin": "jiashan", "kaitouzimu": "js", "code": "JSH" },
        { "id": "01027", "name": "甲山", "pinyin": "jiashan", "kaitouzimu": "js", "code": "JOP" },
        { "id": "01028", "name": "尖山", "pinyin": "jianshan", "kaitouzimu": "js", "code": "JPQ" },
        { "id": "01029", "name": "建设", "pinyin": "jianshe", "kaitouzimu": "js", "code": "JET" },
        { "id": "01030", "name": "建始", "pinyin": "jianshi", "kaitouzimu": "js", "code": "JRN" },
        { "id": "01031", "name": "建水", "pinyin": "jianshui", "kaitouzimu": "js", "code": "JSM" },
        { "id": "01032", "name": "江山", "pinyin": "jiangshan", "kaitouzimu": "js", "code": "JUH" },
        { "id": "01033", "name": "京山", "pinyin": "jingshan", "kaitouzimu": "js", "code": "JCN" },
        { "id": "01034", "name": "建三江", "pinyin": "jiansanjiang", "kaitouzimu": "jsj", "code": "JIB" },
        { "id": "01035", "name": "嘉善南", "pinyin": "jiashannan", "kaitouzimu": "jsn", "code": "EAH" },
        { "id": "01036", "name": "界首市", "pinyin": "jieshoushi", "kaitouzimu": "jss", "code": "JUN" },
        { "id": "01037", "name": "江所田", "pinyin": "jiangsuotian", "kaitouzimu": "jst", "code": "JOM" },
        { "id": "01038", "name": "金山屯", "pinyin": "jinshantun", "kaitouzimu": "jst", "code": "JTB" },
        { "id": "01039", "name": "景泰", "pinyin": "jingtai", "kaitouzimu": "jt", "code": "JTJ" },
        { "id": "01040", "name": "九台", "pinyin": "jiutai", "kaitouzimu": "jt", "code": "JTL" },
        { "id": "01041", "name": "九台南", "pinyin": "jiutainan", "kaitouzimu": "jtn", "code": "JNL" },
        { "id": "01042", "name": "镜铁山", "pinyin": "jingtieshan", "kaitouzimu": "jts", "code": "JVJ" },
        { "id": "01043", "name": "吉文", "pinyin": "jiwen", "kaitouzimu": "jw", "code": "JWX" },
        { "id": "01044", "name": "绩溪北", "pinyin": "jixibei", "kaitouzimu": "jxb", "code": "NRH" },
        { "id": "01045", "name": "介休东", "pinyin": "jiexiudong", "kaitouzimu": "jxd", "code": "JDV" },
        { "id": "01046", "name": "鸡西", "pinyin": "jixi", "kaitouzimu": "jx", "code": "JXB" },
        { "id": "01047", "name": "蓟县", "pinyin": "jixian", "kaitouzimu": "jx", "code": "JKP" },
        { "id": "01048", "name": "嘉祥", "pinyin": "jiaxiang", "kaitouzimu": "jx", "code": "JUK" },
        { "id": "01049", "name": "嘉兴", "pinyin": "jiaxing", "kaitouzimu": "jx", "code": "JXH" },
        { "id": "01050", "name": "介休", "pinyin": "jiexiu", "kaitouzimu": "jx", "code": "JXV" },
        { "id": "01051", "name": "进贤", "pinyin": "jinxian", "kaitouzimu": "jx", "code": "JUG" },
        { "id": "01052", "name": "泾县", "pinyin": "jingxian", "kaitouzimu": "jx", "code": "LOH" },
        { "id": "01053", "name": "井陉", "pinyin": "jingxing", "kaitouzimu": "jx", "code": "JJP" },
        { "id": "01054", "name": "靖西", "pinyin": "jingxi", "kaitouzimu": "jx", "code": "JMZ" },
        { "id": "01055", "name": "莒县", "pinyin": "juxian", "kaitouzimu": "jx", "code": "JKK" },
        { "id": "01056", "name": "嘉兴南", "pinyin": "jiaxingnan", "kaitouzimu": "jxn", "code": "EPH" },
        { "id": "01057", "name": "进贤南", "pinyin": "jinxiannan", "kaitouzimu": "jxn", "code": "JXG" },
        { "id": "01058", "name": "绩溪县", "pinyin": "jixixian", "kaitouzimu": "jxx", "code": "JRH" },
        { "id": "01059", "name": "夹心子", "pinyin": "jiaxinzi", "kaitouzimu": "jxz", "code": "JXT" },
        { "id": "01060", "name": "简阳", "pinyin": "jianyang", "kaitouzimu": "jy", "code": "JYW" },
        { "id": "01061", "name": "建阳", "pinyin": "jianyang", "kaitouzimu": "jy", "code": "JYS" },
        { "id": "01062", "name": "姜堰", "pinyin": "jiangyan", "kaitouzimu": "jy", "code": "UEH" },
        { "id": "01063", "name": "揭阳", "pinyin": "jieyang", "kaitouzimu": "jy", "code": "JYA" },
        { "id": "01064", "name": "巨野", "pinyin": "juye", "kaitouzimu": "jy", "code": "JYK" },
        { "id": "01065", "name": "嘉峪关", "pinyin": "jiayuguan", "kaitouzimu": "jyg", "code": "JGJ" },
        { "id": "01066", "name": "嘉峪关南", "pinyin": "jiayuguannan", "kaitouzimu": "jygn", "code": "JBJ" },
        { "id": "01067", "name": "简阳南", "pinyin": "jianyangnan", "kaitouzimu": "jyn", "code": "JOW" },
        { "id": "01068", "name": "江永", "pinyin": "jiangyong", "kaitouzimu": "jy", "code": "JYZ" },
        { "id": "01069", "name": "江油", "pinyin": "jiangyou", "kaitouzimu": "jy", "code": "JFW" },
        { "id": "01070", "name": "金银潭", "pinyin": "jinyintan", "kaitouzimu": "jyt", "code": "JTN" },
        { "id": "01071", "name": "济源", "pinyin": "jiyuan", "kaitouzimu": "jy", "code": "JYF" },
        { "id": "01072", "name": "江源", "pinyin": "jiangyuan", "kaitouzimu": "jy", "code": "SZL" },
        { "id": "01073", "name": "缙云", "pinyin": "jinyun", "kaitouzimu": "jy", "code": "JYH" },
        { "id": "01074", "name": "靖宇", "pinyin": "jingyu", "kaitouzimu": "jy", "code": "JYL" },
        { "id": "01075", "name": "靖远", "pinyin": "jingyuan", "kaitouzimu": "jy", "code": "JYJ" },
        { "id": "01076", "name": "缙云西", "pinyin": "jinyunxi", "kaitouzimu": "jyx", "code": "PYH" },
        { "id": "01077", "name": "靖远西", "pinyin": "jingyuanxi", "kaitouzimu": "jyx", "code": "JXJ" },
        { "id": "01078", "name": "蓟州北", "pinyin": "jizhoubei", "kaitouzimu": "jzb", "code": "JKP" },
        { "id": "01079", "name": "胶州北", "pinyin": "jiaozhoubei", "kaitouzimu": "jzb", "code": "JZK" },
        { "id": "01080", "name": "焦作东", "pinyin": "jiaozuodong", "kaitouzimu": "jzd", "code": "WEF" },
        { "id": "01081", "name": "蓟州", "pinyin": "jizhou", "kaitouzimu": "jz", "code": "JKP" },
        { "id": "01082", "name": "胶州", "pinyin": "jiaozhou", "kaitouzimu": "jz", "code": "JXK" },
        { "id": "01083", "name": "金寨", "pinyin": "jinzhai", "kaitouzimu": "jz", "code": "JZH" },
        { "id": "01084", "name": "金州", "pinyin": "jinzhou", "kaitouzimu": "jz", "code": "JZT" },
        { "id": "01085", "name": "锦州", "pinyin": "jinzhou", "kaitouzimu": "jz", "code": "JZD" },
        { "id": "01086", "name": "晋中", "pinyin": "jinzhong", "kaitouzimu": "jz", "code": "JZV" },
        { "id": "01087", "name": "晋州", "pinyin": "jinzhou", "kaitouzimu": "jz", "code": "JXP" },
        { "id": "01088", "name": "荆州", "pinyin": "jingzhou", "kaitouzimu": "jz", "code": "JBN" },
        { "id": "01089", "name": "景州", "pinyin": "jingzhou", "kaitouzimu": "jz", "code": "JEP" },
        { "id": "01090", "name": "靖州", "pinyin": "jingzhou", "kaitouzimu": "jz", "code": "JEQ" },
        { "id": "01091", "name": "锦州南", "pinyin": "jinzhounan", "kaitouzimu": "jzn", "code": "JOD" },
        { "id": "01092", "name": "焦作", "pinyin": "jiaozuo", "kaitouzimu": "jz", "code": "JOF" },
        { "id": "01093", "name": "旧庄窝", "pinyin": "jiuzhuangwo", "kaitouzimu": "jzw", "code": "JVP" },
        { "id": "01094", "name": "金杖子", "pinyin": "jinzhangzi", "kaitouzimu": "jzz", "code": "JYD" },],
    K: [

        { "id": "01095", "name": "开封北", "pinyin": "kaifengbei", "kaitouzimu": "kfb", "code": "KBF" },
        { "id": "01096", "name": "开化", "pinyin": "kaihua", "kaitouzimu": "kh", "code": "KHU" },
        { "id": "01097", "name": "开安", "pinyin": "kaian", "kaitouzimu": "ka", "code": "KAT" },
        { "id": "01098", "name": "康城", "pinyin": "kangcheng", "kaitouzimu": "kc", "code": "KCP" },
        { "id": "01099", "name": "库车", "pinyin": "kuche", "kaitouzimu": "kc", "code": "KCR" },
        { "id": "01100", "name": "库都尔", "pinyin": "kuduer", "kaitouzimu": "kde", "code": "KDX" },
        { "id": "01101", "name": "宽甸", "pinyin": "kuandian", "kaitouzimu": "kd", "code": "KDT" },
        { "id": "01102", "name": "克东", "pinyin": "kedong", "kaitouzimu": "kd", "code": "KOB" },
        { "id": "01103", "name": "库尔勒", "pinyin": "kuerle", "kaitouzimu": "kel", "code": "KLR" },
        { "id": "01104", "name": "开封", "pinyin": "kaifeng", "kaitouzimu": "kf", "code": "KFF" },
        { "id": "01105", "name": "开福寺", "pinyin": "kaifusi", "kaitouzimu": "kfs", "code": "FLQ" },
        { "id": "01106", "name": "开江", "pinyin": "kaijiang", "kaitouzimu": "kj", "code": "KAW" },
        { "id": "01107", "name": "康金井", "pinyin": "kangjinjing", "kaitouzimu": "kjj", "code": "KJB" },
        { "id": "01108", "name": "岢岚", "pinyin": "kelan", "kaitouzimu": "kl", "code": "KLV" },
        { "id": "01109", "name": "凯里", "pinyin": "kaili", "kaitouzimu": "kl", "code": "KLW" },
        { "id": "01110", "name": "凯里南", "pinyin": "kailinan", "kaitouzimu": "kln", "code": "QKW" },
        { "id": "01111", "name": "喀喇其", "pinyin": "kalaqi", "kaitouzimu": "klq", "code": "KQX" },
        { "id": "01112", "name": "开鲁", "pinyin": "kailu", "kaitouzimu": "kl", "code": "KLC" },
        { "id": "01113", "name": "库伦", "pinyin": "kulun", "kaitouzimu": "kl", "code": "KLD" },
        { "id": "01114", "name": "克拉玛依", "pinyin": "kelamayi", "kaitouzimu": "klmy", "code": "KHR" },
        { "id": "01115", "name": "昆明", "pinyin": "kunming", "kaitouzimu": "km", "code": "KMM" },
        { "id": "01116", "name": "昆明西", "pinyin": "kunmingxi", "kaitouzimu": "kmx", "code": "KXM" },
        { "id": "01117", "name": "开平南", "pinyin": "kaipingnan", "kaitouzimu": "kpn", "code": "PVQ" },
        { "id": "01118", "name": "口前", "pinyin": "kouqian", "kaitouzimu": "kq", "code": "KQL" },
        { "id": "01119", "name": "喀什", "pinyin": "kashi", "kaitouzimu": "ks", "code": "KSR" },
        { "id": "01120", "name": "克山", "pinyin": "keshan", "kaitouzimu": "ks", "code": "KSB" },
        { "id": "01121", "name": "奎山", "pinyin": "kuishan", "kaitouzimu": "ks", "code": "KAB" },
        { "id": "01122", "name": "昆山", "pinyin": "kunshan", "kaitouzimu": "ks", "code": "KSH" },
        { "id": "01123", "name": "昆山南", "pinyin": "kunshannan", "kaitouzimu": "ksn", "code": "KNH" },
        { "id": "01124", "name": "葵潭", "pinyin": "kuitan", "kaitouzimu": "kt", "code": "KTQ" },
        { "id": "01125", "name": "通榆", "pinyin": "kaitong", "kaitouzimu": "kt", "code": "KTT" },
        { "id": "01126", "name": "奎屯", "pinyin": "kuitun", "kaitouzimu": "kt", "code": "KTR" },
        { "id": "01127", "name": "昆都仑召", "pinyin": "kundulunzhao", "kaitouzimu": "kdlz", "code": "KDC" },
        { "id": "01128", "name": "昆明南", "pinyin": "kunmingnan", "kaitouzimu": "kmn", "code": "KOM" },
        { "id": "01129", "name": "康熙岭", "pinyin": "kangxiling", "kaitouzimu": "kxl", "code": "KXZ" },
        { "id": "01130", "name": "开阳", "pinyin": "kaiyang", "kaitouzimu": "ky", "code": "KVW" },
        { "id": "01131", "name": "昆阳", "pinyin": "kunyang", "kaitouzimu": "ky", "code": "KAM" },
        { "id": "01132", "name": "克一河", "pinyin": "keyihe", "kaitouzimu": "kyh", "code": "KHX" },
        { "id": "01133", "name": "开原", "pinyin": "kaiyuan", "kaitouzimu": "ky", "code": "KYT" },
        { "id": "01134", "name": "开原西", "pinyin": "kaiyuanxi", "kaitouzimu": "kyx", "code": "KXT" },
        { "id": "01135", "name": "康庄", "pinyin": "kangzhuang", "kaitouzimu": "kz", "code": "KZP" },
        { "id": "01136", "name": "喀左", "pinyin": "kazuo", "kaitouzimu": "kz", "code": "KZT" },],
    L: [
        { "id": "01137", "name": "隆安东", "pinyin": "longandong", "kaitouzimu": "lad", "code": "IDZ" },
        { "id": "01138", "name": "六安", "pinyin": "luan", "kaitouzimu": "la", "code": "UAH" },
        { "id": "01139", "name": "兰考南", "pinyin": "lankaonan", "kaitouzimu": "lkn", "code": "LUF" },
        { "id": "01140", "name": "灵宝", "pinyin": "lingbao", "kaitouzimu": "lb", "code": "LBF" },
        { "id": "01141", "name": "来宾北", "pinyin": "laibinbei", "kaitouzimu": "lbb", "code": "UCZ" },
        { "id": "01142", "name": "来宾", "pinyin": "laibin", "kaitouzimu": "lb", "code": "UBZ" },
        { "id": "01143", "name": "老边", "pinyin": "laobian", "kaitouzimu": "lb", "code": "LLT" },
        { "id": "01144", "name": "灵璧", "pinyin": "lingbi", "kaitouzimu": "lb", "code": "GMH" },
        { "id": "01145", "name": "寮步", "pinyin": "liaobu", "kaitouzimu": "lb", "code": "LTQ" },
        { "id": "01146", "name": "灵宝西", "pinyin": "lingbaoxi", "kaitouzimu": "lbx", "code": "LPF" },
        { "id": "01147", "name": "绿博园", "pinyin": "lvboyuan", "kaitouzimu": "lby", "code": "LCF" },
        { "id": "01148", "name": "隆昌北", "pinyin": "longchangbei", "kaitouzimu": "lcb", "code": "NWW" },
        { "id": "01149", "name": "芦潮港", "pinyin": "luchaogang", "kaitouzimu": "lcg", "code": "UCH" },
        { "id": "01150", "name": "乐昌", "pinyin": "lechang", "kaitouzimu": "lc", "code": "LCQ" },
        { "id": "01151", "name": "黎城", "pinyin": "licheng", "kaitouzimu": "lc", "code": "UCP" },
        { "id": "01152", "name": "利川", "pinyin": "lichuan", "kaitouzimu": "lc", "code": "LCN" },
        { "id": "01153", "name": "聊城", "pinyin": "liaocheng", "kaitouzimu": "lc", "code": "UCK" },
        { "id": "01154", "name": "临城", "pinyin": "lincheng", "kaitouzimu": "lc", "code": "UUP" },
        { "id": "01155", "name": "临川", "pinyin": "linchuan", "kaitouzimu": "lc", "code": "LCG" },
        { "id": "01156", "name": "陵城", "pinyin": "lingcheng", "kaitouzimu": "lc", "code": "LGK" },
        { "id": "01157", "name": "龙川", "pinyin": "longchuan", "kaitouzimu": "lc", "code": "LUQ" },
        { "id": "01158", "name": "隆昌", "pinyin": "longchang", "kaitouzimu": "lc", "code": "LCW" },
        { "id": "01159", "name": "陆川", "pinyin": "luchuan", "kaitouzimu": "lc", "code": "LKZ" },
        { "id": "01160", "name": "潞城", "pinyin": "lucheng", "kaitouzimu": "lc", "code": "UTP" },
        { "id": "01161", "name": "罗城", "pinyin": "luocheng", "kaitouzimu": "lc", "code": "VCZ" },
        { "id": "01162", "name": "蓝村", "pinyin": "lancun", "kaitouzimu": "lc", "code": "LCK" },
        { "id": "01163", "name": "老城镇", "pinyin": "laochengzhen", "kaitouzimu": "lcz", "code": "ACQ" },
        { "id": "01164", "name": "两当", "pinyin": "liangdang", "kaitouzimu": "ld", "code": "LDY" },
        { "id": "01165", "name": "鹿道", "pinyin": "ludao", "kaitouzimu": "ld", "code": "LDL" },
        { "id": "01166", "name": "龙洞堡", "pinyin": "longdongbao", "kaitouzimu": "ldb", "code": "FVW" },
        { "id": "01167", "name": "娄底", "pinyin": "loudi", "kaitouzimu": "ld", "code": "LDQ" },
        { "id": "01168", "name": "乐都南", "pinyin": "ledunan", "kaitouzimu": "ldn", "code": "LVO" },
        { "id": "01169", "name": "娄底南", "pinyin": "loudinan", "kaitouzimu": "ldn", "code": "UOQ" },
        { "id": "01170", "name": "乐东", "pinyin": "ledong", "kaitouzimu": "ld", "code": "UQQ" },
        { "id": "01171", "name": "林东", "pinyin": "lindong", "kaitouzimu": "ld", "code": "LRC" },
        { "id": "01172", "name": "乐都", "pinyin": "ledu", "kaitouzimu": "ld", "code": "LDO" },
        { "id": "01173", "name": "梁底下", "pinyin": "liangdixia", "kaitouzimu": "ldx", "code": "LDP" },
        { "id": "01174", "name": "离堆公园", "pinyin": "liduigongyuan", "kaitouzimu": "ldgy", "code": "INW" },
        { "id": "01175", "name": "六道河子", "pinyin": "liudaohezi", "kaitouzimu": "ldhz", "code": "LVP" },
        { "id": "01176", "name": "乐昌东", "pinyin": "lechangdong", "kaitouzimu": "lcd", "code": "ILQ" },
        { "id": "01177", "name": "廊坊", "pinyin": "langfang", "kaitouzimu": "lf", "code": "LJP" },
        { "id": "01178", "name": "娄烦", "pinyin": "loufan", "kaitouzimu": "lf", "code": "USV" },
        { "id": "01179", "name": "鲁番", "pinyin": "lufan", "kaitouzimu": "lf", "code": "LVM" },
        { "id": "01180", "name": "落垡", "pinyin": "luofa", "kaitouzimu": "lf", "code": "LOP" },
        { "id": "01181", "name": "廊坊北", "pinyin": "langfangbei", "kaitouzimu": "lfb", "code": "LFP" },
        { "id": "01182", "name": "临汾", "pinyin": "linfen", "kaitouzimu": "lf", "code": "LFV" },
        { "id": "01183", "name": "龙丰", "pinyin": "longfeng", "kaitouzimu": "lf", "code": "KFQ" },
        { "id": "01184", "name": "陆丰", "pinyin": "lufeng", "kaitouzimu": "lf", "code": "LLQ" },
        { "id": "01185", "name": "禄丰南", "pinyin": "lufengnan", "kaitouzimu": "lfn", "code": "LQM" },
        { "id": "01186", "name": "老府", "pinyin": "laofu", "kaitouzimu": "lf", "code": "UFD" },
        { "id": "01187", "name": "临汾西", "pinyin": "linfenxi", "kaitouzimu": "lfx", "code": "LXV" },
        { "id": "01188", "name": "兰岗", "pinyin": "langang", "kaitouzimu": "lg", "code": "LNB" },
        { "id": "01189", "name": "龙骨甸", "pinyin": "longgudian", "kaitouzimu": "lgd", "code": "LGM" },
        { "id": "01190", "name": "临高南", "pinyin": "lingaonan", "kaitouzimu": "lgn", "code": "KGQ" },
        { "id": "01191", "name": "龙沟", "pinyin": "longgou", "kaitouzimu": "lg", "code": "LGJ" },
        { "id": "01192", "name": "芦沟", "pinyin": "lugou", "kaitouzimu": "lg", "code": "LOM" },
        { "id": "01193", "name": "拉古", "pinyin": "lagu", "kaitouzimu": "lg", "code": "LGB" },
        { "id": "01194", "name": "麓谷", "pinyin": "lugu", "kaitouzimu": "lg", "code": "BNQ" },
        { "id": "01195", "name": "良各庄", "pinyin": "lianggezhuang", "kaitouzimu": "lgz", "code": "LGP" },
        { "id": "01196", "name": "拉哈", "pinyin": "laha", "kaitouzimu": "lh", "code": "LHX" },
        { "id": "01197", "name": "林海", "pinyin": "linhai", "kaitouzimu": "lh", "code": "LXX" },
        { "id": "01198", "name": "临海", "pinyin": "linhai", "kaitouzimu": "lh", "code": "UFH" },
        { "id": "01199", "name": "凌海", "pinyin": "linghai", "kaitouzimu": "lh", "code": "JID" },
        { "id": "01200", "name": "临河", "pinyin": "linhe", "kaitouzimu": "lh", "code": "LHC" },
        { "id": "01201", "name": "柳河", "pinyin": "liuhe", "kaitouzimu": "lh", "code": "LNL" },
        { "id": "01202", "name": "六合", "pinyin": "liuhe", "kaitouzimu": "lh", "code": "KLH" },
        { "id": "01203", "name": "滦河", "pinyin": "luanhe", "kaitouzimu": "lh", "code": "UDP" },
        { "id": "01204", "name": "漯河", "pinyin": "luohe", "kaitouzimu": "lh", "code": "LON" },
        { "id": "01205", "name": "龙华", "pinyin": "longhua", "kaitouzimu": "lh", "code": "LHP" },
        { "id": "01206", "name": "隆化", "pinyin": "longhua", "kaitouzimu": "lh", "code": "UHP" },
        { "id": "01207", "name": "隆回", "pinyin": "longhui", "kaitouzimu": "lh", "code": "LHA" },
        { "id": "01208", "name": "绿化", "pinyin": "lvhua", "kaitouzimu": "lh", "code": "LWJ" },
        { "id": "01209", "name": "漯河西", "pinyin": "luohexi", "kaitouzimu": "lhx", "code": "LBN" },
        { "id": "01210", "name": "滦河沿", "pinyin": "luanheyan", "kaitouzimu": "lhy", "code": "UNP" },
        { "id": "01211", "name": "六合镇", "pinyin": "liuhezhen", "kaitouzimu": "lhz", "code": "LEX" },
        { "id": "01212", "name": "梁平南", "pinyin": "liangpingnan", "kaitouzimu": "lpn", "code": "LPE" },
        { "id": "01213", "name": "柳江", "pinyin": "liujiang", "kaitouzimu": "lj", "code": "UQZ" },
        { "id": "01214", "name": "亮甲店", "pinyin": "liangjiadian", "kaitouzimu": "ljd", "code": "LRT" },
        { "id": "01215", "name": "刘家店", "pinyin": "liujiadian", "kaitouzimu": "ljd", "code": "UDT" },
        { "id": "01216", "name": "罗江东", "pinyin": "luojiangdong", "kaitouzimu": "ljd", "code": "IKW" },
        { "id": "01217", "name": "刘家河", "pinyin": "liujiahe", "kaitouzimu": "ljh", "code": "LVT" },
        { "id": "01218", "name": "李家", "pinyin": "lijia", "kaitouzimu": "lj", "code": "LJB" },
        { "id": "01219", "name": "丽江", "pinyin": "lijiang", "kaitouzimu": "lj", "code": "LHM" },
        { "id": "01220", "name": "连江", "pinyin": "lianjiang", "kaitouzimu": "lj", "code": "LKS" },
        { "id": "01221", "name": "廉江", "pinyin": "lianjiang", "kaitouzimu": "lj", "code": "LJZ" },
        { "id": "01222", "name": "两家", "pinyin": "liangjia", "kaitouzimu": "lj", "code": "UJT" },
        { "id": "01223", "name": "临江", "pinyin": "linjiang", "kaitouzimu": "lj", "code": "LQL" },
        { "id": "01224", "name": "龙嘉", "pinyin": "longjia", "kaitouzimu": "lj", "code": "UJL" },
        { "id": "01225", "name": "龙江", "pinyin": "longjiang", "kaitouzimu": "lj", "code": "LJX" },
        { "id": "01226", "name": "龙井", "pinyin": "longjing", "kaitouzimu": "lj", "code": "LJL" },
        { "id": "01227", "name": "庐江", "pinyin": "lujiang", "kaitouzimu": "lj", "code": "UJH" },
        { "id": "01228", "name": "罗江", "pinyin": "luojiang", "kaitouzimu": "lj", "code": "LJW" },
        { "id": "01229", "name": "莲江口", "pinyin": "lianjiangkou", "kaitouzimu": "ljk", "code": "LHB" },
        { "id": "01230", "name": "蔺家楼", "pinyin": "linjialou", "kaitouzimu": "ljl", "code": "ULK" },
        { "id": "01231", "name": "利津南", "pinyin": "lijinnan", "kaitouzimu": "ljn", "code": "LNK" },
        { "id": "01232", "name": "李家坪", "pinyin": "lijiaping", "kaitouzimu": "ljp", "code": "LIJ" },
        { "id": "01233", "name": "兰考", "pinyin": "lankao", "kaitouzimu": "lk", "code": "LKF" },
        { "id": "01234", "name": "林口", "pinyin": "linkou", "kaitouzimu": "lk", "code": "LKB" },
        { "id": "01235", "name": "路口铺", "pinyin": "lukoupu", "kaitouzimu": "lkp", "code": "LKQ" },
        { "id": "01236", "name": "龙口市", "pinyin": "longkoushi", "kaitouzimu": "lks", "code": "UKK" },
        { "id": "01237", "name": "老莱", "pinyin": "laolai", "kaitouzimu": "ll", "code": "LAX" },
        { "id": "01238", "name": "兰陵北", "pinyin": "lanlingbei", "kaitouzimu": "llb", "code": "COK" },
        { "id": "01239", "name": "沥林北", "pinyin": "lilinbei", "kaitouzimu": "llb", "code": "KBQ" },
        { "id": "01240", "name": "龙里北", "pinyin": "longlibei", "kaitouzimu": "llb", "code": "KFW" },
        { "id": "01241", "name": "醴陵东", "pinyin": "lilingdong", "kaitouzimu": "lld", "code": "UKQ" },
        { "id": "01242", "name": "拉林", "pinyin": "lalin", "kaitouzimu": "ll", "code": "LAB" },
        { "id": "01243", "name": "兰棱", "pinyin": "lanling", "kaitouzimu": "ll", "code": "LLB" },
        { "id": "01244", "name": "醴陵", "pinyin": "liling", "kaitouzimu": "ll", "code": "LLG" },
        { "id": "01245", "name": "临澧", "pinyin": "linli", "kaitouzimu": "ll", "code": "LWQ" },
        { "id": "01246", "name": "零陵", "pinyin": "lingling", "kaitouzimu": "ll", "code": "UWZ" },
        { "id": "01247", "name": "龙里", "pinyin": "longli", "kaitouzimu": "ll", "code": "LLW" },
        { "id": "01248", "name": "陆良", "pinyin": "luliang", "kaitouzimu": "ll", "code": "LRM" },
        { "id": "01249", "name": "吕梁", "pinyin": "lvliang", "kaitouzimu": "ll", "code": "LHV" },
        { "id": "01250", "name": "柳林南", "pinyin": "liulinnan", "kaitouzimu": "lln", "code": "LKV" },
        { "id": "01251", "name": "卢龙", "pinyin": "lulong", "kaitouzimu": "ll", "code": "UAP" },
        { "id": "01252", "name": "喇嘛甸", "pinyin": "lamadian", "kaitouzimu": "lmd", "code": "LMX" },
        { "id": "01253", "name": "里木店", "pinyin": "limudian", "kaitouzimu": "lmd", "code": "LMB" },
        { "id": "01254", "name": "洛门", "pinyin": "luomen", "kaitouzimu": "lm", "code": "LMJ" },
        { "id": "01255", "name": "龙南", "pinyin": "longnan", "kaitouzimu": "ln", "code": "UNG" },
        { "id": "01256", "name": "陇南", "pinyin": "longnan", "kaitouzimu": "ln", "code": "INJ" },
        { "id": "01257", "name": "娄山关南", "pinyin": "loushanguannan", "kaitouzimu": "lsgn", "code": "LSE" },
        { "id": "01258", "name": "梁平", "pinyin": "liangping", "kaitouzimu": "lp", "code": "UQW" },
        { "id": "01259", "name": "滦平", "pinyin": "luanping", "kaitouzimu": "lp", "code": "UPP" },
        { "id": "01260", "name": "罗平", "pinyin": "luoping", "kaitouzimu": "lp", "code": "LPM" },
        { "id": "01261", "name": "落坡岭", "pinyin": "luopoling", "kaitouzimu": "lpl", "code": "LPP" },
        { "id": "01262", "name": "乐平市", "pinyin": "lepingshi", "kaitouzimu": "lps", "code": "LPG" },
        { "id": "01263", "name": "六盘山", "pinyin": "liupanshan", "kaitouzimu": "lps", "code": "UPJ" },
        { "id": "01264", "name": "六盘水", "pinyin": "liupanshui", "kaitouzimu": "lps", "code": "UMW" },
        { "id": "01265", "name": "临清", "pinyin": "linqing", "kaitouzimu": "lq", "code": "UQK" },
        { "id": "01266", "name": "灵丘", "pinyin": "lingqiu", "kaitouzimu": "lq", "code": "LVV" },
        { "id": "01267", "name": "龙泉寺", "pinyin": "longquansi", "kaitouzimu": "lqs", "code": "UQJ" },
        { "id": "01268", "name": "礼泉", "pinyin": "liquan", "kaitouzimu": "lq", "code": "LGY" },
        { "id": "01269", "name": "拉萨", "pinyin": "lasa", "kaitouzimu": "ls", "code": "LSO" },
        { "id": "01270", "name": "乐山北", "pinyin": "leshanbei", "kaitouzimu": "ls", "code": "UTW" },
        { "id": "01271", "name": "乐善村", "pinyin": "leshancun", "kaitouzimu": "lsc", "code": "LUM" },
        { "id": "01272", "name": "冷水江东", "pinyin": "lengshuijiangdong", "kaitouzimu": "lsjd", "code": "UDQ" },
        { "id": "01273", "name": "灵石东", "pinyin": "lingshidong", "kaitouzimu": "lsd", "code": "UDV" },
        { "id": "01274", "name": "连山关", "pinyin": "lianshanguan", "kaitouzimu": "lsg", "code": "LGT" },
        { "id": "01275", "name": "流水沟", "pinyin": "liushuigou", "kaitouzimu": "lsg", "code": "USP" },
        { "id": "01276", "name": "乐山", "pinyin": "leshan", "kaitouzimu": "ls", "code": "IVW" },
        { "id": "01277", "name": "丽水", "pinyin": "lishui", "kaitouzimu": "ls", "code": "USH" },
        { "id": "01278", "name": "溧水", "pinyin": "lishui", "kaitouzimu": "ls", "code": "LDH" },
        { "id": "01279", "name": "梁山", "pinyin": "liangshan", "kaitouzimu": "ls", "code": "LMK" },
        { "id": "01280", "name": "灵石", "pinyin": "lingshi", "kaitouzimu": "ls", "code": "LSV" },
        { "id": "01281", "name": "陵水", "pinyin": "lingshui", "kaitouzimu": "ls", "code": "LIQ" },
        { "id": "01282", "name": "龙市", "pinyin": "longshi", "kaitouzimu": "sh", "code": "LAG" },
        { "id": "01283", "name": "庐山", "pinyin": "lushan", "kaitouzimu": "ls", "code": "LSG" },
        { "id": "01284", "name": "鲁山", "pinyin": "lushan", "kaitouzimu": "ls", "code": "LAF" },
        { "id": "01285", "name": "露水河", "pinyin": "lushuihe", "kaitouzimu": "lsh", "code": "LUL" },
        { "id": "01286", "name": "罗山", "pinyin": "luoshan", "kaitouzimu": "ls", "code": "LRN" },
        { "id": "01287", "name": "旅顺", "pinyin": "lvshun", "kaitouzimu": "ls", "code": "LST" },
        { "id": "01288", "name": "林盛堡", "pinyin": "linshengpu", "kaitouzimu": "lsp", "code": "LBT" },
        { "id": "01289", "name": "柳树屯", "pinyin": "liushutun", "kaitouzimu": "lst", "code": "LSD" },
        { "id": "01290", "name": "岚山西", "pinyin": "lanshanxi", "kaitouzimu": "lsx", "code": "UWK" },
        { "id": "01291", "name": "梨树镇", "pinyin": "lishuzhen", "kaitouzimu": "lsz", "code": "LSB" },
        { "id": "01292", "name": "李石寨", "pinyin": "lishizhai", "kaitouzimu": "lsz", "code": "LET" },
        { "id": "01293", "name": "龙山镇", "pinyin": "longshanzhen", "kaitouzimu": "lsz", "code": "LAS" },
        { "id": "01294", "name": "黎塘", "pinyin": "litang", "kaitouzimu": "lt", "code": "LTZ" },
        { "id": "01295", "name": "芦台", "pinyin": "lutai", "kaitouzimu": "lt", "code": "LTP" },
        { "id": "01296", "name": "轮台", "pinyin": "luntai", "kaitouzimu": "lt", "code": "LAR" },
        { "id": "01297", "name": "龙塘坝", "pinyin": "longtangba", "kaitouzimu": "ltb", "code": "LBM" },
        { "id": "01298", "name": "濑湍", "pinyin": "laituan", "kaitouzimu": "lt", "code": "LVZ" },
        { "id": "01299", "name": "骆驼巷", "pinyin": "luotuoxiang", "kaitouzimu": "ltx", "code": "LTJ" },
        { "id": "01300", "name": "珞璜南", "pinyin": "luohuangnan", "kaitouzimu": "lhn", "code": "LNE" },
        { "id": "01301", "name": "李旺", "pinyin": "liwang", "kaitouzimu": "lw", "code": "VLJ" },
        { "id": "01302", "name": "莱芜东", "pinyin": "laiwudong", "kaitouzimu": "lwd", "code": "LWK" },
        { "id": "01303", "name": "洛湾三江", "pinyin": "luowansanjiang", "kaitouzimu": "lwsj", "code": "KRW" },
        { "id": "01304", "name": "狼尾山", "pinyin": "langweishan", "kaitouzimu": "lws", "code": "LRJ" },
        { "id": "01305", "name": "灵武", "pinyin": "lingwu", "kaitouzimu": "lw", "code": "LNJ" },
        { "id": "01306", "name": "莱芜西", "pinyin": "laiwuxi", "kaitouzimu": "lwx", "code": "UXK" },
        { "id": "01307", "name": "莱西北", "pinyin": "laixibei", "kaitouzimu": "lxb", "code": "LBK" },
        { "id": "01308", "name": "莱西", "pinyin": "laixi", "kaitouzimu": "lx", "code": "LXK" },
        { "id": "01309", "name": "兰溪", "pinyin": "lanxi", "kaitouzimu": "lx", "code": "LWH" },
        { "id": "01310", "name": "岚县", "pinyin": "lanxian", "kaitouzimu": "lx", "code": "UXV" },
        { "id": "01311", "name": "朗乡", "pinyin": "langxiang", "kaitouzimu": "lx", "code": "LXB" },
        { "id": "01312", "name": "澧县", "pinyin": "lixian", "kaitouzimu": "lx", "code": "LEQ" },
        { "id": "01313", "name": "林西", "pinyin": "linxi", "kaitouzimu": "lx", "code": "LXC" },
        { "id": "01314", "name": "临西", "pinyin": "linxi", "kaitouzimu": "lx", "code": "UEP" },
        { "id": "01315", "name": "临湘", "pinyin": "linxiang", "kaitouzimu": "lx", "code": "LXQ" },
        { "id": "01316", "name": "陇西", "pinyin": "longxi", "kaitouzimu": "lx", "code": "LXJ" },
        { "id": "01317", "name": "陇县", "pinyin": "longxian", "kaitouzimu": "lx", "code": "LXY" },
        { "id": "01318", "name": "芦溪", "pinyin": "luxi", "kaitouzimu": "lx", "code": "LUG" },
        { "id": "01319", "name": "滦县", "pinyin": "luanxian", "kaitouzimu": "lx", "code": "UXP" },
        { "id": "01320", "name": "莱阳", "pinyin": "laiyang", "kaitouzimu": "ly", "code": "LYK" },
        { "id": "01321", "name": "耒阳", "pinyin": "leiyang", "kaitouzimu": "ly", "code": "LYQ" },
        { "id": "01322", "name": "溧阳", "pinyin": "liyang", "kaitouzimu": "ly", "code": "LEH" },
        { "id": "01323", "name": "辽阳", "pinyin": "liaoyang", "kaitouzimu": "ly", "code": "LYT" },
        { "id": "01324", "name": "龙岩", "pinyin": "longyan", "kaitouzimu": "ly", "code": "LYS" },
        { "id": "01325", "name": "洛阳", "pinyin": "luoyang", "kaitouzimu": "ly", "code": "LYF" },
        { "id": "01326", "name": "略阳", "pinyin": "lueyang", "kaitouzimu": "ly", "code": "LYY" },
        { "id": "01327", "name": "临沂北", "pinyin": "linyibei", "kaitouzimu": "lyb", "code": "UYK" },
        { "id": "01328", "name": "连云港东", "pinyin": "lianyungangdong", "kaitouzimu": "lygd", "code": "UKH" },
        { "id": "01329", "name": "临沂东", "pinyin": "linyidong", "kaitouzimu": "lyd", "code": "UYK" },
        { "id": "01330", "name": "凌源东", "pinyin": "lingyuandong", "kaitouzimu": "lyd", "code": "LDD" },
        { "id": "01331", "name": "洛阳东", "pinyin": "luoyangdong", "kaitouzimu": "lyd", "code": "LDF" },
        { "id": "01332", "name": "连云港", "pinyin": "lianyungang", "kaitouzimu": "lyg", "code": "UIH" },
        { "id": "01333", "name": "老营", "pinyin": "laoying", "kaitouzimu": "ly", "code": "LXL" },
        { "id": "01334", "name": "临沂", "pinyin": "linyi", "kaitouzimu": "ly", "code": "LVK" },
        { "id": "01335", "name": "临邑", "pinyin": "linyi", "kaitouzimu": "ly", "code": "LUK" },
        { "id": "01336", "name": "临颍", "pinyin": "linying", "kaitouzimu": "ly", "code": "LNF" },
        { "id": "01337", "name": "洛阳龙门", "pinyin": "luoyanglongmen", "kaitouzimu": "lylm", "code": "LLF" },
        { "id": "01338", "name": "柳园南", "pinyin": "liuyuannan", "kaitouzimu": "lyn", "code": "LNR" },
        { "id": "01339", "name": "龙游", "pinyin": "longyou", "kaitouzimu": "ly", "code": "LMH" },
        { "id": "01340", "name": "涞源", "pinyin": "laiyuan", "kaitouzimu": "ly", "code": "LYP" },
        { "id": "01341", "name": "涟源", "pinyin": "lianyuan", "kaitouzimu": "ly", "code": "LAQ" },
        { "id": "01342", "name": "辽源", "pinyin": "liaoyuan", "kaitouzimu": "ly", "code": "LYL" },
        { "id": "01343", "name": "林源", "pinyin": "linyuan", "kaitouzimu": "ly", "code": "LYX" },
        { "id": "01344", "name": "凌源", "pinyin": "lingyuan", "kaitouzimu": "ly", "code": "LYD" },
        { "id": "01345", "name": "柳园", "pinyin": "liuyuan", "kaitouzimu": "ly", "code": "DHR" },
        { "id": "01346", "name": "罗源", "pinyin": "luoyuan", "kaitouzimu": "ly", "code": "LVS" },
        { "id": "01347", "name": "耒阳西", "pinyin": "leiyangxi", "kaitouzimu": "lyx", "code": "LPQ" },
        { "id": "01348", "name": "临淄北", "pinyin": "linzibei", "kaitouzimu": "lzb", "code": "UEK" },
        { "id": "01349", "name": "鹿寨北", "pinyin": "luzhaibei", "kaitouzimu": "lzb", "code": "LSZ" },
        { "id": "01350", "name": "兰州东", "pinyin": "lanzhoudong", "kaitouzimu": "lzd", "code": "LVJ" },
        { "id": "01351", "name": "临泽", "pinyin": "linze", "kaitouzimu": "lz", "code": "LEJ" },
        { "id": "01352", "name": "龙爪沟", "pinyin": "longzhuagou", "kaitouzimu": "lzg", "code": "LZT" },
        { "id": "01353", "name": "拉鲊", "pinyin": "lazha", "kaitouzimu": "lz", "code": "LEM" },
        { "id": "01354", "name": "来舟", "pinyin": "laizhou", "kaitouzimu": "lz", "code": "LZS" },
        { "id": "01355", "name": "兰州", "pinyin": "lanzhou", "kaitouzimu": "lz", "code": "LZJ" },
        { "id": "01356", "name": "阆中", "pinyin": "langzhong", "kaitouzimu": "lz", "code": "LZE" },
        { "id": "01357", "name": "雷州", "pinyin": "leizhou", "kaitouzimu": "lz", "code": "UAQ" },
        { "id": "01358", "name": "立志", "pinyin": "lizhi", "kaitouzimu": "lz", "code": "LZX" },
        { "id": "01359", "name": "辽中", "pinyin": "liaozhong", "kaitouzimu": "lz", "code": "LZD" },
        { "id": "01360", "name": "柳州", "pinyin": "liuzhou", "kaitouzimu": "lz", "code": "LZZ" },
        { "id": "01361", "name": "六枝", "pinyin": "liuzhi", "kaitouzimu": "lz", "code": "LIW" },
        { "id": "01362", "name": "龙镇", "pinyin": "longzhen", "kaitouzimu": "lz", "code": "LZA" },
        { "id": "01363", "name": "鹿寨", "pinyin": "luzhai", "kaitouzimu": "lz", "code": "LIZ" },
        { "id": "01364", "name": "临泽南", "pinyin": "linzenan", "kaitouzimu": "lzn", "code": "LDJ" },
        { "id": "01365", "name": "兰州新区", "pinyin": "lanzhouxinqu", "kaitouzimu": "lzxq", "code": "LQJ" },
        { "id": "01366", "name": "兰州西", "pinyin": "lanzhouxi", "kaitouzimu": "lzx", "code": "LAJ" },],
    M: [
        { "id": "01367", "name": "马鞍山东", "pinyin": "maanshandong", "kaitouzimu": "masd", "code": "OMH" },
        { "id": "01368", "name": "毛陈", "pinyin": "maochen", "kaitouzimu": "mc", "code": "MHN" },
        { "id": "01369", "name": "茂名西", "pinyin": "maomingxi", "kaitouzimu": "mmx", "code": "MMZ" },
        { "id": "01370", "name": "马鞍山", "pinyin": "maanshan", "kaitouzimu": "mas", "code": "MAH" },
        { "id": "01371", "name": "毛坝", "pinyin": "maoba", "kaitouzimu": "mb", "code": "MBY" },
        { "id": "01372", "name": "毛坝关", "pinyin": "maobaguan", "kaitouzimu": "mbg", "code": "MGY" },
        { "id": "01373", "name": "麻城北", "pinyin": "machengbei", "kaitouzimu": "mcb", "code": "MBN" },
        { "id": "01374", "name": "麻城", "pinyin": "macheng", "kaitouzimu": "mc", "code": "MCN" },
        { "id": "01375", "name": "渑池", "pinyin": "mianchi", "kaitouzimu": "mc", "code": "MCF" },
        { "id": "01376", "name": "庙城", "pinyin": "miaocheng", "kaitouzimu": "mc", "code": "MAP" },
        { "id": "01377", "name": "明城", "pinyin": "mingcheng", "kaitouzimu": "mc", "code": "MCL" },
        { "id": "01378", "name": "渑池南", "pinyin": "mianchinan", "kaitouzimu": "mcn", "code": "MNF" },
        { "id": "01379", "name": "茅草坪", "pinyin": "maocaoping", "kaitouzimu": "mcp", "code": "KPM" },
        { "id": "01380", "name": "猛洞河", "pinyin": "mengdonghe", "kaitouzimu": "mdh", "code": "MUQ" },
        { "id": "01381", "name": "免渡河", "pinyin": "mianduhe", "kaitouzimu": "mdh", "code": "MDX" },
        { "id": "01382", "name": "牡丹江", "pinyin": "mudanjiang", "kaitouzimu": "mdj", "code": "MDB" },
        { "id": "01383", "name": "磨刀石", "pinyin": "modaoshi", "kaitouzimu": "mds", "code": "MOB" },
        { "id": "01384", "name": "弥渡", "pinyin": "midu", "kaitouzimu": "md", "code": "MDF" },
        { "id": "01385", "name": "莫尔道嘎", "pinyin": "moerdaoga", "kaitouzimu": "medg", "code": "MRX" },
        { "id": "01386", "name": "帽儿山", "pinyin": "maoershan", "kaitouzimu": "mes", "code": "MRB" },
        { "id": "01387", "name": "帽儿山西", "pinyin": "mershanxi", "kaitouzimu": "mesx", "code": "MUB" },
        { "id": "01388", "name": "明港", "pinyin": "minggang", "kaitouzimu": "mg", "code": "MGN" },
        { "id": "01389", "name": "明港东", "pinyin": "minggangdong", "kaitouzimu": "mgd", "code": "MDN" },
        { "id": "01390", "name": "满归", "pinyin": "mangui", "kaitouzimu": "mg", "code": "MHX" },
        { "id": "01391", "name": "明光", "pinyin": "mingguang", "kaitouzimu": "mg", "code": "MGH" },
        { "id": "01392", "name": "漠河", "pinyin": "mohe", "kaitouzimu": "mh", "code": "MVX" },
        { "id": "01393", "name": "梅河口", "pinyin": "meihekou", "kaitouzimu": "mhk", "code": "MHL" },
        { "id": "01394", "name": "民和南", "pinyin": "minhenan", "kaitouzimu": "mhn", "code": "MNO" },
        { "id": "01395", "name": "马皇", "pinyin": "mahuang", "kaitouzimu": "mh", "code": "MHZ" },
        { "id": "01396", "name": "弥勒", "pinyin": "mile", "kaitouzimu": "ml", "code": "MLM" },
        { "id": "01397", "name": "民权北", "pinyin": "minquanbei", "kaitouzimu": "mqb", "code": "MIF" },
        { "id": "01398", "name": "岷县", "pinyin": "minxian", "kaitouzimu": "mx", "code": "MXJ" },
        { "id": "01399", "name": "孟家岗", "pinyin": "mengjiagang", "kaitouzimu": "mjg", "code": "MGB" },
        { "id": "01400", "name": "闵集", "pinyin": "minji", "kaitouzimu": "mj", "code": "MJN" },
        { "id": "01401", "name": "马兰", "pinyin": "malan", "kaitouzimu": "ml", "code": "MLR" },
        { "id": "01402", "name": "美兰", "pinyin": "meilan", "kaitouzimu": "ml", "code": "MHQ" },
        { "id": "01403", "name": "汨罗东", "pinyin": "miluodong", "kaitouzimu": "mld", "code": "MQQ" },
        { "id": "01404", "name": "民乐", "pinyin": "minle", "kaitouzimu": "ml", "code": "MBJ" },
        { "id": "01405", "name": "马莲河", "pinyin": "malianhe", "kaitouzimu": "mlh", "code": "MHB" },
        { "id": "01406", "name": "马林", "pinyin": "malin", "kaitouzimu": "ml", "code": "MID" },
        { "id": "01407", "name": "茅岭", "pinyin": "maoling", "kaitouzimu": "ml", "code": "MLZ" },
        { "id": "01408", "name": "茂林", "pinyin": "maolin", "kaitouzimu": "ml", "code": "MLD" },
        { "id": "01409", "name": "庙岭", "pinyin": "miaoling", "kaitouzimu": "ml", "code": "MLL" },
        { "id": "01410", "name": "穆棱", "pinyin": "muling", "kaitouzimu": "ml", "code": "MLB" },
        { "id": "01411", "name": "马龙", "pinyin": "malong", "kaitouzimu": "ml", "code": "MGM" },
        { "id": "01412", "name": "木里图", "pinyin": "mulitu", "kaitouzimu": "mlt", "code": "MUD" },
        { "id": "01413", "name": "汨罗", "pinyin": "miluo", "kaitouzimu": "ml", "code": "MLQ" },
        { "id": "01414", "name": "茂名东", "pinyin": "maomingdong", "kaitouzimu": "mmd", "code": "MDQ" },
        { "id": "01415", "name": "茂名", "pinyin": "maoming", "kaitouzimu": "mm", "code": "MMZ" },
        { "id": "01416", "name": "玛纳斯湖", "pinyin": "manasihu", "kaitouzimu": "mnsh", "code": "MNR" },
        { "id": "01417", "name": "冕宁", "pinyin": "mianning", "kaitouzimu": "mn", "code": "UGW" },
        { "id": "01418", "name": "玛纳斯", "pinyin": "manasi", "kaitouzimu": "mns", "code": "MSR" },
        { "id": "01419", "name": "沐滂", "pinyin": "mupang", "kaitouzimu": "mp", "code": "MPQ" },
        { "id": "01420", "name": "牟平", "pinyin": "muping", "kaitouzimu": "mp", "code": "MBK" },
        { "id": "01421", "name": "闽清北", "pinyin": "minqingbei", "kaitouzimu": "mqb", "code": "MBS" },
        { "id": "01422", "name": "马桥河", "pinyin": "maqiaohe", "kaitouzimu": "mqh", "code": "MQB" },
        { "id": "01423", "name": "闽清", "pinyin": "minqing", "kaitouzimu": "mq", "code": "MQS" },
        { "id": "01424", "name": "民权", "pinyin": "minquan", "kaitouzimu": "mq", "code": "MQF" },
        { "id": "01425", "name": "眉山东", "pinyin": "meishandong", "kaitouzimu": "msd", "code": "IUW" },
        { "id": "01426", "name": "麻山", "pinyin": "mashan", "kaitouzimu": "ms", "code": "MAB" },
        { "id": "01427", "name": "眉山", "pinyin": "meishan", "kaitouzimu": "ms", "code": "MSW" },
        { "id": "01428", "name": "密山", "pinyin": "mishan", "kaitouzimu": "ms", "code": "MSB" },
        { "id": "01429", "name": "庙山", "pinyin": "miaoshan", "kaitouzimu": "ms", "code": "MSN" },
        { "id": "01430", "name": "名山", "pinyin": "mingshan", "kaitouzimu": "ms", "code": "MSE" },
        { "id": "01431", "name": "明水河", "pinyin": "mingshuihe", "kaitouzimu": "msh", "code": "MUT" },
        { "id": "01432", "name": "马三家", "pinyin": "masanjia", "kaitouzimu": "msj", "code": "MJT" },
        { "id": "01433", "name": "漫水湾", "pinyin": "manshuiwan", "kaitouzimu": "msw", "code": "MKW" },
        { "id": "01434", "name": "茂舍祖", "pinyin": "maoshezu", "kaitouzimu": "msz", "code": "MOM" },
        { "id": "01435", "name": "米沙子", "pinyin": "mishazi", "kaitouzimu": "msz", "code": "MST" },
        { "id": "01436", "name": "马踏", "pinyin": "mata", "kaitouzimu": "mt", "code": "PWQ" },
        { "id": "01437", "name": "暮云", "pinyin": "muyun", "kaitouzimu": "my", "code": "KIQ" },
        { "id": "01438", "name": "麻尾", "pinyin": "mawei", "kaitouzimu": "mw", "code": "VAW" },
        { "id": "01439", "name": "美溪", "pinyin": "meixi", "kaitouzimu": "mx", "code": "MEB" },
        { "id": "01440", "name": "勉县", "pinyin": "mianxian", "kaitouzimu": "mx", "code": "MVY" },
        { "id": "01441", "name": "麻阳", "pinyin": "mayang", "kaitouzimu": "my", "code": "MVQ" },
        { "id": "01442", "name": "绵阳", "pinyin": "mianyang", "kaitouzimu": "my", "code": "MYW" },
        { "id": "01443", "name": "密云北", "pinyin": "miyunbei", "kaitouzimu": "myb", "code": "MUP" },
        { "id": "01444", "name": "米易", "pinyin": "miyi", "kaitouzimu": "my", "code": "MMW" },
        { "id": "01445", "name": "麦园", "pinyin": "maiyuan", "kaitouzimu": "my", "code": "MYS" },
        { "id": "01446", "name": "门源", "pinyin": "menyuan", "kaitouzimu": "my", "code": "MYO" },
        { "id": "01447", "name": "墨玉", "pinyin": "moyu", "kaitouzimu": "my", "code": "MUR" },
        { "id": "01448", "name": "蒙自北", "pinyin": "mengzibei", "kaitouzimu": "mzb", "code": "MBM" },
        { "id": "01449", "name": "梅州", "pinyin": "meizhou", "kaitouzimu": "mz", "code": "MOQ" },
        { "id": "01450", "name": "孟庄", "pinyin": "mengzhuang", "kaitouzimu": "mz", "code": "MZF" },
        { "id": "01451", "name": "米脂", "pinyin": "mizhi", "kaitouzimu": "mz", "code": "MEY" },
        { "id": "01452", "name": "庙庄", "pinyin": "miaozhuang", "kaitouzimu": "mz", "code": "MZJ" },
        { "id": "01453", "name": "明珠", "pinyin": "mingzhu", "kaitouzimu": "mz", "code": "MFQ" },
        { "id": "01454", "name": "蒙自", "pinyin": "mengzi", "kaitouzimu": "mz", "code": "MZM" },
        { "id": "01455", "name": "满洲里", "pinyin": "manzhouli", "kaitouzimu": "mzl", "code": "MLX" },],
    N: [
        { "id": "01456", "name": "宁安", "pinyin": "ningan", "kaitouzimu": "na", "code": "NAB" },
        { "id": "01457", "name": "农安", "pinyin": "nongan", "kaitouzimu": "na", "code": "NAT" },
        { "id": "01458", "name": "宁波东", "pinyin": "ningbodong", "kaitouzimu": "nbd", "code": "NVH" },
        { "id": "01459", "name": "宁波", "pinyin": "ningbo", "kaitouzimu": "nb", "code": "NGH" },
        { "id": "01460", "name": "南博山", "pinyin": "nanboshan", "kaitouzimu": "nbs", "code": "NBK" },
        { "id": "01461", "name": "南部", "pinyin": "nanbu", "kaitouzimu": "nb", "code": "NBE" },
        { "id": "01462", "name": "南曹", "pinyin": "nancao", "kaitouzimu": "nc", "code": "NEF" },
        { "id": "01463", "name": "南充北", "pinyin": "nanchongbei", "kaitouzimu": "ncb", "code": "NCE" },
        { "id": "01464", "name": "南 昌", "pinyin": "nanchang", "kaitouzimu": "nc", "code": "NOG" },
        { "id": "01465", "name": "南岔", "pinyin": "nancha", "kaitouzimu": "nc", "code": "NCB" },
        { "id": "01466", "name": "南昌", "pinyin": "nanchang", "kaitouzimu": "nc", "code": "NCG" },
        { "id": "01467", "name": "南城", "pinyin": "nancheng", "kaitouzimu": "nc", "code": "NDG" },
        { "id": "01468", "name": "南充", "pinyin": "nanchong", "kaitouzimu": "nc", "code": "NCW" },
        { "id": "01469", "name": "南仇", "pinyin": "nanqiu", "kaitouzimu": "nc", "code": "NCK" },
        { "id": "01470", "name": "南城司", "pinyin": "nanchengsi", "kaitouzimu": "ncs", "code": "NSP" },
        { "id": "01471", "name": "宁村", "pinyin": "ningcun", "kaitouzimu": "nc", "code": "NCZ" },
        { "id": "01472", "name": "南昌西", "pinyin": "nanchangxi", "kaitouzimu": "ncx", "code": "NXG" },
        { "id": "01473", "name": "南丹", "pinyin": "nandan", "kaitouzimu": "nd", "code": "NDZ" },
        { "id": "01474", "name": "宁德", "pinyin": "ningde", "kaitouzimu": "nd", "code": "NES" },
        { "id": "01475", "name": "南大庙", "pinyin": "nandamiao", "kaitouzimu": "ndm", "code": "NMP" },
        { "id": "01476", "name": "宁东南", "pinyin": "ningdongnan", "kaitouzimu": "ndn", "code": "NDJ" },
        { "id": "01477", "name": "宁东", "pinyin": "ningdong", "kaitouzimu": "nd", "code": "NOJ" },
        { "id": "01478", "name": "南芬北", "pinyin": "nanfenbei", "kaitouzimu": "nfb", "code": "NUT" },
        { "id": "01479", "name": "南芬", "pinyin": "nanfen", "kaitouzimu": "nf", "code": "NFT" },
        { "id": "01480", "name": "南丰", "pinyin": "nanfeng", "kaitouzimu": "nf", "code": "NFG" },
        { "id": "01481", "name": "南观村", "pinyin": "nanguancun", "kaitouzimu": "ngc", "code": "NGP" },
        { "id": "01482", "name": "南宫东", "pinyin": "nangongdong", "kaitouzimu": "ngd", "code": "NFP" },
        { "id": "01483", "name": "南关岭", "pinyin": "nanguanling", "kaitouzimu": "ngl", "code": "NLT" },
        { "id": "01484", "name": "宁国", "pinyin": "ningguo", "kaitouzimu": "ng", "code": "NNH" },
        { "id": "01485", "name": "宁海", "pinyin": "ninghai", "kaitouzimu": "nh", "code": "NHH" },
        { "id": "01486", "name": "南华北", "pinyin": "nanhuabei", "kaitouzimu": "nhb", "code": "NHS" },
        { "id": "01487", "name": "南河川", "pinyin": "nanhechuan", "kaitouzimu": "nhc", "code": "NHJ" },
        { "id": "01488", "name": "南湖东", "pinyin": "nanhudong", "kaitouzimu": "nhd", "code": "NDN" },
        { "id": "01489", "name": "讷河", "pinyin": "nehe", "kaitouzimu": "nh", "code": "NHX" },
        { "id": "01490", "name": "牛河梁", "pinyin": "niuheliang", "kaitouzimu": "nhl", "code": "LKT" },
        { "id": "01491", "name": "南华", "pinyin": "nanhua", "kaitouzimu": "nh", "code": "NHS" },
        { "id": "01492", "name": "泥河子", "pinyin": "nihezi", "kaitouzimu": "nhz", "code": "NHD" },
        { "id": "01493", "name": "宁强南", "pinyin": "ningqiangnan", "kaitouzimu": "nqn", "code": "NOY" },
        { "id": "01494", "name": "内江北", "pinyin": "neijiangbei", "kaitouzimu": "njb", "code": "NKW" },
        { "id": "01495", "name": "南江", "pinyin": "nanjiang", "kaitouzimu": "nj", "code": "FIW" },
        { "id": "01496", "name": "南京", "pinyin": "nanjing", "kaitouzimu": "nj", "code": "NJH" },
        { "id": "01497", "name": "南靖", "pinyin": "nanjing", "kaitouzimu": "nj", "code": "NJS" },
        { "id": "01498", "name": "内江", "pinyin": "neijiang", "kaitouzimu": "nj", "code": "NJW" },
        { "id": "01499", "name": "嫩江", "pinyin": "nenjiang", "kaitouzimu": "nj", "code": "NGX" },
        { "id": "01500", "name": "能家", "pinyin": "nengjia", "kaitouzimu": "nj", "code": "NJD" },
        { "id": "01501", "name": "宁家", "pinyin": "ningjia", "kaitouzimu": "nj", "code": "NVT" },
        { "id": "01502", "name": "牛家", "pinyin": "niujia", "kaitouzimu": "nj", "code": "NJB" },
        { "id": "01503", "name": "南江口", "pinyin": "nanjiangkou", "kaitouzimu": "nj", "code": "NDQ" },
        { "id": "01504", "name": "南京南", "pinyin": "nanjingnan", "kaitouzimu": "njn", "code": "NKH" },
        { "id": "01505", "name": "南口", "pinyin": "nankou", "kaitouzimu": "nk", "code": "NKP" },
        { "id": "01506", "name": "南口前", "pinyin": "nankouqian", "kaitouzimu": "nkq", "code": "NKT" },
        { "id": "01507", "name": "南朗", "pinyin": "nanlang", "kaitouzimu": "nl", "code": "NNQ" },
        { "id": "01508", "name": "奈林皋", "pinyin": "nailingao", "kaitouzimu": "nlg", "code": "NGT" },
        { "id": "01509", "name": "乃林", "pinyin": "nailin", "kaitouzimu": "nl", "code": "NLD" },
        { "id": "01510", "name": "南陵", "pinyin": "nanling", "kaitouzimu": "nl", "code": "LLH" },
        { "id": "01511", "name": "尼勒克", "pinyin": "nileke", "kaitouzimu": "nlk", "code": "NIR" },
        { "id": "01512", "name": "那罗", "pinyin": "naluo", "kaitouzimu": "nl", "code": "ULZ" },
        { "id": "01513", "name": "宁陵县", "pinyin": "ninglingxian", "kaitouzimu": "nlx", "code": "NLF" },
        { "id": "01514", "name": "奈曼", "pinyin": "naiman", "kaitouzimu": "nm", "code": "NMD" },
        { "id": "01515", "name": "宁明", "pinyin": "ningming", "kaitouzimu": "nm", "code": "NMZ" },
        { "id": "01516", "name": "南木", "pinyin": "nanmu", "kaitouzimu": "nm", "code": "NMX" },
        { "id": "01517", "name": "尼木", "pinyin": "nimu", "kaitouzimu": "nm", "code": "NMO" },
        { "id": "01518", "name": "南宁东", "pinyin": "nanningdong", "kaitouzimu": "nnd", "code": "NFZ" },
        { "id": "01519", "name": "南宁", "pinyin": "nanning", "kaitouzimu": "nn", "code": "NNZ" },
        { "id": "01520", "name": "南宁西", "pinyin": "nanningxi", "kaitouzimu": "nnx", "code": "NXZ" },
        { "id": "01521", "name": "南堡北", "pinyin": "nanpubei", "kaitouzimu": "npb", "code": "TLP" },
        { "id": "01522", "name": "南平北", "pinyin": "nanpingbei", "kaitouzimu": "npb", "code": "NBS" },
        { "id": "01523", "name": "南平", "pinyin": "nanping", "kaitouzimu": "np", "code": "NPS" },
        { "id": "01524", "name": "南平南", "pinyin": "nanpingnan", "kaitouzimu": "npn", "code": "NNS" },
        { "id": "01525", "name": "那铺", "pinyin": "napu", "kaitouzimu": "np", "code": "NPZ" },
        { "id": "01526", "name": "南桥", "pinyin": "nanqiao", "kaitouzimu": "nq", "code": "NQD" },
        { "id": "01527", "name": "那曲", "pinyin": "naqu", "kaitouzimu": "nq", "code": "NQO" },
        { "id": "01528", "name": "暖泉", "pinyin": "nuanquan", "kaitouzimu": "nq", "code": "NQJ" },
        { "id": "01529", "name": "南台", "pinyin": "nantai", "kaitouzimu": "nt", "code": "NTT" },
        { "id": "01530", "name": "南通", "pinyin": "nantong", "kaitouzimu": "nt", "code": "NUH" },
        { "id": "01531", "name": "南头", "pinyin": "nantou", "kaitouzimu": "nt", "code": "NOQ" },
        { "id": "01532", "name": "宁武", "pinyin": "ningwu", "kaitouzimu": "nw", "code": "NWV" },
        { "id": "01533", "name": "南湾子", "pinyin": "nanwanzi", "kaitouzimu": "nwz", "code": "NWP" },
        { "id": "01534", "name": "南翔北", "pinyin": "nanxiangbei", "kaitouzimu": "nxb", "code": "NEH" },
        { "id": "01535", "name": "南雄", "pinyin": "nanxiong", "kaitouzimu": "nx", "code": "NCQ" },
        { "id": "01536", "name": "内乡", "pinyin": "neixiang", "kaitouzimu": "nx", "code": "NXF" },
        { "id": "01537", "name": "宁乡", "pinyin": "ningxiang", "kaitouzimu": "nx", "code": "NXQ" },
        { "id": "01538", "name": "牛心台", "pinyin": "niuxintai", "kaitouzimu": "nxt", "code": "NXT" },
        { "id": "01539", "name": "南阳", "pinyin": "nanyang", "kaitouzimu": "ny", "code": "NFF" },
        { "id": "01540", "name": "纳雍", "pinyin": "nayong", "kaitouzimu": "ny", "code": "NYE" },
        { "id": "01541", "name": "南峪", "pinyin": "nanyu", "kaitouzimu": "ny", "code": "NUP" },
        { "id": "01542", "name": "南阳寨", "pinyin": "nanyangzhai", "kaitouzimu": "nyz", "code": "NYF" },
        { "id": "01543", "name": "娘子关", "pinyin": "niangziguan", "kaitouzimu": "nzg", "code": "NIP" },
        { "id": "01544", "name": "南召", "pinyin": "nanzhao", "kaitouzimu": "nz", "code": "NAF" },
        { "id": "01545", "name": "南杂木", "pinyin": "nanzamu", "kaitouzimu": "nzm", "code": "NZT" },
        { "id": "01546", "name": "碾子山", "pinyin": "nianzishan", "kaitouzimu": "nzs", "code": "NZX" },],
    O: [],
    P: [
        { "id": "01547", "name": "蓬安", "pinyin": "pengan", "kaitouzimu": "pa", "code": "PAW" },
        { "id": "01548", "name": "平安", "pinyin": "pingan", "kaitouzimu": "pa", "code": "PAL" },
        { "id": "01549", "name": "普安", "pinyin": "puan", "kaitouzimu": "pa", "code": "PAN" },
        { "id": "01550", "name": "盘州", "pinyin": "panzhou", "kaitouzimu": "pz", "code": "PAE" },
        { "id": "01551", "name": "平安驿", "pinyin": "pinganyi", "kaitouzimu": "pay", "code": "PNO" },
        { "id": "01552", "name": "磐安镇", "pinyin": "pananzhen", "kaitouzimu": "paz", "code": "PAJ" },
        { "id": "01553", "name": "平安镇", "pinyin": "pinganzhen", "kaitouzimu": "paz", "code": "PZT" },
        { "id": "01554", "name": "屏边", "pinyin": "pingbian", "kaitouzimu": "pb", "code": "PBM" },
        { "id": "01555", "name": "蒲城东", "pinyin": "puchengdong", "kaitouzimu": "pcd", "code": "PEY" },
        { "id": "01556", "name": "平昌", "pinyin": "pingchang", "kaitouzimu": "pc", "code": "PCE" },
        { "id": "01557", "name": "蒲城", "pinyin": "pucheng", "kaitouzimu": "pc", "code": "PCY" },
        { "id": "01558", "name": "裴德", "pinyin": "peide", "kaitouzimu": "pd", "code": "PDB" },
        { "id": "01559", "name": "偏店", "pinyin": "piandian", "kaitouzimu": "pd", "code": "PRP" },
        { "id": "01560", "name": "普定", "pinyin": "puding", "kaitouzimu": "pd", "code": "PGW" },
        { "id": "01561", "name": "平顶山", "pinyin": "pingdingshan", "kaitouzimu": "pds", "code": "PEN" },
        { "id": "01562", "name": "平度", "pinyin": "pingdu", "kaitouzimu": "pd", "code": "PAK" },
        { "id": "01563", "name": "平顶山西", "pinyin": "pingdingshanxi", "kaitouzimu": "pdsx", "code": "BFF" },
        { "id": "01564", "name": "坡底下", "pinyin": "podixia", "kaitouzimu": "pdx", "code": "PXJ" },
        { "id": "01565", "name": "瓢儿屯", "pinyin": "piaoertun", "kaitouzimu": "pet", "code": "PRT" },
        { "id": "01566", "name": "平房", "pinyin": "pingfang", "kaitouzimu": "pf", "code": "PFB" },
        { "id": "01567", "name": "平岗", "pinyin": "pinggang", "kaitouzimu": "pg", "code": "PGL" },
        { "id": "01568", "name": "盘关", "pinyin": "panguan", "kaitouzimu": "pg", "code": "PAM" },
        { "id": "01569", "name": "平关", "pinyin": "pingguan", "kaitouzimu": "pg", "code": "PGM" },
        { "id": "01570", "name": "平果", "pinyin": "pingguo", "kaitouzimu": "pg", "code": "PGZ" },
        { "id": "01571", "name": "徘徊北", "pinyin": "paihuaibei", "kaitouzimu": "phb", "code": "PHP" },
        { "id": "01572", "name": "平河口", "pinyin": "pinghekou", "kaitouzimu": "phk", "code": "PHM" },
        { "id": "01573", "name": "平坝南", "pinyin": "pingbanan", "kaitouzimu": "pbn", "code": "PBE" },
        { "id": "01574", "name": "平湖", "pinyin": "pinghu", "kaitouzimu": "ph", "code": "PHQ" },
        { "id": "01575", "name": "盘锦北", "pinyin": "panjinbei", "kaitouzimu": "pjb", "code": "PBD" },
        { "id": "01576", "name": "潘家店", "pinyin": "panjiadian", "kaitouzimu": "pjd", "code": "PDP" },
        { "id": "01577", "name": "盘锦", "pinyin": "panjin", "kaitouzimu": "pj", "code": "PVD" },
        { "id": "01578", "name": "蒲江", "pinyin": "pujiang", "kaitouzimu": "pj", "code": "PJE" },
        { "id": "01579", "name": "皮口南", "pinyin": "pikounan", "kaitouzimu": "pk", "code": "PKT" },
        { "id": "01580", "name": "皮口", "pinyin": "pikou", "kaitouzimu": "pk", "code": "PUT" },
        { "id": "01581", "name": "盘龙城", "pinyin": "panlongcheng", "kaitouzimu": "plc", "code": "PNN" },
        { "id": "01582", "name": "普兰店", "pinyin": "pulandian", "kaitouzimu": "pld", "code": "PLT" },
        { "id": "01583", "name": "偏岭", "pinyin": "pianling", "kaitouzimu": "pl", "code": "PNT" },
        { "id": "01584", "name": "平凉", "pinyin": "pingliang", "kaitouzimu": "pl", "code": "PIJ" },
        { "id": "01585", "name": "平凉南", "pinyin": "pingliangnan", "kaitouzimu": "pln", "code": "POJ" },
        { "id": "01586", "name": "蓬莱市", "pinyin": "penglaishi", "kaitouzimu": "pls", "code": "POK" },
        { "id": "01587", "name": "普宁", "pinyin": "puning", "kaitouzimu": "pn", "code": "PEQ" },
        { "id": "01588", "name": "平南南", "pinyin": "pingnannan", "kaitouzimu": "pn", "code": "PAZ" },
        { "id": "01589", "name": "鄱阳", "pinyin": "poyang", "kaitouzimu": "py", "code": "PYG" },
        { "id": "01590", "name": "平泉北", "pinyin": "pingquanbei", "kaitouzimu": "pqb", "code": "PBP" },
        { "id": "01591", "name": "平泉", "pinyin": "pingquan", "kaitouzimu": "pq", "code": "PQP" },
        { "id": "01592", "name": "彭山北", "pinyin": "pengshanbei", "kaitouzimu": "psb", "code": "PPW" },
        { "id": "01593", "name": "盘山", "pinyin": "panshan", "kaitouzimu": "ps", "code": "PUD" },
        { "id": "01594", "name": "磐石", "pinyin": "panshi", "kaitouzimu": "ps", "code": "PSL" },
        { "id": "01595", "name": "彭山", "pinyin": "pengshan", "kaitouzimu": "ps", "code": "PSW" },
        { "id": "01596", "name": "彭水", "pinyin": "pengshui", "kaitouzimu": "ps", "code": "PHW" },
        { "id": "01597", "name": "皮山", "pinyin": "pishan", "kaitouzimu": "ps", "code": "PSR" },
        { "id": "01598", "name": "平山", "pinyin": "pingshan", "kaitouzimu": "ps", "code": "PSB" },
        { "id": "01599", "name": "平社", "pinyin": "pingshe", "kaitouzimu": "ps", "code": "PSV" },
        { "id": "01600", "name": "坪上", "pinyin": "pingshang", "kaitouzimu": "ps", "code": "PSK" },
        { "id": "01601", "name": "坪石", "pinyin": "pingshi", "kaitouzimu": "ps", "code": "PSQ" },
        { "id": "01602", "name": "平台", "pinyin": "pingtai", "kaitouzimu": "pt", "code": "PVT" },
        { "id": "01603", "name": "平田", "pinyin": "pingtian", "kaitouzimu": "pt", "code": "PTM" },
        { "id": "01604", "name": "莆田", "pinyin": "putian", "kaitouzimu": "pt", "code": "PTS" },
        { "id": "01605", "name": "葡萄菁", "pinyin": "putaojing", "kaitouzimu": "ptj", "code": "PTW" },
        { "id": "01606", "name": "普安县", "pinyin": "puanxian", "kaitouzimu": "pax", "code": "PUE" },
        { "id": "01607", "name": "普者黑", "pinyin": "puzhehei", "kaitouzimu": "pzh", "code": "PZM" },
        { "id": "01608", "name": "平旺", "pinyin": "pingwang", "kaitouzimu": "pw", "code": "PWV" },
        { "id": "01609", "name": "普湾", "pinyin": "puwan", "kaitouzimu": "pw", "code": "PWT" },
        { "id": "01610", "name": "萍乡北", "pinyin": "pingxiangbei", "kaitouzimu": "pxb", "code": "PBG" },
        { "id": "01611", "name": "平型关", "pinyin": "pingxingguan", "kaitouzimu": "pxg", "code": "PGV" },
        { "id": "01612", "name": "蓬溪", "pinyin": "pengxi", "kaitouzimu": "px", "code": "KZW" },
        { "id": "01613", "name": "郫县", "pinyin": "pixian", "kaitouzimu": "px", "code": "PWW" },
        { "id": "01614", "name": "凭祥", "pinyin": "pingxiang", "kaitouzimu": "px", "code": "PXZ" },
        { "id": "01615", "name": "萍乡", "pinyin": "pingxiang", "kaitouzimu": "px", "code": "PXG" },
        { "id": "01616", "name": "普雄", "pinyin": "puxiong", "kaitouzimu": "px", "code": "POW" },
        { "id": "01617", "name": "郫县西", "pinyin": "pixianxi", "kaitouzimu": "pxx", "code": "PCW" },
        { "id": "01618", "name": "彭阳", "pinyin": "pengyang", "kaitouzimu": "py", "code": "PYJ" },
        { "id": "01619", "name": "平洋", "pinyin": "pingyang", "kaitouzimu": "py", "code": "PYX" },
        { "id": "01620", "name": "平遥", "pinyin": "pingyao", "kaitouzimu": "py", "code": "PYV" },
        { "id": "01621", "name": "濮阳", "pinyin": "puyang", "kaitouzimu": "py", "code": "PYF" },
        { "id": "01622", "name": "平遥古城", "pinyin": "pingyaogucheng", "kaitouzimu": "pygc", "code": "PDV" },
        { "id": "01623", "name": "平原东", "pinyin": "pingyuandong", "kaitouzimu": "pyd", "code": "PUK" },
        { "id": "01624", "name": "平邑", "pinyin": "pingyi", "kaitouzimu": "py", "code": "PIK" },
        { "id": "01625", "name": "平原堡", "pinyin": "pingyuanpu", "kaitouzimu": "pyp", "code": "PPJ" },
        { "id": "01626", "name": "平峪", "pinyin": "pingyu", "kaitouzimu": "py", "code": "PYP" },
        { "id": "01627", "name": "平原", "pinyin": "pingyuan", "kaitouzimu": "py", "code": "PYK" },
        { "id": "01628", "name": "彭泽", "pinyin": "pengze", "kaitouzimu": "pz", "code": "PZG" },
        { "id": "01629", "name": "攀枝花", "pinyin": "panzhihua", "kaitouzimu": "pzh", "code": "PRW" },
        { "id": "01630", "name": "彭州", "pinyin": "pengzhou", "kaitouzimu": "pz", "code": "PMW" },
        { "id": "01631", "name": "邳州", "pinyin": "pizhou", "kaitouzimu": "pz", "code": "PJH" },
        { "id": "01632", "name": "平庄", "pinyin": "pingzhuang", "kaitouzimu": "pz", "code": "PZD" },
        { "id": "01633", "name": "泡子", "pinyin": "paozi", "kaitouzimu": "pz", "code": "POD" },
        { "id": "01634", "name": "平庄南", "pinyin": "pingzhuangnan", "kaitouzimu": "pzn", "code": "PND" },],
    Q: [
        { "id": "01635", "name": "迁安", "pinyin": "qianan", "kaitouzimu": "qa", "code": "QQP" },
        { "id": "01636", "name": "乾安", "pinyin": "qianan", "kaitouzimu": "qa", "code": "QOT" },
        { "id": "01637", "name": "庆安", "pinyin": "qingan", "kaitouzimu": "qa", "code": "QAB" },
        { "id": "01638", "name": "青白江东", "pinyin": "qingbaijiangdong", "kaitouzimu": "qbjd", "code": "QFW" },
        { "id": "01639", "name": "蕲春", "pinyin": "qichun", "kaitouzimu": "qc", "code": "QRN" },
        { "id": "01640", "name": "青城山", "pinyin": "qingchengshan", "kaitouzimu": "qcs", "code": "QSW" },
        { "id": "01641", "name": "青岛", "pinyin": "qingdao", "kaitouzimu": "qd", "code": "QDK" },
        { "id": "01642", "name": "祁东北", "pinyin": "qidongbei", "kaitouzimu": "qd", "code": "QRQ" },
        { "id": "01643", "name": "青岛北", "pinyin": "qingdaobei", "kaitouzimu": "qdb", "code": "QHK" },
        { "id": "01644", "name": "千岛湖", "pinyin": "qiandaohu", "kaitouzimu": "qdh", "code": "QDU" },
        { "id": "01645", "name": "七甸", "pinyin": "qidian", "kaitouzimu": "qd", "code": "QDM" },
        { "id": "01646", "name": "祁东", "pinyin": "qidong", "kaitouzimu": "qd", "code": "QMQ" },
        { "id": "01647", "name": "启东", "pinyin": "qidong", "kaitouzimu": "qd", "code": "QOU" },
        { "id": "01648", "name": "青堆", "pinyin": "qingdui", "kaitouzimu": "qd", "code": "QET" },
        { "id": "01649", "name": "青岛西", "pinyin": "qingdaoxi", "kaitouzimu": "qdx", "code": "QUK" },
        { "id": "01650", "name": "曲阜东", "pinyin": "qufudong", "kaitouzimu": "qfd", "code": "QAK" },
        { "id": "01651", "name": "前锋", "pinyin": "qianfeng", "kaitouzimu": "qf", "code": "QFB" },
        { "id": "01652", "name": "庆丰", "pinyin": "qingfeng", "kaitouzimu": "qf", "code": "QFT" },
        { "id": "01653", "name": "奇峰塔", "pinyin": "qifengta", "kaitouzimu": "qft", "code": "QVP" },
        { "id": "01654", "name": "曲阜", "pinyin": "qufu", "kaitouzimu": "qf", "code": "QFK" },
        { "id": "01655", "name": "琼海", "pinyin": "qionghai", "kaitouzimu": "qh", "code": "QYQ" },
        { "id": "01656", "name": "清河门北", "pinyin": "qinghemenbei", "kaitouzimu": "qhmb", "code": "QBD" },
        { "id": "01657", "name": "清河城", "pinyin": "qinghecheng", "kaitouzimu": "qhc", "code": "QYP" },
        { "id": "01658", "name": "秦皇岛", "pinyin": "qinhuangdao", "kaitouzimu": "qhd", "code": "QTP" },
        { "id": "01659", "name": "齐河", "pinyin": "qihe", "kaitouzimu": "qh", "code": "QIK" },
        { "id": "01660", "name": "千河", "pinyin": "qianhe", "kaitouzimu": "qh", "code": "QUY" },
        { "id": "01661", "name": "清河", "pinyin": "qinghe", "kaitouzimu": "qh", "code": "QIP" },
        { "id": "01662", "name": "清河门", "pinyin": "qinghemen", "kaitouzimu": "qhm", "code": "QHD" },
        { "id": "01663", "name": "清华园", "pinyin": "qinghuayuan", "kaitouzimu": "qhy", "code": "QHP" },
        { "id": "01664", "name": "綦江东", "pinyin": "qijiangdong", "kaitouzimu": "qjd", "code": "QDE" },
        { "id": "01665", "name": "秦安", "pinyin": "qinan", "kaitouzimu": "qa", "code": "QGJ" },
        { "id": "01666", "name": "青川", "pinyin": "qingchuan", "kaitouzimu": "qc", "code": "QCE" },
        { "id": "01667", "name": "綦江", "pinyin": "qijiang", "kaitouzimu": "qj", "code": "QJW" },
        { "id": "01668", "name": "潜江", "pinyin": "qianjiang", "kaitouzimu": "qj", "code": "QJN" },
        { "id": "01669", "name": "黔江", "pinyin": "qianjiang", "kaitouzimu": "qj", "code": "QNW" },
        { "id": "01670", "name": "秦家", "pinyin": "qinjia", "kaitouzimu": "qj", "code": "QJB" },
        { "id": "01671", "name": "渠旧", "pinyin": "qujiu", "kaitouzimu": "qj", "code": "QJZ" },
        { "id": "01672", "name": "曲江", "pinyin": "qujiang", "kaitouzimu": "qj", "code": "QIM" },
        { "id": "01673", "name": "曲靖", "pinyin": "qujing", "kaitouzimu": "qj", "code": "QJM" },
        { "id": "01674", "name": "全椒", "pinyin": "quanjiao", "kaitouzimu": "qj", "code": "INH" },
        { "id": "01675", "name": "祁家堡", "pinyin": "qijiapu", "kaitouzimu": "qjb", "code": "QBT" },
        { "id": "01676", "name": "清涧县", "pinyin": "qingjianxian", "kaitouzimu": "qjx", "code": "QNY" },
        { "id": "01677", "name": "前进镇", "pinyin": "qianjinzhen", "kaitouzimu": "qjz", "code": "QEB" },
        { "id": "01678", "name": "秦家庄", "pinyin": "qinjiazhuang", "kaitouzimu": "qjz", "code": "QZV" },
        { "id": "01679", "name": "邛崃", "pinyin": "qionglai", "kaitouzimu": "ql", "code": "QLE" },
        { "id": "01680", "name": "七里河", "pinyin": "qilihe", "kaitouzimu": "qlh", "code": "QLD" },
        { "id": "01681", "name": "秦岭", "pinyin": "qinling", "kaitouzimu": "ql", "code": "QLY" },
        { "id": "01682", "name": "青莲", "pinyin": "qinglian", "kaitouzimu": "ql", "code": "QEW" },
        { "id": "01683", "name": "渠黎", "pinyin": "quli", "kaitouzimu": "ql", "code": "QLZ" },
        { "id": "01684", "name": "青龙", "pinyin": "qinglong", "kaitouzimu": "ql", "code": "QIB" },
        { "id": "01685", "name": "青龙山", "pinyin": "qinglongshan", "kaitouzimu": "qls", "code": "QGH" },
        { "id": "01686", "name": "祁门", "pinyin": "qimen", "kaitouzimu": "qm", "code": "QIH" },
        { "id": "01687", "name": "前磨头", "pinyin": "qianmotou", "kaitouzimu": "qmt", "code": "QMP" },
        { "id": "01688", "name": "齐齐哈尔", "pinyin": "qiqihaer", "kaitouzimu": "qqhe", "code": "QHX" },
        { "id": "01689", "name": "齐齐哈尔南", "pinyin": "qiqihaernan", "kaitouzimu": "qqhen", "code": "QNB" },
        { "id": "01690", "name": "清水北", "pinyin": "qingshuibei", "kaitouzimu": "qsb", "code": "QEJ" },
        { "id": "01691", "name": "岐山", "pinyin": "qishan", "kaitouzimu": "qs", "code": "QAY" },
        { "id": "01692", "name": "前山", "pinyin": "qianshan", "kaitouzimu": "qs", "code": "QXQ" },
        { "id": "01693", "name": "青山", "pinyin": "qingshan", "kaitouzimu": "qs", "code": "QSB" },
        { "id": "01694", "name": "青神", "pinyin": "qingshen", "kaitouzimu": "qs", "code": "QVW" },
        { "id": "01695", "name": "清水", "pinyin": "qingshui", "kaitouzimu": "qs", "code": "QUJ" },
        { "id": "01696", "name": "庆盛", "pinyin": "qingsheng", "kaitouzimu": "qs", "code": "QSQ" },
        { "id": "01697", "name": "确山", "pinyin": "queshan", "kaitouzimu": "qs", "code": "QSN" },
        { "id": "01698", "name": "清水县", "pinyin": "qingshuixian", "kaitouzimu": "qsx", "code": "QIJ" },
        { "id": "01699", "name": "曲水县", "pinyin": "qushuixian", "kaitouzimu": "qsx", "code": "QSO" },
        { "id": "01700", "name": "戚墅堰", "pinyin": "qishuyan", "kaitouzimu": "qsy", "code": "QYH" },
        { "id": "01701", "name": "七台河", "pinyin": "qitaihe", "kaitouzimu": "qth", "code": "QTB" },
        { "id": "01702", "name": "青田", "pinyin": "qingtian", "kaitouzimu": "qt", "code": "QVH" },
        { "id": "01703", "name": "桥头", "pinyin": "qiaotou", "kaitouzimu": "qt", "code": "QAT" },
        { "id": "01704", "name": "青铜峡", "pinyin": "qingtongxia", "kaitouzimu": "qtx", "code": "QTJ" },
        { "id": "01705", "name": "曲靖北", "pinyin": "qujingbei", "kaitouzimu": "qjb", "code": "QBM" },
        { "id": "01706", "name": "前卫", "pinyin": "qianwei", "kaitouzimu": "qw", "code": "QWD" },
        { "id": "01707", "name": "前苇塘", "pinyin": "qianweitang", "kaitouzimu": "qwt", "code": "QWP" },
        { "id": "01708", "name": "祁县东", "pinyin": "qixiandong", "kaitouzimu": "qxd", "code": "QGV" },
        { "id": "01709", "name": "祁县", "pinyin": "qixian", "kaitouzimu": "qx", "code": "QXV" },
        { "id": "01710", "name": "乾县", "pinyin": "qianxian", "kaitouzimu": "qx", "code": "QBY" },
        { "id": "01711", "name": "桥西", "pinyin": "qiaoxi", "kaitouzimu": "qx", "code": "QXJ" },
        { "id": "01712", "name": "沁县", "pinyin": "qinxian", "kaitouzimu": "qx", "code": "QVV" },
        { "id": "01713", "name": "青县", "pinyin": "qingxian", "kaitouzimu": "qx", "code": "QXP" },
        { "id": "01714", "name": "渠县", "pinyin": "quxian", "kaitouzimu": "qx", "code": "QRW" },
        { "id": "01715", "name": "旗下营南", "pinyin": "qixiayingnan", "kaitouzimu": "qxyn", "code": "QNC" },
        { "id": "01716", "name": "清徐", "pinyin": "qingxu", "kaitouzimu": "qx", "code": "QUV" },
        { "id": "01717", "name": "旗下营", "pinyin": "qixiaying", "kaitouzimu": "qxy", "code": "QXC" },
        { "id": "01718", "name": "祁阳", "pinyin": "qiyang", "kaitouzimu": "qy", "code": "QWQ" },
        { "id": "01719", "name": "千阳", "pinyin": "qianyang", "kaitouzimu": "qy", "code": "QOY" },
        { "id": "01720", "name": "沁阳", "pinyin": "qinyang", "kaitouzimu": "qy", "code": "QYF" },
        { "id": "01721", "name": "泉阳", "pinyin": "quanyang", "kaitouzimu": "qy", "code": "QYL" },
        { "id": "01722", "name": "祁阳北", "pinyin": "qiyangbei", "kaitouzimu": "qy", "code": "QVQ" },
        { "id": "01723", "name": "七营", "pinyin": "qiying", "kaitouzimu": "qy", "code": "QYJ" },
        { "id": "01724", "name": "庆阳山", "pinyin": "qingyangshan", "kaitouzimu": "qys", "code": "QSJ" },
        { "id": "01725", "name": "清原", "pinyin": "qingyuan", "kaitouzimu": "qy", "code": "QYT" },
        { "id": "01726", "name": "清远", "pinyin": "qingyuan", "kaitouzimu": "qy", "code": "QBQ" },
        { "id": "01727", "name": "青州市北", "pinyin": "qingzhoushibei", "kaitouzimu": "qzsb", "code": "QOK" },
        { "id": "01728", "name": "钦州东", "pinyin": "qinzhoudong", "kaitouzimu": "qzd", "code": "QDZ" },
        { "id": "01729", "name": "泉州东", "pinyin": "quanzhoudong", "kaitouzimu": "qzd", "code": "QRS" },
        { "id": "01730", "name": "钦州", "pinyin": "qinzhou", "kaitouzimu": "qz", "code": "QRZ" },
        { "id": "01731", "name": "衢州", "pinyin": "quzhou", "kaitouzimu": "qz", "code": "QEH" },
        { "id": "01732", "name": "泉州", "pinyin": "quanzhou", "kaitouzimu": "qz", "code": "QYS" },
        { "id": "01733", "name": "全州南", "pinyin": "quanzhounan", "kaitouzimu": "qzn", "code": "QNZ" },
        { "id": "01734", "name": "青州市", "pinyin": "qingzhoushi", "kaitouzimu": "qzs", "code": "QZK" },
        { "id": "01735", "name": "棋子湾", "pinyin": "qiziwan", "kaitouzimu": "qzw", "code": "QZQ" },],
    R: [
        { "id": "01736", "name": "融安", "pinyin": "rongan", "kaitouzimu": "ra", "code": "RAZ" },
        { "id": "01737", "name": "瑞安", "pinyin": "ruian", "kaitouzimu": "ra", "code": "RAH" },
        { "id": "01738", "name": "仁布", "pinyin": "renbu", "kaitouzimu": "rb", "code": "RUO" },
        { "id": "01739", "name": "荣昌北", "pinyin": "rongchangbei", "kaitouzimu": "rcb", "code": "RQW" },
        { "id": "01740", "name": "荣昌", "pinyin": "rongchang", "kaitouzimu": "rc", "code": "RCW" },
        { "id": "01741", "name": "荣成", "pinyin": "rongcheng", "kaitouzimu": "rc", "code": "RCK" },
        { "id": "01742", "name": "瑞昌", "pinyin": "ruichang", "kaitouzimu": "rc", "code": "RCG" },
        { "id": "01743", "name": "如东", "pinyin": "rudong", "kaitouzimu": "rd", "code": "RIH" },
        { "id": "01744", "name": "如皋", "pinyin": "rugao", "kaitouzimu": "rg", "code": "RBH" },
        { "id": "01745", "name": "容桂", "pinyin": "ronggui", "kaitouzimu": "rg", "code": "RUQ" },
        { "id": "01746", "name": "汝箕沟", "pinyin": "rujigou", "kaitouzimu": "rqg", "code": "RQJ" },
        { "id": "01747", "name": "榕江", "pinyin": "rongjiang", "kaitouzimu": "rj", "code": "RVW" },
        { "id": "01748", "name": "瑞金", "pinyin": "ruijin", "kaitouzimu": "rj", "code": "RJG" },
        { "id": "01749", "name": "日喀则", "pinyin": "rikaze", "kaitouzimu": "rkz", "code": "RKO" },
        { "id": "01750", "name": "饶平", "pinyin": "raoping", "kaitouzimu": "rp", "code": "RVQ" },
        { "id": "01751", "name": "任丘", "pinyin": "renqiu", "kaitouzimu": "rq", "code": "RQP" },
        { "id": "01752", "name": "热水", "pinyin": "reshui", "kaitouzimu": "rs", "code": "RSD" },
        { "id": "01753", "name": "融水", "pinyin": "rongshui", "kaitouzimu": "rs", "code": "RSZ" },
        { "id": "01754", "name": "乳山", "pinyin": "rushan", "kaitouzimu": "rs", "code": "ROK" },
        { "id": "01755", "name": "瑞昌西", "pinyin": "ruichangxi", "kaitouzimu": "rcx", "code": "RXG" },
        { "id": "01756", "name": "容县", "pinyin": "rongxian", "kaitouzimu": "rx", "code": "RXZ" },
        { "id": "01757", "name": "饶阳", "pinyin": "raoyang", "kaitouzimu": "ry", "code": "RVP" },
        { "id": "01758", "name": "汝阳", "pinyin": "ruyang", "kaitouzimu": "ry", "code": "RYF" },
        { "id": "01759", "name": "绕阳河", "pinyin": "raoyanghe", "kaitouzimu": "ryh", "code": "RHD" },
        { "id": "01760", "name": "日照", "pinyin": "rizhao", "kaitouzimu": "rz", "code": "RZK" },
        { "id": "01761", "name": "汝州", "pinyin": "ruzhou", "kaitouzimu": "rz", "code": "ROF" },
        { "id": "01762", "name": "日照西", "pinyin": "rizhaoxi", "kaitouzimu": "rzx", "code": "KZK" },],
    S: [
        { "id": "01763", "name": "三  亚", "pinyin": "sanya", "kaitouzimu": "sya", "code": "JUQ" },
        { "id": "01764", "name": "石坝", "pinyin": "shiba", "kaitouzimu": "sb", "code": "OBJ" },
        { "id": "01765", "name": "上板城", "pinyin": "shangbancheng", "kaitouzimu": "sbc", "code": "SBP" },
        { "id": "01766", "name": "施秉", "pinyin": "shibing", "kaitouzimu": "sb", "code": "AQW" },
        { "id": "01767", "name": "上板城南", "pinyin": "shangbanchengnan", "kaitouzimu": "sbcn", "code": "OBP" },
        { "id": "01768", "name": "世博园", "pinyin": "shiboyuan", "kaitouzimu": "sby", "code": "ZWT" },
        { "id": "01769", "name": "双城北", "pinyin": "shuangchengbei", "kaitouzimu": "scb", "code": "SBB" },
        { "id": "01770", "name": "沙城", "pinyin": "shacheng", "kaitouzimu": "sc", "code": "SCP" },
        { "id": "01771", "name": "莎车", "pinyin": "shache", "kaitouzimu": "sc", "code": "SCR" },
        { "id": "01772", "name": "商城", "pinyin": "shangcheng", "kaitouzimu": "sc", "code": "SWN" },
        { "id": "01773", "name": "神池", "pinyin": "shenchi", "kaitouzimu": "sc", "code": "SMV" },
        { "id": "01774", "name": "石城", "pinyin": "shicheng", "kaitouzimu": "sc", "code": "SCT" },
        { "id": "01775", "name": "舒城", "pinyin": "shucheng", "kaitouzimu": "sc", "code": "OCH" },
        { "id": "01776", "name": "顺昌", "pinyin": "shunchang", "kaitouzimu": "sc", "code": "SCS" },
        { "id": "01777", "name": "宋城路", "pinyin": "songchenglu", "kaitouzimu": "scl", "code": "SFF" },
        { "id": "01778", "name": "双城堡", "pinyin": "shuangchengpu", "kaitouzimu": "scb", "code": "SCB" },
        { "id": "01779", "name": "山城镇", "pinyin": "shanchengzhen", "kaitouzimu": "scz", "code": "SCL" },
        { "id": "01780", "name": "山丹", "pinyin": "shandan", "kaitouzimu": "sd", "code": "SDJ" },
        { "id": "01781", "name": "顺德", "pinyin": "shunde", "kaitouzimu": "sd", "code": "ORQ" },
        { "id": "01782", "name": "绥德", "pinyin": "suide", "kaitouzimu": "sd", "code": "ODY" },
        { "id": "01783", "name": "三道湖", "pinyin": "sandaohu", "kaitouzimu": "sdh", "code": "SDL" },
        { "id": "01784", "name": "邵东", "pinyin": "shaodong", "kaitouzimu": "sd", "code": "FIQ" },
        { "id": "01785", "name": "水洞", "pinyin": "shuidong", "kaitouzimu": "sd", "code": "SIL" },
        { "id": "01786", "name": "商都", "pinyin": "shangdu", "kaitouzimu": "sd", "code": "SXC" },
        { "id": "01787", "name": "十渡", "pinyin": "shidu", "kaitouzimu": "sd", "code": "SEP" },
        { "id": "01788", "name": "四道湾", "pinyin": "sidaowan", "kaitouzimu": "sdw", "code": "OUD" },
        { "id": "01789", "name": "三都县", "pinyin": "sanduxian", "kaitouzimu": "sdx", "code": "KKW" },
        { "id": "01790", "name": "顺德学院", "pinyin": "shundexueyuan", "kaitouzimu": "sdxy", "code": "OJQ" },
        { "id": "01791", "name": "绅坊", "pinyin": "shenfang", "kaitouzimu": "sf", "code": "OLH" },
        { "id": "01792", "name": "胜芳", "pinyin": "shengfang", "kaitouzimu": "sf", "code": "SUP" },
        { "id": "01793", "name": "双峰北", "pinyin": "shuangfengbei", "kaitouzimu": "sfb", "code": "NFQ" },
        { "id": "01794", "name": "双丰", "pinyin": "shuangfeng", "kaitouzimu": "sf", "code": "OFB" },
        { "id": "01795", "name": "绥芬河", "pinyin": "suifenhe", "kaitouzimu": "sfh", "code": "SFB" },
        { "id": "01796", "name": "四方台", "pinyin": "sifangtai", "kaitouzimu": "sft", "code": "STB" },
        { "id": "01797", "name": "水富", "pinyin": "shuifu", "kaitouzimu": "sf", "code": "OTW" },
        { "id": "01798", "name": "韶关东", "pinyin": "shaoguandong", "kaitouzimu": "sgd", "code": "SGQ" },
        { "id": "01799", "name": "三关口", "pinyin": "sanguankou", "kaitouzimu": "sgk", "code": "OKJ" },
        { "id": "01800", "name": "桑根达来", "pinyin": "sanggendalai", "kaitouzimu": "sgdl", "code": "OGC" },
        { "id": "01801", "name": "韶关", "pinyin": "shaoguan", "kaitouzimu": "sg", "code": "SNQ" },
        { "id": "01802", "name": "上高镇", "pinyin": "shanggaozhen", "kaitouzimu": "sgz", "code": "SVK" },
        { "id": "01803", "name": "沙海", "pinyin": "shahai", "kaitouzimu": "sh", "code": "SED" },
        { "id": "01804", "name": "上海", "pinyin": "shanghai", "kaitouzimu": "sh", "code": "SHH" },
        { "id": "01805", "name": "上杭", "pinyin": "shanghang", "kaitouzimu": "sh", "code": "JBS" },
        { "id": "01806", "name": "沙岭子西", "pinyin": "shalingzixi", "kaitouzimu": "slzx", "code": "IXP" },
        { "id": "01807", "name": "绍兴东", "pinyin": "shaoxingdong", "kaitouzimu": "sxd", "code": "SSH" },
        { "id": "01808", "name": "沙坪坝", "pinyin": "shapingba", "kaitouzimu": "spb", "code": "CYW" },
        { "id": "01809", "name": "沙河", "pinyin": "shahe", "kaitouzimu": "sh", "code": "SHP" },
        { "id": "01810", "name": "商河", "pinyin": "shanghe", "kaitouzimu": "sh", "code": "SOK" },
        { "id": "01811", "name": "蜀河", "pinyin": "shuhe", "kaitouzimu": "sh", "code": "SHY" },
        { "id": "01812", "name": "松河", "pinyin": "songhe", "kaitouzimu": "sh", "code": "SBM" },
        { "id": "01813", "name": "沈阳西", "pinyin": "shenyangxi", "kaitouzimu": "syx", "code": "OOT" },
        { "id": "01814", "name": "山海关", "pinyin": "shanhaiguan", "kaitouzimu": "shg", "code": "SHD" },
        { "id": "01815", "name": "石林西", "pinyin": "shilinxi", "kaitouzimu": "slx", "code": "SYM" },
        { "id": "01816", "name": "沙河口", "pinyin": "shahekou", "kaitouzimu": "shk", "code": "SKT" },
        { "id": "01817", "name": "赛汗塔拉", "pinyin": "saihantala", "kaitouzimu": "shtl", "code": "SHC" },
        { "id": "01818", "name": "上海南", "pinyin": "shanghainan", "kaitouzimu": "shn", "code": "SNH" },
        { "id": "01819", "name": "泗洪", "pinyin": "sihong", "kaitouzimu": "sh", "code": "GQH" },
        { "id": "01820", "name": "上海虹桥", "pinyin": "shanghaihongqiao", "kaitouzimu": "shhq", "code": "AOH" },
        { "id": "01821", "name": "沙河市", "pinyin": "shaheshi", "kaitouzimu": "shs", "code": "VOP" },
        { "id": "01822", "name": "沙后所", "pinyin": "shahousuo", "kaitouzimu": "shs", "code": "SSD" },
        { "id": "01823", "name": "山河屯", "pinyin": "shanhetun", "kaitouzimu": "sht", "code": "SHL" },
        { "id": "01824", "name": "四会", "pinyin": "sihui", "kaitouzimu": "sh", "code": "AHQ" },
        { "id": "01825", "name": "绥化", "pinyin": "suihua", "kaitouzimu": "sh", "code": "SHB" },
        { "id": "01826", "name": "双吉", "pinyin": "shuangji", "kaitouzimu": "sj", "code": "SML" },
        { "id": "01827", "name": "树木岭", "pinyin": "shumuling", "kaitouzimu": "sml", "code": "FMQ" },
        { "id": "01828", "name": "三河县", "pinyin": "sanhexian", "kaitouzimu": "shx", "code": "OXP" },
        { "id": "01829", "name": "上海西", "pinyin": "shanghaixi", "kaitouzimu": "shx", "code": "SXH" },
        { "id": "01830", "name": "四合永", "pinyin": "siheyong", "kaitouzimu": "shy", "code": "OHD" },
        { "id": "01831", "name": "三合庄", "pinyin": "sanhezhuang", "kaitouzimu": "shz", "code": "SVP" },
        { "id": "01832", "name": "三汇镇", "pinyin": "sanhuizhen", "kaitouzimu": "shz", "code": "OZW" },
        { "id": "01833", "name": "石河子", "pinyin": "shihezi", "kaitouzimu": "shz", "code": "SZR" },
        { "id": "01834", "name": "双河镇", "pinyin": "shuanghezhen", "kaitouzimu": "shz", "code": "SEL" },
        { "id": "01835", "name": "石家庄北", "pinyin": "shijiazhuangbei", "kaitouzimu": "sjzb", "code": "VVP" },
        { "id": "01836", "name": "三家店", "pinyin": "sanjiadian", "kaitouzimu": "sjd", "code": "ODP" },
        { "id": "01837", "name": "石家庄东", "pinyin": "shijiazhuangdong", "kaitouzimu": "sjzd", "code": "SXP" },
        { "id": "01838", "name": "三间房", "pinyin": "sanjianfang", "kaitouzimu": "sjf", "code": "SFX" },
        { "id": "01839", "name": "沈家河", "pinyin": "shenjiahe", "kaitouzimu": "sjh", "code": "OJJ" },
        { "id": "01840", "name": "水家湖", "pinyin": "shuijiahu", "kaitouzimu": "sjh", "code": "SQH" },
        { "id": "01841", "name": "松江河", "pinyin": "songjianghe", "kaitouzimu": "sjh", "code": "SJL" },
        { "id": "01842", "name": "尚家", "pinyin": "shangjia", "kaitouzimu": "sj", "code": "SJB" },
        { "id": "01843", "name": "沈家", "pinyin": "shenjia", "kaitouzimu": "sj", "code": "OJB" },
        { "id": "01844", "name": "松江", "pinyin": "songjiang", "kaitouzimu": "sj", "code": "SAH" },
        { "id": "01845", "name": "孙家", "pinyin": "sunjia", "kaitouzimu": "sj", "code": "SUB" },
        { "id": "01846", "name": "三江口", "pinyin": "sanjiangkou", "kaitouzimu": "sjk", "code": "SKD" },
        { "id": "01847", "name": "司家岭", "pinyin": "sijialing", "kaitouzimu": "sjl", "code": "OLK" },
        { "id": "01848", "name": "三江南", "pinyin": "sanjiangnan", "kaitouzimu": "sjn", "code": "SWZ" },
        { "id": "01849", "name": "石景山南", "pinyin": "shijingshannan", "kaitouzimu": "sjsn", "code": "SRP" },
        { "id": "01850", "name": "松江南", "pinyin": "songjiangnan", "kaitouzimu": "sjn", "code": "IMH" },
        { "id": "01851", "name": "邵家堂", "pinyin": "shaojiatang", "kaitouzimu": "sjt", "code": "SJJ" },
        { "id": "01852", "name": "苏家屯", "pinyin": "sujiatun", "kaitouzimu": "sjt", "code": "SXT" },
        { "id": "01853", "name": "三江县", "pinyin": "sanjiangxian", "kaitouzimu": "sjx", "code": "SOZ" },
        { "id": "01854", "name": "三家寨", "pinyin": "sanjiazhai", "kaitouzimu": "sjz", "code": "SMM" },
        { "id": "01855", "name": "三井子", "pinyin": "sanjingzi", "kaitouzimu": "sjz", "code": "OJT" },
        { "id": "01856", "name": "深井子", "pinyin": "shenjingzi", "kaitouzimu": "sjz", "code": "SWT" },
        { "id": "01857", "name": "施家嘴", "pinyin": "shijiazui", "kaitouzimu": "sjz", "code": "SHM" },
        { "id": "01858", "name": "十家子", "pinyin": "shijiazi", "kaitouzimu": "sjz", "code": "SJD" },
        { "id": "01859", "name": "石家庄", "pinyin": "shijiazhuang", "kaitouzimu": "sjz", "code": "SJP" },
        { "id": "01860", "name": "松江镇", "pinyin": "songjiangzhen", "kaitouzimu": "sjz", "code": "OZL" },
        { "id": "01861", "name": "舒兰", "pinyin": "shulan", "kaitouzimu": "sl", "code": "SLL" },
        { "id": "01862", "name": "双流机场", "pinyin": "shuangliujichang", "kaitouzimu": "sljc", "code": "IPW" },
        { "id": "01863", "name": "什里店", "pinyin": "shilidian", "kaitouzimu": "sld", "code": "OMP" },
        { "id": "01864", "name": "疏勒", "pinyin": "shule", "kaitouzimu": "sl", "code": "SUR" },
        { "id": "01865", "name": "舍力虎", "pinyin": "shelihu", "kaitouzimu": "slh", "code": "VLD" },
        { "id": "01866", "name": "疏勒河", "pinyin": "shulehe", "kaitouzimu": "slh", "code": "SHJ" },
        { "id": "01867", "name": "双龙湖", "pinyin": "shuanglonghu", "kaitouzimu": "slh", "code": "OHB" },
        { "id": "01868", "name": "石林", "pinyin": "shilin", "kaitouzimu": "sl", "code": "SLM" },
        { "id": "01869", "name": "石磷", "pinyin": "shilin", "kaitouzimu": "sl", "code": "SPB" },
        { "id": "01870", "name": "石岭", "pinyin": "shiling", "kaitouzimu": "sl", "code": "SOL" },
        { "id": "01871", "name": "双辽", "pinyin": "shuangliao", "kaitouzimu": "sl", "code": "ZJD" },
        { "id": "01872", "name": "绥棱", "pinyin": "suiling", "kaitouzimu": "sl", "code": "SIB" },
        { "id": "01873", "name": "石林南", "pinyin": "shilinnan", "kaitouzimu": "sln", "code": "LNM" },
        { "id": "01874", "name": "石龙", "pinyin": "shilong", "kaitouzimu": "sl", "code": "SLQ" },
        { "id": "01875", "name": "萨拉齐", "pinyin": "salaqi", "kaitouzimu": "slq", "code": "SLC" },
        { "id": "01876", "name": "商洛", "pinyin": "shangluo", "kaitouzimu": "sl", "code": "OLY" },
        { "id": "01877", "name": "索伦", "pinyin": "suolun", "kaitouzimu": "sl", "code": "SNT" },
        { "id": "01878", "name": "双流西", "pinyin": "shuangliuxi", "kaitouzimu": "slx", "code": "IQW" },
        { "id": "01879", "name": "沙岭子", "pinyin": "shalingzi", "kaitouzimu": "slz", "code": "SLP" },
        { "id": "01880", "name": "胜利镇", "pinyin": "shenglizhen", "kaitouzimu": "slz", "code": "OLB" },
        { "id": "01881", "name": "三明北", "pinyin": "sanmingbei", "kaitouzimu": "smb", "code": "SHS" },
        { "id": "01882", "name": "石门县北", "pinyin": "shimenxianbei", "kaitouzimu": "smxb", "code": "VFQ" },
        { "id": "01883", "name": "三明", "pinyin": "sanming", "kaitouzimu": "sm", "code": "SMS" },
        { "id": "01884", "name": "三门峡南", "pinyin": "sanmenxianan", "kaitouzimu": "smxn", "code": "SCF" },
        { "id": "01885", "name": "神木南", "pinyin": "shenmunan", "kaitouzimu": "smn", "code": "OMY" },
        { "id": "01886", "name": "神木", "pinyin": "shenmu", "kaitouzimu": "sm", "code": "OMY" },
        { "id": "01887", "name": "三门峡", "pinyin": "sanmenxia", "kaitouzimu": "smx", "code": "SMF" },
        { "id": "01888", "name": "三门峡西", "pinyin": "sanmenxiaxi", "kaitouzimu": "smxx", "code": "SXF" },
        { "id": "01889", "name": "三门县", "pinyin": "sanmenxian", "kaitouzimu": "smx", "code": "OQH" },
        { "id": "01890", "name": "石门县", "pinyin": "shimenxian", "kaitouzimu": "smx", "code": "OMQ" },
        { "id": "01891", "name": "商南", "pinyin": "shangnan", "kaitouzimu": "sn", "code": "ONY" },
        { "id": "01892", "name": "肃宁", "pinyin": "suning", "kaitouzimu": "sn", "code": "SYP" },
        { "id": "01893", "name": "遂宁", "pinyin": "suining", "kaitouzimu": "sn", "code": "NIW" },
        { "id": "01894", "name": "苏尼特左旗", "pinyin": "sunitezuoqi", "kaitouzimu": "sntzq", "code": "ONC" },
        { "id": "01895", "name": "宋", "pinyin": "song", "kaitouzimu": "s", "code": "SOB" },
        { "id": "01896", "name": "嵩明", "pinyin": "songming", "kaitouzimu": "sm", "code": "SVM" },
        { "id": "01897", "name": "双牌", "pinyin": "shuangpai", "kaitouzimu": "sp", "code": "SBZ" },
        { "id": "01898", "name": "山坡东", "pinyin": "shanpodong", "kaitouzimu": "spd", "code": "SBN" },
        { "id": "01899", "name": "四平东", "pinyin": "sipingdong", "kaitouzimu": "spd", "code": "PPT" },
        { "id": "01900", "name": "四平", "pinyin": "siping", "kaitouzimu": "sp", "code": "SPT" },
        { "id": "01901", "name": "遂平", "pinyin": "suiping", "kaitouzimu": "sp", "code": "SON" },
        { "id": "01902", "name": "沙坡头", "pinyin": "shapotou", "kaitouzimu": "spt", "code": "SFJ" },
        { "id": "01903", "name": "沙桥", "pinyin": "shaqiao", "kaitouzimu": "sq", "code": "SQM" },
        { "id": "01904", "name": "商丘", "pinyin": "shangqiu", "kaitouzimu": "sq", "code": "SQF" },
        { "id": "01905", "name": "沈丘", "pinyin": "shenqiu", "kaitouzimu": "sq", "code": "SQN" },
        { "id": "01906", "name": "石桥", "pinyin": "shiqiao", "kaitouzimu": "sq", "code": "SQE" },
        { "id": "01907", "name": "商丘南", "pinyin": "shangqiunan", "kaitouzimu": "sqn", "code": "SPF" },
        { "id": "01908", "name": "水泉", "pinyin": "shuiquan", "kaitouzimu": "sq", "code": "SID" },
        { "id": "01909", "name": "石泉县", "pinyin": "shiquanxian", "kaitouzimu": "sqx", "code": "SXY" },
        { "id": "01910", "name": "石桥子", "pinyin": "shiqiaozi", "kaitouzimu": "sqz", "code": "SQT" },
        { "id": "01911", "name": "上饶", "pinyin": "shangrao", "kaitouzimu": "sr", "code": "SRG" },
        { "id": "01912", "name": "石人城", "pinyin": "shirencheng", "kaitouzimu": "src", "code": "SRB" },
        { "id": "01913", "name": "石人", "pinyin": "shiren", "kaitouzimu": "sr", "code": "SRL" },
        { "id": "01914", "name": "三水北", "pinyin": "sanshuibei", "kaitouzimu": "ssb", "code": "ARQ" },
        { "id": "01915", "name": "鄯善北", "pinyin": "shanshanbei", "kaitouzimu": "ssb", "code": "SMR" },
        { "id": "01916", "name": "狮山北", "pinyin": "shishanbei", "kaitouzimu": "ssb", "code": "NSQ" },
        { "id": "01917", "name": "松山湖北", "pinyin": "songshanhubei", "kaitouzimu": "sshb", "code": "KUQ" },
        { "id": "01918", "name": "三水", "pinyin": "sanshui", "kaitouzimu": "ss", "code": "SJQ" },
        { "id": "01919", "name": "山市", "pinyin": "shanshi", "kaitouzimu": "ss", "code": "SQB" },
        { "id": "01920", "name": "鄯善", "pinyin": "shanshan", "kaitouzimu": "ss", "code": "SSR" },
        { "id": "01921", "name": "韶山", "pinyin": "shaoshan", "kaitouzimu": "ss", "code": "SSQ" },
        { "id": "01922", "name": "神树", "pinyin": "shenshu", "kaitouzimu": "ss", "code": "SWB" },
        { "id": "01923", "name": "狮山", "pinyin": "shishan", "kaitouzimu": "ss", "code": "KSQ" },
        { "id": "01924", "name": "石山", "pinyin": "shishan", "kaitouzimu": "ss", "code": "SAD" },
        { "id": "01925", "name": "首山", "pinyin": "shoushan", "kaitouzimu": "ss", "code": "SAT" },
        { "id": "01926", "name": "泗水", "pinyin": "sishui", "kaitouzimu": "ss", "code": "OSK" },
        { "id": "01927", "name": "松树", "pinyin": "songshu", "kaitouzimu": "ss", "code": "SFT" },
        { "id": "01928", "name": "三十家", "pinyin": "sanshijia", "kaitouzimu": "ssj", "code": "SRD" },
        { "id": "01929", "name": "三水南", "pinyin": "sanshuinan", "kaitouzimu": "ssn", "code": "RNQ" },
        { "id": "01930", "name": "韶山南", "pinyin": "shaoshannan", "kaitouzimu": "ssn", "code": "INQ" },
        { "id": "01931", "name": "宿松", "pinyin": "susong", "kaitouzimu": "ss", "code": "OAH" },
        { "id": "01932", "name": "三十里堡", "pinyin": "sanshilipu", "kaitouzimu": "sslb", "code": "SST" },
        { "id": "01933", "name": "三穗", "pinyin": "sansui", "kaitouzimu": "ss", "code": "QHW" },
        { "id": "01934", "name": "双水镇", "pinyin": "shuangshuizhen", "kaitouzimu": "ssz", "code": "PQQ" },
        { "id": "01935", "name": "松树镇", "pinyin": "songshuzhen", "kaitouzimu": "ssz", "code": "SSL" },
        { "id": "01936", "name": "松桃", "pinyin": "songtao", "kaitouzimu": "st", "code": "MZQ" },
        { "id": "01937", "name": "索图罕", "pinyin": "suotuhan", "kaitouzimu": "sth", "code": "SHX" },
        { "id": "01938", "name": "石梯", "pinyin": "shiti", "kaitouzimu": "st", "code": "STE" },
        { "id": "01939", "name": "三堂集", "pinyin": "santangji", "kaitouzimu": "stj", "code": "SDH" },
        { "id": "01940", "name": "汕头", "pinyin": "shantou", "kaitouzimu": "st", "code": "OTQ" },
        { "id": "01941", "name": "神头", "pinyin": "shentou", "kaitouzimu": "st", "code": "SEV" },
        { "id": "01942", "name": "石头", "pinyin": "shitou", "kaitouzimu": "st", "code": "OTB" },
        { "id": "01943", "name": "沙沱", "pinyin": "shatuo", "kaitouzimu": "st", "code": "SFM" },
        { "id": "01944", "name": "上万", "pinyin": "shangwan", "kaitouzimu": "sw", "code": "SWP" },
        { "id": "01945", "name": "汕尾", "pinyin": "shanwei", "kaitouzimu": "sw", "code": "OGQ" },
        { "id": "01946", "name": "邵武", "pinyin": "shaowu", "kaitouzimu": "sw", "code": "SWS" },
        { "id": "01947", "name": "孙吴", "pinyin": "sunwu", "kaitouzimu": "sw", "code": "SKB" },
        { "id": "01948", "name": "沙湾县", "pinyin": "shawanxian", "kaitouzimu": "swx", "code": "SXR" },
        { "id": "01949", "name": "绍兴北", "pinyin": "shaoxingbei", "kaitouzimu": "sxb", "code": "SLH" },
        { "id": "01950", "name": "歙县北", "pinyin": "shexianbei", "kaitouzimu": "sxb", "code": "NPH" },
        { "id": "01951", "name": "沙县", "pinyin": "shaxian", "kaitouzimu": "sx", "code": "SAS" },
        { "id": "01952", "name": "绍兴", "pinyin": "shaoxing", "kaitouzimu": "sx", "code": "SOH" },
        { "id": "01953", "name": "涉县", "pinyin": "shexian", "kaitouzimu": "sx", "code": "OEP" },
        { "id": "01954", "name": "石岘", "pinyin": "shixian", "kaitouzimu": "sj", "code": "SXL" },
        { "id": "01955", "name": "始兴", "pinyin": "shixing", "kaitouzimu": "sx", "code": "IPQ" },
        { "id": "01956", "name": "泗县", "pinyin": "sixian", "kaitouzimu": "sx", "code": "GPH" },
        { "id": "01957", "name": "遂溪", "pinyin": "suixi", "kaitouzimu": "sx", "code": "SXZ" },
        { "id": "01958", "name": "歙县", "pinyin": "shexian", "kaitouzimu": "sx", "code": "OVH" },
        { "id": "01959", "name": "上西铺", "pinyin": "shangxipu", "kaitouzimu": "sxp", "code": "SXM" },
        { "id": "01960", "name": "石峡子", "pinyin": "shixiazi", "kaitouzimu": "sxz", "code": "SXJ" },
        { "id": "01961", "name": "三亚", "pinyin": "sanya", "kaitouzimu": "sy", "code": "SEQ" },
        { "id": "01962", "name": "三阳", "pinyin": "sanyang", "kaitouzimu": "sy", "code": "SYU" },
        { "id": "01963", "name": "邵阳", "pinyin": "shaoyang", "kaitouzimu": "sy", "code": "SYQ" },
        { "id": "01964", "name": "射阳", "pinyin": "sheyang", "kaitouzimu": "sy", "code": "SAU" },
        { "id": "01965", "name": "沈阳", "pinyin": "shenyang", "kaitouzimu": "sy", "code": "SYT" },
        { "id": "01966", "name": "十堰", "pinyin": "shiyan", "kaitouzimu": "sy", "code": "SNN" },
        { "id": "01967", "name": "寿阳", "pinyin": "shouyang", "kaitouzimu": "sy", "code": "SYV" },
        { "id": "01968", "name": "沭阳", "pinyin": "shuyang", "kaitouzimu": "sy", "code": "FMH" },
        { "id": "01969", "name": "双阳", "pinyin": "shuangyang", "kaitouzimu": "sy", "code": "OYT" },
        { "id": "01970", "name": "双洋", "pinyin": "shuangyang", "kaitouzimu": "sy", "code": "SQS" },
        { "id": "01971", "name": "水洋", "pinyin": "shuiyang", "kaitouzimu": "sy", "code": "OYP" },
        { "id": "01972", "name": "泗阳", "pinyin": "siyang", "kaitouzimu": "sy", "code": "MPH" },
        { "id": "01973", "name": "绥阳", "pinyin": "suiyang", "kaitouzimu": "sy", "code": "SYB" },
        { "id": "01974", "name": "牙拉盖图", "pinyin": "sanya", "kaitouzimu": "sy", "code": "JUQ" },
        { "id": "01975", "name": "上虞北", "pinyin": "shangyubei", "kaitouzimu": "syb", "code": "SSH" },
        { "id": "01976", "name": "邵阳北", "pinyin": "shaoyangbei", "kaitouzimu": "syb", "code": "OVQ" },
        { "id": "01977", "name": "沈阳北", "pinyin": "shenyangbei", "kaitouzimu": "syb", "code": "SBT" },
        { "id": "01978", "name": "松原北", "pinyin": "songyuanbei", "kaitouzimu": "syb", "code": "OCT" },
        { "id": "01979", "name": "三阳川", "pinyin": "sanyangchuan", "kaitouzimu": "syc", "code": "SYJ" },
        { "id": "01980", "name": "上腰墩", "pinyin": "shangyaodun", "kaitouzimu": "syd", "code": "SPJ" },
        { "id": "01981", "name": "沈阳东", "pinyin": "shenyangdong", "kaitouzimu": "syd", "code": "SDT" },
        { "id": "01982", "name": "三营", "pinyin": "sanying", "kaitouzimu": "sy", "code": "OEJ" },
        { "id": "01983", "name": "山阴", "pinyin": "shanyin", "kaitouzimu": "sy", "code": "SNV" },
        { "id": "01984", "name": "顺义", "pinyin": "shunyi", "kaitouzimu": "sy", "code": "SOP" },
        { "id": "01985", "name": "三义井", "pinyin": "sanyijing", "kaitouzimu": "syj", "code": "OYD" },
        { "id": "01986", "name": "沈阳南", "pinyin": "shenyangnan", "kaitouzimu": "syn", "code": "SOT" },
        { "id": "01987", "name": "三源浦", "pinyin": "sanyuanpu", "kaitouzimu": "syp", "code": "SYL" },
        { "id": "01988", "name": "三元区", "pinyin": "sanyuanqu", "kaitouzimu": "syq", "code": "SMS" },
        { "id": "01989", "name": "双鸭山", "pinyin": "shuangyashan", "kaitouzimu": "sys", "code": "SSB" },
        { "id": "01990", "name": "三原", "pinyin": "sanyuan", "kaitouzimu": "sy", "code": "SAY" },
        { "id": "01991", "name": "上虞", "pinyin": "shangyu", "kaitouzimu": "sy", "code": "BDH" },
        { "id": "01992", "name": "上园", "pinyin": "shangyuan", "kaitouzimu": "sy", "code": "SUD" },
        { "id": "01993", "name": "水源", "pinyin": "shuiyuan", "kaitouzimu": "sy", "code": "OYJ" },
        { "id": "01994", "name": "松原", "pinyin": "songyuan", "kaitouzimu": "sy", "code": "VYT" },
        { "id": "01995", "name": "邵阳西", "pinyin": "shaoyangxi", "kaitouzimu": "syx", "code": "SXA" },
        { "id": "01996", "name": "桑园子", "pinyin": "sangyuanzi", "kaitouzimu": "syz", "code": "SAJ" },
        { "id": "01997", "name": "深圳北", "pinyin": "shenzhenbei", "kaitouzimu": "szb", "code": "IOQ" },
        { "id": "01998", "name": "苏州北", "pinyin": "suzhoubei", "kaitouzimu": "szb", "code": "OHH" },
        { "id": "01999", "name": "绥中北", "pinyin": "suizhongbei", "kaitouzimu": "szb", "code": "SND" },
        { "id": "02000", "name": "深圳东", "pinyin": "shenzhendong", "kaitouzimu": "szd", "code": "BJQ" },
        { "id": "02001", "name": "宿州东", "pinyin": "suzhoudong", "kaitouzimu": "szd", "code": "SRH" },
        { "id": "02002", "name": "尚志", "pinyin": "shangzhi", "kaitouzimu": "sz", "code": "SZB" },
        { "id": "02003", "name": "深圳", "pinyin": "shenzhen", "kaitouzimu": "sz", "code": "SZQ" },
        { "id": "02004", "name": "深州", "pinyin": "shenzhou", "kaitouzimu": "sz", "code": "OZP" },
        { "id": "02005", "name": "神州", "pinyin": "shenzhou", "kaitouzimu": "sz", "code": "SRQ" },
        { "id": "02006", "name": "师庄", "pinyin": "shizhuang", "kaitouzimu": "sz", "code": "SNM" },
        { "id": "02007", "name": "朔州", "pinyin": "shuozhou", "kaitouzimu": "sz", "code": "SUV" },
        { "id": "02008", "name": "苏州", "pinyin": "suzhou", "kaitouzimu": "sz", "code": "SZH" },
        { "id": "02009", "name": "绥中", "pinyin": "suizhong", "kaitouzimu": "sz", "code": "SZD" },
        { "id": "02010", "name": "随州", "pinyin": "suizhou", "kaitouzimu": "sz", "code": "SZN" },
        { "id": "02011", "name": "孙镇", "pinyin": "sunzhen", "kaitouzimu": "sz", "code": "OZY" },
        { "id": "02012", "name": "宿州", "pinyin": "suzhou", "kaitouzimu": "sz", "code": "OXH" },
        { "id": "02013", "name": "松滋", "pinyin": "songzi", "kaitouzimu": "sz", "code": "SIN" },
        { "id": "02014", "name": "尚志南", "pinyin": "shangzhinan", "kaitouzimu": "szn", "code": "OZB" },
        { "id": "02015", "name": "师宗", "pinyin": "shizong", "kaitouzimu": "sz", "code": "SEM" },
        { "id": "02016", "name": "苏州新区", "pinyin": "suzhouxinqu", "kaitouzimu": "szxq", "code": "ITH" },
        { "id": "02017", "name": "苏州园区", "pinyin": "suzhouyuanqu", "kaitouzimu": "szyq", "code": "KAH" },
        { "id": "02018", "name": "深圳坪山", "pinyin": "shenzhenpingshan", "kaitouzimu": "szps", "code": "IFQ" },
        { "id": "02019", "name": "石嘴山", "pinyin": "shizuishan", "kaitouzimu": "szs", "code": "QQJ" },
        { "id": "02020", "name": "深圳西", "pinyin": "shenzhenxi", "kaitouzimu": "szx", "code": "OSQ" },
        { "id": "02021", "name": "石柱县", "pinyin": "shizhuxian", "kaitouzimu": "szx", "code": "OSW" },],
    T: [
        { "id": "02022", "name": "太和北", "pinyin": "taihebei", "kaitouzimu": "thb", "code": "JYN" },
        { "id": "02023", "name": "台安", "pinyin": "taian", "kaitouzimu": "ta", "code": "TID" },
        { "id": "02024", "name": "台安南", "pinyin": "taiannan", "kaitouzimu": "tan", "code": "TAD" },
        { "id": "02025", "name": "泰安", "pinyin": "taian", "kaitouzimu": "ta", "code": "TMK" },
        { "id": "02026", "name": "通安驿", "pinyin": "tonganyi", "kaitouzimu": "tay", "code": "TAJ" },
        { "id": "02027", "name": "塘豹", "pinyin": "tangbao", "kaitouzimu": "tb", "code": "TBQ" },
        { "id": "02028", "name": "桐柏", "pinyin": "tongbai", "kaitouzimu": "tb", "code": "TBF" },
        { "id": "02029", "name": "通北", "pinyin": "tongbei", "kaitouzimu": "tb", "code": "TBB" },
        { "id": "02030", "name": "桃村北", "pinyin": "taocunbei", "kaitouzimu": "tcb", "code": "TOK" },
        { "id": "02031", "name": "郯城", "pinyin": "tancheng", "kaitouzimu": "tc", "code": "TZK" },
        { "id": "02032", "name": "汤池", "pinyin": "tangchi", "kaitouzimu": "tc", "code": "TCX" },
        { "id": "02033", "name": "铁厂", "pinyin": "tiechang", "kaitouzimu": "tc", "code": "TCL" },
        { "id": "02034", "name": "桐城", "pinyin": "tongcheng", "kaitouzimu": "tc", "code": "TTH" },
        { "id": "02035", "name": "桃村", "pinyin": "taocun", "kaitouzimu": "tc", "code": "TCK" },
        { "id": "02036", "name": "通道", "pinyin": "tongdao", "kaitouzimu": "td", "code": "TRQ" },
        { "id": "02037", "name": "田东北", "pinyin": "tiandongbei", "kaitouzimu": "tdb", "code": "TBZ" },
        { "id": "02038", "name": "土地堂东", "pinyin": "tuditangdong", "kaitouzimu": "tdtd", "code": "TTN" },
        { "id": "02039", "name": "田东", "pinyin": "tiandong", "kaitouzimu": "td", "code": "TDZ" },
        { "id": "02040", "name": "塔尔气", "pinyin": "taerqi", "kaitouzimu": "teq", "code": "TVX" },
        { "id": "02041", "name": "天岗", "pinyin": "tiangang", "kaitouzimu": "tg", "code": "TGL" },
        { "id": "02042", "name": "土贵乌拉", "pinyin": "tuguiwula", "kaitouzimu": "tgwl", "code": "TGC" },
        { "id": "02043", "name": "通沟", "pinyin": "tonggou", "kaitouzimu": "tg", "code": "TOL" },
        { "id": "02044", "name": "太谷", "pinyin": "taigu", "kaitouzimu": "tg", "code": "TGV" },
        { "id": "02045", "name": "塘沽", "pinyin": "tanggu", "kaitouzimu": "tg", "code": "TGP" },
        { "id": "02046", "name": "潼关", "pinyin": "tongguan", "kaitouzimu": "tg", "code": "TGY" },
        { "id": "02047", "name": "太谷西", "pinyin": "taiguxi", "kaitouzimu": "tgx", "code": "TIV" },
        { "id": "02048", "name": "塔哈", "pinyin": "taha", "kaitouzimu": "th", "code": "THX" },
        { "id": "02049", "name": "棠海", "pinyin": "tanghai", "kaitouzimu": "th", "code": "THM" },
        { "id": "02050", "name": "通海", "pinyin": "tonghai", "kaitouzimu": "th", "code": "TAM" },
        { "id": "02051", "name": "吐哈", "pinyin": "tuha", "kaitouzimu": "th", "code": "THR" },
        { "id": "02052", "name": "塔河", "pinyin": "tahe", "kaitouzimu": "th", "code": "TXX" },
        { "id": "02053", "name": "泰和", "pinyin": "taihe", "kaitouzimu": "th", "code": "THG" },
        { "id": "02054", "name": "唐河", "pinyin": "tanghe", "kaitouzimu": "th", "code": "THF" },
        { "id": "02055", "name": "天河街", "pinyin": "tianhejie", "kaitouzimu": "thj", "code": "TEN" },
        { "id": "02056", "name": "唐海南", "pinyin": "tanghainan", "kaitouzimu": "thn", "code": "IEP" },
        { "id": "02057", "name": "太湖", "pinyin": "taihu", "kaitouzimu": "th", "code": "TKH" },
        { "id": "02058", "name": "通化", "pinyin": "tonghua", "kaitouzimu": "th", "code": "THL" },
        { "id": "02059", "name": "通化县", "pinyin": "tonghuaxian", "kaitouzimu": "thx", "code": "TXL" },
        { "id": "02060", "name": "天河机场", "pinyin": "tianhejichang", "kaitouzimu": "thjc", "code": "TJN" },
        { "id": "02061", "name": "天水南", "pinyin": "tianshuinan", "kaitouzimu": "tsn", "code": "TIJ" },
        { "id": "02062", "name": "天津北", "pinyin": "tianjinbei", "kaitouzimu": "tjb", "code": "TBP" },
        { "id": "02063", "name": "天津", "pinyin": "tianjin", "kaitouzimu": "tj", "code": "TJP" },
        { "id": "02064", "name": "同江", "pinyin": "tongjiang", "kaitouzimu": "tj", "code": "TJB" },
        { "id": "02065", "name": "团结", "pinyin": "tuanjie", "kaitouzimu": "tj", "code": "TIX" },
        { "id": "02066", "name": "谭家井", "pinyin": "tanjiajing", "kaitouzimu": "tjj", "code": "TNJ" },
        { "id": "02067", "name": "天津南", "pinyin": "tianjinnan", "kaitouzimu": "tjn", "code": "TIP" },
        { "id": "02068", "name": "陶家屯", "pinyin": "taojiatun", "kaitouzimu": "tjt", "code": "TOT" },
        { "id": "02069", "name": "唐家湾", "pinyin": "tangjiawan", "kaitouzimu": "tjw", "code": "PDQ" },
        { "id": "02070", "name": "天津西", "pinyin": "tianjinxi", "kaitouzimu": "tjx", "code": "TXP" },
        { "id": "02071", "name": "统军庄", "pinyin": "tongjunzhuang", "kaitouzimu": "tjz", "code": "TZP" },
        { "id": "02072", "name": "托克托东", "pinyin": "tuoketuodong", "kaitouzimu": "tktd", "code": "TVC" },
        { "id": "02073", "name": "泰来", "pinyin": "tailai", "kaitouzimu": "tl", "code": "TLX" },
        { "id": "02074", "name": "铜陵北", "pinyin": "tonglingbei", "kaitouzimu": "tlb", "code": "KXH" },
        { "id": "02075", "name": "吐鲁番北", "pinyin": "tulufanbei", "kaitouzimu": "tlfb", "code": "TAR" },
        { "id": "02076", "name": "吐列毛杜", "pinyin": "tuliemaodu", "kaitouzimu": "tlmd", "code": "TMD" },
        { "id": "02077", "name": "吐鲁番", "pinyin": "tulufan", "kaitouzimu": "tlf", "code": "TFR" },
        { "id": "02078", "name": "图里河", "pinyin": "tulihe", "kaitouzimu": "tlh", "code": "TEX" },
        { "id": "02079", "name": "田林", "pinyin": "tianlin", "kaitouzimu": "tl", "code": "TFZ" },
        { "id": "02080", "name": "铁力", "pinyin": "tieli", "kaitouzimu": "tl", "code": "TLB" },
        { "id": "02081", "name": "铁岭", "pinyin": "tieling", "kaitouzimu": "tl", "code": "TLT" },
        { "id": "02082", "name": "亭亮", "pinyin": "tingliang", "kaitouzimu": "tl", "code": "TIZ" },
        { "id": "02083", "name": "通辽", "pinyin": "tongliao", "kaitouzimu": "tl", "code": "TLD" },
        { "id": "02084", "name": "铜陵", "pinyin": "tongling", "kaitouzimu": "tl", "code": "TJH" },
        { "id": "02085", "name": "桐庐", "pinyin": "tonglu", "kaitouzimu": "tl", "code": "TLU" },
        { "id": "02086", "name": "铁岭西", "pinyin": "tielingxi", "kaitouzimu": "tlx", "code": "PXT" },
        { "id": "02087", "name": "陶赖昭", "pinyin": "taolaizhao", "kaitouzimu": "tlz", "code": "TPT" },
        { "id": "02088", "name": "图们北", "pinyin": "tumenbei", "kaitouzimu": "tmb", "code": "QSL" },
        { "id": "02089", "name": "天门", "pinyin": "tianmen", "kaitouzimu": "tm", "code": "TMN" },
        { "id": "02090", "name": "图们", "pinyin": "tumen", "kaitouzimu": "tm", "code": "TML" },
        { "id": "02091", "name": "天门南", "pinyin": "tianmennan", "kaitouzimu": "tmn", "code": "TNN" },
        { "id": "02092", "name": "太姥山", "pinyin": "taimushan", "kaitouzimu": "tms", "code": "TLS" },
        { "id": "02093", "name": "土牧尔台", "pinyin": "tumuertai", "kaitouzimu": "tmet", "code": "TRC" },
        { "id": "02094", "name": "土门子", "pinyin": "tumenzi", "kaitouzimu": "tmz", "code": "TCJ" },
        { "id": "02095", "name": "洮南", "pinyin": "taonan", "kaitouzimu": "tn", "code": "TVT" },
        { "id": "02096", "name": "潼南", "pinyin": "tongnan", "kaitouzimu": "tn", "code": "TVW" },
        { "id": "02097", "name": "泰宁", "pinyin": "taining", "kaitouzimu": "tn", "code": "TNS" },
        { "id": "02098", "name": "通渭", "pinyin": "tongwei", "kaitouzimu": "tw", "code": "TWJ" },
        { "id": "02099", "name": "通州", "pinyin": "tongzhou", "kaitouzimu": "tz", "code": "TOP" },
        { "id": "02100", "name": "桐梓北", "pinyin": "tongzibei", "kaitouzimu": "tzb", "code": "TBE" },
        { "id": "02101", "name": "桐梓东", "pinyin": "tongzidong", "kaitouzimu": "tzd", "code": "TDE" },
        { "id": "02102", "name": "太平川", "pinyin": "taipingchuan", "kaitouzimu": "tpc", "code": "TIT" },
        { "id": "02103", "name": "太平镇", "pinyin": "taipingzhen", "kaitouzimu": "tpz", "code": "TEB" },
        { "id": "02104", "name": "台前", "pinyin": "taiqian", "kaitouzimu": "tq", "code": "TTK" },
        { "id": "02105", "name": "图强", "pinyin": "tuqiang", "kaitouzimu": "tq", "code": "TQX" },
        { "id": "02106", "name": "天桥岭", "pinyin": "tianqiaoling", "kaitouzimu": "tql", "code": "TQL" },
        { "id": "02107", "name": "土桥子", "pinyin": "tuqiaozi", "kaitouzimu": "tqz", "code": "TQJ" },
        { "id": "02108", "name": "铜仁", "pinyin": "tongren", "kaitouzimu": "tr", "code": "RDQ" },
        { "id": "02109", "name": "铜仁南", "pinyin": "tongrennan", "kaitouzimu": "trn", "code": "TNW" },
        { "id": "02110", "name": "唐山北", "pinyin": "tangshanbei", "kaitouzimu": "tsb", "code": "FUP" },
        { "id": "02111", "name": "汤山城", "pinyin": "tangshancheng", "kaitouzimu": "tsc", "code": "TCT" },
        { "id": "02112", "name": "田师府", "pinyin": "tianshifu", "kaitouzimu": "tsf", "code": "TFT" },
        { "id": "02113", "name": "台山", "pinyin": "taishan", "kaitouzimu": "ts", "code": "PUQ" },
        { "id": "02114", "name": "泰山", "pinyin": "taishan", "kaitouzimu": "ts", "code": "TAK" },
        { "id": "02115", "name": "唐山", "pinyin": "tangshan", "kaitouzimu": "ts", "code": "TSP" },
        { "id": "02116", "name": "桃山", "pinyin": "taoshan", "kaitouzimu": "ts", "code": "TAB" },
        { "id": "02117", "name": "天水", "pinyin": "tianshui", "kaitouzimu": "ts", "code": "TSJ" },
        { "id": "02118", "name": "塔石嘴", "pinyin": "tashizui", "kaitouzimu": "tsz", "code": "TIM" },
        { "id": "02119", "name": "通途", "pinyin": "tongtu", "kaitouzimu": "tt", "code": "TUT" },
        { "id": "02120", "name": "汤旺河", "pinyin": "tangwanghe", "kaitouzimu": "twh", "code": "THB" },
        { "id": "02121", "name": "田心东", "pinyin": "tianxindong", "kaitouzimu": "txd", "code": "KQQ" },
        { "id": "02122", "name": "汤逊湖", "pinyin": "tangxunhu", "kaitouzimu": "txh", "code": "THN" },
        { "id": "02123", "name": "藤县", "pinyin": "tengxian", "kaitouzimu": "tx", "code": "TAZ" },
        { "id": "02124", "name": "同心", "pinyin": "tongxin", "kaitouzimu": "tx", "code": "TXJ" },
        { "id": "02125", "name": "桐乡", "pinyin": "tongxiang", "kaitouzimu": "tx", "code": "TCH" },
        { "id": "02126", "name": "土溪", "pinyin": "tuxi", "kaitouzimu": "tx", "code": "TSW" },
        { "id": "02127", "name": "田阳", "pinyin": "tianyang", "kaitouzimu": "ty", "code": "TRZ" },
        { "id": "02128", "name": "太原北", "pinyin": "taiyuanbei", "kaitouzimu": "tyb", "code": "TBV" },
        { "id": "02129", "name": "太原东", "pinyin": "taiyuandong", "kaitouzimu": "tyd", "code": "TDV" },
        { "id": "02130", "name": "汤阴", "pinyin": "tangyin", "kaitouzimu": "ty", "code": "TYF" },
        { "id": "02131", "name": "天义", "pinyin": "tianyi", "kaitouzimu": "ty", "code": "TND" },
        { "id": "02132", "name": "驼腰岭", "pinyin": "tuoyaoling", "kaitouzimu": "tyl", "code": "TIL" },
        { "id": "02133", "name": "太原南", "pinyin": "taiyuannan", "kaitouzimu": "tyn", "code": "TNV" },
        { "id": "02134", "name": "通远堡", "pinyin": "tongyuanpu", "kaitouzimu": "tyb", "code": "TYT" },
        { "id": "02135", "name": "太阳山", "pinyin": "taiyangshan", "kaitouzimu": "tys", "code": "TYJ" },
        { "id": "02136", "name": "太阳升", "pinyin": "taiyangsheng", "kaitouzimu": "tys", "code": "TQT" },
        { "id": "02137", "name": "太原", "pinyin": "taiyuan", "kaitouzimu": "ty", "code": "TYV" },
        { "id": "02138", "name": "汤原", "pinyin": "tangyuan", "kaitouzimu": "ty", "code": "TYB" },
        { "id": "02139", "name": "通远堡西", "pinyin": "tongyuanpuxi", "kaitouzimu": "typx", "code": "TST" },
        { "id": "02140", "name": "塔崖驿", "pinyin": "tayayi", "kaitouzimu": "tyy", "code": "TYP" },
        { "id": "02141", "name": "滕州东", "pinyin": "tengzhoudong", "kaitouzimu": "tzd", "code": "TEK" },
        { "id": "02142", "name": "台州", "pinyin": "taizhou", "kaitouzimu": "tz", "code": "TZH" },
        { "id": "02143", "name": "泰州", "pinyin": "taizhou", "kaitouzimu": "tz", "code": "UTH" },
        { "id": "02144", "name": "滕州", "pinyin": "tengzhou", "kaitouzimu": "tz", "code": "TXK" },
        { "id": "02145", "name": "天镇", "pinyin": "tianzhen", "kaitouzimu": "tz", "code": "TZV" },
        { "id": "02146", "name": "天祝", "pinyin": "tianzhu", "kaitouzimu": "tz", "code": "TZJ" },
        { "id": "02147", "name": "桐梓", "pinyin": "tongzi", "kaitouzimu": "tz", "code": "TZW" },
        { "id": "02148", "name": "桐子林", "pinyin": "tongzilin", "kaitouzimu": "tzl", "code": "TEW" },
        { "id": "02149", "name": "天柱山", "pinyin": "tianzhushan", "kaitouzimu": "tzs", "code": "QWH" },
        { "id": "02150", "name": "通州西", "pinyin": "tongzhouxi", "kaitouzimu": "tzx", "code": "TAP" },],
    U: [],
    V: [],
    W: [
        { "id": "02151", "name": "文安", "pinyin": "wenan", "kaitouzimu": "wa", "code": "WBP" },
        { "id": "02152", "name": "武安", "pinyin": "wuan", "kaitouzimu": "wa", "code": "WAP" },
        { "id": "02153", "name": "万州北", "pinyin": "wanzhoubei", "kaitouzimu": "wzb", "code": "WZE" },
        { "id": "02154", "name": "王安镇", "pinyin": "wanganzhen", "kaitouzimu": "waz", "code": "WVP" },
        { "id": "02155", "name": "旺苍", "pinyin": "wangcang", "kaitouzimu": "wc", "code": "WEW" },
        { "id": "02156", "name": "五叉沟", "pinyin": "wuchagou", "kaitouzimu": "wcg", "code": "WCT" },
        { "id": "02157", "name": "温春", "pinyin": "wenchun", "kaitouzimu": "wc", "code": "WDB" },
        { "id": "02158", "name": "文昌", "pinyin": "wenchang", "kaitouzimu": "wc", "code": "WEQ" },
        { "id": "02159", "name": "吴川", "pinyin": "wuchuan", "kaitouzimu": "wc", "code": "WAQ" },
        { "id": "02160", "name": "五常", "pinyin": "wuchang", "kaitouzimu": "wc", "code": "WCB" },
        { "id": "02161", "name": "武昌", "pinyin": "wuchang", "kaitouzimu": "wc", "code": "WCN" },
        { "id": "02162", "name": "五大连池", "pinyin": "wudalianchi", "kaitouzimu": "wdlc", "code": "WRB" },
        { "id": "02163", "name": "文登东", "pinyin": "wendengdong", "kaitouzimu": "wdd", "code": "WGK" },
        { "id": "02164", "name": "文登", "pinyin": "wendeng", "kaitouzimu": "wd", "code": "WBK" },
        { "id": "02165", "name": "五道沟", "pinyin": "wudaogou", "kaitouzimu": "wdg", "code": "WDL" },
        { "id": "02166", "name": "五道河", "pinyin": "wudaohe", "kaitouzimu": "wdh", "code": "WHP" },
        { "id": "02167", "name": "文地", "pinyin": "wendi", "kaitouzimu": "wd", "code": "WNZ" },
        { "id": "02168", "name": "卫东", "pinyin": "weidong", "kaitouzimu": "wd", "code": "WVT" },
        { "id": "02169", "name": "武当山", "pinyin": "wudangshan", "kaitouzimu": "wds", "code": "WRN" },
        { "id": "02170", "name": "望都", "pinyin": "wangdu", "kaitouzimu": "wd", "code": "WDP" },
        { "id": "02171", "name": "乌尔旗汗", "pinyin": "wuerqihan", "kaitouzimu": "weqh", "code": "WHX" },
        { "id": "02172", "name": "渭源", "pinyin": "weiyuan", "kaitouzimu": "wy", "code": "WEJ" },
        { "id": "02173", "name": "潍坊", "pinyin": "weifang", "kaitouzimu": "wf", "code": "WFK" },
        { "id": "02174", "name": "潍坊北", "pinyin": "weifangbei", "kaitouzimu": "wfb", "code": "WJK" },
        { "id": "02175", "name": "瓦房店", "pinyin": "wafangdian", "kaitouzimu": "wfd", "code": "WDT" },
        { "id": "02176", "name": "五府山", "pinyin": "wufushan", "kaitouzimu": "wfs", "code": "WFG" },
        { "id": "02177", "name": "万发屯", "pinyin": "wanfatun", "kaitouzimu": "wft", "code": "WFB" },
        { "id": "02178", "name": "王府", "pinyin": "wangfu", "kaitouzimu": "wf", "code": "WUT" },
        { "id": "02179", "name": "瓦房店西", "pinyin": "wafangdianxi", "kaitouzimu": "wfdx", "code": "WXT" },
        { "id": "02180", "name": "王岗", "pinyin": "wanggang", "kaitouzimu": "wg", "code": "WGB" },
        { "id": "02181", "name": "湾沟", "pinyin": "wangou", "kaitouzimu": "wg", "code": "WGL" },
        { "id": "02182", "name": "武功", "pinyin": "wugong", "kaitouzimu": "wg", "code": "WGY" },
        { "id": "02183", "name": "吴官田", "pinyin": "wuguantian", "kaitouzimu": "wgt", "code": "WGM" },
        { "id": "02184", "name": "威海", "pinyin": "weihai", "kaitouzimu": "wh", "code": "WKK" },
        { "id": "02185", "name": "乌海", "pinyin": "wuhai", "kaitouzimu": "wh", "code": "WVC" },
        { "id": "02186", "name": "武汉", "pinyin": "wuhan", "kaitouzimu": "wh", "code": "WHN" },
        { "id": "02187", "name": "威海北", "pinyin": "weihaibei", "kaitouzimu": "whb", "code": "WHK" },
        { "id": "02188", "name": "威虎岭北", "pinyin": "weihulingbei", "kaitouzimu": "whlb", "code": "WBL" },
        { "id": "02189", "name": "苇河", "pinyin": "weihe", "kaitouzimu": "wh", "code": "WHB" },
        { "id": "02190", "name": "卫辉", "pinyin": "weihui", "kaitouzimu": "wh", "code": "WHF" },
        { "id": "02191", "name": "芜湖", "pinyin": "wuhu", "kaitouzimu": "wh", "code": "WHH" },
        { "id": "02192", "name": "苇河西", "pinyin": "weihexi", "kaitouzimu": "whx", "code": "WIB" },
        { "id": "02193", "name": "乌海西", "pinyin": "wuhaixi", "kaitouzimu": "whx", "code": "WXC" },
        { "id": "02194", "name": "吴家川", "pinyin": "wujiachuan", "kaitouzimu": "wjc", "code": "WCJ" },
        { "id": "02195", "name": "威箐", "pinyin": "weiqing", "kaitouzimu": "wq", "code": "WAM" },
        { "id": "02196", "name": "渭津", "pinyin": "weijin", "kaitouzimu": "wj", "code": "WJL" },
        { "id": "02197", "name": "温江", "pinyin": "wenjiang", "kaitouzimu": "wj", "code": "WJE" },
        { "id": "02198", "name": "五家", "pinyin": "wujia", "kaitouzimu": "wj", "code": "WUB" },
        { "id": "02199", "name": "午汲", "pinyin": "wuji", "kaitouzimu": "wj", "code": "WJP" },
        { "id": "02200", "name": "吴家屯", "pinyin": "wujiatun", "kaitouzimu": "wjt", "code": "WJT" },
        { "id": "02201", "name": "王家湾", "pinyin": "wangjiawan", "kaitouzimu": "wjw", "code": "WJJ" },
        { "id": "02202", "name": "王家营西", "pinyin": "wangjiayingxi", "kaitouzimu": "wjyx", "code": "KNM" },
        { "id": "02203", "name": "倭肯", "pinyin": "woken", "kaitouzimu": "wk", "code": "WQB" },
        { "id": "02204", "name": "五棵树", "pinyin": "wukeshu", "kaitouzimu": "wks", "code": "WKT" },
        { "id": "02205", "name": "五龙背", "pinyin": "wulongbei", "kaitouzimu": "wlb", "code": "WBT" },
        { "id": "02206", "name": "乌兰哈达", "pinyin": "wulanhada", "kaitouzimu": "wlhd", "code": "WLC" },
        { "id": "02207", "name": "五龙背东", "pinyin": "wulongbeidong", "kaitouzimu": "wlbd", "code": "WMT" },
        { "id": "02208", "name": "万乐", "pinyin": "wanle", "kaitouzimu": "wl", "code": "WEB" },
        { "id": "02209", "name": "瓦拉干", "pinyin": "walagan", "kaitouzimu": "wlg", "code": "WVX" },
        { "id": "02210", "name": "温岭", "pinyin": "wenling", "kaitouzimu": "wl", "code": "VHH" },
        { "id": "02211", "name": "五莲", "pinyin": "wulian", "kaitouzimu": "wl", "code": "WLK" },
        { "id": "02212", "name": "乌龙泉南", "pinyin": "wulongquannan", "kaitouzimu": "wlqn", "code": "WFN" },
        { "id": "02213", "name": "乌鲁木齐南", "pinyin": "wulumuqinan", "kaitouzimu": "wlmqn", "code": "WMR" },
        { "id": "02214", "name": "武隆", "pinyin": "wulong", "kaitouzimu": "wl", "code": "WLW" },
        { "id": "02215", "name": "乌拉特前旗", "pinyin": "wulateqianqi", "kaitouzimu": "wltqq", "code": "WQC" },
        { "id": "02216", "name": "乌鲁木齐", "pinyin": "wulumuqi", "kaitouzimu": "wlmq", "code": "WAR" },
        { "id": "02217", "name": "乌拉山", "pinyin": "wulashan", "kaitouzimu": "wls", "code": "WSC" },
        { "id": "02218", "name": "卧里屯", "pinyin": "wolitun", "kaitouzimu": "wlt", "code": "WLX" },
        { "id": "02219", "name": "乌兰浩特", "pinyin": "wulanhaote", "kaitouzimu": "wlht", "code": "WWT" },
        { "id": "02220", "name": "乌兰木图", "pinyin": "wulanmutu", "kaitouzimu": "wlmt", "code": "VLT" },
        { "id": "02221", "name": "渭南", "pinyin": "weinan", "kaitouzimu": "wn", "code": "WNY" },
        { "id": "02222", "name": "渭南北", "pinyin": "weinanbei", "kaitouzimu": "wnb", "code": "WBY" },
        { "id": "02223", "name": "乌奴耳", "pinyin": "wunuer", "kaitouzimu": "wne", "code": "WRX" },
        { "id": "02224", "name": "万年", "pinyin": "wannian", "kaitouzimu": "wn", "code": "WWG" },
        { "id": "02225", "name": "万宁", "pinyin": "wanning", "kaitouzimu": "wn", "code": "WNQ" },
        { "id": "02226", "name": "渭南南", "pinyin": "weinannan", "kaitouzimu": "wnn", "code": "WVY" },
        { "id": "02227", "name": "五女山", "pinyin": "wunvshan", "kaitouzimu": "wns", "code": "WET" },
        { "id": "02228", "name": "渭南镇", "pinyin": "weinanzhen", "kaitouzimu": "wnz", "code": "WNJ" },
        { "id": "02229", "name": "沃皮", "pinyin": "wopi", "kaitouzimu": "wp", "code": "WPT" },
        { "id": "02230", "name": "吴堡", "pinyin": "wupu", "kaitouzimu": "wb", "code": "WUY" },
        { "id": "02231", "name": "汪清", "pinyin": "wangqing", "kaitouzimu": "wq", "code": "WQL" },
        { "id": "02232", "name": "吴桥", "pinyin": "wuqiao", "kaitouzimu": "wq", "code": "WUP" },
        { "id": "02233", "name": "武清", "pinyin": "wuqing", "kaitouzimu": "wq", "code": "WWP" },
        { "id": "02234", "name": "威舍", "pinyin": "weishe", "kaitouzimu": "ws", "code": "WSM" },
        { "id": "02235", "name": "文水", "pinyin": "wenshui", "kaitouzimu": "ws", "code": "WEV" },
        { "id": "02236", "name": "武山", "pinyin": "wushan", "kaitouzimu": "ws", "code": "WSJ" },
        { "id": "02237", "name": "武胜", "pinyin": "wusheng", "kaitouzimu": "ws", "code": "WSE" },
        { "id": "02238", "name": "乌苏", "pinyin": "wusu", "kaitouzimu": "ws", "code": "WSR" },
        { "id": "02239", "name": "魏善庄", "pinyin": "weishanzhuang", "kaitouzimu": "wsz", "code": "WSP" },
        { "id": "02240", "name": "王瞳", "pinyin": "wangtong", "kaitouzimu": "wt", "code": "WTP" },
        { "id": "02241", "name": "歪头山", "pinyin": "waitoushan", "kaitouzimu": "wts", "code": "WIT" },
        { "id": "02242", "name": "五台山", "pinyin": "wutaishan", "kaitouzimu": "wts", "code": "WSV" },
        { "id": "02243", "name": "王团庄", "pinyin": "wangtuanzhuang", "kaitouzimu": "wtz", "code": "WZJ" },
        { "id": "02244", "name": "乌兰察布", "pinyin": "wulanchabu", "kaitouzimu": "wlcb", "code": "WPC" },
        { "id": "02245", "name": "五通", "pinyin": "wutong", "kaitouzimu": "wt", "code": "WTZ" },
        { "id": "02246", "name": "无为", "pinyin": "wuwei", "kaitouzimu": "ww", "code": "IIH" },
        { "id": "02247", "name": "武威", "pinyin": "wuwei", "kaitouzimu": "ww", "code": "WUJ" },
        { "id": "02248", "name": "武威南", "pinyin": "wuweinan", "kaitouzimu": "wwn", "code": "WWJ" },
        { "id": "02249", "name": "瓦屋山", "pinyin": "wawushan", "kaitouzimu": "wws", "code": "WAH" },
        { "id": "02250", "name": "五五", "pinyin": "wuwu", "kaitouzimu": "ww", "code": "WVR" },
        { "id": "02251", "name": "无锡东", "pinyin": "wuxidong", "kaitouzimu": "wxd", "code": "WGH" },
        { "id": "02252", "name": "卫星", "pinyin": "weixing", "kaitouzimu": "wx", "code": "WVB" },
        { "id": "02253", "name": "闻喜", "pinyin": "wenxi", "kaitouzimu": "wx", "code": "WXV" },
        { "id": "02254", "name": "乌西", "pinyin": "wuxi", "kaitouzimu": "wx", "code": "WXR" },
        { "id": "02255", "name": "无锡", "pinyin": "wuxi", "kaitouzimu": "wx", "code": "WXH" },
        { "id": "02256", "name": "武乡", "pinyin": "wuxiang", "kaitouzimu": "wx", "code": "WVV" },
        { "id": "02257", "name": "无锡新区", "pinyin": "wuxixinqu", "kaitouzimu": "wxxq", "code": "IFH" },
        { "id": "02258", "name": "吴圩", "pinyin": "wuxu", "kaitouzimu": "wy", "code": "WYZ" },
        { "id": "02259", "name": "武穴", "pinyin": "wuxue", "kaitouzimu": "wx", "code": "WXN" },
        { "id": "02260", "name": "闻喜西", "pinyin": "wenxixi", "kaitouzimu": "wxx", "code": "WOV" },
        { "id": "02261", "name": "王杨", "pinyin": "wangyang", "kaitouzimu": "wy", "code": "WYB" },
        { "id": "02262", "name": "武夷山北", "pinyin": "wuyishanbei", "kaitouzimu": "wysb", "code": "WBS" },
        { "id": "02263", "name": "武义北", "pinyin": "wuyibei", "kaitouzimu": "wyb", "code": "WDH" },
        { "id": "02264", "name": "武夷山东", "pinyin": "wuyishandong", "kaitouzimu": "wysd", "code": "WCS" },
        { "id": "02265", "name": "五营", "pinyin": "wuying", "kaitouzimu": "wy", "code": "WWB" },
        { "id": "02266", "name": "武义", "pinyin": "wuyi", "kaitouzimu": "wy", "code": "RYH" },
        { "id": "02267", "name": "乌伊岭", "pinyin": "wuyiling", "kaitouzimu": "wyl", "code": "WPB" },
        { "id": "02268", "name": "武夷山", "pinyin": "wuyishan", "kaitouzimu": "wys", "code": "WAS" },
        { "id": "02269", "name": "瓦窑田", "pinyin": "wayaotian", "kaitouzimu": "wjt", "code": "WIM" },
        { "id": "02270", "name": "万源", "pinyin": "wanyuan", "kaitouzimu": "wy", "code": "WYY" },
        { "id": "02271", "name": "五原", "pinyin": "wuyuan", "kaitouzimu": "wy", "code": "WYC" },
        { "id": "02272", "name": "婺源", "pinyin": "wuyuan", "kaitouzimu": "wy", "code": "WYG" },
        { "id": "02273", "name": "苇子沟", "pinyin": "weizigou", "kaitouzimu": "wzg", "code": "WZL" },
        { "id": "02274", "name": "万州", "pinyin": "wanzhou", "kaitouzimu": "wz", "code": "WYW" },
        { "id": "02275", "name": "韦庄", "pinyin": "weizhuang", "kaitouzimu": "wz", "code": "WZY" },
        { "id": "02276", "name": "温州", "pinyin": "wenzhou", "kaitouzimu": "wz", "code": "RZH" },
        { "id": "02277", "name": "梧州", "pinyin": "wuzhou", "kaitouzimu": "wz", "code": "WZZ" },
        { "id": "02278", "name": "五寨", "pinyin": "wuzhai", "kaitouzimu": "wz", "code": "WZV" },
        { "id": "02279", "name": "武陟", "pinyin": "wuzhi", "kaitouzimu": "wz", "code": "WIF" },
        { "id": "02280", "name": "温州南", "pinyin": "wenzhounan", "kaitouzimu": "wzn", "code": "VRH" },
        { "id": "02281", "name": "梧州南", "pinyin": "wuzhounan", "kaitouzimu": "wzn", "code": "WBZ" },
        { "id": "02282", "name": "王兆屯", "pinyin": "wangzhaotun", "kaitouzimu": "wzt", "code": "WZB" },
        { "id": "02283", "name": "微子镇", "pinyin": "weizizhen", "kaitouzimu": "wzz", "code": "WQP" },
        { "id": "02284", "name": "魏杖子", "pinyin": "weizhangzi", "kaitouzimu": "wzz", "code": "WKD" },],
    X: [
        { "id": "02285", "name": "西安北", "pinyin": "xianbei", "kaitouzimu": "xab", "code": "EAY" },
        { "id": "02286", "name": "兴安北", "pinyin": "xinganbei", "kaitouzimu": "xab", "code": "XDZ" },
        { "id": "02287", "name": "西安", "pinyin": "xian", "kaitouzimu": "xa", "code": "XAY" },
        { "id": "02288", "name": "西安南", "pinyin": "xiannan", "kaitouzimu": "xan", "code": "CAY" },
        { "id": "02289", "name": "新安", "pinyin": "xinan", "kaitouzimu": "xa", "code": "EAM" },
        { "id": "02290", "name": "兴安", "pinyin": "xingan", "kaitouzimu": "xa", "code": "XAZ" },
        { "id": "02291", "name": "新安县", "pinyin": "xinanxian", "kaitouzimu": "xax", "code": "XAF" },
        { "id": "02292", "name": "新保安", "pinyin": "xinbaoan", "kaitouzimu": "xba", "code": "XAP" },
        { "id": "02293", "name": "下板城", "pinyin": "xiabancheng", "kaitouzimu": "xbc", "code": "EBP" },
        { "id": "02294", "name": "西八里", "pinyin": "xibali", "kaitouzimu": "xbl", "code": "XLP" },
        { "id": "02295", "name": "许昌东", "pinyin": "xuchangdong", "kaitouzimu": "xcd", "code": "XVF" },
        { "id": "02296", "name": "西昌", "pinyin": "xichang", "kaitouzimu": "xc", "code": "ECW" },
        { "id": "02297", "name": "项城", "pinyin": "xiangcheng", "kaitouzimu": "xc", "code": "ERN" },
        { "id": "02298", "name": "兴城", "pinyin": "xingcheng", "kaitouzimu": "xc", "code": "XCD" },
        { "id": "02299", "name": "许昌", "pinyin": "xuchang", "kaitouzimu": "xc", "code": "XCF" },
        { "id": "02300", "name": "宣城", "pinyin": "xuancheng", "kaitouzimu": "xc", "code": "ECH" },
        { "id": "02301", "name": "西昌南", "pinyin": "xichangnan", "kaitouzimu": "xcn", "code": "ENW" },
        { "id": "02302", "name": "小村", "pinyin": "xiaocun", "kaitouzimu": "xc", "code": "XEM" },
        { "id": "02303", "name": "新绰源", "pinyin": "xinchuoyuan", "kaitouzimu": "xcy", "code": "XRX" },
        { "id": "02304", "name": "下城子", "pinyin": "xiachengzi", "kaitouzimu": "xcz", "code": "XCB" },
        { "id": "02305", "name": "新城子", "pinyin": "xinchengzi", "kaitouzimu": "xcz", "code": "XCT" },
        { "id": "02306", "name": "新都东", "pinyin": "xindudong", "kaitouzimu": "xdd", "code": "EWW" },
        { "id": "02307", "name": "喜德", "pinyin": "xide", "kaitouzimu": "xd", "code": "EDW" },
        { "id": "02308", "name": "小得江", "pinyin": "xiaodejiang", "kaitouzimu": "xdj", "code": "EJM" },
        { "id": "02309", "name": "西大庙", "pinyin": "xidamiao", "kaitouzimu": "xdm", "code": "XMP" },
        { "id": "02310", "name": "小东", "pinyin": "xiaodong", "kaitouzimu": "xdo", "code": "XOD" },
        { "id": "02311", "name": "小董", "pinyin": "xiaodong", "kaitouzimu": "xd", "code": "XEZ" },
        { "id": "02312", "name": "西渡", "pinyin": "xidu", "kaitouzimu": "xd", "code": "XDA" },
        { "id": "02313", "name": "香坊", "pinyin": "xiangfang", "kaitouzimu": "xf", "code": "XFB" },
        { "id": "02314", "name": "西丰", "pinyin": "xifeng", "kaitouzimu": "xf", "code": "XFT" },
        { "id": "02315", "name": "息烽", "pinyin": "xifeng", "kaitouzimu": "xf", "code": "XFW" },
        { "id": "02316", "name": "襄汾", "pinyin": "xiangfen", "kaitouzimu": "xf", "code": "XFV" },
        { "id": "02317", "name": "信丰", "pinyin": "xinfeng", "kaitouzimu": "xf", "code": "EFG" },
        { "id": "02318", "name": "襄汾西", "pinyin": "xiangfenxi", "kaitouzimu": "xfx", "code": "XTV" },
        { "id": "02319", "name": "孝感", "pinyin": "xiaogan", "kaitouzimu": "xg", "code": "XGN" },
        { "id": "02320", "name": "新干", "pinyin": "xingan", "kaitouzimu": "xg", "code": "EGG" },
        { "id": "02321", "name": "轩岗", "pinyin": "xuangang", "kaitouzimu": "xg", "code": "XGV" },
        { "id": "02322", "name": "孝感北", "pinyin": "xiaoganbei", "kaitouzimu": "xgb", "code": "XJN" },
        { "id": "02323", "name": "西固城", "pinyin": "xigucheng", "kaitouzimu": "xgc", "code": "XUJ" },
        { "id": "02324", "name": "西固", "pinyin": "xigu", "kaitouzimu": "xg", "code": "XIJ" },
        { "id": "02325", "name": "兴国", "pinyin": "xingguo", "kaitouzimu": "xg", "code": "EUG" },
        { "id": "02326", "name": "香港西九龙", "pinyin": "xianggangxijiulong", "kaitouzimu": "xgxjl", "code": "XJA" },
        { "id": "02327", "name": "夏官营", "pinyin": "xiaguanying", "kaitouzimu": "xgy", "code": "XGJ" },
        { "id": "02328", "name": "西岗子", "pinyin": "xigangzi", "kaitouzimu": "xgz", "code": "NBB" },
        { "id": "02329", "name": "宣汉", "pinyin": "xuanhan", "kaitouzimu": "xh", "code": "XHY" },
        { "id": "02330", "name": "西湖东", "pinyin": "xihudong", "kaitouzimu": "xhd", "code": "WDQ" },
        { "id": "02331", "name": "襄河", "pinyin": "xianghe", "kaitouzimu": "xh", "code": "XXB" },
        { "id": "02332", "name": "新和", "pinyin": "xinhe", "kaitouzimu": "xh", "code": "XIR" },
        { "id": "02333", "name": "宣和", "pinyin": "xuanhe", "kaitouzimu": "xh", "code": "XWJ" },
        { "id": "02334", "name": "斜河涧", "pinyin": "xiehejian", "kaitouzimu": "xhj", "code": "EEP" },
        { "id": "02335", "name": "新化南", "pinyin": "xinhuanan", "kaitouzimu": "xhn", "code": "EJQ" },
        { "id": "02336", "name": "新华屯", "pinyin": "xinhuatun", "kaitouzimu": "xht", "code": "XAX" },
        { "id": "02337", "name": "新华", "pinyin": "xinhua", "kaitouzimu": "xh", "code": "XHB" },
        { "id": "02338", "name": "新化", "pinyin": "xinhua", "kaitouzimu": "xh", "code": "EHQ" },
        { "id": "02339", "name": "新晃", "pinyin": "xinhuang", "kaitouzimu": "xh", "code": "XLQ" },
        { "id": "02340", "name": "新会", "pinyin": "xinhui", "kaitouzimu": "xh", "code": "EFQ" },
        { "id": "02341", "name": "宣化", "pinyin": "xuanhua", "kaitouzimu": "xh", "code": "XHP" },
        { "id": "02342", "name": "新晃西", "pinyin": "xinhuangxi", "kaitouzimu": "xhx", "code": "EWQ" },
        { "id": "02343", "name": "兴和西", "pinyin": "xinghexi", "kaitouzimu": "xhx", "code": "XEC" },
        { "id": "02344", "name": "下花园", "pinyin": "xiahuayuan", "kaitouzimu": "xhy", "code": "XYP" },
        { "id": "02345", "name": "小河沿", "pinyin": "xiaoheyan", "kaitouzimu": "xhy", "code": "XYD" },
        { "id": "02346", "name": "小河镇", "pinyin": "xiaohezhen", "kaitouzimu": "xhz", "code": "EKY" },
        { "id": "02347", "name": "先锋", "pinyin": "xianfeng", "kaitouzimu": "xf", "code": "NQQ" },
        { "id": "02348", "name": "湘府路", "pinyin": "xiangfulu", "kaitouzimu": "xfl", "code": "FVQ" },
        { "id": "02349", "name": "香樟路", "pinyin": "xiangzhanglu", "kaitouzimu": "xzl", "code": "FNQ" },
        { "id": "02350", "name": "孝感东", "pinyin": "xiaogandong", "kaitouzimu": "xgd", "code": "GDN" },
        { "id": "02351", "name": "萧县北", "pinyin": "xiaoxianbei", "kaitouzimu": "xxb", "code": "QSH" },
        { "id": "02352", "name": "修文县", "pinyin": "xiuwenxian", "kaitouzimu": "xwx", "code": "XWE" },
        { "id": "02353", "name": "徐家店", "pinyin": "xujiadian", "kaitouzimu": "xjd", "code": "HYK" },
        { "id": "02354", "name": "峡江", "pinyin": "xiajiang", "kaitouzimu": "xj", "code": "EJG" },
        { "id": "02355", "name": "辛集", "pinyin": "xinji", "kaitouzimu": "xj", "code": "ENP" },
        { "id": "02356", "name": "新江", "pinyin": "xinjiang", "kaitouzimu": "xj", "code": "XJM" },
        { "id": "02357", "name": "新绛", "pinyin": "xinjiang", "kaitouzimu": "xj", "code": "XJV" },
        { "id": "02358", "name": "新津", "pinyin": "xinjin", "kaitouzimu": "xj", "code": "IRW" },
        { "id": "02359", "name": "徐家", "pinyin": "xujia", "kaitouzimu": "xj", "code": "XJB" },
        { "id": "02360", "name": "西街口", "pinyin": "xijiekou", "kaitouzimu": "xjk", "code": "EKM" },
        { "id": "02361", "name": "小金口", "pinyin": "xiaojinkou", "kaitouzimu": "xjk", "code": "NKQ" },
        { "id": "02362", "name": "辛集南", "pinyin": "xinjinan", "kaitouzimu": "xjn", "code": "IJP" },
        { "id": "02363", "name": "新津南", "pinyin": "xinjinnan", "kaitouzimu": "xjn", "code": "ITW" },
        { "id": "02364", "name": "许家台", "pinyin": "xujiatai", "kaitouzimu": "xjt", "code": "XTJ" },
        { "id": "02365", "name": "许家屯", "pinyin": "xujiatun", "kaitouzimu": "xjt", "code": "XJT" },
        { "id": "02366", "name": "谢家镇", "pinyin": "xiejiazhen", "kaitouzimu": "xjz", "code": "XMT" },
        { "id": "02367", "name": "兴凯", "pinyin": "xingkai", "kaitouzimu": "xk", "code": "EKB" },
        { "id": "02368", "name": "西来", "pinyin": "xilai", "kaitouzimu": "xl", "code": "XLE" },
        { "id": "02369", "name": "香兰", "pinyin": "xianglan", "kaitouzimu": "xl", "code": "XNB" },
        { "id": "02370", "name": "小榄", "pinyin": "xiaolan", "kaitouzimu": "xl", "code": "EAQ" },
        { "id": "02371", "name": "兴隆店", "pinyin": "xinglongdian", "kaitouzimu": "xld", "code": "XDD" },
        { "id": "02372", "name": "新乐", "pinyin": "xinle", "kaitouzimu": "xl", "code": "ELP" },
        { "id": "02373", "name": "西林", "pinyin": "xilin", "kaitouzimu": "xl", "code": "XYB" },
        { "id": "02374", "name": "西柳", "pinyin": "xiliu", "kaitouzimu": "xl", "code": "GCT" },
        { "id": "02375", "name": "仙林", "pinyin": "xianlin", "kaitouzimu": "xl", "code": "XPH" },
        { "id": "02376", "name": "小岭", "pinyin": "xiaoling", "kaitouzimu": "xl", "code": "XLB" },
        { "id": "02377", "name": "新李", "pinyin": "xinli", "kaitouzimu": "xl", "code": "XLJ" },
        { "id": "02378", "name": "新林", "pinyin": "xinlin", "kaitouzimu": "xl", "code": "XPX" },
        { "id": "02379", "name": "锡林浩特", "pinyin": "xilinhaote", "kaitouzimu": "xlht", "code": "XTC" },
        { "id": "02380", "name": "新立屯", "pinyin": "xinlitun", "kaitouzimu": "xlt", "code": "XLD" },
        { "id": "02381", "name": "兴隆县", "pinyin": "xinglongxian", "kaitouzimu": "xlx", "code": "EXP" },
        { "id": "02382", "name": "新立镇", "pinyin": "xinlizhen", "kaitouzimu": "xlz", "code": "XGT" },
        { "id": "02383", "name": "兴隆镇", "pinyin": "xinglongzhen", "kaitouzimu": "xlz", "code": "XZB" },
        { "id": "02384", "name": "厦门北", "pinyin": "xiamenbei", "kaitouzimu": "xmb", "code": "XKS" },
        { "id": "02385", "name": "新民北", "pinyin": "xinminbei", "kaitouzimu": "xmb", "code": "XOT" },
        { "id": "02386", "name": "厦 门", "pinyin": "xiamen", "kaitouzimu": "xm", "code": "EMS" },
        { "id": "02387", "name": "厦门", "pinyin": "xiamen", "kaitouzimu": "xm", "code": "XMS" },
        { "id": "02388", "name": "新民", "pinyin": "xinmin", "kaitouzimu": "xm", "code": "XMD" },
        { "id": "02389", "name": "厦门高崎", "pinyin": "xiamengaoqi", "kaitouzimu": "xmgq", "code": "XBS" },
        { "id": "02390", "name": "西麻山", "pinyin": "ximashan", "kaitouzimu": "xms", "code": "XMB" },
        { "id": "02391", "name": "下马塘", "pinyin": "xiamatang", "kaitouzimu": "xmt", "code": "XAT" },
        { "id": "02392", "name": "孝南", "pinyin": "xiaonan", "kaitouzimu": "xn", "code": "XNV" },
        { "id": "02393", "name": "咸宁北", "pinyin": "xianningbei", "kaitouzimu": "xnb", "code": "XRN" },
        { "id": "02394", "name": "咸宁东", "pinyin": "xianningdong", "kaitouzimu": "xnd", "code": "XKN" },
        { "id": "02395", "name": "西宁", "pinyin": "xining", "kaitouzimu": "xn", "code": "XNO" },
        { "id": "02396", "name": "咸宁", "pinyin": "xianning", "kaitouzimu": "xn", "code": "XNN" },
        { "id": "02397", "name": "兴宁", "pinyin": "xingning", "kaitouzimu": "xn", "code": "ENQ" },
        { "id": "02398", "name": "咸宁南", "pinyin": "xianningnan", "kaitouzimu": "xnn", "code": "UNN" },
        { "id": "02399", "name": "犀浦东", "pinyin": "xipudong", "kaitouzimu": "xpd", "code": "XAW" },
        { "id": "02400", "name": "西平", "pinyin": "xiping", "kaitouzimu": "xp", "code": "XPN" },
        { "id": "02401", "name": "兴平", "pinyin": "xingping", "kaitouzimu": "xp", "code": "XPY" },
        { "id": "02402", "name": "溆浦南", "pinyin": "xupunan", "kaitouzimu": "xpn", "code": "EMQ" },
        { "id": "02403", "name": "新坪田", "pinyin": "xinpingtian", "kaitouzimu": "xpt", "code": "XPM" },
        { "id": "02404", "name": "犀浦", "pinyin": "xipu", "kaitouzimu": "xp", "code": "XIW" },
        { "id": "02405", "name": "霞浦", "pinyin": "xiapu", "kaitouzimu": "xp", "code": "XOS" },
        { "id": "02406", "name": "溆浦", "pinyin": "xupu", "kaitouzimu": "xp", "code": "EPQ" },
        { "id": "02407", "name": "西平西", "pinyin": "xipingxi", "kaitouzimu": "xpx", "code": "EGQ" },
        { "id": "02408", "name": "新青", "pinyin": "xinqing", "kaitouzimu": "xq", "code": "XQB" },
        { "id": "02409", "name": "新邱", "pinyin": "xinqiu", "kaitouzimu": "xq", "code": "XQD" },
        { "id": "02410", "name": "兴泉堡", "pinyin": "xingquanbu", "kaitouzimu": "xqp", "code": "XQJ" },
        { "id": "02411", "name": "仙人桥", "pinyin": "xianrenqiao", "kaitouzimu": "xrq", "code": "XRL" },
        { "id": "02412", "name": "小寺沟", "pinyin": "xiaosigou", "kaitouzimu": "xsg", "code": "ESP" },
        { "id": "02413", "name": "浠水", "pinyin": "xishui", "kaitouzimu": "xs", "code": "XZN" },
        { "id": "02414", "name": "下社", "pinyin": "xiashe", "kaitouzimu": "xs", "code": "XSV" },
        { "id": "02415", "name": "夏石", "pinyin": "xiashi", "kaitouzimu": "xs", "code": "XIZ" },
        { "id": "02416", "name": "小哨", "pinyin": "xiaoshao", "kaitouzimu": "xs", "code": "XAM" },
        { "id": "02417", "name": "小市", "pinyin": "xiaoshi", "kaitouzimu": "xs", "code": "XST" },
        { "id": "02418", "name": "杏树", "pinyin": "xingshu", "kaitouzimu": "xs", "code": "XSB" },
        { "id": "02419", "name": "秀山", "pinyin": "xiushan", "kaitouzimu": "xs", "code": "ETW" },
        { "id": "02420", "name": "徐水", "pinyin": "xushui", "kaitouzimu": "xs", "code": "XSP" },
        { "id": "02421", "name": "新松浦", "pinyin": "xinsongpu", "kaitouzimu": "xsp", "code": "XOB" },
        { "id": "02422", "name": "杏树屯", "pinyin": "xingshutun", "kaitouzimu": "xst", "code": "XDT" },
        { "id": "02423", "name": "许三湾", "pinyin": "xusanwan", "kaitouzimu": "xsw", "code": "XSJ" },
        { "id": "02424", "name": "响水县", "pinyin": "xiangshuixian", "kaitouzimu": "xsx", "code": "XSU" },
        { "id": "02425", "name": "湘潭", "pinyin": "xiangtan", "kaitouzimu": "xt", "code": "XTQ" },
        { "id": "02426", "name": "向塘", "pinyin": "xiangtang", "kaitouzimu": "xt", "code": "XTG" },
        { "id": "02427", "name": "邢台", "pinyin": "xingtai", "kaitouzimu": "xt", "code": "XTP" },
        { "id": "02428", "name": "湘潭北", "pinyin": "xiangtanbei", "kaitouzimu": "xtb", "code": "EDQ" },
        { "id": "02429", "name": "邢台东", "pinyin": "xingtaidong", "kaitouzimu": "xtd", "code": "EDP" },
        { "id": "02430", "name": "仙桃西", "pinyin": "xiantaoxi", "kaitouzimu": "xtx", "code": "XAN" },
        { "id": "02431", "name": "下台子", "pinyin": "xiataizi", "kaitouzimu": "xtz", "code": "EIP" },
        { "id": "02432", "name": "徐闻", "pinyin": "xuwen", "kaitouzimu": "xw", "code": "XJQ" },
        { "id": "02433", "name": "宣威", "pinyin": "xuanwei", "kaitouzimu": "xw", "code": "XWM" },
        { "id": "02434", "name": "新窝铺", "pinyin": "xinwopu", "kaitouzimu": "xwp", "code": "EPD" },
        { "id": "02435", "name": "西乌旗", "pinyin": "xiwuqi", "kaitouzimu": "xwq", "code": "XWC" },
        { "id": "02436", "name": "修武", "pinyin": "xiuwu", "kaitouzimu": "xw", "code": "XWF" },
        { "id": "02437", "name": "修武西", "pinyin": "xiuwuxi", "kaitouzimu": "xwx", "code": "EXF" },
        { "id": "02438", "name": "新香坊北", "pinyin": "xinxiangfangbei", "kaitouzimu": "xxfb", "code": "RHB" },
        { "id": "02439", "name": "新乡东", "pinyin": "xinxiangdong", "kaitouzimu": "xxd", "code": "EGF" },
        { "id": "02440", "name": "西峡", "pinyin": "xixia", "kaitouzimu": "xx", "code": "XIF" },
        { "id": "02441", "name": "西乡", "pinyin": "xixiang", "kaitouzimu": "xx", "code": "XQY" },
        { "id": "02442", "name": "息县", "pinyin": "xixian", "kaitouzimu": "xx", "code": "ENN" },
        { "id": "02443", "name": "湘乡", "pinyin": "xiangxiang", "kaitouzimu": "xx", "code": "XXQ" },
        { "id": "02444", "name": "孝西", "pinyin": "xiaoxi", "kaitouzimu": "xx", "code": "XOV" },
        { "id": "02445", "name": "新县", "pinyin": "xinxian", "kaitouzimu": "xx", "code": "XSN" },
        { "id": "02446", "name": "新乡", "pinyin": "xinxiang", "kaitouzimu": "xx", "code": "XXF" },
        { "id": "02447", "name": "小新街", "pinyin": "xiaoxinjie", "kaitouzimu": "xxj", "code": "XXM" },
        { "id": "02448", "name": "新兴县", "pinyin": "xinxingxian", "kaitouzimu": "xxx", "code": "XGQ" },
        { "id": "02449", "name": "西小召", "pinyin": "xixiaozhao", "kaitouzimu": "xxz", "code": "XZC" },
        { "id": "02450", "name": "小西庄", "pinyin": "xiaoxizhuang", "kaitouzimu": "xxz", "code": "XXP" },
        { "id": "02451", "name": "咸阳", "pinyin": "xianyang", "kaitouzimu": "xy", "code": "XYY" },
        { "id": "02452", "name": "襄阳", "pinyin": "xiangyang", "kaitouzimu": "xy", "code": "XFN" },
        { "id": "02453", "name": "向阳", "pinyin": "xiangyang", "kaitouzimu": "xy", "code": "XDB" },
        { "id": "02454", "name": "信阳", "pinyin": "xinyang", "kaitouzimu": "xy", "code": "XUN" },
        { "id": "02455", "name": "旬阳", "pinyin": "xunyang", "kaitouzimu": "xy", "code": "XUY" },
        { "id": "02456", "name": "新余北", "pinyin": "xinyubei", "kaitouzimu": "xyb", "code": "XBG" },
        { "id": "02457", "name": "旬阳北", "pinyin": "xunyangbei", "kaitouzimu": "xyb", "code": "XBY" },
        { "id": "02458", "name": "西阳村", "pinyin": "xiyangcun", "kaitouzimu": "xyc", "code": "XQF" },
        { "id": "02459", "name": "熊岳城", "pinyin": "xiongyuecheng", "kaitouzimu": "xyc", "code": "XYT" },
        { "id": "02460", "name": "咸阳秦都", "pinyin": "xianyangqindu", "kaitouzimu": "xyqd", "code": "XOY" },
        { "id": "02461", "name": "襄阳东", "pinyin": "xiangyangdong", "kaitouzimu": "xyd", "code": "XWN" },
        { "id": "02462", "name": "信阳东", "pinyin": "xinyangdong", "kaitouzimu": "xyd", "code": "OYN" },
        { "id": "02463", "name": "兴业", "pinyin": "xingye", "kaitouzimu": "xy", "code": "SNZ" },
        { "id": "02464", "name": "小雨谷", "pinyin": "xiaoyugu", "kaitouzimu": "xyg", "code": "XHM" },
        { "id": "02465", "name": "新沂", "pinyin": "xinyi", "kaitouzimu": "xy", "code": "VIH" },
        { "id": "02466", "name": "信宜", "pinyin": "xinyi", "kaitouzimu": "xy", "code": "EEQ" },
        { "id": "02467", "name": "兴义", "pinyin": "xingyi", "kaitouzimu": "xy", "code": "XRZ" },
        { "id": "02468", "name": "小月旧", "pinyin": "xiaoyuejiu", "kaitouzimu": "xyj", "code": "XFM" },
        { "id": "02469", "name": "仙游", "pinyin": "xianyou", "kaitouzimu": "xy", "code": "XWS" },
        { "id": "02470", "name": "小扬气", "pinyin": "xiaoyangqi", "kaitouzimu": "xyq", "code": "XYX" },
        { "id": "02471", "name": "襄垣", "pinyin": "xiangyuan", "kaitouzimu": "xy", "code": "EIF" },
        { "id": "02472", "name": "祥云", "pinyin": "xiangyun", "kaitouzimu": "xy", "code": "EXM" },
        { "id": "02473", "name": "新余", "pinyin": "xinyu", "kaitouzimu": "xy", "code": "XUG" },
        { "id": "02474", "name": "夏邑县", "pinyin": "xiayixian", "kaitouzimu": "xyx", "code": "EJH" },
        { "id": "02475", "name": "祥云西", "pinyin": "xiangyunxi", "kaitouzimu": "xyx", "code": "EXM" },
        { "id": "02476", "name": "新友谊", "pinyin": "xinyouyi", "kaitouzimu": "xyy", "code": "EYB" },
        { "id": "02477", "name": "新阳镇", "pinyin": "xinyangzhen", "kaitouzimu": "xyz", "code": "XZJ" },
        { "id": "02478", "name": "新郑机场", "pinyin": "xinzhengjichang", "kaitouzimu": "xzjc", "code": "EZF" },
        { "id": "02479", "name": "徐州东", "pinyin": "xuzhoudong", "kaitouzimu": "xzd", "code": "UUH" },
        { "id": "02480", "name": "新帐房", "pinyin": "xinzhangfang", "kaitouzimu": "xzf", "code": "XZX" },
        { "id": "02481", "name": "忻州", "pinyin": "xinzhou", "kaitouzimu": "xz", "code": "XXV" },
        { "id": "02482", "name": "新肇", "pinyin": "xinzhao", "kaitouzimu": "xz", "code": "XZT" },
        { "id": "02483", "name": "徐州", "pinyin": "xuzhou", "kaitouzimu": "xz", "code": "XCH" },
        { "id": "02484", "name": "悬钟", "pinyin": "xuanzhong", "kaitouzimu": "xz", "code": "XRP" },
        { "id": "02485", "name": "汐子", "pinyin": "xizi", "kaitouzimu": "xz", "code": "XZD" },
        { "id": "02486", "name": "西哲里木", "pinyin": "xizhelimu", "kaitouzimu": "xzlm", "code": "XRD" },
        { "id": "02487", "name": "忻州西", "pinyin": "xinzhouxi", "kaitouzimu": "xzx", "code": "IXV" },
        { "id": "02488", "name": "新杖子", "pinyin": "xinzhangzi", "kaitouzimu": "xzz", "code": "ERP" },],
    Y: [
        { "id": "02489", "name": "雅安", "pinyin": "yaan", "kaitouzimu": "ya", "code": "YAE" },
        { "id": "02490", "name": "延安", "pinyin": "yanan", "kaitouzimu": "ya", "code": "YWY" },
        { "id": "02491", "name": "姚安", "pinyin": "yaoan", "kaitouzimu": "ya", "code": "YAC" },
        { "id": "02492", "name": "依安", "pinyin": "yian", "kaitouzimu": "ya", "code": "YAX" },
        { "id": "02493", "name": "永安", "pinyin": "yongan", "kaitouzimu": "ya", "code": "YAS" },
        { "id": "02494", "name": "永安南", "pinyin": "yongannan", "kaitouzimu": "yan", "code": "YQS" },
        { "id": "02495", "name": "洋县西", "pinyin": "yangxianxi", "kaitouzimu": "yxx", "code": "YXY" },
        { "id": "02496", "name": "姚渡", "pinyin": "yaodu", "kaitouzimu": "yd", "code": "AOJ" },
        { "id": "02497", "name": "永安乡", "pinyin": "yonganxiang", "kaitouzimu": "yax", "code": "YNB" },
        { "id": "02498", "name": "宜宾", "pinyin": "yibin", "kaitouzimu": "yb", "code": "YBW" },
        { "id": "02499", "name": "亚布力", "pinyin": "yabuli", "kaitouzimu": "ybl", "code": "YBB" },
        { "id": "02500", "name": "迎宾路", "pinyin": "yingbinlu", "kaitouzimu": "ybl", "code": "YFW" },
        { "id": "02501", "name": "亚布力南", "pinyin": "yabulinan", "kaitouzimu": "ybln", "code": "YWB" },
        { "id": "02502", "name": "叶柏寿", "pinyin": "yebaishou", "kaitouzimu": "ybs", "code": "YBD" },
        { "id": "02503", "name": "元宝山", "pinyin": "yuanbaoshan", "kaitouzimu": "ybs", "code": "YUD" },
        { "id": "02504", "name": "亚布力西", "pinyin": "yabulixi", "kaitouzimu": "yblx", "code": "YSB" },
        { "id": "02505", "name": "羊草", "pinyin": "yangcao", "kaitouzimu": "yc", "code": "YAB" },
        { "id": "02506", "name": "盐城北", "pinyin": "yanchengbei", "kaitouzimu": "ycb", "code": "AEH" },
        { "id": "02507", "name": "运城北", "pinyin": "yunchengbei", "kaitouzimu": "ycb", "code": "ABV" },
        { "id": "02508", "name": "秧草地", "pinyin": "yangcaodi", "kaitouzimu": "ycd", "code": "YKM" },
        { "id": "02509", "name": "宜昌东", "pinyin": "yichangdong", "kaitouzimu": "ycd", "code": "HAN" },
        { "id": "02510", "name": "永川东", "pinyin": "yongchuandong", "kaitouzimu": "ycd", "code": "WMW" },
        { "id": "02511", "name": "禹城东", "pinyin": "yuchengdong", "kaitouzimu": "ycd", "code": "YSK" },
        { "id": "02512", "name": "盐城", "pinyin": "yancheng", "kaitouzimu": "yc", "code": "AFH" },
        { "id": "02513", "name": "盐池", "pinyin": "yanchi", "kaitouzimu": "yc", "code": "YKJ" },
        { "id": "02514", "name": "砚川", "pinyin": "yanchuan", "kaitouzimu": "yc", "code": "YYY" },
        { "id": "02515", "name": "晏城", "pinyin": "yancheng", "kaitouzimu": "yc", "code": "YEK" },
        { "id": "02516", "name": "雁翅", "pinyin": "yanchi", "kaitouzimu": "yc", "code": "YAP" },
        { "id": "02517", "name": "羊场", "pinyin": "yangchang", "kaitouzimu": "yc", "code": "YED" },
        { "id": "02518", "name": "阳岔", "pinyin": "yangcha", "kaitouzimu": "yc", "code": "YAL" },
        { "id": "02519", "name": "阳城", "pinyin": "yangcheng", "kaitouzimu": "yc", "code": "YNF" },
        { "id": "02520", "name": "阳澄湖", "pinyin": "yangchenghu", "kaitouzimu": "ych", "code": "AIH" },
        { "id": "02521", "name": "阳春", "pinyin": "yangchun", "kaitouzimu": "yc", "code": "YQQ" },
        { "id": "02522", "name": "叶城", "pinyin": "yecheng", "kaitouzimu": "yc", "code": "YER" },
        { "id": "02523", "name": "伊春", "pinyin": "yichun", "kaitouzimu": "yc", "code": "YCB" },
        { "id": "02524", "name": "宜昌", "pinyin": "yichang", "kaitouzimu": "yc", "code": "YCN" },
        { "id": "02525", "name": "宜城", "pinyin": "yicheng", "kaitouzimu": "yc", "code": "YIN" },
        { "id": "02526", "name": "宜春", "pinyin": "yichun", "kaitouzimu": "yc", "code": "YEG" },
        { "id": "02527", "name": "银川", "pinyin": "yinchuan", "kaitouzimu": "yc", "code": "YIJ" },
        { "id": "02528", "name": "迎春", "pinyin": "yingchun", "kaitouzimu": "yc", "code": "YYB" },
        { "id": "02529", "name": "应城", "pinyin": "yingcheng", "kaitouzimu": "yc", "code": "YHN" },
        { "id": "02530", "name": "永川", "pinyin": "yongchuan", "kaitouzimu": "yc", "code": "YCW" },
        { "id": "02531", "name": "禹城", "pinyin": "yucheng", "kaitouzimu": "yc", "code": "YCK" },
        { "id": "02532", "name": "岳池", "pinyin": "yuechi", "kaitouzimu": "yc", "code": "AWW" },
        { "id": "02533", "name": "运城", "pinyin": "yuncheng", "kaitouzimu": "yc", "code": "YNV" },
        { "id": "02534", "name": "郓城", "pinyin": "yuncheng", "kaitouzimu": "yc", "code": "YPK" },
        { "id": "02535", "name": "榆次", "pinyin": "yuci", "kaitouzimu": "yc", "code": "YCV" },
        { "id": "02536", "name": "云彩岭", "pinyin": "yuncailing", "kaitouzimu": "ycl", "code": "ACP" },
        { "id": "02537", "name": "杨村", "pinyin": "yangcun", "kaitouzimu": "yc", "code": "YBP" },
        { "id": "02538", "name": "宜春西", "pinyin": "yichunxi", "kaitouzimu": "ycx", "code": "YCG" },
        { "id": "02539", "name": "虞城县", "pinyin": "yuchengxian", "kaitouzimu": "ycx", "code": "IXH" },
        { "id": "02540", "name": "营城子", "pinyin": "yingchengzi", "kaitouzimu": "ycz", "code": "YCT" },
        { "id": "02541", "name": "英德", "pinyin": "yingde", "kaitouzimu": "yd", "code": "YDQ" },
        { "id": "02542", "name": "永登", "pinyin": "yongdeng", "kaitouzimu": "yd", "code": "YDJ" },
        { "id": "02543", "name": "云东海", "pinyin": "yundonghai", "kaitouzimu": "ydh", "code": "NAQ" },
        { "id": "02544", "name": "尹地", "pinyin": "yindi", "kaitouzimu": "yd", "code": "YDM" },
        { "id": "02545", "name": "永定", "pinyin": "yongding", "kaitouzimu": "yd", "code": "YGS" },
        { "id": "02546", "name": "阳东", "pinyin": "yangdong", "kaitouzimu": "yd", "code": "WLQ" },
        { "id": "02547", "name": "雁荡山", "pinyin": "yandangshan", "kaitouzimu": "yds", "code": "YGH" },
        { "id": "02548", "name": "于都", "pinyin": "yudu", "kaitouzimu": "yd", "code": "YDG" },
        { "id": "02549", "name": "园墩", "pinyin": "yuandun", "kaitouzimu": "yd", "code": "YAJ" },
        { "id": "02550", "name": "英德西", "pinyin": "yingdexi", "kaitouzimu": "ydx", "code": "IIQ" },
        { "id": "02551", "name": "伊尔施", "pinyin": "yiershi", "kaitouzimu": "yes", "code": "YET" },
        { "id": "02552", "name": "云浮东", "pinyin": "yunfudong", "kaitouzimu": "yfd", "code": "IXQ" },
        { "id": "02553", "name": "永福南", "pinyin": "yongfunan", "kaitouzimu": "yfn", "code": "YBZ" },
        { "id": "02554", "name": "永丰营", "pinyin": "yongfengying", "kaitouzimu": "yfy", "code": "YYM" },
        { "id": "02555", "name": "燕岗", "pinyin": "yangang", "kaitouzimu": "yg", "code": "YGW" },
        { "id": "02556", "name": "阳高", "pinyin": "yanggao", "kaitouzimu": "yg", "code": "YOV" },
        { "id": "02557", "name": "杨岗", "pinyin": "yanggang", "kaitouzimu": "yg", "code": "YRB" },
        { "id": "02558", "name": "雨格", "pinyin": "yuge", "kaitouzimu": "yg", "code": "VTM" },
        { "id": "02559", "name": "阳谷", "pinyin": "yanggu", "kaitouzimu": "yg", "code": "YIK" },
        { "id": "02560", "name": "友好", "pinyin": "youhao", "kaitouzimu": "yh", "code": "YOB" },
        { "id": "02561", "name": "余杭", "pinyin": "yuhang", "kaitouzimu": "yh", "code": "EVH" },
        { "id": "02562", "name": "沿河城", "pinyin": "yanhecheng", "kaitouzimu": "yhc", "code": "YHP" },
        { "id": "02563", "name": "洋河", "pinyin": "yanghe", "kaitouzimu": "yh", "code": "GTH" },
        { "id": "02564", "name": "岩会", "pinyin": "yanhui", "kaitouzimu": "yh", "code": "AEP" },
        { "id": "02565", "name": "颍上", "pinyin": "yingshang", "kaitouzimu": "ys", "code": "YVH" },
        { "id": "02566", "name": "永济北", "pinyin": "yongjibei", "kaitouzimu": "yjb", "code": "AJV" },
        { "id": "02567", "name": "羊臼河", "pinyin": "yangjiuhe", "kaitouzimu": "yjh", "code": "YHM" },
        { "id": "02568", "name": "延吉", "pinyin": "yanji", "kaitouzimu": "yj", "code": "YJL" },
        { "id": "02569", "name": "盐津", "pinyin": "yanjin", "kaitouzimu": "yj", "code": "AEW" },
        { "id": "02570", "name": "燕郊", "pinyin": "yanjiao", "kaitouzimu": "yj", "code": "AJP" },
        { "id": "02571", "name": "阳江", "pinyin": "yangjiang", "kaitouzimu": "yj", "code": "WRQ" },
        { "id": "02572", "name": "姚家", "pinyin": "yaojia", "kaitouzimu": "yj", "code": "YAT" },
        { "id": "02573", "name": "弋江", "pinyin": "yijiang", "kaitouzimu": "yj", "code": "RVH" },
        { "id": "02574", "name": "营街", "pinyin": "yingjie", "kaitouzimu": "yj", "code": "YAM" },
        { "id": "02575", "name": "永济", "pinyin": "yongji", "kaitouzimu": "yj", "code": "YIV" },
        { "id": "02576", "name": "永嘉", "pinyin": "yongjia", "kaitouzimu": "yj", "code": "URH" },
        { "id": "02577", "name": "余江", "pinyin": "yujiang", "kaitouzimu": "yj", "code": "YHG" },
        { "id": "02578", "name": "岳家井", "pinyin": "yuejiajing", "kaitouzimu": "yjj", "code": "YGJ" },
        { "id": "02579", "name": "一间堡", "pinyin": "yijianpu", "kaitouzimu": "yjb", "code": "YJT" },
        { "id": "02580", "name": "于家堡", "pinyin": "yujiapu", "kaitouzimu": "yjp", "code": "YKP" },
        { "id": "02581", "name": "英吉沙", "pinyin": "yingjisha", "kaitouzimu": "yjs", "code": "YIR" },
        { "id": "02582", "name": "云居寺", "pinyin": "yunjusi", "kaitouzimu": "yjs", "code": "AFP" },
        { "id": "02583", "name": "延吉西", "pinyin": "yanjixi", "kaitouzimu": "yjx", "code": "YXL" },
        { "id": "02584", "name": "燕家庄", "pinyin": "yanjiazhuang", "kaitouzimu": "yjz", "code": "AZK" },
        { "id": "02585", "name": "永康", "pinyin": "yongkang", "kaitouzimu": "yk", "code": "RFH" },
        { "id": "02586", "name": "营口东", "pinyin": "yingkoudong", "kaitouzimu": "ykd", "code": "YGT" },
        { "id": "02587", "name": "永康南", "pinyin": "yongkangnan", "kaitouzimu": "ykn", "code": "QUH" },
        { "id": "02588", "name": "营口", "pinyin": "yingkou", "kaitouzimu": "yk", "code": "YKT" },
        { "id": "02589", "name": "牙克石", "pinyin": "yakeshi", "kaitouzimu": "yks", "code": "YKX" },
        { "id": "02590", "name": "依兰", "pinyin": "yilan", "kaitouzimu": "yl", "code": "YEB" },
        { "id": "02591", "name": "银浪", "pinyin": "yinlang", "kaitouzimu": "yl", "code": "YJX" },
        { "id": "02592", "name": "永郎", "pinyin": "yonglang", "kaitouzimu": "yl", "code": "YLW" },
        { "id": "02593", "name": "宜良北", "pinyin": "yiliangbei", "kaitouzimu": "ylb", "code": "YSM" },
        { "id": "02594", "name": "永乐店", "pinyin": "yongledian", "kaitouzimu": "yld", "code": "YDY" },
        { "id": "02595", "name": "伊拉哈", "pinyin": "yilaha", "kaitouzimu": "ylh", "code": "YLX" },
        { "id": "02596", "name": "运粮河", "pinyin": "yunlianghe", "kaitouzimu": "ylh", "code": "YEF" },
        { "id": "02597", "name": "炎陵", "pinyin": "yanling", "kaitouzimu": "yl", "code": "YAG" },
        { "id": "02598", "name": "阎良", "pinyin": "yanliang", "kaitouzimu": "yl", "code": "YNY" },
        { "id": "02599", "name": "杨林", "pinyin": "yanglin", "kaitouzimu": "yl", "code": "YLM" },
        { "id": "02600", "name": "杨陵", "pinyin": "yangling", "kaitouzimu": "yl", "code": "YSY" },
        { "id": "02601", "name": "伊林", "pinyin": "yilin", "kaitouzimu": "yl", "code": "YLB" },
        { "id": "02602", "name": "彝良", "pinyin": "yiliang", "kaitouzimu": "yl", "code": "ALW" },
        { "id": "02603", "name": "榆林", "pinyin": "yulin", "kaitouzimu": "yl", "code": "ALY" },
        { "id": "02604", "name": "玉林", "pinyin": "yulin", "kaitouzimu": "yl", "code": "YLZ" },
        { "id": "02605", "name": "杨陵南", "pinyin": "yanglingnan", "kaitouzimu": "yln", "code": "YEY" },
        { "id": "02606", "name": "余粮堡", "pinyin": "yuliangpu", "kaitouzimu": "ylb", "code": "YLD" },
        { "id": "02607", "name": "杨柳青", "pinyin": "yangliuqing", "kaitouzimu": "ylq", "code": "YQP" },
        { "id": "02608", "name": "月亮田", "pinyin": "yueliangtian", "kaitouzimu": "ylt", "code": "YUM" },
        { "id": "02609", "name": "亚龙湾", "pinyin": "yalongwan", "kaitouzimu": "ylw", "code": "TWQ" },
        { "id": "02610", "name": "羊马", "pinyin": "yangma", "kaitouzimu": "ym", "code": "YME" },
        { "id": "02611", "name": "义马", "pinyin": "yima", "kaitouzimu": "ym", "code": "YMF" },
        { "id": "02612", "name": "一面坡北", "pinyin": "yimianpobei", "kaitouzimu": "ympb", "code": "YXB" },
        { "id": "02613", "name": "玉门", "pinyin": "yumen", "kaitouzimu": "ym", "code": "YXJ" },
        { "id": "02614", "name": "云梦", "pinyin": "yunmeng", "kaitouzimu": "ym", "code": "YMN" },
        { "id": "02615", "name": "伊敏", "pinyin": "yimin", "kaitouzimu": "ym", "code": "YMX" },
        { "id": "02616", "name": "元谋", "pinyin": "yuanmou", "kaitouzimu": "ym", "code": "YMM" },
        { "id": "02617", "name": "阳明堡", "pinyin": "yangmingbu", "kaitouzimu": "ymp", "code": "YVV" },
        { "id": "02618", "name": "一面坡", "pinyin": "yimianpo", "kaitouzimu": "ymp", "code": "YPB" },
        { "id": "02619", "name": "一面山", "pinyin": "yimianshan", "kaitouzimu": "yms", "code": "YST" },
        { "id": "02620", "name": "沂南", "pinyin": "yinan", "kaitouzimu": "yn", "code": "YNK" },
        { "id": "02621", "name": "宜耐", "pinyin": "yinai", "kaitouzimu": "yn", "code": "YVM" },
        { "id": "02622", "name": "郁南", "pinyin": "yunan", "kaitouzimu": "yn", "code": "YKQ" },
        { "id": "02623", "name": "伊宁东", "pinyin": "yiningdong", "kaitouzimu": "ynd", "code": "YNR" },
        { "id": "02624", "name": "伊宁", "pinyin": "yining", "kaitouzimu": "yn", "code": "YMR" },
        { "id": "02625", "name": "云南驿", "pinyin": "yunnanyi", "kaitouzimu": "yny", "code": "ANM" },
        { "id": "02626", "name": "永城北", "pinyin": "yongchengbei", "kaitouzimu": "ycb", "code": "RGH" },
        { "id": "02627", "name": "阳平关", "pinyin": "yangpingguan", "kaitouzimu": "ypg", "code": "YAY" },
        { "id": "02628", "name": "银瓶", "pinyin": "yinping", "kaitouzimu": "yp", "code": "KPQ" },
        { "id": "02629", "name": "玉屏", "pinyin": "yuping", "kaitouzimu": "yp", "code": "YZW" },
        { "id": "02630", "name": "原平", "pinyin": "yuanping", "kaitouzimu": "yp", "code": "YPV" },
        { "id": "02631", "name": "营盘水", "pinyin": "yingpanshui", "kaitouzimu": "yps", "code": "YZJ" },
        { "id": "02632", "name": "羊堡", "pinyin": "yangpu", "kaitouzimu": "yp", "code": "ABM" },
        { "id": "02633", "name": "延平西", "pinyin": "yanpingxi", "kaitouzimu": "ypx", "code": "YWS" },
        { "id": "02634", "name": "原平西", "pinyin": "yuanpingxi", "kaitouzimu": "ypx", "code": "IPV" },
        { "id": "02635", "name": "阳泉北", "pinyin": "yangquanbei", "kaitouzimu": "yqb", "code": "YPP" },
        { "id": "02636", "name": "乐清", "pinyin": "yueqing", "kaitouzimu": "yq", "code": "UPH" },
        { "id": "02637", "name": "焉耆", "pinyin": "yanqi", "kaitouzimu": "yq", "code": "YSR" },
        { "id": "02638", "name": "延庆", "pinyin": "yanqing", "kaitouzimu": "yq", "code": "YNP" },
        { "id": "02639", "name": "杨桥", "pinyin": "yangqiao", "kaitouzimu": "yq", "code": "YQA" },
        { "id": "02640", "name": "源迁", "pinyin": "yuanqian", "kaitouzimu": "yq", "code": "AQK" },
        { "id": "02641", "name": "阳泉曲", "pinyin": "yangquanqu", "kaitouzimu": "yqq", "code": "YYV" },
        { "id": "02642", "name": "姚千户屯", "pinyin": "yaoqianhutun", "kaitouzimu": "yqht", "code": "YQT" },
        { "id": "02643", "name": "阳曲", "pinyin": "yangqu", "kaitouzimu": "yq", "code": "YQV" },
        { "id": "02644", "name": "阳泉", "pinyin": "yangquan", "kaitouzimu": "yq", "code": "AQP" },
        { "id": "02645", "name": "玉泉", "pinyin": "yuquan", "kaitouzimu": "yq", "code": "YQB" },
        { "id": "02646", "name": "阳曲西", "pinyin": "yangquxi", "kaitouzimu": "yqx", "code": "IQV" },
        { "id": "02647", "name": "榆树沟", "pinyin": "yushugou", "kaitouzimu": "ysg", "code": "YGP" },
        { "id": "02648", "name": "偃师", "pinyin": "yanshi", "kaitouzimu": "ys", "code": "YSF" },
        { "id": "02649", "name": "燕山", "pinyin": "yanshan", "kaitouzimu": "ys", "code": "AOP" },
        { "id": "02650", "name": "阳朔", "pinyin": "yangshuo", "kaitouzimu": "ys", "code": "YCZ" },
        { "id": "02651", "name": "窑上", "pinyin": "yaoshang", "kaitouzimu": "ys", "code": "ASP" },
        { "id": "02652", "name": "沂水", "pinyin": "yishui", "kaitouzimu": "ys", "code": "YUK" },
        { "id": "02653", "name": "营山", "pinyin": "yingshan", "kaitouzimu": "ys", "code": "NUW" },
        { "id": "02654", "name": "永寿", "pinyin": "yongshou", "kaitouzimu": "ys", "code": "ASY" },
        { "id": "02655", "name": "榆社", "pinyin": "yushe", "kaitouzimu": "ys", "code": "YSV" },
        { "id": "02656", "name": "榆树", "pinyin": "yushu", "kaitouzimu": "ys", "code": "YRT" },
        { "id": "02657", "name": "玉山", "pinyin": "yushan", "kaitouzimu": "ys", "code": "YNG" },
        { "id": "02658", "name": "玉舍", "pinyin": "yushe", "kaitouzimu": "ys", "code": "AUM" },
        { "id": "02659", "name": "玉石", "pinyin": "yushi", "kaitouzimu": "ys", "code": "YSJ" },
        { "id": "02660", "name": "元氏", "pinyin": "yuanshi", "kaitouzimu": "ys", "code": "YSP" },
        { "id": "02661", "name": "月山", "pinyin": "yueshan", "kaitouzimu": "ys", "code": "YBF" },
        { "id": "02662", "name": "云山", "pinyin": "yunshan", "kaitouzimu": "ys", "code": "KZQ" },
        { "id": "02663", "name": "杨树岭", "pinyin": "yangshuling", "kaitouzimu": "ysl", "code": "YAD" },
        { "id": "02664", "name": "雁石南", "pinyin": "yanshinan", "kaitouzimu": "ysn", "code": "YMS" },
        { "id": "02665", "name": "玉山南", "pinyin": "yushannan", "kaitouzimu": "ysn", "code": "YGG" },
        { "id": "02666", "name": "野三坡", "pinyin": "yesanpo", "kaitouzimu": "ysp", "code": "AIP" },
        { "id": "02667", "name": "榆树台", "pinyin": "yushutai", "kaitouzimu": "yst", "code": "YUT" },
        { "id": "02668", "name": "榆树屯", "pinyin": "yushutun", "kaitouzimu": "yst", "code": "YSX" },
        { "id": "02669", "name": "鹰手营子", "pinyin": "yingshouyingzi", "kaitouzimu": "ysyz", "code": "YIP" },
        { "id": "02670", "name": "烟台", "pinyin": "yantai", "kaitouzimu": "yt", "code": "YAK" },
        { "id": "02671", "name": "银滩", "pinyin": "yintan", "kaitouzimu": "yt", "code": "CTQ" },
        { "id": "02672", "name": "鹰潭", "pinyin": "yingtan", "kaitouzimu": "yt", "code": "YTG" },
        { "id": "02673", "name": "永泰", "pinyin": "yongtai", "kaitouzimu": "yt", "code": "YTS" },
        { "id": "02674", "name": "源潭", "pinyin": "yuantan", "kaitouzimu": "yt", "code": "YTQ" },
        { "id": "02675", "name": "鹰潭北", "pinyin": "yingtanbei", "kaitouzimu": "ytb", "code": "YKG" },
        { "id": "02676", "name": "伊图里河", "pinyin": "yitulihe", "kaitouzimu": "ytlh", "code": "YEX" },
        { "id": "02677", "name": "烟台南", "pinyin": "yantainan", "kaitouzimu": "ytn", "code": "YLK" },
        { "id": "02678", "name": "伊通", "pinyin": "yitong", "kaitouzimu": "yt", "code": "YTL" },
        { "id": "02679", "name": "牙屯堡", "pinyin": "yatunpu", "kaitouzimu": "ytb", "code": "YTZ" },
        { "id": "02680", "name": "烟筒山", "pinyin": "yantongshan", "kaitouzimu": "yts", "code": "YSL" },
        { "id": "02681", "name": "烟筒屯", "pinyin": "yantongtun", "kaitouzimu": "ytt", "code": "YUX" },
        { "id": "02682", "name": "烟台西", "pinyin": "yantaixi", "kaitouzimu": "ytx", "code": "YTK" },
        { "id": "02683", "name": "玉田县", "pinyin": "yutianxian", "kaitouzimu": "ytx", "code": "ATP" },
        { "id": "02684", "name": "羊尾哨", "pinyin": "yangweishao", "kaitouzimu": "yws", "code": "YWM" },
        { "id": "02685", "name": "义乌", "pinyin": "yiwu", "kaitouzimu": "yw", "code": "YWH" },
        { "id": "02686", "name": "阳西", "pinyin": "yangxi", "kaitouzimu": "yx", "code": "WMQ" },
        { "id": "02687", "name": "阳新", "pinyin": "yangxin", "kaitouzimu": "yx", "code": "YON" },
        { "id": "02688", "name": "阳信", "pinyin": "yangxin", "kaitouzimu": "yx", "code": "YVK" },
        { "id": "02689", "name": "宜兴", "pinyin": "yixing", "kaitouzimu": "yx", "code": "YUH" },
        { "id": "02690", "name": "义县", "pinyin": "yixian", "kaitouzimu": "yx", "code": "YXD" },
        { "id": "02691", "name": "应县", "pinyin": "yingxian", "kaitouzimu": "yx", "code": "YZV" },
        { "id": "02692", "name": "永修", "pinyin": "yongxiu", "kaitouzimu": "yx", "code": "ACG" },
        { "id": "02693", "name": "攸县", "pinyin": "youxian", "kaitouzimu": "yx", "code": "YOG" },
        { "id": "02694", "name": "尤溪", "pinyin": "youxi", "kaitouzimu": "yx", "code": "YXS" },
        { "id": "02695", "name": "玉溪", "pinyin": "yuxi", "kaitouzimu": "yx", "code": "AXM" },
        { "id": "02696", "name": "越西", "pinyin": "yuexi", "kaitouzimu": "yx", "code": "YHW" },
        { "id": "02697", "name": "云霄", "pinyin": "yunxiao", "kaitouzimu": "yx", "code": "YBS" },
        { "id": "02698", "name": "攸县南", "pinyin": "youxiannan", "kaitouzimu": "yxn", "code": "YXG" },
        { "id": "02699", "name": "义县西", "pinyin": "yixianxi", "kaitouzimu": "yxx", "code": "YSD" },
        { "id": "02700", "name": "玉溪西", "pinyin": "yuxixi", "kaitouzimu": "yxx", "code": "YXM" },
        { "id": "02701", "name": "弋阳", "pinyin": "yiyang", "kaitouzimu": "yy", "code": "YIG" },
        { "id": "02702", "name": "益阳", "pinyin": "yiyang", "kaitouzimu": "yy", "code": "AEQ" },
        { "id": "02703", "name": "酉阳", "pinyin": "youyang", "kaitouzimu": "yy", "code": "AFW" },
        { "id": "02704", "name": "余姚", "pinyin": "yuyao", "kaitouzimu": "yy", "code": "YYH" },
        { "id": "02705", "name": "岳阳", "pinyin": "yueyang", "kaitouzimu": "yy", "code": "YYQ" },
        { "id": "02706", "name": "余姚北", "pinyin": "yuyaobei", "kaitouzimu": "yyb", "code": "CTH" },
        { "id": "02707", "name": "岳阳东", "pinyin": "yueyangdong", "kaitouzimu": "yyd", "code": "YIQ" },
        { "id": "02708", "name": "阳邑", "pinyin": "yangyi", "kaitouzimu": "yy", "code": "ARP" },
        { "id": "02709", "name": "鸭园", "pinyin": "yayuan", "kaitouzimu": "yy", "code": "YYL" },
        { "id": "02710", "name": "鸳鸯镇", "pinyin": "yuanyangzhen", "kaitouzimu": "yyz", "code": "YYJ" },
        { "id": "02711", "name": "燕子砭", "pinyin": "yanzibian", "kaitouzimu": "yzb", "code": "YZY" },
        { "id": "02712", "name": "崖州", "pinyin": "yazhou", "kaitouzimu": "yz", "code": "YUQ" },
        { "id": "02713", "name": "兖州", "pinyin": "yanzhou", "kaitouzimu": "yz", "code": "YZK" },
        { "id": "02714", "name": "扬州", "pinyin": "yangzhou", "kaitouzimu": "yz", "code": "YLH" },
        { "id": "02715", "name": "仪征", "pinyin": "yizheng", "kaitouzimu": "yz", "code": "UZH" },
        { "id": "02716", "name": "宜州", "pinyin": "yizhou", "kaitouzimu": "yz", "code": "YSZ" },
        { "id": "02717", "name": "永州", "pinyin": "yongzhou", "kaitouzimu": "yz", "code": "AOQ" },
        { "id": "02718", "name": "榆中", "pinyin": "yuzhong", "kaitouzimu": "yz", "code": "IZJ" },
        { "id": "02719", "name": "迤资", "pinyin": "yizi", "kaitouzimu": "yz", "code": "YQM" },
        { "id": "02720", "name": "羊者窝", "pinyin": "yangzhewo", "kaitouzimu": "wzw", "code": "AEM" },
        { "id": "02721", "name": "杨杖子", "pinyin": "yangzhangzi", "kaitouzimu": "yzz", "code": "YZD" },],
    Z: [
        { "id": "02722", "name": "诏安", "pinyin": "zhaoan", "kaitouzimu": "za", "code": "ZDS" },
        { "id": "02723", "name": "镇安", "pinyin": "zhenan", "kaitouzimu": "za", "code": "ZEY" },
        { "id": "02724", "name": "治安", "pinyin": "zhian", "kaitouzimu": "za", "code": "ZAD" },
        { "id": "02725", "name": "招柏", "pinyin": "zhaobai", "kaitouzimu": "zb", "code": "ZBP" },
        { "id": "02726", "name": "淄博北", "pinyin": "zibobei", "kaitouzimu": "zbb", "code": "ZRK" },
        { "id": "02727", "name": "淄博", "pinyin": "zibo", "kaitouzimu": "zb", "code": "ZBK" },
        { "id": "02728", "name": "张百湾", "pinyin": "zhangbaiwan", "kaitouzimu": "zbw", "code": "ZUP" },
        { "id": "02729", "name": "中川机场", "pinyin": "zhongchuanjichang", "kaitouzimu": "zcjc", "code": "ZJJ" },
        { "id": "02730", "name": "镇城底", "pinyin": "zhenchengdi", "kaitouzimu": "zcd", "code": "ZDV" },
        { "id": "02731", "name": "赵城", "pinyin": "zhaocheng", "kaitouzimu": "zc", "code": "ZCV" },
        { "id": "02732", "name": "枝城", "pinyin": "zhicheng", "kaitouzimu": "zc", "code": "ZCN" },
        { "id": "02733", "name": "诸城", "pinyin": "zhucheng", "kaitouzimu": "zc", "code": "ZQK" },
        { "id": "02734", "name": "子长", "pinyin": "zichang", "kaitouzimu": "zc", "code": "ZHY" },
        { "id": "02735", "name": "邹城", "pinyin": "zoucheng", "kaitouzimu": "zc", "code": "ZIK" },
        { "id": "02736", "name": "章党", "pinyin": "zhangdang", "kaitouzimu": "zd", "code": "ZHT" },
        { "id": "02737", "name": "正定机场", "pinyin": "zhengdingjichang", "kaitouzimu": "zdjc", "code": "ZHP" },
        { "id": "02738", "name": "正定", "pinyin": "zhengding", "kaitouzimu": "zd", "code": "ZDP" },
        { "id": "02739", "name": "肇东", "pinyin": "zhaodong", "kaitouzimu": "zd", "code": "ZDB" },
        { "id": "02740", "name": "纸坊东", "pinyin": "zhifangdong", "kaitouzimu": "zfd", "code": "ZMN" },
        { "id": "02741", "name": "照福铺", "pinyin": "zhaofupu", "kaitouzimu": "zfp", "code": "ZFM" },
        { "id": "02742", "name": "准格尔", "pinyin": "zhungeer", "kaitouzimu": "zge", "code": "ZEC" },
        { "id": "02743", "name": "自贡", "pinyin": "zigong", "kaitouzimu": "zg", "code": "ZGW" },
        { "id": "02744", "name": "章古台", "pinyin": "zhanggutai", "kaitouzimu": "zgt", "code": "ZGD" },
        { "id": "02745", "name": "赵光", "pinyin": "zhaoguang", "kaitouzimu": "zg", "code": "ZGB" },
        { "id": "02746", "name": "珠海", "pinyin": "zhuhai", "kaitouzimu": "zh", "code": "ZHQ" },
        { "id": "02747", "name": "漳县", "pinyin": "zhangxian", "kaitouzimu": "zx", "code": "ZXJ" },
        { "id": "02748", "name": "昭山", "pinyin": "zhaoshan", "kaitouzimu": "zs", "code": "KWQ" },
        { "id": "02749", "name": "珠海北", "pinyin": "zhuhaibei", "kaitouzimu": "zhb", "code": "ZIQ" },
        { "id": "02750", "name": "庄河北", "pinyin": "zhuanghebei", "kaitouzimu": "zhb", "code": "ZUT" },
        { "id": "02751", "name": "中和", "pinyin": "zhonghe", "kaitouzimu": "zh", "code": "ZHX" },
        { "id": "02752", "name": "中华门", "pinyin": "zhonghuamen", "kaitouzimu": "zhm", "code": "VNH" },
        { "id": "02753", "name": "昭化", "pinyin": "zhaohua", "kaitouzimu": "zhu", "code": "ZHW" },
        { "id": "02754", "name": "珠斯花", "pinyin": "zhusihua", "kaitouzimu": "zsh", "code": "ZHD" },
        { "id": "02755", "name": "枝江北", "pinyin": "zhijiangbei", "kaitouzimu": "zjb", "code": "ZIN" },
        { "id": "02756", "name": "织金北", "pinyin": "zhijinbei", "kaitouzimu": "zjb", "code": "ZJE" },
        { "id": "02757", "name": "张家川", "pinyin": "zhangjiachuan", "kaitouzimu": "zjc", "code": "ZIJ" },
        { "id": "02758", "name": "钟家村", "pinyin": "zhongjiacun", "kaitouzimu": "zjc", "code": "ZJY" },
        { "id": "02759", "name": "朱家沟", "pinyin": "zhujiagou", "kaitouzimu": "zjg", "code": "ZUB" },
        { "id": "02760", "name": "紫荆关", "pinyin": "zijingguan", "kaitouzimu": "zjg", "code": "ZYP" },
        { "id": "02761", "name": "湛江", "pinyin": "zhanjiang", "kaitouzimu": "zj", "code": "ZJZ" },
        { "id": "02762", "name": "镇江", "pinyin": "zhenjiang", "kaitouzimu": "zj", "code": "ZJH" },
        { "id": "02763", "name": "织金", "pinyin": "zhijin", "kaitouzimu": "zj", "code": "IZW" },
        { "id": "02764", "name": "芷江", "pinyin": "zhijiang", "kaitouzimu": "zj", "code": "ZPQ" },
        { "id": "02765", "name": "周家", "pinyin": "zhoujia", "kaitouzimu": "zj", "code": "ZOB" },
        { "id": "02766", "name": "诸暨", "pinyin": "zhuji", "kaitouzimu": "zj", "code": "ZDH" },
        { "id": "02767", "name": "张家界", "pinyin": "zhangjiajie", "kaitouzimu": "zjj", "code": "DIQ" },
        { "id": "02768", "name": "张家口", "pinyin": "zhangjiakou", "kaitouzimu": "zjk", "code": "ZKP" },
        { "id": "02769", "name": "张家口南", "pinyin": "zhangjiakounan", "kaitouzimu": "zjkn", "code": "ZMP" },
        { "id": "02770", "name": "镇江南", "pinyin": "zhenjiangnan", "kaitouzimu": "zjn", "code": "ZEH" },
        { "id": "02771", "name": "周家屯", "pinyin": "zhoujiatun", "kaitouzimu": "zjt", "code": "ZOD" },
        { "id": "02772", "name": "褚家湾", "pinyin": "zhujiawan", "kaitouzimu": "cjw", "code": "CWJ" },
        { "id": "02773", "name": "湛江西", "pinyin": "zhanjiangxi", "kaitouzimu": "zjx", "code": "ZWQ" },
        { "id": "02774", "name": "朱家窑", "pinyin": "zhujiayao", "kaitouzimu": "zjy", "code": "ZUJ" },
        { "id": "02775", "name": "曾家坪子", "pinyin": "zengjiapingzi", "kaitouzimu": "zjpz", "code": "ZBW" },
        { "id": "02776", "name": "仲恺", "pinyin": "zhongkai", "kaitouzimu": "zk", "code": "KKQ" },
        { "id": "02777", "name": "曾口", "pinyin": "zengkou", "kaitouzimu": "zk", "code": "ZKE" },
        { "id": "02778", "name": "周口", "pinyin": "zhoukou", "kaitouzimu": "zk", "code": "ZKN" },
        { "id": "02779", "name": "张兰", "pinyin": "zhanglan", "kaitouzimu": "zla", "code": "ZLV" },
        { "id": "02780", "name": "镇赉", "pinyin": "zhenlai", "kaitouzimu": "zl", "code": "ZLT" },
        { "id": "02781", "name": "枣林", "pinyin": "zaolin", "kaitouzimu": "zl", "code": "ZIV" },
        { "id": "02782", "name": "珠琳", "pinyin": "zhulin", "kaitouzimu": "zl", "code": "ZOM" },
        { "id": "02783", "name": "左岭", "pinyin": "zuoling", "kaitouzimu": "zl", "code": "ZSN" },
        { "id": "02784", "name": "哲里木", "pinyin": "zhelimu", "kaitouzimu": "zlm", "code": "ZLC" },
        { "id": "02785", "name": "扎兰屯", "pinyin": "zhalantun", "kaitouzimu": "zlt", "code": "ZTX" },
        { "id": "02786", "name": "扎鲁特", "pinyin": "zhalute", "kaitouzimu": "zlt", "code": "ZLD" },
        { "id": "02787", "name": "扎赉诺尔西", "pinyin": "zhalainuoerxi", "kaitouzimu": "zlnex", "code": "ZXX" },
        { "id": "02788", "name": "樟木头东", "pinyin": "zhangmutoudong", "kaitouzimu": "zmtd", "code": "ZRQ" },
        { "id": "02789", "name": "驻马店", "pinyin": "zhumadian", "kaitouzimu": "zmd", "code": "ZDN" },
        { "id": "02790", "name": "樟木头", "pinyin": "zhangmutou", "kaitouzimu": "zmt", "code": "ZOQ" },
        { "id": "02791", "name": "中牟", "pinyin": "zhongmu", "kaitouzimu": "zm", "code": "ZGF" },
        { "id": "02792", "name": "驻马店西", "pinyin": "zhumadianxi", "kaitouzimu": "zmdx", "code": "ZLN" },
        { "id": "02793", "name": "中宁东", "pinyin": "zhongningdong", "kaitouzimu": "znd", "code": "ZDJ" },
        { "id": "02794", "name": "中宁", "pinyin": "zhongning", "kaitouzimu": "zn", "code": "VNJ" },
        { "id": "02795", "name": "中宁南", "pinyin": "zhongningnan", "kaitouzimu": "znn", "code": "ZNJ" },
        { "id": "02796", "name": "漳平", "pinyin": "zhangping", "kaitouzimu": "zp", "code": "ZPS" },
        { "id": "02797", "name": "镇平", "pinyin": "zhenping", "kaitouzimu": "zp", "code": "ZPF" },
        { "id": "02798", "name": "邹平", "pinyin": "zouping", "kaitouzimu": "zp", "code": "ZLK" },
        { "id": "02799", "name": "泽普", "pinyin": "zepu", "kaitouzimu": "zp", "code": "ZPR" },
        { "id": "02800", "name": "漳浦", "pinyin": "zhangpu", "kaitouzimu": "zp", "code": "ZCS" },
        { "id": "02801", "name": "漳平西", "pinyin": "zhangpingxi", "kaitouzimu": "zpx", "code": "ZXG" },
        { "id": "02802", "name": "章丘北", "pinyin": "zhangqiubei", "kaitouzimu": "zqb", "code": "ZVK" },
        { "id": "02803", "name": "肇庆东", "pinyin": "zhaoqingdong", "kaitouzimu": "zqd", "code": "FCQ" },
        { "id": "02804", "name": "枣强", "pinyin": "zaoqiang", "kaitouzimu": "zq", "code": "ZVP" },
        { "id": "02805", "name": "张桥", "pinyin": "zhangqiao", "kaitouzimu": "zq", "code": "ZQY" },
        { "id": "02806", "name": "章丘", "pinyin": "zhangqiu", "kaitouzimu": "zq", "code": "ZTK" },
        { "id": "02807", "name": "肇庆", "pinyin": "zhaoqing", "kaitouzimu": "zq", "code": "ZVQ" },
        { "id": "02808", "name": "庄桥", "pinyin": "zhuangqiao", "kaitouzimu": "zq", "code": "ZQH" },
        { "id": "02809", "name": "朱日和", "pinyin": "zhurihe", "kaitouzimu": "zrh", "code": "ZRC" },
        { "id": "02810", "name": "泽润里", "pinyin": "zerunli", "kaitouzimu": "zrl", "code": "ZLM" },
        { "id": "02811", "name": "中山北", "pinyin": "zhongshanbei", "kaitouzimu": "zsb", "code": "ZGQ" },
        { "id": "02812", "name": "樟树东", "pinyin": "zhangshudong", "kaitouzimu": "zsd", "code": "ZOG" },
        { "id": "02813", "name": "柞水", "pinyin": "zhashui", "kaitouzimu": "zs", "code": "ZSY" },
        { "id": "02814", "name": "樟树", "pinyin": "zhangshu", "kaitouzimu": "zs", "code": "ZSG" },
        { "id": "02815", "name": "中山", "pinyin": "zhongshan", "kaitouzimu": "zs", "code": "ZSQ" },
        { "id": "02816", "name": "钟山", "pinyin": "zhongshan", "kaitouzimu": "zs", "code": "ZSZ" },
        { "id": "02817", "name": "钟山西", "pinyin": "zhongshanxi", "kaitouzimu": "zsx", "code": "ZAZ" },
        { "id": "02818", "name": "周水子", "pinyin": "zhoushuizi", "kaitouzimu": "zsz", "code": "ZIT" },
        { "id": "02819", "name": "朱砂古镇", "pinyin": "zhushaguzhen", "kaitouzimu": "zsgz", "code": "ZSE" },
        { "id": "02820", "name": "昭通", "pinyin": "zhaotong", "kaitouzimu": "zt", "code": "ZDW" },
        { "id": "02821", "name": "遵义南", "pinyin": "zunyinan", "kaitouzimu": "zyn", "code": "ZNE" },
        { "id": "02822", "name": "遵义西", "pinyin": "zunyixi", "kaitouzimu": "zyx", "code": "ZIW" },
        { "id": "02823", "name": "中卫", "pinyin": "zhongwei", "kaitouzimu": "zw", "code": "ZWJ" },
        { "id": "02824", "name": "珠窝", "pinyin": "zhuwo", "kaitouzimu": "zw", "code": "ZOP" },
        { "id": "02825", "name": "张维屯", "pinyin": "zhangweitun", "kaitouzimu": "zwt", "code": "ZWB" },
        { "id": "02826", "name": "彰武", "pinyin": "zhangwu", "kaitouzimu": "zw", "code": "ZWD" },
        { "id": "02827", "name": "张辛", "pinyin": "zhangxin", "kaitouzimu": "zx", "code": "ZIP" },
        { "id": "02828", "name": "镇西", "pinyin": "zhenxi", "kaitouzimu": "zx", "code": "ZVT" },
        { "id": "02829", "name": "钟祥", "pinyin": "zhongxiang", "kaitouzimu": "zx", "code": "ZTN" },
        { "id": "02830", "name": "资溪", "pinyin": "zixi", "kaitouzimu": "zx", "code": "ZXS" },
        { "id": "02831", "name": "棕溪", "pinyin": "zongxi", "kaitouzimu": "zx", "code": "ZOY" },
        { "id": "02832", "name": "正镶白旗", "pinyin": "zhengxiangbaiqi", "kaitouzimu": "zxbq", "code": "ZXC" },
        { "id": "02833", "name": "枣阳", "pinyin": "zaoyang", "kaitouzimu": "zy", "code": "ZYN" },
        { "id": "02834", "name": "资阳", "pinyin": "ziyang", "kaitouzimu": "zy", "code": "ZYW" },
        { "id": "02835", "name": "紫阳", "pinyin": "ziyang", "kaitouzimu": "zy", "code": "ZVY" },
        { "id": "02836", "name": "竹园坝", "pinyin": "zhuyuanba", "kaitouzimu": "zyb", "code": "ZAW" },
        { "id": "02837", "name": "资阳北", "pinyin": "ziyangbei", "kaitouzimu": "zyb", "code": "FYW" },
        { "id": "02838", "name": "张掖", "pinyin": "zhangye", "kaitouzimu": "zy", "code": "ZYJ" },
        { "id": "02839", "name": "遵义", "pinyin": "zunyi", "kaitouzimu": "zy", "code": "ZIW" },
        { "id": "02840", "name": "镇远", "pinyin": "zhenyuan", "kaitouzimu": "zy", "code": "ZUW" },
        { "id": "02841", "name": "张掖西", "pinyin": "zhangyexi", "kaitouzimu": "zyx", "code": "ZEJ" },
        { "id": "02842", "name": "朱杨溪", "pinyin": "zhuyangxi", "kaitouzimu": "zyx", "code": "ZXW" },
        { "id": "02843", "name": "资中北", "pinyin": "zizhongbei", "kaitouzimu": "zzb", "code": "WZW" },
        { "id": "02844", "name": "枣庄东", "pinyin": "zaozhuangdong", "kaitouzimu": "zzd", "code": "ZNK" },
        { "id": "02845", "name": "漳州东", "pinyin": "zhangzhoudong", "kaitouzimu": "zzd", "code": "GOS" },
        { "id": "02846", "name": "郑州东", "pinyin": "zhengzhoudong", "kaitouzimu": "zzd", "code": "ZAF" },
        { "id": "02847", "name": "涿州东", "pinyin": "zhuozhoudong", "kaitouzimu": "zzd", "code": "ZAP" },
        { "id": "02848", "name": "卓资东", "pinyin": "zhuozidong", "kaitouzimu": "zzd", "code": "ZDC" },
        { "id": "02849", "name": "枣庄", "pinyin": "zaozhuang", "kaitouzimu": "zz", "code": "ZEK" },
        { "id": "02850", "name": "漳州", "pinyin": "zhangzhou", "kaitouzimu": "zz", "code": "ZUS" },
        { "id": "02851", "name": "郑州", "pinyin": "zhengzhou", "kaitouzimu": "zz", "code": "ZZF" },
        { "id": "02852", "name": "中寨", "pinyin": "zhongzhai", "kaitouzimu": "zz", "code": "ZZM" },
        { "id": "02853", "name": "株洲", "pinyin": "zhuzhou", "kaitouzimu": "zz", "code": "ZZQ" },
        { "id": "02854", "name": "壮志", "pinyin": "zhuangzhi", "kaitouzimu": "zz", "code": "ZUX" },
        { "id": "02855", "name": "涿州", "pinyin": "zhuozhou", "kaitouzimu": "zz", "code": "ZXP" },
        { "id": "02856", "name": "资中", "pinyin": "zizhong", "kaitouzimu": "zz", "code": "ZZW" },
        { "id": "02857", "name": "子洲", "pinyin": "zizhou", "kaitouzimu": "zz", "code": "ZZY" },
        { "id": "02858", "name": "咋子", "pinyin": "zhazi", "kaitouzimu": "zz", "code": "ZAL" },
        { "id": "02859", "name": "株洲南", "pinyin": "zhuzhounan", "kaitouzimu": "zzn", "code": "KVQ" },
        { "id": "02860", "name": "卓资山", "pinyin": "zhuozishan", "kaitouzimu": "zzs", "code": "ZZC" },
        { "id": "02861", "name": "枣庄西", "pinyin": "zaozhuangxi", "kaitouzimu": "zzx", "code": "ZFK" },
        { "id": "02862", "name": "郑州西", "pinyin": "zhengzhouxi", "kaitouzimu": "zzx", "code": "XPF" },
        { "id": "02863", "name": "株洲西", "pinyin": "zhuzhouxi", "kaitouzimu": "zzx", "code": "ZAQ" }]
};


export default CHINA_STATION_LIST
